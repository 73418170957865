import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import type {
  CRMBoosterFilters,
  CRMBoosterFilterSetters
} from '../CRMBoosterDetails';
import { CRMBoosterDetailsFiltersActions } from './CRMBoosterDetailsFiltersActions';
import { CRMBoosterDetailsIndustryTab } from './CRMBoosterDetailsIndustryTab';
import { CRMBoosterDetailsMachinesTab } from './CRMBoosterDetailsMachinesTab';
import { CRMBoosterDetailsMaterialsTab } from './CRMBoosterDetailsMaterialsTab';
import { CRMBoosterDetailsOverviewTab } from './CRMBoosterDetailsOverviewTab';
import { CRMBoosterDetailsTechnologiesTab } from './CRMBoosterDetailsTechnologiesTab';

import styles from './styles.module.scss';

enum FilterTabs {
  Overview = 'Overview',
  Industry = 'Industry',
  Technologies = 'Technologies',
  Materials = 'Materials',
  Machines = 'Machines'
}

interface Props {
  className?: string;
  defaultFilters: CRMBoosterFilters;
  updatedFilters: CRMBoosterFilters;
  filterSetters: CRMBoosterFilterSetters;
}

export const CRMBoosterDetailsFilters: FC<Props> = ({
  className,
  defaultFilters,
  updatedFilters,
  filterSetters
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(FilterTabs.Overview);
  const {
    selectedRegion,
    selectedMachine,
    selectedIndustry,
    selectedTechnology,
    selectedMaterial,
    selectedMachinesRange,
    selectedEmployeesRange
  } = updatedFilters;
  const {
    recalculateFilters,
    setSelectedRegion,
    setSelectedMachine,
    setSelectedIndustry,
    setSelectedMaterial,
    setSelectedTechnology,
    resetToDefaultFilters,
    setSelectedMachinesRange,
    setSelectedEmployeesRange
  } = filterSetters;

  const handleTabClick = (tab: FilterTabs) => () => {
    setActiveTab(tab);
  };

  const renderTabs = () => (
    <div className={styles.content}>
      <CRMBoosterDetailsOverviewTab
        isActive={activeTab === FilterTabs.Overview}
        defaultFilters={defaultFilters}
        selectedRegion={selectedRegion}
        setSelectedRegion={setSelectedRegion}
        selectedEmployeesRange={selectedEmployeesRange}
        setSelectedEmployeesRange={setSelectedEmployeesRange}
      />

      <CRMBoosterDetailsIndustryTab
        isActive={activeTab === FilterTabs.Industry}
        selectedIndustry={selectedIndustry}
        setSelectedIndustry={setSelectedIndustry}
      />

      <CRMBoosterDetailsTechnologiesTab
        isActive={activeTab === FilterTabs.Technologies}
        selectedTechnology={selectedTechnology}
        setSelectedTechnology={setSelectedTechnology}
      />

      <CRMBoosterDetailsMaterialsTab
        isActive={activeTab === FilterTabs.Materials}
        selectedMaterial={selectedMaterial}
        setSelectedMaterial={setSelectedMaterial}
      />

      <CRMBoosterDetailsMachinesTab
        isActive={activeTab === FilterTabs.Machines}
        defaultFilters={defaultFilters}
        selectedMachine={selectedMachine}
        setSelectedMachine={setSelectedMachine}
        selectedMachinesRange={selectedMachinesRange}
        setSelectedMachinesRange={setSelectedMachinesRange}
      />
    </div>
  );

  return (
    <aside className={cn(styles.container, className)}>
      <div className={styles.tabs}>
        {Object.values(FilterTabs).map((tab) => (
          <div
            key={tab}
            data-tab={tab}
            onClick={handleTabClick(tab)}
            className={cn(styles.tab, activeTab === tab && styles.active)}
          >
            {t(`Page.CRMBoosterDetails.Tabs.${tab}.Title`)}
          </div>
        ))}
      </div>

      {renderTabs()}

      <CRMBoosterDetailsFiltersActions
        defaultFilters={defaultFilters}
        updatedFilters={updatedFilters}
        recalculateFilters={recalculateFilters}
        resetToDefaultFilters={resetToDefaultFilters}
      />
    </aside>
  );
};
