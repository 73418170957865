import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { trackActivity } from 'actions/userActions';
import cn from 'classnames';
import { FeedbackModal, RequestReportModal, Tooltip } from 'components';
import { Message } from 'entities/Message.entity';
import { FeedbackTypes } from 'enums';
import { MessageTypes } from 'enums/MessageTypes.enum';
import {
  useCRMBoosterAvailableRows,
  useRateChatMessage,
  useUserInfo
} from 'hooks/api';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import { RequestCRMBoosterModal } from '../../RequestCRMBoosterModal';
import { ChatMessageActions } from '../ChatMessageActions';
import { ChatStatement } from '../ChatStatement';
import { LimitedCompanyListNote } from '../LimitedCompanyListNote';

import styles from './styles.module.scss';

interface Props {
  data: Message;
  isLoading?: boolean;
  isLastAnswer?: boolean;
  question?: string | null;
  isMessageSending?: boolean;
  isRateButtonHidden?: boolean;
  isDashboardButtonHidden?: boolean;
  isRegenerateMessageSending?: boolean;
  onSuggestionClick?: (text: string) => void;
  regenerateChatMessage?: (messageId: string) => void;
  onShowDashboardClick?: (data: {
    dashboardId: string;
    isPaid: boolean;
    rootIndustries?: string[] | null;
  }) => void;
  onActivateAccountClick?: () => void;
}

export const ChatMessage: FC<Props> = ({
  data: {
    id,
    type,
    statement,
    hasFeedback,
    isPaid,
    isNotFull,
    messageId,
    suggestions,
    isInterrupted,
    dashboardId,
    rootIndustries,
    isCompanyListLimited,
    activateAccountVisible = false
  },
  question,
  isLoading,
  isMessageSending,
  isLastAnswer,
  isRegenerateMessageSending,
  onShowDashboardClick,
  regenerateChatMessage,
  isDashboardButtonHidden,
  isRateButtonHidden,
  onSuggestionClick,
  onActivateAccountClick
}) => {
  const messageContainerRef = useRef<HTMLDivElement | null>(null);
  const { chatId } = useParams();
  const { t } = useTranslation();
  const { data: availableRows } = useCRMBoosterAvailableRows(dashboardId);
  const { data: userInfo } = useUserInfo();
  const [isReportModalOpen, setReportModalOpen] = useState(false);
  const [isCRMBoosterModalOpen, setCRMBoosterModalOpen] = useState(false);
  const [givenFeedback, setGivenFeedback] = useState<FeedbackTypes | null>(
    null
  );

  const {
    mutate: rateChatMessage,
    isPending: isRatePending,
    isSuccess: isRateSuccess
  } = useRateChatMessage(chatId || '', messageId || '');

  const hasError = id?.includes('error');

  const handleCloseReportModal = () => {
    mixpanel?.track(MixpanelEvents.CloseRequestReportModal, {
      Place: 'Chat',
      'Chat ID': chatId,
      'Message ID': messageId
    });

    setReportModalOpen(false);
  };

  const handleOpenReportModal = () => {
    mixpanel?.track(MixpanelEvents.OpenRequestReportModal, {
      Place: 'Chat',
      'Chat ID': chatId,
      'Message ID': messageId
    });

    setReportModalOpen(true);
  };

  const handleCloseCRMBoosterModal = () => {
    mixpanel?.track(MixpanelEvents.CloseRequestCRMBoosterModal, {
      Place: 'Chat',
      'Chat ID': chatId,
      'Message ID': messageId
    });

    setCRMBoosterModalOpen(false);
  };

  const handleOpenCRMBoosterModal = () => {
    mixpanel?.track(MixpanelEvents.OpenRequestCRMBoosterModal, {
      Place: 'Chat',
      'Chat ID': chatId,
      'Message ID': messageId
    });

    setCRMBoosterModalOpen(true);
  };

  const handleOpenFeedbackModal = (feedback: FeedbackTypes | null) => {
    if (
      !hasFeedback &&
      !isMessageSending &&
      !isRegenerateMessageSending &&
      !isRateSuccess &&
      !isRatePending
    ) {
      setGivenFeedback(feedback);
    }
  };

  const closeFeedbackModal = () => {
    if (!hasFeedback) {
      setGivenFeedback(null);
    }
  };

  const submitFeedback = (feedback: FeedbackTypes, feedbackText?: string) => {
    mixpanel?.track(MixpanelEvents.AnswerFeedback, {
      Feedback: feedback,
      'Feedback Text': feedbackText,
      'Chat ID': chatId,
      'Message ID': messageId,
      Text: statement
    });
    rateChatMessage({ text: feedbackText, feedbackType: feedback });
  };

  const handleShowDashboardClick = () => {
    if (onShowDashboardClick && dashboardId) {
      if (!isPaid) {
        trackActivity();
      }

      onShowDashboardClick({ dashboardId, isPaid: !!isPaid, rootIndustries });
    }
  };

  const handleRegenerateMessage = (messageId?: string) => () => {
    if (regenerateChatMessage && messageId) {
      regenerateChatMessage(messageId);
    }
  };

  const hasFewCompanies = type === MessageTypes.answer && isCompanyListLimited;

  const handleSuggestionClick = (suggestion: string) => () => {
    if (isLoading || !onSuggestionClick || userInfo?.deactivatedAt) return;

    onSuggestionClick(suggestion);
  };

  return (
    <div
      className={cn(
        styles['message-wrapper'],
        hasFewCompanies && styles['companies-list-limited']
      )}
    >
      <RequestReportModal
        question={question || ''}
        isOpen={isReportModalOpen}
        onClose={handleCloseReportModal}
        industry={rootIndustries?.join(', ')}
      />
      <RequestCRMBoosterModal
        question={question || ''}
        dashboardId={dashboardId || ''}
        isOpen={isCRMBoosterModalOpen}
        availableRows={availableRows || 0}
        onClose={handleCloseCRMBoosterModal}
        industry={rootIndustries?.join(', ')}
      />
      <div
        ref={messageContainerRef}
        data-testid="chat-message"
        className={cn(styles.message, styles[type])}
      >
        {!!givenFeedback && (
          <FeedbackModal
            feedback={givenFeedback}
            onClose={closeFeedbackModal}
            onSubmitFeedback={submitFeedback}
          />
        )}
        <ChatStatement
          data={{ type, statement }}
          showCaret={isLastAnswer && isLoading}
        >
          <>
            {isInterrupted &&
              (type === MessageTypes.answer ||
                type === MessageTypes.followup_questions) && (
                <span className={styles['interrupted-note']}>
                  {t('Page.Chat.InterruptedNote')}
                </span>
              )}

            {!!suggestions?.length && (
              <div className={styles.suggestions}>
                {suggestions.map((suggestion) => (
                  <Tooltip
                    key={suggestion}
                    title={t('Page.Chat.DeactivatedAccountTooltip')}
                    disabled={!userInfo?.deactivatedAt}
                  >
                    <div
                      onClick={handleSuggestionClick(suggestion)}
                      className={cn(
                        styles.suggestion,
                        userInfo?.deactivatedAt && styles.disabled
                      )}
                    >
                      {suggestion}
                    </div>
                  </Tooltip>
                ))}
              </div>
            )}

            <ChatMessageActions
              messageId={messageId}
              dashboardId={dashboardId}
              messageType={type}
              isLastAnswer={isLastAnswer}
              isRatePending={isRatePending}
              isRateSuccess={isRateSuccess}
              hasSuggestions={!!suggestions?.length}
              isAnswerNotFull={isNotFull}
              isAnswerHasError={hasError}
              isDashboardPaid={!!isPaid}
              isMessageSending={isMessageSending}
              isAnswerHasFeedback={hasFeedback}
              hasAvailableRows={!!availableRows}
              isRateButtonHidden={isRateButtonHidden}
              isActivateAccountVisible={activateAccountVisible}
              isDashboardButtonHidden={isDashboardButtonHidden}
              isRegenerateMessageSending={isRegenerateMessageSending}
              onShowDashboard={handleShowDashboardClick}
              onOpenFeedbackModal={handleOpenFeedbackModal}
              onRegenerateMessage={handleRegenerateMessage}
              onGenerateReport={handleOpenReportModal}
              onRequestCRMBooster={handleOpenCRMBoosterModal}
              onActivateAccountClick={onActivateAccountClick}
            />
          </>
        </ChatStatement>
      </div>

      {hasFewCompanies && <LimitedCompanyListNote />}
    </div>
  );
};
