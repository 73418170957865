import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from 'assets/InfoIcon.svg';
import cn from 'classnames';
import { Pie } from 'components/Pie';
import type { SelectedFilterItem } from 'types';

import { mockedTechnologiesData } from './mocks';

import styles from './styles.module.scss';

const chartMeasurements = {
  activeSector: {
    innerRadiusIndentation: 42,
    outerRadiusIndentation: 48
  },
  full: {
    textY: 10,
    startAngle: -360,
    cy: '50%',
    innerRadius: 150,
    outerRadius: 190
  },
  half: {
    textY: -10,
    startAngle: 180,
    cy: '70%',
    innerRadius: 75,
    outerRadius: 105
  }
};

interface Props {
  isActive: boolean;
  selectedTechnology: SelectedFilterItem;
  setSelectedTechnology: Dispatch<SetStateAction<SelectedFilterItem>>;
}

export const CRMBoosterDetailsTechnologiesTab: FC<Props> = ({
  isActive,
  selectedTechnology,
  setSelectedTechnology
}) => {
  const [activeIndex, setActiveIndex] = useState<number>();

  const { t } = useTranslation();

  return (
    <div className={cn(styles.container, isActive && styles.active)}>
      <h2 className={styles.title}>
        {t('Page.CRMBoosterDetails.Tabs.Technologies.Title')}
      </h2>

      <div className={styles.note}>
        <InfoIcon />

        {selectedTechnology ? (
          <Trans
            i18nKey="Page.CRMBoosterDetails.Tabs.Technologies.Filter.Chart.Notes.Active"
            values={{ technology: selectedTechnology?.name }}
          >
            Click Recalculate to apply the
            <span className={styles.highlighted}>
              {selectedTechnology?.name}
            </span>
            filter.
          </Trans>
        ) : (
          t(
            'Page.CRMBoosterDetails.Tabs.Technologies.Filter.Chart.Notes.Inactive'
          )
        )}
      </div>

      <Pie
        fullWidth
        activeIndex={activeIndex}
        chartClassname={styles.chart}
        data={mockedTechnologiesData}
        selectedItem={selectedTechnology}
        changeActiveIndex={setActiveIndex}
        chartMeasurements={chartMeasurements}
        setSelectedItem={setSelectedTechnology}
      />
    </div>
  );
};
