import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { ReactComponent as CongratsIcon } from 'assets/congrats.svg';
import { Modal, Title } from 'components';
import { Routes } from 'enums';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import styles from './styles.module.scss';

interface Props {
  place: string;
  isOpen: boolean;
  onClose: () => void;
}
export const RequestCRMBoosterSuccessModal: FC<Props> = ({
  place,
  isOpen,
  onClose
}) => {
  const { chatId, dashboardId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onClick = () => {
    mixpanel?.track(MixpanelEvents.SuccessfulRequestCRMBoosterCTAClick, {
      Place: place,
      'Chat ID': chatId,
      'Dashboard ID': dashboardId || '-'
    });
    onClose();
    navigate(Routes.NewChat);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} contentClassName={styles.modal}>
      <CongratsIcon className={styles.icon} />
      <Title
        className={styles.title}
        title={t('Page.RequestCRMBoosterModal.Confirm.Title')}
      />
      <p className={styles.subtitle}>
        {t('Page.RequestCRMBoosterModal.Confirm.Subtitle')}
      </p>

      <div className={styles.actions}>
        <Button
          size="medium"
          color="primary"
          onClick={onClick}
          variant="contained"
        >
          {t('Common.GoChatPage')}
        </Button>
      </div>
    </Modal>
  );
};
