import { FC } from 'react';
import cn from 'classnames';
import { shortifyNumber } from 'utils/numberUtils';

import styles from './styles.module.scss';

interface Props {
  title?: string;
  className?: string;
  value?: number;
}

export const BigNumberCard: FC<Props> = ({ title, value, className }) => (
  <div className={cn(styles.container, className)}>
    <div className={styles.subtitle}>{title || '-'}</div>
    <div className={styles.title}>{shortifyNumber(value || 0)}</div>
  </div>
);
