import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import cn from 'classnames';
import { CommonTooltip, Tooltip, WidgetEmptyState } from 'components';
import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardParams } from 'entities/DashboardParams.entity';
import { queryKeys } from 'enums';
import { Routes } from 'enums/Routes';
import { useExternalDashboardLink, useIsExternalUserDashboard } from 'hooks';
import { useUserInfo } from 'hooks/api';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import { EditableRow } from './EditableRow';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  data?: DashboardParams;
}

export const DashboardHistory: FC<Props> = ({ data, className }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dashboardId, chatId, messageId } = useParams();
  const isExternalUserDashboard = useIsExternalUserDashboard();
  const { data: userInfo } = useUserInfo();

  const { data: dashboardData } = useQuery<DashboardData>({
    queryKey: queryKeys.dashboardDetails(dashboardId! || messageId!)
  });

  const nestedDashboards = useMemo(() => [...(data?.data || [])], [data?.data]);

  const externalDashboardLink = useExternalDashboardLink();

  const goToResearchPage = () => {
    navigate(Routes.NewChat);

    mixpanel?.track(MixpanelEvents.NewChatClick, {
      'Dashboard ID': dashboardId,
      // eslint-disable-next-line no-restricted-globals
      'Dashboard link': externalDashboardLink,
      'Default Industries': dashboardData?.rootIndustries?.join(', ') || '-',
      'Nested Industries': dashboardData?.nestedIndustries?.join(', ') || '-'
    });
  };

  if (!nestedDashboards.length) {
    return (
      <div className={styles['empty-state']}>
        {t('Page.Dashboard.DashboardHistory.NoMessages')}
      </div>
    );
  }

  const parentDashboard = (data?.data || []).find(
    ({ id }) => id === dashboardData?.originDashboardId
  );

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.header}>
        {parentDashboard?.id && !parentDashboard?.title ? (
          <Skeleton variant="text" className={styles.skeleton} />
        ) : (
          <EditableRow
            id={parentDashboard?.id || ''}
            title={
              parentDashboard?.title ||
              t('Page.Dashboard.ZoomableSunburst.Title')
            }
          >
            <CommonTooltip
              title={
                parentDashboard?.title ||
                t('Page.Dashboard.ZoomableSunburst.Title')
              }
              className={cn(styles.title, 'overflowed-text')}
            />
          </EditableRow>
        )}
      </div>
      {nestedDashboards?.length ? (
        <div className={styles.dashboards}>
          {nestedDashboards.map((nestedDashboard) => (
            <div className={styles.message} key={nestedDashboard.title}>
              {nestedDashboard?.id && !nestedDashboard?.title ? (
                <Skeleton variant="text" className={styles.skeleton} />
              ) : (
                <EditableRow
                  id={nestedDashboard.id || ''}
                  inputClassName={styles['nested-input']}
                  title={
                    nestedDashboard.title ||
                    t('Page.Dashboard.ZoomableSunburst.Title')
                  }
                >
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                  <p
                    onClick={() => {
                      const route = isExternalUserDashboard
                        ? Routes.ExternalUserDashboard
                        : Routes.Dashboard;

                      navigate(
                        route
                          .replace(':dashboardId', nestedDashboard.id || '')
                          .replace(':chatId', chatId || '')
                      );
                    }}
                    className={cn(
                      styles['message-title'],
                      nestedDashboard.id === dashboardData?.id && styles.active
                    )}
                  >
                    {nestedDashboard.title}
                  </p>
                </EditableRow>
              )}
            </div>
          ))}
        </div>
      ) : (
        <WidgetEmptyState />
      )}

      <div className={styles.footer}>
        <Tooltip
          disabled={!userInfo?.deactivatedAt}
          title={t('Common.DeactivatedAccountNote')}
        >
          <span>
            <Button
              fullWidth
              size="medium"
              color="primary"
              variant="outlined"
              onClick={goToResearchPage}
              className={styles.cta}
              disabled={isExternalUserDashboard || !!userInfo?.deactivatedAt}
            >
              {t('Page.Dashboard.DashboardHistory.CreateNewChat')}
            </Button>
          </span>
        </Tooltip>
      </div>
    </div>
  );
};
