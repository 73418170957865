import { useEffect, useRef } from 'react';
import {
  updateDashboardTitle,
  updateWidgetFilter
} from 'actions/dashboardActions';
import axios from 'axios';
import { Chat } from 'entities/Chat.entity';
import { CRMBooster } from 'entities/CRMBooster.entity';
import { Notification } from 'entities/Notification.entity';
import { Report } from 'entities/Report.entity';
import { Routes, SSEEvents } from 'enums';
import { queryClient } from 'index';
import { LocalStorage } from 'services/LocalStorage';
import {
  DashboardTextWidgetsData,
  DashboardTitleData,
  DashboardUpdateData,
  NestedDashboardData,
  NewMessageData,
  WidgetFilterData,
  WidgetRateData
} from 'types';
import { SSE_CONNECTION_OPENED } from 'utils/constants';
import {
  clearStorage,
  updateChatMessages,
  updateCRMBoosters,
  updateCRMBoostersWithNew,
  updateDashboard,
  updateDashboardTextWidgets,
  updateFilteredChats,
  updateHistoryWidget,
  updateNotifications,
  updateRateWidget,
  updateReports,
  updateReportsWithNew
} from 'utils/helpers';

import { useAuthContext } from '../useAuthContext';
import { useSnackbar } from '../useSnackbar';

export const useSSE = () => {
  const eventSource = useRef<EventSource | null>(null);
  const broadcastChannel = useRef<BroadcastChannel | null>(null);
  const snackbar = useSnackbar();

  const { isAuthenticated } = useAuthContext();

  const closeConnections = () => {
    if (eventSource.current) {
      LocalStorage.removeItem(SSE_CONNECTION_OPENED);
      eventSource.current?.close();
      broadcastChannel.current?.postMessage({
        type: SSEEvents.ReassignMainTab
      });
    }
    broadcastChannel.current?.close();
  };

  const createBroadcastChannel = () => {
    broadcastChannel.current = new BroadcastChannel('sse');

    broadcastChannel.current.onmessage = (event) => {
      try {
        const { type, data } = event.data;

        switch (type) {
          case SSEEvents.NewChat:
            updateFilteredChats(data as Chat);
            break;

          case SSEEvents.NewChatMessage:
            updateChatMessages(data as NewMessageData);
            break;

          case SSEEvents.DashboardTextWidgets:
            updateDashboardTextWidgets(data as DashboardTextWidgetsData);
            break;

          case SSEEvents.DashboardTitle:
            updateDashboardTitle(data as DashboardTitleData);
            break;

          case SSEEvents.DashboardUpdate:
            updateDashboard(data as DashboardUpdateData);
            break;

          case SSEEvents.DashboardRateWidget:
            updateRateWidget(data as WidgetRateData);
            break;

          case SSEEvents.DashboardCreateNested:
            updateHistoryWidget(data as NestedDashboardData);
            break;

          case SSEEvents.DashboardUpdateWidgetFilter:
            updateWidgetFilter(data as WidgetFilterData);
            break;

          case SSEEvents.UnauthorizedError:
            updateDashboardTitle(data as DashboardTitleData);
            break;

          case SSEEvents.Notification: {
            updateNotifications(data as Notification);
            break;
          }

          case SSEEvents.NewCRMBooster: {
            updateCRMBoostersWithNew(data as CRMBooster);
            break;
          }

          case SSEEvents.UpdateCRMBooster: {
            updateCRMBoosters(data as CRMBooster);
            break;
          }

          case SSEEvents.NewReport: {
            updateReportsWithNew(data as Report);
            break;
          }

          case SSEEvents.UpdateReport: {
            updateReports(data as Report);
            break;
          }

          case SSEEvents.ReassignMainTab: {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            setTimeout(createEventSource);
            break;
          }

          case SSEEvents.Error:
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            checkAuth();
            break;

          default:
        }
      } catch {
        snackbar.error.commonError();
      }
    };
  };

  const createEventSource = () => {
    const isSSEConnectionOpened = LocalStorage.getItem(SSE_CONNECTION_OPENED);

    if (!isSSEConnectionOpened) {
      eventSource.current = new EventSource(
        `${process.env.REACT_APP_BASE_URL}/sse`,
        {
          withCredentials: true
        }
      );

      eventSource.current.addEventListener(SSEEvents.NewChat, (event) => {
        try {
          const data = JSON.parse(event.data);

          updateFilteredChats(data as Chat);

          broadcastChannel.current?.postMessage({
            type: SSEEvents.NewChat,
            data
          });
        } catch {
          snackbar.error.commonError();
        }
      });

      eventSource.current.addEventListener(
        SSEEvents.NewChatMessage,
        (event) => {
          try {
            const data = JSON.parse(event.data);

            updateChatMessages(data as NewMessageData);

            broadcastChannel.current?.postMessage({
              type: SSEEvents.NewChatMessage,
              data
            });
          } catch {
            snackbar.error.commonError();
          }
        }
      );

      eventSource.current.addEventListener(
        SSEEvents.DashboardTextWidgets,
        (event) => {
          try {
            const data = JSON.parse(event.data);

            updateDashboardTextWidgets(data as DashboardTextWidgetsData);

            broadcastChannel.current?.postMessage({
              type: SSEEvents.DashboardTextWidgets,
              data
            });
          } catch {
            snackbar.error.commonError();
          }
        }
      );

      eventSource.current.addEventListener(
        SSEEvents.DashboardTitle,
        (event) => {
          try {
            const data = JSON.parse(event.data);

            updateDashboardTitle(data as DashboardTitleData);

            broadcastChannel.current?.postMessage({
              type: SSEEvents.DashboardTitle,
              data
            });
          } catch {
            snackbar.error.commonError();
          }
        }
      );

      eventSource.current.addEventListener(
        SSEEvents.DashboardUpdate,
        (event) => {
          try {
            const data = JSON.parse(event.data);

            updateDashboard(data as DashboardUpdateData);

            broadcastChannel.current?.postMessage({
              type: SSEEvents.DashboardUpdate,
              data
            });
          } catch {
            snackbar.error.commonError();
          }
        }
      );

      eventSource.current.addEventListener(
        SSEEvents.DashboardRateWidget,
        (event) => {
          try {
            const data = JSON.parse(event.data);

            updateRateWidget(data as WidgetRateData);

            broadcastChannel.current?.postMessage({
              type: SSEEvents.DashboardRateWidget,
              data
            });
          } catch {
            snackbar.error.commonError();
          }
        }
      );

      eventSource.current.addEventListener(
        SSEEvents.DashboardCreateNested,
        (event) => {
          try {
            const data = JSON.parse(event.data);

            updateHistoryWidget(data as NestedDashboardData);

            broadcastChannel.current?.postMessage({
              type: SSEEvents.DashboardCreateNested,
              data
            });
          } catch {
            snackbar.error.commonError();
          }
        }
      );

      eventSource.current.addEventListener(
        SSEEvents.DashboardUpdateWidgetFilter,
        (event) => {
          try {
            const data = JSON.parse(event.data);

            updateWidgetFilter(data as WidgetFilterData);

            broadcastChannel.current?.postMessage({
              type: SSEEvents.DashboardUpdateWidgetFilter,
              data
            });
          } catch {
            snackbar.error.commonError();
          }
        }
      );

      eventSource.current.addEventListener(
        SSEEvents.UnauthorizedError,
        (event) => {
          try {
            const data = JSON.parse(event.data);

            updateDashboardTitle(data as DashboardTitleData);

            broadcastChannel.current?.postMessage({
              type: SSEEvents.UnauthorizedError,
              data
            });
          } catch {
            snackbar.error.commonError();
          }
        }
      );

      eventSource.current.addEventListener(SSEEvents.Notification, (event) => {
        try {
          const parsedData = JSON.parse(event.data);
          const data = Notification.deserialize(parsedData);

          updateNotifications(data as Notification);

          broadcastChannel.current?.postMessage({
            type: SSEEvents.Notification,
            data
          });
        } catch {
          snackbar.error.commonError();
        }
      });

      eventSource.current.addEventListener(SSEEvents.NewCRMBooster, (event) => {
        try {
          const parsedData = JSON.parse(event.data);
          const data = CRMBooster.deserialize(parsedData);

          updateCRMBoostersWithNew(data as CRMBooster);

          broadcastChannel.current?.postMessage({
            type: SSEEvents.NewCRMBooster,
            data
          });
        } catch {
          snackbar.error.commonError();
        }
      });

      eventSource.current.addEventListener(
        SSEEvents.UpdateCRMBooster,
        (event) => {
          try {
            const parsedData = JSON.parse(event.data);
            const data = CRMBooster.deserialize(parsedData);

            updateCRMBoosters(data as CRMBooster);

            broadcastChannel.current?.postMessage({
              type: SSEEvents.UpdateCRMBooster,
              data
            });
          } catch {
            snackbar.error.commonError();
          }
        }
      );

      eventSource.current.addEventListener(SSEEvents.NewReport, (event) => {
        try {
          const parsedData = JSON.parse(event.data);
          const data = Report.deserialize(parsedData);

          updateReportsWithNew(data as Report);

          broadcastChannel.current?.postMessage({
            type: SSEEvents.NewReport,
            data
          });
        } catch {
          snackbar.error.commonError();
        }
      });

      eventSource.current.addEventListener(SSEEvents.UpdateReport, (event) => {
        try {
          const parsedData = JSON.parse(event.data);
          const data = Report.deserialize(parsedData);

          updateReports(data as Report);

          broadcastChannel.current?.postMessage({
            type: SSEEvents.UpdateReport,
            data
          });
        } catch {
          snackbar.error.commonError();
        }
      });

      eventSource.current.onerror = () => {
        try {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          checkAuth();

          broadcastChannel.current?.postMessage({
            type: SSEEvents.Error
          });
        } catch {
          snackbar.error.commonError();
        }
      };

      // HINT: After adding new SSE handler, don't forget to add it to broadcastChannel handlers above

      LocalStorage.setItem(SSE_CONNECTION_OPENED, true);

      // @ts-expect-error error type
      // eslint-disable-next-line no-unused-expressions
      window.unloadHandlers.unshift(closeConnections);
    }
  };

  const checkAuth = async () => {
    try {
      // Preliminary check to see if we get a 401 response
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/sse`, {
        method: 'GET',
        credentials: 'include'
      });

      if (response.status === 401) {
        // Token is expired, try refreshing it
        await axios.post('/auth/refresh-token', undefined, {
          baseURL: process.env.REACT_APP_BASE_URL,
          withCredentials: true
        });

        if (eventSource.current) {
          eventSource.current.close();
          eventSource.current = null;
          LocalStorage.removeItem(SSE_CONNECTION_OPENED);
          // If the token is valid, proceed to create the EventSource
          createEventSource();
        }
      }
    } catch (error) {
      if (eventSource.current) {
        eventSource.current.close();
        eventSource.current = null;
      }

      await axios.post('/auth/logout', undefined, {
        baseURL: process.env.REACT_APP_BASE_URL
      });

      clearStorage();
      queryClient.removeQueries();

      window.location.href = Routes.Auth;
      return Promise.reject(error);
    }
  };

  useEffect(() => {
    if (isAuthenticated && !broadcastChannel.current) {
      createBroadcastChannel();
    }

    if (isAuthenticated && !eventSource.current) {
      createEventSource();
    }

    if (!isAuthenticated && broadcastChannel.current) {
      broadcastChannel.current.close();
      broadcastChannel.current = null;
    }

    if (!isAuthenticated && eventSource.current) {
      eventSource.current?.close();
      eventSource.current = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
};
