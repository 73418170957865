import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { ReactComponent as CheckIcon } from 'assets/check-small.svg';
import { ReactComponent as EmptySearchIcon } from 'assets/EmptySearchIcon.svg';
import { ReactComponent as UncheckIcon } from 'assets/uncheck-small.svg';
import { SearchFilterInput } from 'components';
import { ChatCard } from 'components/Sidebar/ChatCard';
import { format } from 'date-fns';
import { de, enUS } from 'date-fns/locale';
import { Chat } from 'entities/Chat.entity';
import { Routes } from 'enums';
import { useLanguage } from 'hooks';
import { usePaginatedChats } from 'hooks/api/usePaginatedChats';
import { handleAnimationSwitch } from 'utils/helpers/animationHelper';

import styles from './styles.module.scss';

export const ResearchHistory: FC = () => {
  const { t } = useTranslation();
  const language = useLanguage();
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [includeArchived, setIncludeArchived] = useState(false);
  const navigate = useNavigate();
  const locale = language === 'de' ? de : enUS;
  const filters = { title: searchQuery, includeArchived };
  const { data, isFetched, hasNextPage, fetchNextPage, refetch } =
    usePaginatedChats(filters);

  useEffect(() => {
    handleAnimationSwitch(true);
  }, []);

  const handleSearchButtonClick = (searchQuery: string | null) => {
    setSearchQuery(searchQuery);
  };

  const handleCheckboxClick = (e: ChangeEvent<HTMLInputElement>) => {
    setIncludeArchived(e.target.checked);
  };

  const renderEmptyState = () =>
    isFetched ? (
      <div className={styles['empty-state']}>
        <EmptySearchIcon />
        <h3 className={styles['empty-state-title']}>
          {t('Page.ResearchHistory.EmptyState.Title')}
        </h3>
        <span className={styles['empty-state-description']}>
          {t('Page.ResearchHistory.EmptyState.Description')}
        </span>
      </div>
    ) : null;

  const chats = useMemo(() => {
    if (!data?.pages?.length) return null;

    const flatData = data?.pages.reduce(
      (acc, items) => acc.concat(...items),
      []
    );

    const groupedChats = flatData.reduce(
      (acc: Record<string, Chat[]>, chat) => {
        const formattedDateLabel = format(chat.createdAt, 'LLL d, y', {
          locale
        });

        acc[formattedDateLabel] = acc[formattedDateLabel]?.length
          ? [...acc[formattedDateLabel], chat]
          : [chat];

        return acc;
      },
      {}
    );

    return groupedChats;
  }, [data?.pages, locale]);

  useEffect(() => {
    if (isFetched && !chats && !searchQuery) navigate(Routes.NewChat);
  }, [chats, isFetched, navigate, searchQuery]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <SearchFilterInput
          onSubmit={handleSearchButtonClick}
          className={styles.search}
          placeholder={t('Page.ResearchHistory.InputPlaceholder')}
        />

        <FormControlLabel
          label={t('Form.Label.ShowArchived')}
          classes={{
            root: styles['checkbox-label']
          }}
          control={
            <Checkbox
              color="primary"
              checked={includeArchived}
              onChange={handleCheckboxClick}
              icon={<UncheckIcon />}
              checkedIcon={<CheckIcon />}
              classes={{
                root: styles['checkbox-container']
              }}
            />
          }
        />

        <div className={styles.cards}>
          {isFetched && chats && Object.keys(chats)?.length
            ? Object.entries(chats).map(([date, value]) => (
                <div key={date} className={styles.group}>
                  <p className={styles.date}>{date}</p>

                  {value.map((chat) => (
                    <ChatCard
                      id={chat.id}
                      key={chat.id}
                      title={chat.title || ''}
                      archived={!!chat.archivedAt}
                      dashboards={chat.dashboards}
                      refetchChats={refetch}
                    />
                  ))}
                </div>
              ))
            : renderEmptyState()}
        </div>

        {hasNextPage && (
          <Button
            size="small"
            color="primary"
            variant="outlined"
            className={styles['show-more']}
            onClick={() => fetchNextPage()}
          >
            {t('Page.ResearchHistory.ShowMore')}
          </Button>
        )}
      </div>
    </div>
  );
};
