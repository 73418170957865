import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { LabeledSwitch } from 'components/LabeledSwitch/LabeledSwitch';
import { Routes, SubscriptionCycles, SubscriptionPlans } from 'enums';
import {
  useMobile,
  useScheduledSubscription,
  useTablet,
  useUnpaidSubscription
} from 'hooks';
import {
  useActiveSubscription,
  useScheduleSubscription,
  useUserInfo
} from 'hooks/api';
import { ProfileTabs } from 'views/Profile/Profile';

import { PricingWrapper } from '../PricingWrapper';
import { BasePlanCard } from './BasePlanCard';
import { PlanCard } from './PlanCard';

import styles from './styles.module.scss';

const questions = [
  {
    title: 'Page.Pricing.Plans.Questions.List.First.Title',
    description: 'Page.Pricing.Plans.Questions.List.First.Description',
    button: 'Page.Pricing.Plans.Questions.List.First.Button'
  },
  {
    title: 'Page.Pricing.Plans.Questions.List.Second.Title',
    description: 'Page.Pricing.Plans.Questions.List.Second.Description'
  },
  {
    title: 'Page.Pricing.Plans.Questions.List.Third.Title',
    description: 'Page.Pricing.Plans.Questions.List.Third.Description'
  },
  {
    title: 'Page.Pricing.Plans.Questions.List.Fourth.Title',
    description: 'Page.Pricing.Plans.Questions.List.Fourth.Description'
  }
];

export const SubscriptionPlan: FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isMobile = useMobile();
  const isTablet = useTablet();

  const [billingCycle, setBillingCycle] = useState<SubscriptionCycles>(
    SubscriptionCycles.Monthly
  );
  const { data: userInfo } = useUserInfo();
  const { data: subscriptionDetails, isLoading: isLoadingActiveSubscription } =
    useActiveSubscription();
  const { mutate: scheduleSubscription } = useScheduleSubscription();
  const scheduledSubscription = useScheduledSubscription();
  const { unpaidSubscription, isLoading: isLoadingUnpaidSubscription } =
    useUnpaidSubscription();

  const isLoading = useMemo(
    () => isLoadingActiveSubscription || isLoadingUnpaidSubscription,
    [isLoadingUnpaidSubscription, isLoadingActiveSubscription]
  );

  const currentPlan = useMemo(() => {
    if (unpaidSubscription) {
      return SubscriptionPlans.Premium;
    }
    return userInfo?.subscriptionPlan || SubscriptionPlans.Free;
  }, [unpaidSubscription, userInfo?.subscriptionPlan]);

  const currentBillingCycle: SubscriptionCycles = useMemo(() => {
    if (unpaidSubscription) {
      return unpaidSubscription.period;
    }
    return subscriptionDetails?.period || SubscriptionCycles.Monthly;
  }, [subscriptionDetails?.period, unpaidSubscription]);

  const userPlanBillingCycle: SubscriptionCycles | undefined = useMemo(
    () =>
      unpaidSubscription?.period ||
      scheduledSubscription?.period ||
      subscriptionDetails?.period,

    [
      scheduledSubscription?.period,
      subscriptionDetails?.period,
      unpaidSubscription?.period
    ]
  );

  const selectedPlan = searchParams.get('plan');
  const selectedBillingCycle = searchParams.get('billing-cycle');

  const toggleBillingCycle = (cycle: SubscriptionCycles) => {
    setBillingCycle(cycle);
  };

  useEffect(() => {
    if (selectedBillingCycle && selectedBillingCycle !== billingCycle) {
      setBillingCycle(selectedBillingCycle as SubscriptionCycles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      selectedPlan === SubscriptionPlans.Premium &&
      selectedBillingCycle &&
      selectedBillingCycle !== userPlanBillingCycle &&
      !isLoading
    ) {
      if (
        scheduledSubscription ||
        userPlanBillingCycle === SubscriptionCycles.Yearly
      ) {
        return;
      }

      if (subscriptionDetails) {
        scheduleSubscription({
          period: selectedBillingCycle as SubscriptionCycles
        });
      } else if (unpaidSubscription) {
        navigate(Routes.Profile.replace(':tab?', ProfileTabs.Billing));
      } else {
        navigate({
          pathname: Routes.Checkout,
          search: createSearchParams({
            'billing-cycle': selectedBillingCycle
          }).toString()
        });
      }
    }
  }, [
    currentBillingCycle,
    isLoading,
    navigate,
    scheduleSubscription,
    scheduledSubscription,
    selectedBillingCycle,
    selectedPlan,
    subscriptionDetails,
    unpaidSubscription,
    userPlanBillingCycle
  ]);

  const tabs = useMemo(
    () => [
      {
        id: SubscriptionCycles.Monthly,
        element: <span>{t('Page.Pricing.Plans.Tabs.Monthly')}</span>
      },
      {
        id: SubscriptionCycles.Yearly,
        element: (
          <>
            <span>{t('Page.Pricing.Plans.Tabs.Yearly')}</span>
            <span className={styles.badge}>
              {t('Page.Pricing.Plans.Tabs.Save%')}
            </span>
          </>
        )
      }
    ],
    [t]
  );

  const switchComponent = useMemo(
    () => (
      <div className={styles['switch-container']}>
        <LabeledSwitch
          elements={tabs}
          selectedId={billingCycle}
          onChange={toggleBillingCycle}
        />
      </div>
    ),
    [billingCycle, tabs]
  );

  const premiumPlanState = useMemo(() => {
    if (currentPlan === SubscriptionPlans.Premium) {
      if (billingCycle === currentBillingCycle) {
        if (unpaidSubscription) {
          return {
            disabled: false,
            label: t('Page.Pricing.Plans.Actions.Current')
          };
        }

        return {
          disabled: true,
          label: t('Page.Pricing.Plans.Actions.Current')
        };
      }

      if (
        currentBillingCycle === SubscriptionCycles.Monthly &&
        billingCycle === SubscriptionCycles.Yearly
      ) {
        if (scheduledSubscription?.period === SubscriptionCycles.Yearly) {
          return {
            disabled: true,
            label: t('Page.Pricing.Plans.Actions.Scheduled')
          };
        }

        if (unpaidSubscription) {
          return {
            disabled: true,
            label: t('Page.Pricing.Plans.Actions.UpgradePlan'),
            tooltip: unpaidSubscription
              ? t('Page.Pricing.Plans.UnpaidTooltip')
              : undefined
          };
        }

        return {
          disabled: false,
          label: t('Page.Pricing.Plans.Actions.UpgradePlan')
        };
      }

      if (
        currentBillingCycle === SubscriptionCycles.Yearly &&
        billingCycle === SubscriptionCycles.Monthly
      ) {
        if (scheduledSubscription?.period === SubscriptionCycles.Monthly) {
          return {
            disabled: true,
            label: t('Page.Pricing.Plans.Actions.Scheduled')
          };
        }

        if (unpaidSubscription) {
          return {
            disabled: true,
            label: t('Page.Pricing.Plans.Actions.ChoosePlan'),
            tooltip: unpaidSubscription
              ? t('Page.Pricing.Plans.UnpaidTooltip')
              : undefined
          };
        }

        return {
          disabled: false,
          label: t('Page.Pricing.Plans.Actions.DowngradePlan')
        };
      }
    }

    return {
      disabled: false,
      label: t('Page.Pricing.Plans.Actions.ChoosePlan')
    };
  }, [
    t,
    currentPlan,
    billingCycle,
    unpaidSubscription,
    currentBillingCycle,
    scheduledSubscription?.period
  ]);

  const isSmallScreen = isMobile || isTablet;

  return (
    <PricingWrapper
      questions={questions}
      title={t('Page.Pricing.Plans.Title')}
      subtitle={t('Page.Pricing.Plans.Subtitle')}
    >
      {!isSmallScreen && switchComponent}

      <div className={styles.plans}>
        <BasePlanCard />
        {isSmallScreen && switchComponent}
        <PlanCard
          size="large"
          cycle={billingCycle}
          plan={SubscriptionPlans.Premium}
          buttonLabel={premiumPlanState.label}
          hasActiveSubscription={!!subscriptionDetails}
          hasUnpaidSubscription={!!unpaidSubscription}
          disabled={!!userInfo?.deactivatedAt || premiumPlanState.disabled}
          tooltip={
            userInfo?.deactivatedAt
              ? t('Page.CoinsPackages.DeactivatedAccountTooltip')
              : premiumPlanState.tooltip
          }
        />
      </div>
    </PricingWrapper>
  );
};
