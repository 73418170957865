import {
  custom,
  deserialize,
  list,
  object,
  primitive,
  serializable
} from 'serializr';

import { CompanyContact } from './CompanyContact.entity';
import { Machine } from './Machine.entity';

export class DashboardCompany {
  @serializable(list(primitive()))
  certification?: string[] | null = null;

  @serializable(list(primitive()))
  industry_groups?: string[] | null = null;

  @serializable(list(primitive()))
  materials?: string[] | null = null;

  @serializable(
    custom(
      (sourcePropertyValue: string) => {
        return sourcePropertyValue;
      },
      (jsonValue: string[] | null | string) => {
        return jsonValue === '' ? [] : jsonValue;
      }
    )
  )
  technologies?: string[] | null = null;

  @serializable(list(primitive()))
  images?: string[] | null = null;

  @serializable(list(primitive()))
  naics_codes?: string[] | null = null;

  @serializable(list(primitive()))
  emails?: string[] | null = null;

  @serializable(list(primitive()))
  technology_groups?: string[] | null = null;

  @serializable(
    custom(
      (sourcePropertyValue: string) => {
        return sourcePropertyValue;
      },
      (
        jsonValue: string[] | { industry: string; naics_code: string }[] | null
      ) => {
        return jsonValue;
      }
    )
  )
  industries?: string[] | { industry: string; naics_code: string }[] | null =
    null;

  @serializable(
    custom(
      (sourcePropertyValue: string) => {
        return sourcePropertyValue;
      },
      (
        jsonValue:
          | string[]
          | {
              product_name: string;
              price: string;
              harmonized_system_code: string;
              description: string;
              currency: string;
            }[]
          | null
      ) => {
        return jsonValue;
      }
    )
  )
  products?:
    | string[]
    | {
        product_name: string;
        price: string;
        harmonized_system_code: string;
        description: string;
        currency: string;
      }[]
    | null = null;

  @serializable(
    custom(
      (sourcePropertyValue: string) => {
        return sourcePropertyValue;
      },
      (
        jsonValue: string[] | { number: string; calling_code: string }[] | null
      ) => {
        return jsonValue;
      }
    )
  )
  phone_numbers?: string[] | { number: string; calling_code: string }[] | null =
    null;

  @serializable(list(primitive()))
  other?: string[] | null = null;

  @serializable(list(primitive()))
  harmonized_system_codes?: string[] | null = null;

  @serializable(list(primitive()))
  naics_codes_groups?: string[] | null = null;

  @serializable(list(primitive()))
  material_groups?: string[] | null = null;

  @serializable(list(primitive()))
  harmonized_system_codes_groups?: string[] | null = null;

  @serializable
  location: string = '';

  @serializable
  address?: string = '';

  @serializable
  website_url?: string = '';

  @serializable
  foundation_year?: number = 0;

  @serializable
  latitude?: number | null = null;

  @serializable
  longitude?: number | null = null;

  @serializable
  employee_count?: number = 0;

  @serializable
  n_machines?: number = 0;

  @serializable
  number_of_employees: number = 0;

  @serializable
  name: string = '';

  @serializable
  company_name?: string = '';

  @serializable(list(object(Machine)))
  machines: Machine[] | null = null;

  @serializable(list(object(CompanyContact)))
  contacts?: CompanyContact[] | null = null;

  @serializable
  continent?: string = '';

  @serializable
  country_code?: string = '';

  @serializable
  region?: string = '';

  @serializable(list(primitive()))
  services?: string[] | null = null;

  static deserialize(json: Object | string): DashboardCompany {
    return deserialize(DashboardCompany, json);
  }
}
