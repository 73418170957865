import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { requestFreeSnippet } from 'actions/reportsActions';
import { ApiError } from 'entities/ApiError.entity';
import { ApiErrors, queryKeys } from 'enums';
import { useSnackbar } from 'hooks/useSnackbar';
import { queryClient } from 'index';

export const useCreateFreeSnippet = (
  onSuccess: () => void,
  onError: () => void
) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  return useMutation<
    void,
    ApiError,
    {
      question: string;
      industry?: string;
    }
  >({
    mutationFn: ({ industry, question }) =>
      requestFreeSnippet({ question, industry }),
    onSuccess() {
      queryClient.setQueryData(queryKeys.freeSnippetAvailability, false);

      onSuccess();
    },
    onError(error) {
      if (error?.message === ApiErrors.FreeReportIsPending) {
        snackbar.error.freeReportIsPendingError();
      } else if (error.message === ApiErrors.SpamReport) {
        snackbar.error.commonError({
          ...error,
          message: t('Notifications.SpamReportError')
        });
      } else {
        snackbar.error.commonError(error);
      }

      onError();
    }
  });
};
