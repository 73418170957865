import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { RangeSelector, RangeSelectorValue } from 'components/RangeSelector';
import type { SelectedFilterItem } from 'types';
import { CRMBoosterFilters } from 'views/CRMBoosterDetails/CRMBoosterDetails';
import { ZoomableTreemap } from 'views/DashboardV2/ZoomableTreemapChart/ZoomableTreemap';

import { CRMBoosterFilter } from '../CRMBoosterFilter';
import { mockedTreemapData } from './mocks';

import styles from './styles.module.scss';

interface Props {
  isActive: boolean;
  defaultFilters: CRMBoosterFilters;
  selectedMachine: SelectedFilterItem;
  setSelectedMachine: Dispatch<SetStateAction<SelectedFilterItem>>;
  selectedMachinesRange: number[];
  setSelectedMachinesRange: Dispatch<SetStateAction<number[]>>;
}

const SUCCESS_COLORS = {
  root: '#007727',
  start: '#00983d',
  end: '#e1faec'
};

const DIMENSIONS = {
  width: 400,
  height: 500
};

const TABLET_DIMENSIONS = {
  width: 1000,
  height: 950
};

export const CRMBoosterDetailsMachinesTab: FC<Props> = ({
  isActive,
  defaultFilters,
  selectedMachine,
  setSelectedMachine,
  selectedMachinesRange,
  setSelectedMachinesRange
}) => {
  const { t } = useTranslation();

  const handleSelectMachinesRange = (
    _: MouseEvent,
    value: RangeSelectorValue
  ) => {
    setSelectedMachinesRange(value as number[]);
  };

  return (
    <div className={cn(styles.container, isActive && styles.active)}>
      <h2 className={styles.title}>
        {t('Page.CRMBoosterDetails.Tabs.Machines.Title')}
      </h2>

      <CRMBoosterFilter
        placeholder={t(
          'Page.CRMBoosterDetails.Tabs.Machines.Filter.Treemap.Placeholder'
        )}
        selectedItem={selectedMachine}
        notes={{
          inactive: t(
            'Page.CRMBoosterDetails.Tabs.Machines.Filter.Treemap.Notes.Inactive'
          ),
          active: t(
            'Page.CRMBoosterDetails.Tabs.Machines.Filter.Treemap.Notes.Active',
            { machine: selectedMachine?.name }
          )
        }}
      />

      <div className={styles.treemap}>
        <ZoomableTreemap
          data={mockedTreemapData}
          chartColors={SUCCESS_COLORS}
          selectItem={setSelectedMachine}
          dimensions={{
            mobile: DIMENSIONS,
            tablet: TABLET_DIMENSIONS,
            desktop: DIMENSIONS
          }}
        />
      </div>

      <RangeSelector
        min={defaultFilters.selectedMachinesRange[0]}
        max={defaultFilters.selectedMachinesRange[1]}
        value={selectedMachinesRange}
        label={t('Page.CRMBoosterDetails.Tabs.Machines.Filter.Machines.Label')}
        onChange={handleSelectMachinesRange}
      />
    </div>
  );
};
