import { useState } from 'react';
import { Navigate, Route, Routes as BrowserRoutes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {
  FreeUsersInformationModal,
  GlobalShortcutsModal,
  Header,
  NewUsersInformationModal,
  Sidebar
} from 'components';
import { ActionRequiredModal } from 'components/ActionRequiredModal';
import { CloudExperience } from 'components/CloudExperience';
import { DashboardLimitModal } from 'components/DashboardLimitModal';
import { DashboardProvider } from 'components/DashboardProvider';
import { NoMatch } from 'components/NoMatch';
import { NotificationsProvider } from 'components/NotificationsProvider/NotificationsProvider';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { SeoSettings } from 'components/SeoSettings';
import { WithCompleteUserInfoRoute } from 'components/WithVerifiedUserRoute/WithCompleteUserInfoRoute';
import { Routes } from 'enums/Routes';
import { useScrollPositionReset } from 'hooks';
import { useSSE } from 'hooks/api';
import { useGlobalShortcuts } from 'hooks/useGlobalShortcuts';
import { useHotjarAnalytics } from 'hooks/useHotjarAnalytics';
import { useMixpanelAnalytics } from 'hooks/useMixpanelAnalytics';
import { usePreferredLanguage } from 'hooks/usePreferredLanguage';
import { useRedirectFromSessionStorage } from 'hooks/useRedirectFromSessionStorage';
import { useUnloadHandlers } from 'hooks/useUnloadHandlers';
import { AuthPage } from 'views/AuthPage';
import { CompleteUserInfo } from 'views/CompleteUserInfo';
import { Confirm } from 'views/Confirm';
import { Conversation } from 'views/Conversation';
import { CRMBooster } from 'views/CRMBooster';
import { CRMBoosterCheckout } from 'views/CRMBoosterCheckout';
import { CRMBoosterDetails } from 'views/CRMBoosterDetails';
import { CRMBoosterPurchaseConfirm } from 'views/CRMBoosterPurchaseConfirm';
import { Dashboard } from 'views/Dashboard';
import { EmailMarketingCampaigns } from 'views/EmailMarketingCampaigns';
import { ForgotPassword } from 'views/ForgotPassword';
import { NewDevelopments } from 'views/NewDevelopments';
import { NewPassword } from 'views/NewPassword';
import { Notifications } from 'views/Notifications';
import { OneTimePaymentCheckout } from 'views/OneTimePaymentCheckout';
import { Pricing } from 'views/Pricing';
import { Profile } from 'views/Profile';
import { Report } from 'views/Report';
import { ReportCheckout } from 'views/ReportCheckout';
import { ReportPurchaseConfirm } from 'views/ReportPurchaseConfirm';
import { ResearchHistory } from 'views/ResearchHistory';
import { ResetPasswordConfirm } from 'views/ResetPasswordConfirm';
import { SignIn } from 'views/SignIn';
import { SignUp } from 'views/SignUp';
import { SubscriptionCheckout } from 'views/SubscriptionCheckout';
import { SubscriptionConfirm } from 'views/SubscriptionConfirm';
import { SubscriptionUpgradeConfirm } from 'views/SubscriptionUpgradeConfirm';
import { Unsubscribe } from 'views/Unsubscribe';
import { UpdateEmailConfirm } from 'views/UpdateEmailConfirm';

import 'react-toastify/dist/ReactToastify.css';
import styles from './styles.module.scss';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(BrowserRoutes);

const App = () => {
  useUnloadHandlers();
  useSSE();
  useScrollPositionReset();
  usePreferredLanguage();
  useMixpanelAnalytics();
  useHotjarAnalytics();
  useGlobalShortcuts();
  useRedirectFromSessionStorage();

  const [isAnimationInitialized, setIsAnimationInitialized] = useState(false);

  return (
    <main>
      <SeoSettings />
      <CloudExperience onLoad={setIsAnimationInitialized} />
      <NewUsersInformationModal />
      <GlobalShortcutsModal />
      <FreeUsersInformationModal />
      <DashboardLimitModal />
      <NotificationsProvider />
      <ActionRequiredModal />

      {isAnimationInitialized && (
        <div className={styles.app}>
          <Header />
          <Sidebar />
          <SentryRoutes>
            <Route path={Routes.Root}>
              <Route
                path={Routes.PublicIndustryChat}
                element={<Conversation />}
              />

              <Route
                element={
                  <ProtectedRoute
                    forAuthenticated={false}
                    redirectPath={Routes.NewChat}
                  />
                }
              >
                <Route path={Routes.Auth} element={<AuthPage />} />
                <Route path={Routes.Login} element={<SignIn />} />
                <Route path={Routes.Confirm} element={<Confirm />} />
                <Route path={Routes.Registration} element={<SignUp />} />
                <Route
                  path={Routes.ForgotPassword}
                  element={<ForgotPassword />}
                />
                <Route
                  path={Routes.ConfirmRestorePassword}
                  element={<ResetPasswordConfirm />}
                />
              </Route>
              <Route
                path={Routes.UpdateEmailConfirm}
                element={<UpdateEmailConfirm />}
              />
              <Route path={Routes.Unsubscribe} element={<Unsubscribe />} />
              <Route path={Routes.RestorePassword} element={<NewPassword />} />
              <Route element={<ProtectedRoute redirectPath={Routes.Auth} />}>
                <Route
                  element={
                    <WithCompleteUserInfoRoute
                      redirectPath={Routes.CompleteUserInfo}
                    />
                  }
                >
                  <Route
                    path={Routes.IndustryChat}
                    element={<Conversation />}
                  />
                  <Route
                    path={Routes.Dashboard}
                    element={
                      <DashboardProvider>
                        <Dashboard />
                      </DashboardProvider>
                    }
                  />
                  <Route
                    element={
                      <DashboardProvider>
                        <Dashboard />
                      </DashboardProvider>
                    }
                    path={Routes.ExternalUserDashboardFallback}
                  />
                  <Route
                    element={
                      <DashboardProvider>
                        <Dashboard />
                      </DashboardProvider>
                    }
                    path={Routes.ExternalUserDashboard}
                  />
                  <Route path={Routes.Chat} element={<Conversation />} />
                  <Route
                    path={Routes.ResearchHistory}
                    element={<ResearchHistory />}
                  />
                  <Route
                    path={Routes.CRMBoosterDetails}
                    element={<CRMBoosterDetails />}
                  />
                  <Route path={Routes.Reports} element={<Report />} />
                  <Route path={Routes.CRMBooster} element={<CRMBooster />} />
                  <Route
                    path={Routes.EmailMarketingCampaigns}
                    element={<EmailMarketingCampaigns />}
                  />
                  <Route path={Routes.NewChat} element={<Conversation />} />
                  <Route path={Routes.Profile} element={<Profile />} />
                  <Route
                    element={<Pricing />}
                    path={Routes.SubscriptionPlans}
                  />
                  <Route
                    path={Routes.OneTimePaymentCheckout}
                    element={<OneTimePaymentCheckout />}
                  />
                  <Route
                    path={Routes.SubscriptionConfirm}
                    element={<SubscriptionConfirm />}
                  />
                  <Route
                    path={Routes.SubscriptionUpgradeConfirm}
                    element={<SubscriptionUpgradeConfirm />}
                  />
                  <Route
                    path={Routes.NewDevelopments}
                    element={<NewDevelopments />}
                  />
                  <Route
                    path={Routes.Notifications}
                    element={<Notifications />}
                  />
                  <Route
                    path={Routes.Checkout}
                    element={<SubscriptionCheckout />}
                  />
                  <Route
                    path={Routes.OneTimePaymentCheckout}
                    element={<OneTimePaymentCheckout />}
                  />
                  <Route
                    path={Routes.ReportCheckout}
                    element={<ReportCheckout />}
                  />
                  <Route
                    path={Routes.CRMBoosterCheckout}
                    element={<CRMBoosterCheckout />}
                  />
                  <Route
                    path={Routes.ReportConfirm}
                    element={<ReportPurchaseConfirm />}
                  />
                  <Route
                    path={Routes.CRMBoosterConfirm}
                    element={<CRMBoosterPurchaseConfirm />}
                  />
                  <Route
                    index
                    element={<Navigate replace to={Routes.NewChat} />}
                  />
                </Route>

                <Route
                  path={Routes.CompleteUserInfo}
                  element={<CompleteUserInfo />}
                />
              </Route>

              <Route path="*" element={<NoMatch link={Routes.NewChat} />} />
            </Route>
          </SentryRoutes>
        </div>
      )}
    </main>
  );
};

export default App;
