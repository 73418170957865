import { Subscription } from 'entities/Subscription.entity';
import { OneTimePurchasePlanEnum, SubscriptionCycles } from 'enums';

import { ApiBase } from '../ApiBase';

class PaymentsApi extends ApiBase {
  getSubscriptionConfirmation(sessionId: string) {
    return this.client.get<void>(
      `/subscription-confirmation?sessionId=${sessionId}`
    );
  }

  getReportConfirmation(sessionId: string) {
    return this.client.get<void>(`/report-confirmation?sessionId=${sessionId}`);
  }

  getCRMBoosterConfirmation(sessionId: string) {
    return this.client.get<void>(
      `/crm-booster-confirmation?sessionId=${sessionId}`
    );
  }

  getSubscriptions() {
    return this.client.get<Subscription[]>('/subscriptions');
  }

  getActiveSubscription() {
    return this.client.get<Subscription>('/active-subscription');
  }

  getSubscriptionSecret(paymentData: {
    period: SubscriptionCycles;
    page: string | null;
  }) {
    return this.client.post<{ clientSecret: string }>(
      '/subscription-checkout',
      paymentData
    );
  }

  scheduleSubscription(paymentData: { period: SubscriptionCycles }) {
    return this.client.post<void>('/subscription-upgrade', paymentData);
  }

  getOneTimePaymentSecret(paymentData: { count: OneTimePurchasePlanEnum }) {
    return this.client.post<{ clientSecret: string }>(
      '/payment-checkout',
      paymentData
    );
  }

  getReportPaymentSecret(paymentData: {
    industry?: string;
    question?: string;
  }) {
    return this.client.post<{ clientSecret: string }>('/report', paymentData);
  }

  getCRMBoosterPaymentSecret(paymentData: {
    industry?: string;
    question?: string;
    dashboardId: string;
  }) {
    return this.client.post<{ clientSecret: string }>(
      '/crm-booster',
      paymentData
    );
  }

  getOneTimePaymentConfirmation(sessionId: string) {
    return this.client.get<void>(
      `/payment-confirmation?sessionId=${sessionId}`
    );
  }

  managePayments() {
    return this.client.get<{ url: string }>('/customer-details');
  }

  cancelSubscription() {
    return this.client.post<void>('/subscription-cancellation');
  }

  renewSubscription() {
    return this.client.post<void>('/subscription-renew');
  }
}

const instance = new PaymentsApi('/payments');

export { instance as PaymentsApi };
