import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { ReactComponent as EmptySearchIcon } from 'assets/EmptySearchIcon.svg';
import { SearchFilterInput } from 'components';
import { useCRMBoosterCompanies } from 'hooks/api/usePaginatedCRMBoosterCompanies';
import { mockedCRMBoosterCompaniesMaxAmount } from 'services/API/CRMBooster/mocks';
import { CRMBoosterAppliedFilters } from 'views/CRMBoosterDetails/CRMBoosterDetails';

import styles from './styles.module.scss';

interface Props {
  appliedFilters: Partial<CRMBoosterAppliedFilters>;
}

interface Column {
  id: 'company' | 'industry' | 'name' | 'email' | 'website';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'company', label: 'Company', minWidth: 200 },
  { id: 'industry', label: 'Industry', minWidth: 100 },
  { id: 'name', label: 'Name', minWidth: 100 },
  { id: 'email', label: 'Email', minWidth: 100 },
  { id: 'website', label: 'Website', minWidth: 100 }
];

const defaultCompaniesLimit = 10;

export const CRMBoosterDetailsTable: FC<Props> = ({ appliedFilters }) => {
  const { t } = useTranslation();
  const [offset, setOffset] = useState(0);
  const [query, setQuery] = useState<string | null>(null);

  const filtersWithPagination = {
    ...appliedFilters,
    query,
    offset,
    limit: defaultCompaniesLimit
  };
  const { data: CRMBoosterCompanies } = useCRMBoosterCompanies(
    filtersWithPagination
  );

  const setNextPage = () => {
    setOffset((prevOffset) => {
      const nextOffset = Math.max(prevOffset + defaultCompaniesLimit);
      const isCurrentItemsLessThanLimit =
        CRMBoosterCompanies?.length &&
        CRMBoosterCompanies?.length < defaultCompaniesLimit;
      const isNextOffsetMoreThanMaxItems =
        nextOffset >= mockedCRMBoosterCompaniesMaxAmount;

      if (isCurrentItemsLessThanLimit || isNextOffsetMoreThanMaxItems) {
        return prevOffset;
      }

      return nextOffset;
    });
  };

  const setPrevPage = () => {
    setOffset((prevOffset) => Math.max(prevOffset - defaultCompaniesLimit, 0));
  };

  const handlePageChange = (_: ChangeEvent<unknown>, page: number) => {
    const currentPage = offset / defaultCompaniesLimit + 1;

    if (currentPage === page) return currentPage;

    if (currentPage < page) {
      setNextPage();
    } else {
      setPrevPage();
    }
  };

  const handleSubmitSearchQuery = (searchQuery: string | null) => {
    setQuery(searchQuery);
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(
      mockedCRMBoosterCompaniesMaxAmount / defaultCompaniesLimit
    );

    const defaultPage = Math.floor(offset / defaultCompaniesLimit) + 1;
    const displayedFromTotal = totalPages
      ? t(
          'Page.CRMBoosterDetails.Content.Table.Pagination.DisplayedItemsOfTotal',
          {
            offset: offset + 1,
            limit: Math.min(
              offset + defaultCompaniesLimit,
              mockedCRMBoosterCompaniesMaxAmount
            ),
            total: mockedCRMBoosterCompaniesMaxAmount
          }
        )
      : t(
          'Page.CRMBoosterDetails.Content.Table.Pagination.DisplayedItemsOfTotalEmpty'
        );

    return (
      <div className={styles.pagination}>
        <div className={styles['pagination-rows-per-page']}>
          {t('Page.CRMBoosterDetails.Content.Table.Pagination.RowsPerPage')}
          <span className={styles['pagination-rows-per-page-value']}>
            {defaultCompaniesLimit}
          </span>
        </div>

        <div className={styles['pagination-items-from-total']}>
          {displayedFromTotal}
        </div>

        {!!totalPages && (
          <Pagination
            count={totalPages}
            defaultPage={defaultPage}
            onChange={handlePageChange}
          />
        )}
      </div>
    );
  };

  const renderEmptyState = () => (
    <div className={styles['empty-state']}>
      <EmptySearchIcon />
      <h3 className={styles['empty-state-title']}>
        {t('Page.CRMBoosterDetails.Content.Table.EmptyState.Title')}
      </h3>
      <span className={styles['empty-state-description']}>
        {t('Page.CRMBoosterDetails.Content.Table.EmptyState.Description')}
      </span>
    </div>
  );

  return (
    <>
      <SearchFilterInput
        placeholder={t(
          'Page.CRMBoosterDetails.Content.Table.Search.Placeholder'
        )}
        onSubmit={handleSubmitSearchQuery}
      />

      <div className={styles.container}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {CRMBoosterCompanies?.length ? (
              <TableBody>
                {CRMBoosterCompanies.map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];

                      return (
                        <TableCell
                          className="overflowed-text"
                          key={column.id}
                          align={column.align}
                        >
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              renderEmptyState()
            )}
          </Table>
        </TableContainer>

        {renderPagination()}
      </div>
    </>
  );
};
