import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { fetchCRMBoosters } from 'actions/CRMBoosterActions';
import { CRMBooster } from 'entities/CRMBooster.entity';
import { queryKeys } from 'enums';
import { useAuthContext } from 'hooks/useAuthContext';
import { FetchCRMBoostersFilters } from 'services/API/CRMBooster/CRMBoosterApi';

const defaultCRMBoostersLimit = 10;
const defaultCRMBoostersOffset = 0;

export const usePaginatedCRMBoosters = (filters?: FetchCRMBoostersFilters) => {
  const { isAuthenticated } = useAuthContext();

  return useInfiniteQuery<CRMBooster[]>({
    enabled: isAuthenticated,
    queryKey: queryKeys.paginatedCRMBoosters(filters),
    queryFn: ({ pageParam }) => {
      const offset = pageParam as number;
      const limit = filters?.limit || defaultCRMBoostersLimit;

      return fetchCRMBoosters({
        ...filters,
        limit,
        offset
      });
    },
    initialPageParam: filters?.offset || defaultCRMBoostersOffset,
    getNextPageParam: (lastPage, pages) => {
      const limit = filters?.limit || defaultCRMBoostersLimit;
      const nextOffset = limit * pages.length;

      if (lastPage?.length < limit) return null;

      return nextOffset;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 1,
    placeholderData: keepPreviousData
  });
};
