import { deserialize, serializable } from 'serializr';

export class CRMBoosterCompany {
  @serializable
  id: string = '';

  @serializable
  name: string = '';

  @serializable
  company: string = '';

  @serializable
  email: string = '';

  @serializable
  website: string = '';

  @serializable
  industry: string = '';

  static deserialize(json: Object | string): CRMBoosterCompany {
    return deserialize(CRMBoosterCompany, json);
  }
}
