import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MailIcon } from 'assets/mail-big.svg';
import { handleAnimationSwitch } from 'utils/helpers/animationHelper';

import styles from './styles.module.scss';

export const EmailMarketingCampaignsPlaceholder: FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    handleAnimationSwitch(false);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles['placeholder-state']}>
        <MailIcon />

        <h3 className={styles['placeholder-state-title']}>
          {t('Page.EmailMarketingCampaigns.Placeholder.Title')}
        </h3>
        <div
          className={styles['placeholder-state-description']}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t('Page.EmailMarketingCampaigns.Placeholder.Description')
          }}
        />
      </div>
    </div>
  );
};
