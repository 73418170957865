import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { Button } from '@mui/material';
import cn from 'classnames';
import { Modal } from 'components';
import { Routes } from 'enums';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import { RequestCRMBoosterSuccessModal } from './RequestCRMBoosterSuccessModal';

import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  availableRows: number;
  onClose: () => void;
  industry?: string;
  question?: string;
  dashboardId: string;
}

export const RequestCRMBoosterModal: FC<Props> = ({
  isOpen,
  onClose,
  industry,
  question,
  dashboardId,
  availableRows
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { chatId } = useParams();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);

  const pricePerRow = 1.5;

  const getTrackPlace = () => {
    const { pathname } = window.location;
    let place = '-';

    if (pathname.includes('chat')) place = 'Chat';
    if (pathname.includes('dashboard')) place = 'Dashboard';

    return place;
  };

  useEffect(() => {
    if (!document.getElementById('recaptcha-script')) {
      const script = document.createElement('script');

      script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
      script.id = 'recaptcha-script';

      document.body.appendChild(script);
    }
  }, []);

  const closeSuccessModal = useCallback(() => {
    setIsSuccessModalOpen(false);
  }, []);

  const onClick = () => {
    // @ts-expect-error undeclared variable
    if (grecaptcha) {
      // @ts-expect-error undeclared variable
      grecaptcha.ready(() => {
        mixpanel?.track(MixpanelEvents.RequestCRMBooster, {
          Place: getTrackPlace(),
          'Chat ID': chatId,
          'Dashboard ID': dashboardId || '-'
        });
        onClose();
        navigate({
          pathname: Routes.CRMBoosterCheckout,
          search: createSearchParams({
            industry: industry || '',
            question: question || '',
            dashboardId: dashboardId || ''
          }).toString()
        });
      });
    }
  };

  return (
    <>
      <RequestCRMBoosterSuccessModal
        place={getTrackPlace()}
        isOpen={isSuccessModalOpen}
        onClose={closeSuccessModal}
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        titleClassName={styles.title}
        title={t('Page.RequestCRMBoosterModal.Title')}
      >
        <p className={styles.subtitle}>
          {t('Page.RequestCRMBoosterModal.Subtitle')}
        </p>

        <div className={styles['main-content']}>
          <div className={styles['input-container']}>
            <span className={styles.label}>
              {t('Page.RequestCRMBoosterModal.Industry')}
            </span>
            <TextareaAutosize
              disabled
              maxRows={1}
              value={industry}
              className={cn(styles.container, 'capitalize')}
            />
          </div>
          <div className={styles['input-container']}>
            <span className={styles.label}>
              {t('Page.RequestCRMBoosterModal.AdditionalInformation')}
            </span>
            <TextareaAutosize
              disabled
              minRows={2}
              value={t(
                'Page.RequestCRMBoosterModal.AdditionalInformationPlaceholder',
                { question }
              )}
              className={styles.container}
            />
          </div>
          <p className={cn(styles.subtitle, styles.indented)}>
            {t('Page.RequestCRMBoosterModal.AdditionalInfo', {
              pricePerRow,
              amount: availableRows,
              totalPrice: (availableRows || 0) * pricePerRow
            })}
          </p>
        </div>

        <div className={styles.buttons}>
          <Button
            fullWidth
            size="medium"
            type="submit"
            color="primary"
            variant="outlined"
            onClick={onClose}
            className={styles.cancel}
          >
            {t('Page.RequestCRMBoosterModal.Cancel')}
          </Button>

          <Button
            size="medium"
            color="primary"
            variant="contained"
            onClick={onClick}
            className={styles.submit}
          >
            {t('Page.RequestCRMBoosterModal.RequestCRMBooster')}
          </Button>
        </div>
      </Modal>
    </>
  );
};
