import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { fetchCRMBoosterCompanies } from 'actions/CRMBoosterActions';
import { CRMBoosterCompany } from 'entities/CRMBoosterCompany.entity';
import { queryKeys } from 'enums';
import { useAuthContext } from 'hooks/useAuthContext';
import { FetchCRMBoosterCompaniesFilters } from 'services/API/CRMBooster/CRMBoosterApi';

export const useCRMBoosterCompanies = (
  filters?: FetchCRMBoosterCompaniesFilters
) => {
  const { isAuthenticated } = useAuthContext();

  return useQuery<CRMBoosterCompany[]>({
    enabled: isAuthenticated,
    queryKey: queryKeys.CRMBoosterCompanies(filters),
    queryFn: () => fetchCRMBoosterCompanies(filters),
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: 1,
    placeholderData: keepPreviousData
  });
};
