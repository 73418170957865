import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Collapse } from '@mui/material';
import { ReactComponent as FilterIcon } from 'assets/filter.svg';
import cn from 'classnames';
import { FilterItem } from 'components/FloatingFilter/FloatingFilter';
import { FilterTypeEnum } from 'enums/FilterType.enum';
import { useMobile, useTablet } from 'hooks';
import { SelectedFilterItem } from 'types';
import { handleAnimationSwitch } from 'utils/helpers/animationHelper';

import { CRMBoosterDetailsContent } from './CRMBoosterDetailsContent';
import { CRMBoosterDetailsFilters } from './CRMBoosterDetailsFilters';

import styles from './styles.module.scss';

export type CRMBoosterFilters = {
  selectedRegion: SelectedFilterItem;
  selectedMachine: SelectedFilterItem;
  selectedIndustry: SelectedFilterItem;
  selectedMaterial: SelectedFilterItem;
  selectedTechnology: SelectedFilterItem;
  selectedMachinesRange: number[];
  selectedEmployeesRange: number[];
};

export type CRMBoosterAppliedFilters = {
  selectedMachine: SelectedFilterItem[];
  selectedIndustry: SelectedFilterItem[];
  selectedRegion: SelectedFilterItem[];
  selectedMaterial: SelectedFilterItem[];
  selectedTechnology: SelectedFilterItem[];
  selectedMachinesRange: number[];
  selectedEmployeesRange: number[];
};

export type CRMBoosterFilterSetters = {
  resetToDefaultFilters(): void;
  recalculateFilters(): void;
  setSelectedRegion: Dispatch<SetStateAction<SelectedFilterItem>>;
  setSelectedMachine: Dispatch<SetStateAction<SelectedFilterItem>>;
  setSelectedIndustry: Dispatch<SetStateAction<SelectedFilterItem>>;
  setSelectedMaterial: Dispatch<SetStateAction<SelectedFilterItem>>;
  setSelectedTechnology: Dispatch<SetStateAction<SelectedFilterItem>>;
  setSelectedMachinesRange: Dispatch<SetStateAction<number[]>>;
  setSelectedEmployeesRange: Dispatch<SetStateAction<number[]>>;
};

type CombinableFilterKeys =
  | 'selectedRegion'
  | 'selectedIndustry'
  | 'selectedTechnology'
  | 'selectedMaterial'
  | 'selectedMachine';

export const CRMBoosterDetails: FC = () => {
  const { t } = useTranslation();
  const isTablet = useTablet();
  const isMobile = useMobile();

  const [appliedFilters, setAppliedFilters] = useState<
    Partial<CRMBoosterAppliedFilters>
  >({});
  const [selectedRegion, setSelectedRegion] =
    useState<SelectedFilterItem>(null);
  const [selectedMachine, setSelectedMachine] =
    useState<SelectedFilterItem>(null);
  const [selectedIndustry, setSelectedIndustry] =
    useState<SelectedFilterItem>(null);
  const [selectedMaterial, setSelectedMaterial] =
    useState<SelectedFilterItem>(null);
  const [selectedTechnology, setSelectedTechnology] =
    useState<SelectedFilterItem>(null);

  const [expanded, setIsExpanded] = useState(false);

  // TODO: Change default values to be from BE side
  const [selectedMachinesRange, setSelectedMachinesRange] = useState<number[]>([
    0, 8599
  ]);
  const [selectedEmployeesRange, setSelectedEmployeesRange] = useState<
    number[]
  >([0, 300316]);

  useEffect(() => {
    handleAnimationSwitch(true);
  }, []);

  const defaultFilters: CRMBoosterFilters = useMemo(
    () => ({
      selectedRegion,
      selectedMachine,
      selectedIndustry,
      selectedMaterial,
      selectedTechnology,
      selectedMachinesRange,
      selectedEmployeesRange
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updatedFilters: CRMBoosterFilters = {
    selectedRegion,
    selectedMachine,
    selectedIndustry,
    selectedMaterial,
    selectedTechnology,
    selectedMachinesRange,
    selectedEmployeesRange
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetToDefaultFilters = () => {
    setSelectedRegion(defaultFilters.selectedRegion);
    setSelectedMachine(defaultFilters.selectedMachine);
    setSelectedMachinesRange(defaultFilters.selectedMachinesRange);
    setSelectedIndustry(defaultFilters.selectedIndustry);
    setSelectedTechnology(defaultFilters.selectedTechnology);
    setSelectedMaterial(defaultFilters.selectedMaterial);
    setSelectedEmployeesRange(defaultFilters.selectedEmployeesRange);
  };

  const combineFilters = (
    key: CombinableFilterKeys,
    selectedFilterItem?: SelectedFilterItem
  ) => {
    if (selectedFilterItem) {
      const hasMatch = appliedFilters?.[key]?.find(
        (item) => item?.name === selectedFilterItem.name
      );

      return hasMatch
        ? appliedFilters[key]
        : [...(appliedFilters?.[key] || []), selectedFilterItem];
    }
    return appliedFilters?.[key] || [];
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const recalculateFilters = () => {
    const newFilters = {
      selectedMachine: combineFilters('selectedMachine', selectedMachine),
      selectedMaterial: combineFilters('selectedMaterial', selectedMaterial),
      selectedTechnology: combineFilters(
        'selectedTechnology',
        selectedTechnology
      ),
      selectedIndustry: combineFilters('selectedIndustry', selectedIndustry),
      selectedRegion: selectedRegion
        ? [selectedRegion]
        : [...(appliedFilters.selectedRegion || [])],
      selectedMachinesRange,
      selectedEmployeesRange
    };

    setAppliedFilters(newFilters);
    setSelectedRegion(defaultFilters.selectedRegion);
    setSelectedMachine(defaultFilters.selectedMachine);
    setSelectedIndustry(defaultFilters.selectedIndustry);
    setSelectedTechnology(defaultFilters.selectedTechnology);
    setSelectedMaterial(defaultFilters.selectedMaterial);
  };

  const filterSetters: CRMBoosterFilterSetters = useMemo(
    () => ({
      resetToDefaultFilters,
      recalculateFilters,
      setSelectedRegion,
      setSelectedMachine,
      setSelectedIndustry,
      setSelectedMaterial,
      setSelectedTechnology,
      setSelectedMachinesRange,
      setSelectedEmployeesRange
    }),
    [recalculateFilters, resetToDefaultFilters]
  );

  const handleRemoveFilter = ({ typeName }: FilterItem) => {
    const newFilters = { ...appliedFilters };

    switch (typeName) {
      case FilterTypeEnum.Regions:
        setSelectedRegion(null);
        delete newFilters.selectedRegion;
        break;
      case FilterTypeEnum.EmployeesNumber:
        setSelectedEmployeesRange([0, 0]);
        newFilters.selectedEmployeesRange = [0, 0];
        break;
      case FilterTypeEnum.Industries:
        setSelectedIndustry(null);
        delete newFilters.selectedIndustry;
        break;
      case FilterTypeEnum.Technologies:
        setSelectedTechnology(null);
        delete newFilters.selectedTechnology;
        break;
      case FilterTypeEnum.Materials:
        setSelectedMaterial(null);
        delete newFilters.selectedMaterial;
        break;
      case FilterTypeEnum.Machines:
        setSelectedMachine(null);
        delete newFilters.selectedMachine;
        break;
      case FilterTypeEnum.MachinesNumber:
        setSelectedMachinesRange([0, 0]);
        newFilters.selectedMachinesRange = [0, 0];
        break;
      default:
        break;
    }

    setAppliedFilters(newFilters);
  };

  const buildAppliedFilterName = (key: CombinableFilterKeys, name?: string) => {
    const prevFilters = appliedFilters?.[key] ? appliedFilters[key] : [];
    return [prevFilters?.map((appliedFilter) => appliedFilter?.name), name]
      .filter(Boolean)
      .join(', ');
  };

  const mappedFilters: FilterItem[] = [
    {
      name: buildAppliedFilterName('selectedRegion', selectedRegion?.name),
      typeName: FilterTypeEnum.Regions
    },
    {
      name: selectedEmployeesRange[1],
      typeName: FilterTypeEnum.EmployeesNumber
    },
    {
      name: buildAppliedFilterName('selectedIndustry', selectedIndustry?.name),
      typeName: FilterTypeEnum.Industries
    },
    {
      name: buildAppliedFilterName(
        'selectedTechnology',
        selectedTechnology?.name
      ),
      typeName: FilterTypeEnum.Technologies
    },
    {
      name: buildAppliedFilterName('selectedMaterial', selectedMaterial?.name),
      typeName: FilterTypeEnum.Materials
    },
    {
      name: buildAppliedFilterName('selectedMachine', selectedMachine?.name),
      typeName: FilterTypeEnum.Machines
    },
    {
      name: selectedMachinesRange?.[1],
      typeName: FilterTypeEnum.MachinesNumber
    }
  ].filter(({ name }) => name);

  const toggleFilters = useCallback(() => {
    setIsExpanded((value) => !value);
  }, []);

  return (
    <div className={styles.container}>
      {isTablet || isMobile ? (
        <div className={cn(styles.filter)}>
          <div className={styles.header}>
            <div className={styles['title-container']}>
              <FilterIcon className={styles['filter-icon']} />
              <span className={styles.title}>
                {t('Page.Dashboard.AppliedFilters', {
                  amount: mappedFilters.length
                })}
              </span>
            </div>
            <KeyboardArrowDownIcon
              onClick={toggleFilters}
              className={cn(styles['arrow-icon'], expanded && styles.expanded)}
            />
          </div>
          <Collapse unmountOnExit in={expanded}>
            <CRMBoosterDetailsFilters
              filterSetters={filterSetters}
              defaultFilters={defaultFilters}
              updatedFilters={updatedFilters}
              className={styles['collapsable-container']}
            />
          </Collapse>
        </div>
      ) : (
        <CRMBoosterDetailsFilters
          defaultFilters={defaultFilters}
          updatedFilters={updatedFilters}
          filterSetters={filterSetters}
        />
      )}
      <CRMBoosterDetailsContent
        appliedFilters={appliedFilters}
        updatedFilters={mappedFilters}
        onRemoveFilter={handleRemoveFilter}
      />
    </div>
  );
};
