import { InfiniteData } from '@tanstack/react-query';
import { Chat } from 'entities/Chat.entity';
import { CRMBooster } from 'entities/CRMBooster.entity';
import { DashboardData } from 'entities/Dashboard.entity';
import { Message } from 'entities/Message.entity';
import { Notification } from 'entities/Notification.entity';
import { Report } from 'entities/Report.entity';
import { InsightsType, queryKeys } from 'enums';
import { updateWidgetFeedbackCachedData } from 'hooks/api';
import { queryClient } from 'index';
import {
  DashboardTextWidgetsData,
  DashboardTitleData,
  DashboardUpdateData,
  NestedDashboardData,
  NewMessageData,
  WidgetFilterData,
  WidgetRateData
} from 'types/SSE';
import {
  DEFAULT_CRM_BOOSTER_FILTERS,
  DEFAULT_NOTIFICATIONS_FILTERS,
  DEFAULT_REPORTS_FILTERS,
  DEFAULT_SIDEBAR_CHATS_FILTERS
} from 'utils/constants';

const getCurrentDashboardId = () => {
  // eslint-disable-next-line no-restricted-globals
  const splittedUrl = location.pathname.split('/');

  return splittedUrl?.[4];
};

export const updateDashboardTextWidgets = ({
  dashboardId,
  widgets
}: DashboardTextWidgetsData) => {
  if (!dashboardId) {
    return;
  }

  const prevDashboardData: DashboardData | undefined = queryClient.getQueryData(
    queryKeys.dashboardDetails(dashboardId)
  );

  if (!prevDashboardData) return;

  const updatedWidgets = prevDashboardData.widgets?.map((widget) => {
    const matchedWidget = widgets.find(({ type }) => type === widget.type);

    if (matchedWidget) {
      return matchedWidget;
    }

    return widget;
  });

  if (updatedWidgets?.length) {
    queryClient.setQueryData(queryKeys.dashboardDetails(dashboardId!), {
      ...prevDashboardData,
      widgets: updatedWidgets
    });
  }
};

export const updateDashboardTitle = ({
  dashboardId,
  title,
  originDashboardId
}: DashboardTitleData) => {
  if (!dashboardId) {
    return;
  }
  const currentDashboardId = getCurrentDashboardId();

  if (currentDashboardId !== dashboardId) {
    const prevDashboardData: DashboardData | undefined =
      queryClient.getQueryData(queryKeys.dashboardDetails(currentDashboardId));

    const historyWidget = prevDashboardData?.widgets?.find(
      (widget) => widget.type === InsightsType.DashboardHistory
    );

    const isSameOrigin = historyWidget?.params?.data?.find(
      (dashboard) => dashboard.id === dashboardId
    );

    if (!isSameOrigin) {
      return;
    }
  }

  const currentId =
    currentDashboardId !== dashboardId ? currentDashboardId : dashboardId;

  queryClient.invalidateQueries({
    queryKey: queryKeys.paginatedChats().slice(0, 2),
    exact: false
  });

  if (originDashboardId) {
    queryClient.invalidateQueries({
      queryKey: queryKeys.nestedDashboards(originDashboardId)
    });

    queryClient.refetchQueries({
      queryKey: queryKeys.nestedDashboards(originDashboardId)
    });
  }

  const prevDashboardData: DashboardData | undefined = queryClient.getQueryData(
    queryKeys.dashboardDetails(currentId)
  );

  if (!prevDashboardData) return;

  const updatedWidgets = prevDashboardData.widgets?.map((widget) => {
    if (widget.type === InsightsType.DashboardHistory) {
      const updatedData = widget?.params?.data?.map((item) => {
        if (item.id === dashboardId) {
          return {
            ...item,
            title
          };
        }

        return item;
      });

      return {
        ...widget,
        params: {
          ...widget.params,
          data: updatedData
        }
      };
    }

    return widget;
  });

  if (updatedWidgets?.length) {
    queryClient.setQueryData(queryKeys.dashboardDetails(currentId!), {
      ...prevDashboardData,
      title,
      widgets: updatedWidgets
    });
  }
};

export const updateHistoryWidget = ({
  originDashboardId,
  nestedDashboards
}: NestedDashboardData) => {
  const currentDashboardId = getCurrentDashboardId();

  if (!currentDashboardId) {
    return;
  }

  const currentDashboard: DashboardData | undefined = queryClient.getQueryData(
    queryKeys.dashboardDetails(currentDashboardId)
  );

  if (
    currentDashboard?.id !== originDashboardId &&
    currentDashboard?.originDashboardId !== originDashboardId
  ) {
    return;
  }

  const updatedWidgets = currentDashboard.widgets?.map((widget) => {
    if (widget.type === InsightsType.DashboardHistory) {
      return {
        ...widget,
        params: {
          ...widget.params,
          data: nestedDashboards
        }
      };
    }

    return widget;
  });

  if (updatedWidgets?.length) {
    queryClient.setQueryData(queryKeys.dashboardDetails(currentDashboardId!), {
      ...currentDashboard,
      widgets: updatedWidgets
    });
  }
};

export const updateDashboard = ({
  dashboardId,
  data,
  nestedDashboards
}: DashboardUpdateData) => {
  const currentDashboardId = getCurrentDashboardId();

  if (!currentDashboardId || currentDashboardId !== dashboardId) {
    return;
  }

  const prevDashboardData: DashboardData | undefined = queryClient.getQueryData(
    queryKeys.dashboardDetails(dashboardId)
  );

  if (!prevDashboardData) return;

  queryClient.setQueryData(queryKeys.dashboardDetails(dashboardId!), {
    ...prevDashboardData,
    ...data
  });

  updateHistoryWidget({
    originDashboardId: prevDashboardData.originDashboardId,
    nestedDashboards
  });
};

export const updateRateWidget = ({ widgetId }: WidgetRateData) => {
  const currentDashboardId = getCurrentDashboardId();

  if (!currentDashboardId) {
    return;
  }

  const currentDashboard: DashboardData | undefined = queryClient.getQueryData(
    queryKeys.dashboardDetails(currentDashboardId)
  );

  const matchedWidget = currentDashboard?.widgets?.find(
    (widget) => widget.id === widgetId
  );

  if (!matchedWidget) {
    return;
  }

  updateWidgetFeedbackCachedData(widgetId, currentDashboardId);
};

export const updateWidgetFilter = ({
  widgetId,
  dashboardId,
  filters,
  mapType
}: WidgetFilterData) => {
  const currentDashboardId = getCurrentDashboardId();

  if (!currentDashboardId || currentDashboardId !== dashboardId) {
    return;
  }

  const currentDashboard: DashboardData | undefined = queryClient.getQueryData(
    queryKeys.dashboardDetails(currentDashboardId)
  );

  if (!currentDashboard) {
    return;
  }

  const matchedWidget = currentDashboard?.widgets?.find(
    (widget) => widget.id === widgetId
  );

  if (!matchedWidget) {
    return;
  }

  const updatedWidgets = currentDashboard.widgets?.map((widget) => {
    if (widget.id === widgetId) {
      return {
        ...widget,
        filters,
        mapType
      };
    }

    return widget;
  });

  if (updatedWidgets?.length) {
    queryClient.setQueryData(queryKeys.dashboardDetails(currentDashboardId!), {
      ...currentDashboard,
      widgets: updatedWidgets
    });
  }
};

export const updateFilteredChats = (chat: Chat) => {
  queryClient.setQueriesData<InfiniteData<Chat[]>>(
    {
      queryKey: queryKeys
        .paginatedChats(DEFAULT_SIDEBAR_CHATS_FILTERS)
        .slice(0, 2),
      exact: false
    },
    (data) => {
      if (!data) return data;

      const newData = JSON.parse(JSON.stringify(data)) as InfiniteData<Chat[]>;

      // Remove existing chat from all pages
      newData.pages = newData.pages.map((page) =>
        page.filter(({ id }: Chat) => id !== chat.id)
      );

      // Add the chat to the first page
      newData.pages[0].unshift(chat);

      // Handle overflow
      for (let i = 0; i < newData.pages.length; i += 1) {
        while (newData.pages[i].length > 10) {
          const overflowItem = { ...newData.pages[i].pop() } as Chat;

          if (newData.pages[i + 1]) {
            newData.pages[i + 1].unshift(overflowItem);
          }
        }
      }

      return newData;
    }
  );
};

export const updateChatMessages = (data: NewMessageData) => {
  const prevMessages: Array<Message> | undefined = queryClient.getQueryData(
    queryKeys.chatMessages(data.chatId)
  );

  const hasMessage = prevMessages?.find(({ id }) => {
    return data.messages.find((message) => message.id === id);
  });

  if (!hasMessage) {
    queryClient.setQueryData(queryKeys.chatMessages(data.chatId), [
      ...data.messages.reverse(),
      ...(prevMessages || [])
    ]);

    queryClient.refetchQueries({
      queryKey: queryKeys.paginatedChats(DEFAULT_SIDEBAR_CHATS_FILTERS)
    });
  }
};

export const updateNotifications = (notification: Notification) => {
  queryClient.setQueryData(
    queryKeys.notifications(DEFAULT_NOTIFICATIONS_FILTERS),
    (notifications: Notification[]) => {
      const filteredNotifications = (notifications || []).filter(
        ({ id }) => id !== notification.id
      );

      return [...filteredNotifications, notification].slice(
        -DEFAULT_NOTIFICATIONS_FILTERS.limit
      );
    }
  );

  queryClient.setQueryData<InfiniteData<Notification[]>>(
    queryKeys.paginatedNotifications,
    (data) => {
      if (!data) return data;

      const isNotificationAlreadyAdded = data.pages[0].find(
        ({ id }: Notification) => id === notification.id
      );

      if (isNotificationAlreadyAdded) return data;

      const newData = JSON.parse(JSON.stringify(data));

      newData.pages[0].unshift(notification);

      for (let i = 0; i < newData.pages.length; i += 1) {
        const currentPage = newData.pages[i];

        if (currentPage.length > DEFAULT_NOTIFICATIONS_FILTERS.limit) {
          const overflowItem = currentPage.pop();

          if (
            newData.pages[i + 1] &&
            newData.pages[i + 1].length < DEFAULT_NOTIFICATIONS_FILTERS.limit
          ) {
            newData.pages[i + 1].unshift(overflowItem);
          }
        }
      }

      const lastPage = newData.pages[newData.pages.length - 1];

      if (lastPage.length > DEFAULT_NOTIFICATIONS_FILTERS.limit) {
        lastPage.pop();
      }

      return newData;
    }
  );
};

export const updateReports = (report: Report) => {
  queryClient.setQueriesData<InfiniteData<Report[]>>(
    {
      queryKey: queryKeys.paginatedReports().slice(0, -1),
      exact: false
    },
    (data) => {
      if (!data) return data;

      const newData = {
        ...data,
        pages: (data as InfiniteData<Report[]>).pages.map((page) =>
          page.map((item) => {
            if (item.id === report.id) {
              return report;
            }

            return item;
          })
        )
      };

      return newData;
    }
  );
};

export const updateCRMBoosters = (crmBooster: CRMBooster) => {
  queryClient.setQueriesData(
    {
      queryKey: queryKeys.paginatedCRMBoosters().slice(0, -1),
      exact: false
    },
    (data) => {
      if (!data) return data;

      const newData = {
        ...data,
        pages: (data as InfiniteData<CRMBooster[]>).pages.map((page) =>
          page.map((item) => {
            if (item.id === crmBooster.id) {
              return crmBooster;
            }

            return item;
          })
        )
      };

      return newData;
    }
  );
};

export const updateReportsWithNew = (report: Report) => {
  queryClient.setQueriesData<InfiniteData<Report[]>>(
    {
      queryKey: queryKeys.paginatedReports().slice(0, -1),
      exact: false
    },
    (data) => {
      if (!data) return data;

      const isReportAlreadyAdded = data.pages[0].find(
        ({ id }: Report) => id === report.id
      );

      if (isReportAlreadyAdded) return data;

      const newData = JSON.parse(JSON.stringify(data));

      newData.pages[0].unshift(report);

      for (let i = 0; i < newData.pages.length; i += 1) {
        const currentPage = newData.pages[i];

        if (currentPage.length > DEFAULT_REPORTS_FILTERS.limit) {
          const overflowItem = currentPage.pop();

          if (
            newData.pages[i + 1] &&
            newData.pages[i + 1].length < DEFAULT_REPORTS_FILTERS.limit
          ) {
            newData.pages[i + 1].unshift(overflowItem);
          }
        }
      }

      const lastPage = newData.pages[newData.pages.length - 1];

      if (lastPage.length > DEFAULT_REPORTS_FILTERS.limit) {
        lastPage.pop();
      }

      return newData;
    }
  );
};

export const updateCRMBoostersWithNew = (crmBooster: CRMBooster) => {
  queryClient.setQueryData(
    queryKeys.paginatedCRMBoosters().slice(0, -1),
    (crmBoosters: CRMBooster[]) => {
      const filteredCRMBooster = (crmBoosters || []).filter(
        ({ id }) => id !== crmBooster.id
      );

      return [...filteredCRMBooster, crmBoosters].slice(
        -DEFAULT_CRM_BOOSTER_FILTERS.limit
      );
    }
  );

  queryClient.setQueryData<InfiniteData<CRMBooster[]>>(
    queryKeys.paginatedCRMBoosters().slice(0, -1),
    (data) => {
      if (!data) return data;

      const isCRMBoosterAlreadyAdded = data.pages[0].find(
        ({ id }: CRMBooster) => id === crmBooster.id
      );

      if (isCRMBoosterAlreadyAdded) return data;

      const newData = JSON.parse(JSON.stringify(data));

      newData.pages[0].unshift(crmBooster);

      for (let i = 0; i < newData.pages.length; i += 1) {
        const currentPage = newData.pages[i];

        if (currentPage.length > DEFAULT_CRM_BOOSTER_FILTERS.limit) {
          const overflowItem = currentPage.pop();

          if (
            newData.pages[i + 1] &&
            newData.pages[i + 1].length < DEFAULT_CRM_BOOSTER_FILTERS.limit
          ) {
            newData.pages[i + 1].unshift(overflowItem);
          }
        }
      }

      const lastPage = newData.pages[newData.pages.length - 1];

      if (lastPage.length > DEFAULT_CRM_BOOSTER_FILTERS.limit) {
        lastPage.pop();
      }

      return newData;
    }
  );
};
