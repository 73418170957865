import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import cn from 'classnames';
import { CommonTooltip } from 'components';
import { DashboardCompany } from 'entities/DashboardCompany.entity';

import { MachineModal } from '../MachineModal';
import { MachineParkModal } from '../MachineParkModal';
import { ImagesSection } from './ImagesSection';

import styles from './styles.module.scss';

enum InfoItems {
  industries = 'industries',
  materials = 'materials',
  certification = 'certification',
  technologies = 'technologies',
  products = 'products'
}

enum ContactItems {
  websiteUrl = 'website_url',
  phoneNumber = 'phone_numbers',
  companyEmail = 'emails',
  numberOfEmployees = 'number_of_employees',
  foundationYear = 'foundation_year'
}

const ContactLabels = Object.freeze({
  [ContactItems.websiteUrl]: 'Page.Dashboard.ManufactureProfile.Website',
  [ContactItems.phoneNumber]: 'Page.Dashboard.ManufactureProfile.PhoneNumber',
  [ContactItems.companyEmail]: 'Page.Dashboard.ManufactureProfile.CompanyEmail',
  [ContactItems.numberOfEmployees]:
    'Page.Dashboard.ManufactureProfile.NumberOfEmployees',
  [ContactItems.foundationYear]:
    'Page.Dashboard.ManufactureProfile.DateOfEstablishment'
});

const InfoLabels = Object.freeze({
  [InfoItems.industries]: 'Page.Dashboard.ManufactureProfile.Industries',
  [InfoItems.materials]: 'Page.Dashboard.ManufactureProfile.Materials',
  [InfoItems.certification]: 'Page.Dashboard.ManufactureProfile.Certification',
  [InfoItems.technologies]: 'Page.Dashboard.ManufactureProfile.Technologies',
  [InfoItems.products]: 'Page.Dashboard.ManufactureProfile.Products'
});

interface Props {
  data: DashboardCompany;
  className?: string;
}

export const ManufactureCard: FC<Props> = ({ data, className }) => {
  const { t } = useTranslation();

  const contentRef = useRef<HTMLDivElement>(null);
  const [hasScroll, setHasScroll] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showMachinesParkModal, setShowMachinesParkModal] =
    useState<boolean>(false);

  const closeDetailsModal = () => setShowDetails(false);
  const openDetailsModal = () => setShowDetails(true);

  const closeMachinesParkModal = () => setShowMachinesParkModal(false);
  const openMachinesParkModal = () => setShowMachinesParkModal(true);

  const { machines } = data;
  const machineDetails = machines?.[0]?.details;
  const machineDetailsRows = useMemo(
    () =>
      machineDetails
        ? Object.entries(machineDetails)
            .filter(([, value]) => value)
            .map(([key, value]) => ({
              label: key,
              value: value ? value.toString() : ''
            }))
        : [],
    [machineDetails]
  );

  const machinesData = useMemo(() => {
    const rowsData = data.machines?.reduce(
      (acc, obj) => {
        ['Brand', 'Model', 'Technology'].forEach((property) => {
          if (obj.details?.[property]) {
            // @ts-expect-error type error
            acc[property.toLowerCase()].push(obj?.details[property]);
          }
        });
        return acc;
      },
      {
        brand: [],
        model: [],
        technology: []
      }
    );

    return {
      // @ts-expect-error type error
      brand: [...new Set(rowsData?.brand)],
      // @ts-expect-error type error
      model: [...new Set(rowsData?.model)],
      // @ts-expect-error type error
      technology: [...new Set(rowsData?.technology)]
    };
  }, [data.machines]);

  const totalAmount = useMemo(() => {
    return data.machines?.reduce((sum, machine) => {
      return sum + (machine?.details?.Quantity || 0);
    }, 0);
  }, [data.machines]);

  const machineCommonDetailsRows = useMemo(
    () => [
      {
        title: t('Page.Dashboard.ManufactureProfile.MachineBrand'),
        value: machinesData?.brand?.length
          ? machinesData.brand?.map((brand) => brand).join(', ')
          : '',
        className: 'capitalize'
      },
      {
        title: t('Page.Dashboard.ManufactureProfile.MachineModel'),
        value: machinesData?.model?.length
          ? machinesData.model.map((model) => model).join(', ')
          : '',
        onClick: openDetailsModal,
        className: cn(
          styles.type,
          !machineDetailsRows.length && styles['no-data']
        )
      },
      {
        title: t('Page.Dashboard.ManufactureProfile.MachineTechnologies'),
        value: machinesData?.technology?.length
          ? machinesData.technology.map((technology) => technology).join(', ')
          : '',
        className: 'capitalize'
      },
      {
        title: t('Page.Dashboard.ManufactureProfile.Amount'),
        value: totalAmount || ''
      }
    ],
    [machineDetailsRows.length, machinesData, t, totalAmount]
  );

  useEffect(() => {
    const element = contentRef.current;

    if (element) {
      setHasScroll(
        element.scrollHeight > element.getBoundingClientRect().height
      );
    }
  }, []);

  const contactRows = [
    {
      title: ContactLabels.website_url,
      value: data.website_url,
      renderCell: (data: DashboardCompany['website_url']) => (
        <Link
          to={`https://${data}` || ''}
          target="_blank"
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={cn(styles['cell-value'], styles.link)}
        >
          {data}
        </Link>
      )
    },
    {
      title: ContactLabels.number_of_employees,
      value: data.employee_count
    },
    {
      title: ContactLabels.foundation_year,
      value: data.foundation_year
    }
  ];

  const infoRows = [
    {
      label: t(InfoLabels[InfoItems.industries]),
      value:
        typeof data?.industries?.[0] === 'string'
          ? data.industries.join(', ')
          : // @ts-expect-error type error
            data?.industries?.map(({ industry }) => industry).join(', ')
    },
    {
      label: t(InfoLabels[InfoItems.products]),
      value:
        typeof data.products?.[0] === 'string'
          ? data?.products?.join(', ')
          : // @ts-expect-error type error
            // eslint-disable-next-line camelcase
            data?.products?.map(({ product_name }) => product_name).join(', ')
    },
    {
      label: t(InfoLabels[InfoItems.certification]),
      value: data?.certification?.join(', ')
    },
    {
      label: t(InfoLabels[InfoItems.technologies]),
      value: data?.technologies?.join(', ')
    }
  ];

  return (
    <div className={cn(styles.card, hasScroll && styles.overflowed, className)}>
      {!!machineDetailsRows.length && machineDetails && (
        <MachineModal
          isOpen={showDetails}
          data={machineDetailsRows}
          title={machines?.[0]?.model}
          onClose={closeDetailsModal}
        />
      )}
      {!!machines?.length && (
        <MachineParkModal
          data={machines}
          isOpen={showMachinesParkModal}
          onClose={closeMachinesParkModal}
        />
      )}
      <div className={styles.heading}>
        <CommonTooltip
          title={data.company_name || ''}
          className={cn(styles.title, 'overflowed-text-multiline')}
        />
      </div>

      <div ref={contentRef} className={styles.content}>
        <div className={styles.address}>
          <div className={styles['address-cell']}>
            <span className={styles['address-cell-title']}>
              {t('Page.Dashboard.ManufactureProfile.Address')}
            </span>
            <span className={styles['cell-value']}>
              <span className={styles.location}>
                <LocationOnOutlinedIcon className={styles.icon} />
                <span className="overflowed-text">{data.address}</span>
              </span>
            </span>
          </div>
          <div className={styles.cells}>
            {contactRows
              .filter((row) => row.value)
              .map((row) => (
                <div key={row.title} className={styles.cell}>
                  <span className={styles['address-cell-title']}>
                    {t(row.title)}
                  </span>
                  {row.renderCell ? (
                    row.renderCell(row.value)
                  ) : (
                    <span className={styles['cell-value']}>{row.value}</span>
                  )}
                </div>
              ))}
          </div>
        </div>
        <div className={styles.separator} />
        {!!data?.contacts?.length && (
          <>
            <div className={styles.info}>
              {data.contacts?.map((contact) => (
                <div key={contact.email} className={styles.row}>
                  <span className={styles.label}>{`${
                    contact.first_name || ''
                  } ${contact.last_name || ''}`}</span>
                  <span className={styles.value}>{`${contact.email} ${
                    contact.last_name || ''
                  }`}</span>
                </div>
              ))}
            </div>
            <div className={styles.separator} />
          </>
        )}

        <div className={styles.info}>
          {infoRows
            .filter((row) => row.value)
            .map((row) => (
              <div key={row.label} className={styles.row}>
                <span className={styles.label}>{row.label}:</span>
                <span className={styles.value}>{row.value}</span>
              </div>
            ))}
        </div>
        <div className={styles.separator} />
        <div className={styles.details}>
          <div className={styles['details-content']}>
            {data.machines?.length ? (
              <div className={styles.cells}>
                {machineCommonDetailsRows
                  .filter(({ value }) => value)
                  .map((cell) => (
                    <div key={cell.title} className={styles.cell}>
                      <span className={styles['cell-title']}>{cell.title}</span>
                      <span
                        className={cn(styles['cell-value'], cell.className)}
                        onClick={cell.onClick}
                      >
                        {cell.value}
                      </span>
                    </div>
                  ))}
              </div>
            ) : (
              <span className={styles.note}>
                {t('Page.Dashboard.ManufactureProfile.NoMachines')}
              </span>
            )}
          </div>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <p
            onClick={openMachinesParkModal}
            className={cn(
              styles.note,
              !data.n_machines && styles.hidden,
              machines?.length && styles['has-data']
            )}
          >
            {t('Page.Dashboard.ManufactureProfile.MachineParkLink', {
              amount: data.n_machines
            })}
          </p>
        </div>
        <div className={styles.separator} />
        <ImagesSection images={data.images} />
      </div>
    </div>
  );
};
