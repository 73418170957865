export const mockedIndustryData: any = {
  name: 'All industries',
  children: [
    {
      name: 'General Merchandise Stores ',
      full_name: 'General Merchandise Stores ',
      code: '452',
      children: [],
      value: 0.004961975906062708
    },
    {
      name: 'Professional',
      full_name: 'Professional, Scientific, and Technical Services',
      code: '54',
      children: [
        {
          name: 'Professional',
          full_name: 'Professional, Scientific, and Technical Services',
          code: '541',
          children: [
            {
              name: 'Architectural',
              full_name: 'Architectural, Engineering, and Related Services',
              code: '5413',
              children: [
                {
                  name: 'Engineering Services',
                  full_name: 'Engineering Services',
                  code: '54133',
                  children: [
                    {
                      name: 'Engineering Services',
                      full_name: 'Engineering Services',
                      code: '541330',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Architectural Services',
                  full_name: 'Architectural Services',
                  code: '54131',
                  children: [
                    {
                      name: 'Architectural Services',
                      full_name: 'Architectural Services',
                      code: '541310',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Testing Laboratories',
                  full_name: 'Testing Laboratories',
                  code: '54138',
                  children: [
                    {
                      name: 'Testing Laboratories',
                      full_name: 'Testing Laboratories',
                      code: '541380',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Landscape Architectural Services',
                  full_name: 'Landscape Architectural Services',
                  code: '54132',
                  children: [
                    {
                      name: 'Landscape Architectural Services',
                      full_name: 'Landscape Architectural Services',
                      code: '541320',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Surveying and Mapping (except Geophysical) Services',
                  full_name:
                    'Surveying and Mapping (except Geophysical) Services',
                  code: '54137',
                  children: [
                    {
                      name: 'Surveying and Mapping (except Geophysical) Services',
                      full_name:
                        'Surveying and Mapping (except Geophysical) Services',
                      code: '541370',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Geophysical Surveying and Mapping Services',
                  full_name: 'Geophysical Surveying and Mapping Services',
                  code: '54136',
                  children: [
                    {
                      name: 'Geophysical Surveying and Mapping Services',
                      full_name: 'Geophysical Surveying and Mapping Services',
                      code: '541360',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Drafting Services',
                  full_name: 'Drafting Services',
                  code: '54134',
                  children: [
                    {
                      name: 'Drafting Services',
                      full_name: 'Drafting Services',
                      code: '541340',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Building Inspection Services',
                  full_name: 'Building Inspection Services',
                  code: '54135',
                  children: [
                    {
                      name: 'Building Inspection Services',
                      full_name: 'Building Inspection Services',
                      code: '541350',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Computer Systems Design and Related Services',
              full_name: 'Computer Systems Design and Related Services',
              code: '5415',
              children: [
                {
                  name: 'Computer Systems Design and Related Services',
                  full_name: 'Computer Systems Design and Related Services',
                  code: '54151',
                  children: [
                    {
                      name: 'Custom Computer Programming Services ',
                      full_name: 'Custom Computer Programming Services ',
                      code: '541511',
                      children: [],
                      value: 48.59061588760272
                    },
                    {
                      name: 'Computer Systems Design Services ',
                      full_name: 'Computer Systems Design Services ',
                      code: '541512',
                      children: [],
                      value: 34.77953521251215
                    },
                    {
                      name: 'Other Computer Related Services',
                      full_name: 'Other Computer Related Services',
                      code: '541519',
                      children: [],
                      value: 14.897941150481575
                    },
                    {
                      name: 'Computer Facilities Management Services ',
                      full_name: 'Computer Facilities Management Services ',
                      code: '541513',
                      children: [],
                      value: 1.7319077494035522
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Scientific Research and Development Services',
              full_name: 'Scientific Research and Development Services',
              code: '5417',
              children: [
                {
                  name: 'Research and Development in the Physical',
                  full_name:
                    'Research and Development in the Physical, Engineering, and Life Sciences',
                  code: '54171',
                  children: [
                    {
                      name: 'Research and Development in the Physical',
                      full_name:
                        'Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology) ',
                      code: '541715',
                      children: [],
                      value: 95.49945115257958
                    },
                    {
                      name: 'Research and Development in Biotechnology (except Nanobiotechnology)',
                      full_name:
                        'Research and Development in Biotechnology (except Nanobiotechnology)',
                      code: '541714',
                      children: [],
                      value: 4.500548847420418
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Research and Development in the Social Sciences and Humanities',
                  full_name:
                    'Research and Development in the Social Sciences and Humanities',
                  code: '54172',
                  children: [
                    {
                      name: 'Research and Development in the Social Sciences and Humanities ',
                      full_name:
                        'Research and Development in the Social Sciences and Humanities ',
                      code: '541720',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Management',
              full_name:
                'Management, Scientific, and Technical Consulting Services',
              code: '5416',
              children: [
                {
                  name: 'Management Consulting Services',
                  full_name: 'Management Consulting Services',
                  code: '54161',
                  children: [
                    {
                      name: 'Administrative Management and General Management Consulting Services ',
                      full_name:
                        'Administrative Management and General Management Consulting Services ',
                      code: '541611',
                      children: [],
                      value: 42.81837916063676
                    },
                    {
                      name: 'Other Management Consulting Services ',
                      full_name: 'Other Management Consulting Services ',
                      code: '541618',
                      children: [],
                      value: 22.919681620839363
                    },
                    {
                      name: 'Marketing Consulting Services ',
                      full_name: 'Marketing Consulting Services ',
                      code: '541613',
                      children: [],
                      value: 14.50795947901592
                    },
                    {
                      name: 'Process',
                      full_name:
                        'Process, Physical Distribution, and Logistics Consulting Services ',
                      code: '541614',
                      children: [],
                      value: 11.089001447178003
                    },
                    {
                      name: 'Human Resources Consulting Services ',
                      full_name: 'Human Resources Consulting Services ',
                      code: '541612',
                      children: [],
                      value: 8.664978292329957
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Environmental Consulting Services',
                  full_name: 'Environmental Consulting Services',
                  code: '54162',
                  children: [
                    {
                      name: 'Environmental Consulting Services',
                      full_name: 'Environmental Consulting Services',
                      code: '541620',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Other Scientific and Technical Consulting Services',
                  full_name:
                    'Other Scientific and Technical Consulting Services',
                  code: '54169',
                  children: [
                    {
                      name: 'Other Scientific and Technical Consulting Services',
                      full_name:
                        'Other Scientific and Technical Consulting Services',
                      code: '541690',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Specialized Design Services',
              full_name: 'Specialized Design Services',
              code: '5414',
              children: [
                {
                  name: 'Interior Design Services',
                  full_name: 'Interior Design Services',
                  code: '54141',
                  children: [
                    {
                      name: 'Interior Design Services',
                      full_name: 'Interior Design Services',
                      code: '541410',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Graphic Design Services',
                  full_name: 'Graphic Design Services',
                  code: '54143',
                  children: [
                    {
                      name: 'Graphic Design Services',
                      full_name: 'Graphic Design Services',
                      code: '541430',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Industrial Design Services',
                  full_name: 'Industrial Design Services',
                  code: '54142',
                  children: [
                    {
                      name: 'Industrial Design Services',
                      full_name: 'Industrial Design Services',
                      code: '541420',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Other Specialized Design Services',
                  full_name: 'Other Specialized Design Services',
                  code: '54149',
                  children: [
                    {
                      name: 'Other Specialized Design Services',
                      full_name: 'Other Specialized Design Services',
                      code: '541490',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Advertising',
              full_name: 'Advertising, Public Relations, and Related Services',
              code: '5418',
              children: [
                {
                  name: 'Outdoor Advertising',
                  full_name: 'Outdoor Advertising',
                  code: '54185',
                  children: [
                    {
                      name: 'Outdoor Advertising',
                      full_name: 'Outdoor Advertising',
                      code: '541850',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Advertising Agencies',
                  full_name: 'Advertising Agencies',
                  code: '54181',
                  children: [
                    {
                      name: 'Advertising Agencies',
                      full_name: 'Advertising Agencies',
                      code: '541810',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Other Services Related to Advertising',
                  full_name: 'Other Services Related to Advertising',
                  code: '54189',
                  children: [
                    {
                      name: 'Other Services Related to Advertising ',
                      full_name: 'Other Services Related to Advertising ',
                      code: '541890',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Public Relations Agencies',
                  full_name: 'Public Relations Agencies',
                  code: '54182',
                  children: [
                    {
                      name: 'Public Relations Agencies',
                      full_name: 'Public Relations Agencies',
                      code: '541820',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Other Professional',
              full_name:
                'Other Professional, Scientific, and Technical Services',
              code: '5419',
              children: [
                {
                  name: 'All Other Professional',
                  full_name:
                    'All Other Professional, Scientific, and Technical Services',
                  code: '54199',
                  children: [
                    {
                      name: 'All Other Professional',
                      full_name:
                        'All Other Professional, Scientific, and Technical Services',
                      code: '541990',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Photographic Services',
                  full_name: 'Photographic Services',
                  code: '54192',
                  children: [
                    {
                      name: 'Photography Studios',
                      full_name: 'Photography Studios, Portrait ',
                      code: '541921',
                      children: [],
                      value: 64.49704142011834
                    },
                    {
                      name: 'Commercial Photography ',
                      full_name: 'Commercial Photography ',
                      code: '541922',
                      children: [],
                      value: 35.50295857988166
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Veterinary Services',
                  full_name: 'Veterinary Services',
                  code: '54194',
                  children: [
                    {
                      name: 'Veterinary Services ',
                      full_name: 'Veterinary Services ',
                      code: '541940',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Translation and Interpretation Services',
                  full_name: 'Translation and Interpretation Services',
                  code: '54193',
                  children: [
                    {
                      name: 'Translation and Interpretation Services',
                      full_name: 'Translation and Interpretation Services',
                      code: '541930',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Marketing Research and Public Opinion Polling',
                  full_name: 'Marketing Research and Public Opinion Polling',
                  code: '54191',
                  children: [
                    {
                      name: 'Marketing Research and Public Opinion Polling',
                      full_name:
                        'Marketing Research and Public Opinion Polling',
                      code: '541910',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Legal Services',
              full_name: 'Legal Services',
              code: '5411',
              children: [
                {
                  name: 'Offices of Lawyers',
                  full_name: 'Offices of Lawyers',
                  code: '54111',
                  children: [
                    {
                      name: 'Offices of Lawyers',
                      full_name: 'Offices of Lawyers',
                      code: '541110',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Other Legal Services',
                  full_name: 'Other Legal Services',
                  code: '54119',
                  children: [
                    {
                      name: 'All Other Legal Services ',
                      full_name: 'All Other Legal Services ',
                      code: '541199',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Accounting',
              full_name:
                'Accounting, Tax Preparation, Bookkeeping, and Payroll Services',
              code: '5412',
              children: [
                {
                  name: 'Accounting',
                  full_name:
                    'Accounting, Tax Preparation, Bookkeeping, and Payroll Services',
                  code: '54121',
                  children: [
                    {
                      name: 'Other Accounting Services ',
                      full_name: 'Other Accounting Services ',
                      code: '541219',
                      children: [],
                      value: 41.37931034482759
                    },
                    {
                      name: 'Offices of Certified Public Accountants ',
                      full_name: 'Offices of Certified Public Accountants ',
                      code: '541211',
                      children: [],
                      value: 32.4712643678161
                    },
                    {
                      name: 'Tax Preparation Services ',
                      full_name: 'Tax Preparation Services ',
                      code: '541213',
                      children: [],
                      value: 26.14942528735632
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        }
      ],
      value: 0
    },
    {
      name: 'Utilities',
      full_name: 'Utilities',
      code: '22',
      children: [
        {
          name: 'Utilities ',
          full_name: 'Utilities ',
          code: '221',
          children: [
            {
              name: 'Electric Power Generation',
              full_name:
                'Electric Power Generation, Transmission and Distribution',
              code: '2211',
              children: [
                {
                  name: 'Electric Power Generation ',
                  full_name: 'Electric Power Generation ',
                  code: '22111',
                  children: [
                    {
                      name: 'Other Electric Power Generation ',
                      full_name: 'Other Electric Power Generation ',
                      code: '221118',
                      children: [],
                      value: 82.53658536585365
                    },
                    {
                      name: 'Solar Electric Power Generation ',
                      full_name: 'Solar Electric Power Generation ',
                      code: '221114',
                      children: [],
                      value: 9.164227642276423
                    },
                    {
                      name: 'Wind Electric Power Generation ',
                      full_name: 'Wind Electric Power Generation ',
                      code: '221115',
                      children: [],
                      value: 5.014634146341463
                    },
                    {
                      name: 'Fossil Fuel Electric Power Generation ',
                      full_name: 'Fossil Fuel Electric Power Generation ',
                      code: '221112',
                      children: [],
                      value: 0.9365853658536585
                    },
                    {
                      name: 'Nuclear Electric Power Generation ',
                      full_name: 'Nuclear Electric Power Generation ',
                      code: '221113',
                      children: [],
                      value: 0.8715447154471544
                    },
                    {
                      name: 'Biomass Electric Power Generation ',
                      full_name: 'Biomass Electric Power Generation ',
                      code: '221117',
                      children: [],
                      value: 0.8390243902439025
                    },
                    {
                      name: 'Hydroelectric Power Generation ',
                      full_name: 'Hydroelectric Power Generation ',
                      code: '221111',
                      children: [],
                      value: 0.6373983739837399
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Electric Power Transmission',
                  full_name:
                    'Electric Power Transmission, Control, and Distribution ',
                  code: '22112',
                  children: [
                    {
                      name: 'Electric Power Distribution ',
                      full_name: 'Electric Power Distribution ',
                      code: '221122',
                      children: [],
                      value: 83.29177057356608
                    },
                    {
                      name: 'Electric Bulk Power Transmission and Control ',
                      full_name:
                        'Electric Bulk Power Transmission and Control ',
                      code: '221121',
                      children: [],
                      value: 16.708229426433917
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Water',
              full_name: 'Water, Sewage and Other Systems ',
              code: '2213',
              children: [
                {
                  name: 'Water Supply and Irrigation Systems ',
                  full_name: 'Water Supply and Irrigation Systems ',
                  code: '22131',
                  children: [
                    {
                      name: 'Water Supply and Irrigation Systems ',
                      full_name: 'Water Supply and Irrigation Systems ',
                      code: '221310',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Sewage Treatment Facilities ',
                  full_name: 'Sewage Treatment Facilities ',
                  code: '22132',
                  children: [
                    {
                      name: 'Sewage Treatment Facilities ',
                      full_name: 'Sewage Treatment Facilities ',
                      code: '221320',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Steam and Air-Conditioning Supply ',
                  full_name: 'Steam and Air-Conditioning Supply ',
                  code: '22133',
                  children: [
                    {
                      name: 'Steam and Air-Conditioning Supply ',
                      full_name: 'Steam and Air-Conditioning Supply ',
                      code: '221330',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Natural Gas Distribution ',
              full_name: 'Natural Gas Distribution ',
              code: '2212',
              children: [
                {
                  name: 'Natural Gas Distribution ',
                  full_name: 'Natural Gas Distribution ',
                  code: '22121',
                  children: [
                    {
                      name: 'Natural Gas Distribution ',
                      full_name: 'Natural Gas Distribution ',
                      code: '221210',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        }
      ],
      value: 0
    },
    {
      name: 'Construction',
      full_name: 'Construction',
      code: '23',
      children: [
        {
          name: 'Construction of Buildings',
          full_name: 'Construction of Buildings',
          code: '236',
          children: [
            {
              name: 'Nonresidential Building Construction',
              full_name: 'Nonresidential Building Construction',
              code: '2362',
              children: [
                {
                  name: 'Commercial and Institutional Building Construction',
                  full_name:
                    'Commercial and Institutional Building Construction',
                  code: '23622',
                  children: [
                    {
                      name: 'Commercial and Institutional Building Construction ',
                      full_name:
                        'Commercial and Institutional Building Construction ',
                      code: '236220',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Industrial Building Construction',
                  full_name: 'Industrial Building Construction',
                  code: '23621',
                  children: [
                    {
                      name: 'Industrial Building Construction ',
                      full_name: 'Industrial Building Construction ',
                      code: '236210',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Residential Building Construction',
              full_name: 'Residential Building Construction',
              code: '2361',
              children: [
                {
                  name: 'Residential Building Construction',
                  full_name: 'Residential Building Construction',
                  code: '23611',
                  children: [
                    {
                      name: 'Residential Remodelers ',
                      full_name: 'Residential Remodelers ',
                      code: '236118',
                      children: [],
                      value: 57.61937716262976
                    },
                    {
                      name: 'New Single-Family Housing Construction (except For-Sale Builders) ',
                      full_name:
                        'New Single-Family Housing Construction (except For-Sale Builders) ',
                      code: '236115',
                      children: [],
                      value: 36.12456747404844
                    },
                    {
                      name: 'New Multifamily Housing Construction (except For-Sale Builders) ',
                      full_name:
                        'New Multifamily Housing Construction (except For-Sale Builders) ',
                      code: '236116',
                      children: [],
                      value: 5.162629757785467
                    },
                    {
                      name: 'New Housing For-Sale Builders ',
                      full_name: 'New Housing For-Sale Builders ',
                      code: '236117',
                      children: [],
                      value: 1.093425605536332
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Specialty Trade Contractors',
          full_name: 'Specialty Trade Contractors',
          code: '238',
          children: [
            {
              name: 'Building Equipment Contractors',
              full_name: 'Building Equipment Contractors',
              code: '2382',
              children: [
                {
                  name: 'Electrical Contractors and Other Wiring Installation Contractors',
                  full_name:
                    'Electrical Contractors and Other Wiring Installation Contractors',
                  code: '23821',
                  children: [
                    {
                      name: 'Electrical Contractors and Other Wiring Installation Contractors',
                      full_name:
                        'Electrical Contractors and Other Wiring Installation Contractors',
                      code: '238210',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Plumbing',
                  full_name:
                    'Plumbing, Heating, and Air-Conditioning Contractors',
                  code: '23822',
                  children: [
                    {
                      name: 'Plumbing',
                      full_name:
                        'Plumbing, Heating, and Air-Conditioning Contractors ',
                      code: '238220',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Other Building Equipment Contractors',
                  full_name: 'Other Building Equipment Contractors',
                  code: '23829',
                  children: [
                    {
                      name: 'Other Building Equipment Contractors ',
                      full_name: 'Other Building Equipment Contractors ',
                      code: '238290',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Building Finishing Contractors',
              full_name: 'Building Finishing Contractors',
              code: '2383',
              children: [
                {
                  name: 'Finish Carpentry Contractors',
                  full_name: 'Finish Carpentry Contractors',
                  code: '23835',
                  children: [
                    {
                      name: 'Finish Carpentry Contractors',
                      full_name: 'Finish Carpentry Contractors',
                      code: '238350',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Other Building Finishing Contractors',
                  full_name: 'Other Building Finishing Contractors',
                  code: '23839',
                  children: [
                    {
                      name: 'Other Building Finishing Contractors',
                      full_name: 'Other Building Finishing Contractors',
                      code: '238390',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Painting and Wall Covering Contractors',
                  full_name: 'Painting and Wall Covering Contractors',
                  code: '23832',
                  children: [
                    {
                      name: 'Painting and Wall Covering Contractors',
                      full_name: 'Painting and Wall Covering Contractors',
                      code: '238320',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Drywall and Insulation Contractors',
                  full_name: 'Drywall and Insulation Contractors',
                  code: '23831',
                  children: [
                    {
                      name: 'Drywall and Insulation Contractors ',
                      full_name: 'Drywall and Insulation Contractors ',
                      code: '238310',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Flooring Contractors',
                  full_name: 'Flooring Contractors',
                  code: '23833',
                  children: [
                    {
                      name: 'Flooring Contractors',
                      full_name: 'Flooring Contractors',
                      code: '238330',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Tile and Terrazzo Contractors',
                  full_name: 'Tile and Terrazzo Contractors',
                  code: '23834',
                  children: [
                    {
                      name: 'Tile and Terrazzo Contractors',
                      full_name: 'Tile and Terrazzo Contractors',
                      code: '238340',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Other Specialty Trade Contractors',
              full_name: 'Other Specialty Trade Contractors',
              code: '2389',
              children: [
                {
                  name: 'All Other Specialty Trade Contractors',
                  full_name: 'All Other Specialty Trade Contractors',
                  code: '23899',
                  children: [
                    {
                      name: 'All Other Specialty Trade Contractors',
                      full_name: 'All Other Specialty Trade Contractors',
                      code: '238990',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Site Preparation Contractors',
                  full_name: 'Site Preparation Contractors',
                  code: '23891',
                  children: [
                    {
                      name: 'Site Preparation Contractors',
                      full_name: 'Site Preparation Contractors',
                      code: '238910',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Foundation',
              full_name:
                'Foundation, Structure, and Building Exterior Contractors',
              code: '2381',
              children: [
                {
                  name: 'Other Foundation',
                  full_name:
                    'Other Foundation, Structure, and Building Exterior Contractors ',
                  code: '23819',
                  children: [
                    {
                      name: 'Other Foundation',
                      full_name:
                        'Other Foundation, Structure, and Building Exterior Contractors ',
                      code: '238190',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Structural Steel and Precast Concrete Contractors ',
                  full_name:
                    'Structural Steel and Precast Concrete Contractors ',
                  code: '23812',
                  children: [
                    {
                      name: 'Structural Steel and Precast Concrete Contractors ',
                      full_name:
                        'Structural Steel and Precast Concrete Contractors ',
                      code: '238120',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Roofing Contractors ',
                  full_name: 'Roofing Contractors ',
                  code: '23816',
                  children: [
                    {
                      name: 'Roofing Contractors ',
                      full_name: 'Roofing Contractors ',
                      code: '238160',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Glass and Glazing Contractors ',
                  full_name: 'Glass and Glazing Contractors ',
                  code: '23815',
                  children: [
                    {
                      name: 'Glass and Glazing Contractors ',
                      full_name: 'Glass and Glazing Contractors ',
                      code: '238150',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Poured Concrete Foundation and Structure Contractors ',
                  full_name:
                    'Poured Concrete Foundation and Structure Contractors ',
                  code: '23811',
                  children: [
                    {
                      name: 'Poured Concrete Foundation and Structure Contractors ',
                      full_name:
                        'Poured Concrete Foundation and Structure Contractors ',
                      code: '238110',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Siding Contractors ',
                  full_name: 'Siding Contractors ',
                  code: '23817',
                  children: [
                    {
                      name: 'Siding Contractors ',
                      full_name: 'Siding Contractors ',
                      code: '238170',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Masonry Contractors ',
                  full_name: 'Masonry Contractors ',
                  code: '23814',
                  children: [
                    {
                      name: 'Masonry Contractors ',
                      full_name: 'Masonry Contractors ',
                      code: '238140',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Framing Contractors ',
                  full_name: 'Framing Contractors ',
                  code: '23813',
                  children: [
                    {
                      name: 'Framing Contractors ',
                      full_name: 'Framing Contractors ',
                      code: '238130',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Heavy and Civil Engineering Construction',
          full_name: 'Heavy and Civil Engineering Construction',
          code: '237',
          children: [
            {
              name: 'Utility System Construction',
              full_name: 'Utility System Construction',
              code: '2371',
              children: [
                {
                  name: 'Power and Communication Line and Related Structures Construction',
                  full_name:
                    'Power and Communication Line and Related Structures Construction',
                  code: '23713',
                  children: [
                    {
                      name: 'Power and Communication Line and Related Structures Construction ',
                      full_name:
                        'Power and Communication Line and Related Structures Construction ',
                      code: '237130',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Water and Sewer Line and Related Structures Construction',
                  full_name:
                    'Water and Sewer Line and Related Structures Construction',
                  code: '23711',
                  children: [
                    {
                      name: 'Water and Sewer Line and Related Structures Construction ',
                      full_name:
                        'Water and Sewer Line and Related Structures Construction ',
                      code: '237110',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Oil and Gas Pipeline and Related Structures Construction',
                  full_name:
                    'Oil and Gas Pipeline and Related Structures Construction',
                  code: '23712',
                  children: [
                    {
                      name: 'Oil and Gas Pipeline and Related Structures Construction ',
                      full_name:
                        'Oil and Gas Pipeline and Related Structures Construction ',
                      code: '237120',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Highway',
              full_name: 'Highway, Street, and Bridge Construction',
              code: '2373',
              children: [
                {
                  name: 'Highway',
                  full_name: 'Highway, Street, and Bridge Construction',
                  code: '23731',
                  children: [
                    {
                      name: 'Highway',
                      full_name: 'Highway, Street, and Bridge Construction ',
                      code: '237310',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Other Heavy and Civil Engineering Construction',
              full_name: 'Other Heavy and Civil Engineering Construction',
              code: '2379',
              children: [
                {
                  name: 'Other Heavy and Civil Engineering Construction',
                  full_name: 'Other Heavy and Civil Engineering Construction',
                  code: '23799',
                  children: [
                    {
                      name: 'Other Heavy and Civil Engineering Construction ',
                      full_name:
                        'Other Heavy and Civil Engineering Construction ',
                      code: '237990',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        }
      ],
      value: 0
    },
    {
      name: 'Other Services (except Public Administration)',
      full_name: 'Other Services (except Public Administration)',
      code: '81',
      children: [
        {
          name: 'Repair and Maintenance',
          full_name: 'Repair and Maintenance',
          code: '811',
          children: [
            {
              name: 'Automotive Repair and Maintenance',
              full_name: 'Automotive Repair and Maintenance',
              code: '8111',
              children: [
                {
                  name: 'Automotive Mechanical and Electrical Repair and Maintenance',
                  full_name:
                    'Automotive Mechanical and Electrical Repair and Maintenance',
                  code: '81111',
                  children: [
                    {
                      name: 'General Automotive Repair ',
                      full_name: 'General Automotive Repair ',
                      code: '811111',
                      children: [],
                      value: 86.91890644826394
                    },
                    {
                      name: 'Other Automotive Mechanical and Electrical Repair and Maintenance ',
                      full_name:
                        'Other Automotive Mechanical and Electrical Repair and Maintenance ',
                      code: '811118',
                      children: [],
                      value: 8.986042219402467
                    },
                    {
                      name: 'Automotive Exhaust System Repair ',
                      full_name: 'Automotive Exhaust System Repair ',
                      code: '811112',
                      children: [],
                      value: 4.095051332333602
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Other Automotive Repair and Maintenance',
                  full_name: 'Other Automotive Repair and Maintenance',
                  code: '81119',
                  children: [
                    {
                      name: 'All Other Automotive Repair and Maintenance ',
                      full_name: 'All Other Automotive Repair and Maintenance ',
                      code: '811198',
                      children: [],
                      value: 83.32003192338388
                    },
                    {
                      name: 'Car Washes ',
                      full_name: 'Car Washes ',
                      code: '811192',
                      children: [],
                      value: 12.290502793296088
                    },
                    {
                      name: 'Automotive Oil Change and Lubrication Shops ',
                      full_name: 'Automotive Oil Change and Lubrication Shops ',
                      code: '811191',
                      children: [],
                      value: 4.389465283320032
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Automotive Body',
                  full_name:
                    'Automotive Body, Paint, Interior, and Glass Repair',
                  code: '81112',
                  children: [
                    {
                      name: 'Automotive Body',
                      full_name:
                        'Automotive Body, Paint, and Interior Repair and Maintenance ',
                      code: '811121',
                      children: [],
                      value: 79.20792079207921
                    },
                    {
                      name: 'Automotive Glass Replacement Shops ',
                      full_name: 'Automotive Glass Replacement Shops ',
                      code: '811122',
                      children: [],
                      value: 20.792079207920793
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance',
              full_name:
                'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance',
              code: '8113',
              children: [
                {
                  name: 'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance',
                  full_name:
                    'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance',
                  code: '81131',
                  children: [
                    {
                      name: 'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance ',
                      full_name:
                        'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance ',
                      code: '811310',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Electronic and Precision Equipment Repair and Maintenance',
              full_name:
                'Electronic and Precision Equipment Repair and Maintenance',
              code: '8112',
              children: [
                {
                  name: 'Electronic and Precision Equipment Repair and Maintenance',
                  full_name:
                    'Electronic and Precision Equipment Repair and Maintenance',
                  code: '81121',
                  children: [
                    {
                      name: 'Other Electronic and Precision Equipment Repair and Maintenance ',
                      full_name:
                        'Other Electronic and Precision Equipment Repair and Maintenance ',
                      code: '811219',
                      children: [],
                      value: 71.60356347438753
                    },
                    {
                      name: 'Computer and Office Machine Repair and Maintenance ',
                      full_name:
                        'Computer and Office Machine Repair and Maintenance ',
                      code: '811212',
                      children: [],
                      value: 18.70824053452116
                    },
                    {
                      name: 'Communication Equipment Repair and Maintenance ',
                      full_name:
                        'Communication Equipment Repair and Maintenance ',
                      code: '811213',
                      children: [],
                      value: 5.56792873051225
                    },
                    {
                      name: 'Consumer Electronics Repair and Maintenance ',
                      full_name: 'Consumer Electronics Repair and Maintenance ',
                      code: '811211',
                      children: [],
                      value: 4.120267260579064
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Personal and Household Goods Repair and Maintenance',
              full_name: 'Personal and Household Goods Repair and Maintenance',
              code: '8114',
              children: [
                {
                  name: 'Other Personal and Household Goods Repair and Maintenance',
                  full_name:
                    'Other Personal and Household Goods Repair and Maintenance',
                  code: '81149',
                  children: [
                    {
                      name: 'Other Personal and Household Goods Repair and Maintenance ',
                      full_name:
                        'Other Personal and Household Goods Repair and Maintenance ',
                      code: '811490',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Home and Garden Equipment and Appliance Repair and Maintenance',
                  full_name:
                    'Home and Garden Equipment and Appliance Repair and Maintenance',
                  code: '81141',
                  children: [
                    {
                      name: 'Appliance Repair and Maintenance ',
                      full_name: 'Appliance Repair and Maintenance ',
                      code: '811412',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Reupholstery and Furniture Repair',
                  full_name: 'Reupholstery and Furniture Repair',
                  code: '81142',
                  children: [
                    {
                      name: 'Reupholstery and Furniture Repair',
                      full_name: 'Reupholstery and Furniture Repair',
                      code: '811420',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Personal and Laundry Services',
          full_name: 'Personal and Laundry Services',
          code: '812',
          children: [
            {
              name: 'Personal Care Services ',
              full_name: 'Personal Care Services ',
              code: '8121',
              children: [
                {
                  name: 'Other Personal Care Services ',
                  full_name: 'Other Personal Care Services ',
                  code: '81219',
                  children: [
                    {
                      name: 'Other Personal Care Services ',
                      full_name: 'Other Personal Care Services ',
                      code: '812199',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Hair',
                  full_name: 'Hair, Nail, and Skin Care Services ',
                  code: '81211',
                  children: [
                    {
                      name: 'Beauty Salons ',
                      full_name: 'Beauty Salons ',
                      code: '812112',
                      children: [],
                      value: 78.81773399014779
                    },
                    {
                      name: 'Nail Salons ',
                      full_name: 'Nail Salons ',
                      code: '812113',
                      children: [],
                      value: 21.182266009852217
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Drycleaning and Laundry Services ',
              full_name: 'Drycleaning and Laundry Services ',
              code: '8123',
              children: [
                {
                  name: 'Drycleaning and Laundry Services (except Coin-Operated) ',
                  full_name:
                    'Drycleaning and Laundry Services (except Coin-Operated) ',
                  code: '81232',
                  children: [
                    {
                      name: 'Drycleaning and Laundry Services (except Coin-Operated) ',
                      full_name:
                        'Drycleaning and Laundry Services (except Coin-Operated) ',
                      code: '812320',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Linen and Uniform Supply ',
                  full_name: 'Linen and Uniform Supply ',
                  code: '81233',
                  children: [
                    {
                      name: 'Linen Supply ',
                      full_name: 'Linen Supply ',
                      code: '812331',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Other Personal Services ',
              full_name: 'Other Personal Services ',
              code: '8129',
              children: [
                {
                  name: 'All Other Personal Services ',
                  full_name: 'All Other Personal Services ',
                  code: '81299',
                  children: [
                    {
                      name: 'All Other Personal Services ',
                      full_name: 'All Other Personal Services ',
                      code: '812990',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Parking Lots and Garages ',
                  full_name: 'Parking Lots and Garages ',
                  code: '81293',
                  children: [
                    {
                      name: 'Parking Lots and Garages ',
                      full_name: 'Parking Lots and Garages ',
                      code: '812930',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Pet Care (except Veterinary) Services ',
                  full_name: 'Pet Care (except Veterinary) Services ',
                  code: '81291',
                  children: [
                    {
                      name: 'Pet Care (except Veterinary) Services ',
                      full_name: 'Pet Care (except Veterinary) Services ',
                      code: '812910',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Death Care Services ',
              full_name: 'Death Care Services ',
              code: '8122',
              children: [
                {
                  name: 'Cemeteries and Crematories ',
                  full_name: 'Cemeteries and Crematories ',
                  code: '81222',
                  children: [
                    {
                      name: 'Cemeteries and Crematories ',
                      full_name: 'Cemeteries and Crematories ',
                      code: '812220',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Religious',
          full_name:
            'Religious, Grantmaking, Civic, Professional, and Similar Organizations',
          code: '813',
          children: [
            {
              name: 'Civic and Social Organizations ',
              full_name: 'Civic and Social Organizations ',
              code: '8134',
              children: [
                {
                  name: 'Civic and Social Organizations ',
                  full_name: 'Civic and Social Organizations ',
                  code: '81341',
                  children: [
                    {
                      name: 'Civic and Social Organizations ',
                      full_name: 'Civic and Social Organizations ',
                      code: '813410',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Business',
              full_name:
                'Business, Professional, Labor, Political, and Similar Organizations ',
              code: '8139',
              children: [
                {
                  name: 'Professional Organizations ',
                  full_name: 'Professional Organizations ',
                  code: '81392',
                  children: [
                    {
                      name: 'Professional Organizations ',
                      full_name: 'Professional Organizations ',
                      code: '813920',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Social Advocacy Organizations ',
              full_name: 'Social Advocacy Organizations ',
              code: '8133',
              children: [
                {
                  name: 'Social Advocacy Organizations ',
                  full_name: 'Social Advocacy Organizations ',
                  code: '81331',
                  children: [
                    {
                      name: 'Environment',
                      full_name:
                        'Environment, Conservation and Wildlife Organizations ',
                      code: '813312',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        }
      ],
      value: 0
    },
    {
      name: 'Agriculture',
      full_name: 'Agriculture, Forestry, Fishing and Hunting',
      code: '11',
      children: [
        {
          name: 'Crop Production',
          full_name: 'Crop Production',
          code: '111',
          children: [
            {
              name: 'Other Crop Farming',
              full_name: 'Other Crop Farming',
              code: '1119',
              children: [
                {
                  name: 'All Other Crop Farming',
                  full_name: 'All Other Crop Farming',
                  code: '11199',
                  children: [
                    {
                      name: 'All Other Miscellaneous Crop Farming ',
                      full_name: 'All Other Miscellaneous Crop Farming ',
                      code: '111998',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Fruit and Tree Nut Farming',
              full_name: 'Fruit and Tree Nut Farming',
              code: '1113',
              children: [
                {
                  name: 'Noncitrus Fruit and Tree Nut Farming',
                  full_name: 'Noncitrus Fruit and Tree Nut Farming',
                  code: '11133',
                  children: [
                    {
                      name: 'Tree Nut Farming ',
                      full_name: 'Tree Nut Farming ',
                      code: '111335',
                      children: [],
                      value: 61.11757857974389
                    },
                    {
                      name: 'Other Noncitrus Fruit Farming ',
                      full_name: 'Other Noncitrus Fruit Farming ',
                      code: '111339',
                      children: [],
                      value: 28.987194412107105
                    },
                    {
                      name: 'Grape Vineyards ',
                      full_name: 'Grape Vineyards ',
                      code: '111332',
                      children: [],
                      value: 9.89522700814901
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Greenhouse',
              full_name: 'Greenhouse, Nursery, and Floriculture Production',
              code: '1114',
              children: [
                {
                  name: 'Nursery and Floriculture Production',
                  full_name: 'Nursery and Floriculture Production',
                  code: '11142',
                  children: [
                    {
                      name: 'Nursery and Tree Production ',
                      full_name: 'Nursery and Tree Production ',
                      code: '111421',
                      children: [],
                      value: 79.19621749408984
                    },
                    {
                      name: 'Floriculture Production ',
                      full_name: 'Floriculture Production ',
                      code: '111422',
                      children: [],
                      value: 20.803782505910164
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Food Crops Grown Under Cover',
                  full_name: 'Food Crops Grown Under Cover',
                  code: '11141',
                  children: [
                    {
                      name: 'Other Food Crops Grown Under Cover ',
                      full_name: 'Other Food Crops Grown Under Cover ',
                      code: '111419',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Oilseed and Grain Farming',
              full_name: 'Oilseed and Grain Farming',
              code: '1111',
              children: [
                {
                  name: 'Corn Farming',
                  full_name: 'Corn Farming',
                  code: '11115',
                  children: [
                    {
                      name: 'Corn Farming ',
                      full_name: 'Corn Farming ',
                      code: '111150',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Soybean Farming',
                  full_name: 'Soybean Farming',
                  code: '11111',
                  children: [
                    {
                      name: 'Soybean Farming',
                      full_name: 'Soybean Farming',
                      code: '111110',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Support Activities for Agriculture and Forestry',
          full_name: 'Support Activities for Agriculture and Forestry',
          code: '115',
          children: [
            {
              name: 'Support Activities for Crop Production',
              full_name: 'Support Activities for Crop Production',
              code: '1151',
              children: [
                {
                  name: 'Support Activities for Crop Production',
                  full_name: 'Support Activities for Crop Production',
                  code: '11511',
                  children: [
                    {
                      name: 'Soil Preparation',
                      full_name: 'Soil Preparation, Planting, and Cultivating ',
                      code: '115112',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Support Activities for Forestry',
              full_name: 'Support Activities for Forestry',
              code: '1153',
              children: [
                {
                  name: 'Support Activities for Forestry',
                  full_name: 'Support Activities for Forestry',
                  code: '11531',
                  children: [
                    {
                      name: 'Support Activities for Forestry',
                      full_name: 'Support Activities for Forestry',
                      code: '115310',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Support Activities for Animal Production',
              full_name: 'Support Activities for Animal Production',
              code: '1152',
              children: [
                {
                  name: 'Support Activities for Animal Production',
                  full_name: 'Support Activities for Animal Production',
                  code: '11521',
                  children: [
                    {
                      name: 'Support Activities for Animal Production',
                      full_name: 'Support Activities for Animal Production',
                      code: '115210',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Animal Production and Aquaculture',
          full_name: 'Animal Production and Aquaculture',
          code: '112',
          children: [
            {
              name: 'Cattle Ranching and Farming',
              full_name: 'Cattle Ranching and Farming',
              code: '1121',
              children: [
                {
                  name: 'Dairy Cattle and Milk Production',
                  full_name: 'Dairy Cattle and Milk Production',
                  code: '11212',
                  children: [
                    {
                      name: 'Dairy Cattle and Milk Production',
                      full_name: 'Dairy Cattle and Milk Production',
                      code: '112120',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Aquaculture',
              full_name: 'Aquaculture',
              code: '1125',
              children: [
                {
                  name: 'Aquaculture',
                  full_name: 'Aquaculture',
                  code: '11251',
                  children: [
                    {
                      name: 'Finfish Farming and Fish Hatcheries ',
                      full_name: 'Finfish Farming and Fish Hatcheries ',
                      code: '112511',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Hog and Pig Farming',
              full_name: 'Hog and Pig Farming',
              code: '1122',
              children: [
                {
                  name: 'Hog and Pig Farming',
                  full_name: 'Hog and Pig Farming',
                  code: '11221',
                  children: [
                    {
                      name: 'Hog and Pig Farming ',
                      full_name: 'Hog and Pig Farming ',
                      code: '112210',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Forestry and Logging',
          full_name: 'Forestry and Logging',
          code: '113',
          children: [
            {
              name: 'Logging',
              full_name: 'Logging',
              code: '1133',
              children: [
                {
                  name: 'Logging',
                  full_name: 'Logging',
                  code: '11331',
                  children: [
                    {
                      name: 'Logging ',
                      full_name: 'Logging ',
                      code: '113310',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Forest Nurseries and Gathering of Forest Products',
              full_name: 'Forest Nurseries and Gathering of Forest Products',
              code: '1132',
              children: [
                {
                  name: 'Forest Nurseries and Gathering of Forest Products',
                  full_name:
                    'Forest Nurseries and Gathering of Forest Products',
                  code: '11321',
                  children: [
                    {
                      name: 'Forest Nurseries and Gathering of Forest Products ',
                      full_name:
                        'Forest Nurseries and Gathering of Forest Products ',
                      code: '113210',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Timber Tract Operations',
              full_name: 'Timber Tract Operations',
              code: '1131',
              children: [
                {
                  name: 'Timber Tract Operations',
                  full_name: 'Timber Tract Operations',
                  code: '11311',
                  children: [
                    {
                      name: 'Timber Tract Operations',
                      full_name: 'Timber Tract Operations',
                      code: '113110',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Fishing',
          full_name: 'Fishing, Hunting and Trapping',
          code: '114',
          children: [
            {
              name: 'Fishing',
              full_name: 'Fishing',
              code: '1141',
              children: [
                {
                  name: 'Fishing',
                  full_name: 'Fishing',
                  code: '11411',
                  children: [
                    {
                      name: 'Other Marine Fishing ',
                      full_name: 'Other Marine Fishing ',
                      code: '114119',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        }
      ],
      value: 0
    },
    {
      name: 'Wholesale Trade',
      full_name: 'Wholesale Trade',
      code: '42',
      children: [
        {
          name: 'Merchant Wholesalers',
          full_name: 'Merchant Wholesalers, Durable Goods ',
          code: '423',
          children: [
            {
              name: 'Machinery',
              full_name:
                'Machinery, Equipment, and Supplies Merchant Wholesalers ',
              code: '4238',
              children: [
                {
                  name: 'Industrial Machinery and Equipment Merchant Wholesalers ',
                  full_name:
                    'Industrial Machinery and Equipment Merchant Wholesalers ',
                  code: '42383',
                  children: [
                    {
                      name: 'Industrial Machinery and Equipment Merchant Wholesalers ',
                      full_name:
                        'Industrial Machinery and Equipment Merchant Wholesalers ',
                      code: '423830',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Industrial Supplies Merchant Wholesalers ',
                  full_name: 'Industrial Supplies Merchant Wholesalers ',
                  code: '42384',
                  children: [
                    {
                      name: 'Industrial Supplies Merchant Wholesalers',
                      full_name: 'Industrial Supplies Merchant Wholesalers',
                      code: '423840',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Service Establishment Equipment and Supplies Merchant Wholesalers ',
                  full_name:
                    'Service Establishment Equipment and Supplies Merchant Wholesalers ',
                  code: '42385',
                  children: [
                    {
                      name: 'Service Establishment Equipment and Supplies Merchant Wholesalers ',
                      full_name:
                        'Service Establishment Equipment and Supplies Merchant Wholesalers ',
                      code: '423850',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers ',
                  full_name:
                    'Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers ',
                  code: '42381',
                  children: [
                    {
                      name: 'Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers ',
                      full_name:
                        'Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers ',
                      code: '423810',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers ',
                  full_name:
                    'Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers ',
                  code: '42386',
                  children: [
                    {
                      name: 'Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers ',
                      full_name:
                        'Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers ',
                      code: '423860',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Farm and Garden Machinery and Equipment Merchant Wholesalers ',
                  full_name:
                    'Farm and Garden Machinery and Equipment Merchant Wholesalers ',
                  code: '42382',
                  children: [
                    {
                      name: 'Farm and Garden Machinery and Equipment Merchant Wholesalers ',
                      full_name:
                        'Farm and Garden Machinery and Equipment Merchant Wholesalers ',
                      code: '423820',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Motor Vehicle and Motor Vehicle Parts and Supplies Merchant Wholesalers ',
              full_name:
                'Motor Vehicle and Motor Vehicle Parts and Supplies Merchant Wholesalers ',
              code: '4231',
              children: [
                {
                  name: 'Motor Vehicle Supplies and New Parts Merchant Wholesalers ',
                  full_name:
                    'Motor Vehicle Supplies and New Parts Merchant Wholesalers ',
                  code: '42312',
                  children: [
                    {
                      name: 'Motor Vehicle Supplies and New Parts Merchant Wholesalers ',
                      full_name:
                        'Motor Vehicle Supplies and New Parts Merchant Wholesalers ',
                      code: '423120',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Automobile and Other Motor Vehicle Merchant Wholesalers ',
                  full_name:
                    'Automobile and Other Motor Vehicle Merchant Wholesalers ',
                  code: '42311',
                  children: [
                    {
                      name: 'Automobile and Other Motor Vehicle Merchant Wholesalers ',
                      full_name:
                        'Automobile and Other Motor Vehicle Merchant Wholesalers ',
                      code: '423110',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Tire and Tube Merchant Wholesalers ',
                  full_name: 'Tire and Tube Merchant Wholesalers ',
                  code: '42313',
                  children: [
                    {
                      name: 'Tire and Tube Merchant Wholesalers ',
                      full_name: 'Tire and Tube Merchant Wholesalers ',
                      code: '423130',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Household Appliances and Electrical and Electronic Goods Merchant Wholesalers ',
              full_name:
                'Household Appliances and Electrical and Electronic Goods Merchant Wholesalers ',
              code: '4236',
              children: [
                {
                  name: 'Electrical Apparatus and Equipment',
                  full_name:
                    'Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers ',
                  code: '42361',
                  children: [
                    {
                      name: 'Electrical Apparatus and Equipment',
                      full_name:
                        'Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers ',
                      code: '423610',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Other Electronic Parts and Equipment Merchant Wholesalers ',
                  full_name:
                    'Other Electronic Parts and Equipment Merchant Wholesalers ',
                  code: '42369',
                  children: [
                    {
                      name: 'Other Electronic Parts and Equipment Merchant Wholesalers ',
                      full_name:
                        'Other Electronic Parts and Equipment Merchant Wholesalers ',
                      code: '423690',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Household Appliances',
                  full_name:
                    'Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers ',
                  code: '42362',
                  children: [
                    {
                      name: 'Household Appliances',
                      full_name:
                        'Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers ',
                      code: '423620',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Metal and Mineral (except Petroleum) Merchant Wholesalers ',
              full_name:
                'Metal and Mineral (except Petroleum) Merchant Wholesalers ',
              code: '4235',
              children: [
                {
                  name: 'Metal Service Centers and Other Metal Merchant Wholesalers ',
                  full_name:
                    'Metal Service Centers and Other Metal Merchant Wholesalers ',
                  code: '42351',
                  children: [
                    {
                      name: 'Metal Service Centers and Other Metal Merchant Wholesalers ',
                      full_name:
                        'Metal Service Centers and Other Metal Merchant Wholesalers ',
                      code: '423510',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Miscellaneous Durable Goods Merchant Wholesalers ',
              full_name: 'Miscellaneous Durable Goods Merchant Wholesalers ',
              code: '4239',
              children: [
                {
                  name: 'Other Miscellaneous Durable Goods Merchant Wholesalers ',
                  full_name:
                    'Other Miscellaneous Durable Goods Merchant Wholesalers ',
                  code: '42399',
                  children: [
                    {
                      name: 'Other Miscellaneous Durable Goods Merchant Wholesalers ',
                      full_name:
                        'Other Miscellaneous Durable Goods Merchant Wholesalers ',
                      code: '423990',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Recyclable Material Merchant Wholesalers ',
                  full_name: 'Recyclable Material Merchant Wholesalers ',
                  code: '42393',
                  children: [
                    {
                      name: 'Recyclable Material Merchant Wholesalers ',
                      full_name: 'Recyclable Material Merchant Wholesalers ',
                      code: '423930',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Sporting and Recreational Goods and Supplies Merchant Wholesalers',
                  full_name:
                    'Sporting and Recreational Goods and Supplies Merchant Wholesalers',
                  code: '42391',
                  children: [
                    {
                      name: 'Sporting and Recreational Goods and Supplies Merchant Wholesalers ',
                      full_name:
                        'Sporting and Recreational Goods and Supplies Merchant Wholesalers ',
                      code: '423910',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Lumber and Other Construction Materials Merchant Wholesalers ',
              full_name:
                'Lumber and Other Construction Materials Merchant Wholesalers ',
              code: '4233',
              children: [
                {
                  name: 'Brick',
                  full_name:
                    'Brick, Stone, and Related Construction Material Merchant Wholesalers ',
                  code: '42332',
                  children: [
                    {
                      name: 'Brick',
                      full_name:
                        'Brick, Stone, and Related Construction Material Merchant Wholesalers ',
                      code: '423320',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Other Construction Material Merchant Wholesalers ',
                  full_name:
                    'Other Construction Material Merchant Wholesalers ',
                  code: '42339',
                  children: [
                    {
                      name: 'Other Construction Material Merchant Wholesalers ',
                      full_name:
                        'Other Construction Material Merchant Wholesalers ',
                      code: '423390',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Lumber',
                  full_name:
                    'Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers ',
                  code: '42331',
                  children: [
                    {
                      name: 'Lumber',
                      full_name:
                        'Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers ',
                      code: '423310',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Roofing',
                  full_name:
                    'Roofing, Siding, and Insulation Material Merchant Wholesalers ',
                  code: '42333',
                  children: [
                    {
                      name: 'Roofing',
                      full_name:
                        'Roofing, Siding, and Insulation Material Merchant Wholesalers ',
                      code: '423330',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Professional and Commercial Equipment and Supplies Merchant Wholesalers ',
              full_name:
                'Professional and Commercial Equipment and Supplies Merchant Wholesalers ',
              code: '4234',
              children: [
                {
                  name: 'Medical',
                  full_name:
                    'Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers ',
                  code: '42345',
                  children: [
                    {
                      name: 'Medical',
                      full_name:
                        'Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers ',
                      code: '423450',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Other Commercial Equipment Merchant Wholesalers ',
                  full_name: 'Other Commercial Equipment Merchant Wholesalers ',
                  code: '42344',
                  children: [
                    {
                      name: 'Other Commercial Equipment Merchant Wholesalers ',
                      full_name:
                        'Other Commercial Equipment Merchant Wholesalers ',
                      code: '423440',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Computer and Computer Peripheral Equipment and Software Merchant Wholesalers ',
                  full_name:
                    'Computer and Computer Peripheral Equipment and Software Merchant Wholesalers ',
                  code: '42343',
                  children: [
                    {
                      name: 'Computer and Computer Peripheral Equipment and Software Merchant Wholesalers ',
                      full_name:
                        'Computer and Computer Peripheral Equipment and Software Merchant Wholesalers ',
                      code: '423430',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Other Professional Equipment and Supplies Merchant Wholesalers ',
                  full_name:
                    'Other Professional Equipment and Supplies Merchant Wholesalers ',
                  code: '42349',
                  children: [
                    {
                      name: 'Other Professional Equipment and Supplies Merchant Wholesalers ',
                      full_name:
                        'Other Professional Equipment and Supplies Merchant Wholesalers ',
                      code: '423490',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Office Equipment Merchant Wholesalers ',
                  full_name: 'Office Equipment Merchant Wholesalers ',
                  code: '42342',
                  children: [
                    {
                      name: 'Office Equipment Merchant Wholesalers ',
                      full_name: 'Office Equipment Merchant Wholesalers ',
                      code: '423420',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Hardware',
              full_name:
                'Hardware, and Plumbing and Heating Equipment and Supplies Merchant Wholesalers ',
              code: '4237',
              children: [
                {
                  name: 'Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers ',
                  full_name:
                    'Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers ',
                  code: '42373',
                  children: [
                    {
                      name: 'Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers ',
                      full_name:
                        'Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers ',
                      code: '423730',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Hardware Merchant Wholesalers ',
                  full_name: 'Hardware Merchant Wholesalers ',
                  code: '42371',
                  children: [
                    {
                      name: 'Hardware Merchant Wholesalers ',
                      full_name: 'Hardware Merchant Wholesalers ',
                      code: '423710',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers ',
                  full_name:
                    'Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers ',
                  code: '42372',
                  children: [
                    {
                      name: 'Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers ',
                      full_name:
                        'Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers ',
                      code: '423720',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Refrigeration Equipment and Supplies Merchant Wholesalers ',
                  full_name:
                    'Refrigeration Equipment and Supplies Merchant Wholesalers ',
                  code: '42374',
                  children: [
                    {
                      name: 'Refrigeration Equipment and Supplies Merchant Wholesalers ',
                      full_name:
                        'Refrigeration Equipment and Supplies Merchant Wholesalers ',
                      code: '423740',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Furniture and Home Furnishing Merchant Wholesalers ',
              full_name: 'Furniture and Home Furnishing Merchant Wholesalers ',
              code: '4232',
              children: [
                {
                  name: 'Furniture Merchant Wholesalers ',
                  full_name: 'Furniture Merchant Wholesalers ',
                  code: '42321',
                  children: [
                    {
                      name: 'Furniture Merchant Wholesalers ',
                      full_name: 'Furniture Merchant Wholesalers ',
                      code: '423210',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Home Furnishing Merchant Wholesalers ',
                  full_name: 'Home Furnishing Merchant Wholesalers ',
                  code: '42322',
                  children: [
                    {
                      name: 'Home Furnishing Merchant Wholesalers ',
                      full_name: 'Home Furnishing Merchant Wholesalers ',
                      code: '423220',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Merchant Wholesalers',
          full_name: 'Merchant Wholesalers, Nondurable Goods ',
          code: '424',
          children: [
            {
              name: 'Petroleum and Petroleum Products Merchant Wholesalers ',
              full_name:
                'Petroleum and Petroleum Products Merchant Wholesalers ',
              code: '4247',
              children: [
                {
                  name: 'Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals) ',
                  full_name:
                    'Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals) ',
                  code: '42472',
                  children: [
                    {
                      name: 'Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals) ',
                      full_name:
                        'Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals) ',
                      code: '424720',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Petroleum Bulk Stations and Terminals ',
                  full_name: 'Petroleum Bulk Stations and Terminals ',
                  code: '42471',
                  children: [
                    {
                      name: 'Petroleum Bulk Stations and Terminals ',
                      full_name: 'Petroleum Bulk Stations and Terminals ',
                      code: '424710',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Grocery and Related Product Merchant Wholesalers ',
              full_name: 'Grocery and Related Product Merchant Wholesalers ',
              code: '4244',
              children: [
                {
                  name: 'Other Grocery and Related Products Merchant Wholesalers ',
                  full_name:
                    'Other Grocery and Related Products Merchant Wholesalers ',
                  code: '42449',
                  children: [
                    {
                      name: 'Other Grocery and Related Products Merchant Wholesalers ',
                      full_name:
                        'Other Grocery and Related Products Merchant Wholesalers ',
                      code: '424490',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: "Drugs and Druggists' Sundries Merchant Wholesalers ",
              full_name: "Drugs and Druggists' Sundries Merchant Wholesalers ",
              code: '4242',
              children: [
                {
                  name: "Drugs and Druggists' Sundries Merchant Wholesalers ",
                  full_name:
                    "Drugs and Druggists' Sundries Merchant Wholesalers ",
                  code: '42421',
                  children: [
                    {
                      name: "Drugs and Druggists' Sundries Merchant Wholesalers ",
                      full_name:
                        "Drugs and Druggists' Sundries Merchant Wholesalers ",
                      code: '424210',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Chemical and Allied Products Merchant Wholesalers ',
              full_name: 'Chemical and Allied Products Merchant Wholesalers ',
              code: '4246',
              children: [
                {
                  name: 'Other Chemical and Allied Products Merchant Wholesalers ',
                  full_name:
                    'Other Chemical and Allied Products Merchant Wholesalers ',
                  code: '42469',
                  children: [
                    {
                      name: 'Other Chemical and Allied Products Merchant Wholesalers ',
                      full_name:
                        'Other Chemical and Allied Products Merchant Wholesalers ',
                      code: '424690',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Paper and Paper Product Merchant Wholesalers ',
              full_name: 'Paper and Paper Product Merchant Wholesalers ',
              code: '4241',
              children: [
                {
                  name: 'Stationery and Office Supplies Merchant Wholesalers ',
                  full_name:
                    'Stationery and Office Supplies Merchant Wholesalers ',
                  code: '42412',
                  children: [
                    {
                      name: 'Stationery and Office Supplies Merchant Wholesalers ',
                      full_name:
                        'Stationery and Office Supplies Merchant Wholesalers ',
                      code: '424120',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Industrial and Personal Service Paper Merchant Wholesalers ',
                  full_name:
                    'Industrial and Personal Service Paper Merchant Wholesalers ',
                  code: '42413',
                  children: [
                    {
                      name: 'Industrial and Personal Service Paper Merchant Wholesalers ',
                      full_name:
                        'Industrial and Personal Service Paper Merchant Wholesalers ',
                      code: '424130',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Miscellaneous Nondurable Goods Merchant Wholesalers ',
              full_name: 'Miscellaneous Nondurable Goods Merchant Wholesalers ',
              code: '4249',
              children: [
                {
                  name: 'Other Miscellaneous Nondurable Goods Merchant Wholesalers ',
                  full_name:
                    'Other Miscellaneous Nondurable Goods Merchant Wholesalers ',
                  code: '42499',
                  children: [
                    {
                      name: 'Other Miscellaneous Nondurable Goods Merchant Wholesalers ',
                      full_name:
                        'Other Miscellaneous Nondurable Goods Merchant Wholesalers ',
                      code: '424990',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Beer',
              full_name:
                'Beer, Wine, and Distilled Alcoholic Beverage Merchant Wholesalers ',
              code: '4248',
              children: [
                {
                  name: 'Wine and Distilled Alcoholic Beverage Merchant Wholesalers ',
                  full_name:
                    'Wine and Distilled Alcoholic Beverage Merchant Wholesalers ',
                  code: '42482',
                  children: [
                    {
                      name: 'Wine and Distilled Alcoholic Beverage Merchant Wholesalers ',
                      full_name:
                        'Wine and Distilled Alcoholic Beverage Merchant Wholesalers ',
                      code: '424820',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        }
      ],
      value: 0
    },
    {
      name: 'Information',
      full_name: 'Information',
      code: '51',
      children: [
        {
          name: 'Publishing Industries (except Internet)',
          full_name: 'Publishing Industries (except Internet)',
          code: '511',
          children: [
            {
              name: 'Software Publishers',
              full_name: 'Software Publishers',
              code: '5112',
              children: [
                {
                  name: 'Software Publishers',
                  full_name: 'Software Publishers',
                  code: '51121',
                  children: [
                    {
                      name: 'Software Publishers',
                      full_name: 'Software Publishers',
                      code: '511210',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Newspaper',
              full_name:
                'Newspaper, Periodical, Book, and Directory Publishers',
              code: '5111',
              children: [
                {
                  name: 'Book Publishers',
                  full_name: 'Book Publishers',
                  code: '51113',
                  children: [
                    {
                      name: 'Book Publishers ',
                      full_name: 'Book Publishers ',
                      code: '511130',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Periodical Publishers',
                  full_name: 'Periodical Publishers',
                  code: '51112',
                  children: [
                    {
                      name: 'Periodical Publishers ',
                      full_name: 'Periodical Publishers ',
                      code: '511120',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Newspaper Publishers',
                  full_name: 'Newspaper Publishers',
                  code: '51111',
                  children: [
                    {
                      name: 'Newspaper Publishers ',
                      full_name: 'Newspaper Publishers ',
                      code: '511110',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Other Publishers',
                  full_name: 'Other Publishers',
                  code: '51119',
                  children: [
                    {
                      name: 'All Other Publishers ',
                      full_name: 'All Other Publishers ',
                      code: '511199',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Data Processing',
          full_name: 'Data Processing, Hosting, and Related Services',
          code: '518',
          children: [
            {
              name: 'Data Processing',
              full_name: 'Data Processing, Hosting, and Related Services',
              code: '5182',
              children: [
                {
                  name: 'Data Processing',
                  full_name: 'Data Processing, Hosting, and Related Services',
                  code: '51821',
                  children: [
                    {
                      name: 'Data Processing',
                      full_name:
                        'Data Processing, Hosting, and Related Services',
                      code: '518210',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Telecommunications',
          full_name: 'Telecommunications',
          code: '517',
          children: [
            {
              name: 'Wired and Wireless Telecommunications (except Satellite)',
              full_name:
                'Wired and Wireless Telecommunications (except Satellite)',
              code: '5171',
              children: [
                {
                  name: 'Wired and Wireless Telecommunications Carriers (except Satellite)',
                  full_name:
                    'Wired and Wireless Telecommunications Carriers (except Satellite)',
                  code: '51711',
                  children: [],
                  value: 100
                }
              ],
              value: 0
            },
            {
              name: 'Other Telecommunications',
              full_name: 'Other Telecommunications',
              code: '5179',
              children: [
                {
                  name: 'Other Telecommunications',
                  full_name: 'Other Telecommunications',
                  code: '51791',
                  children: [
                    {
                      name: 'All Other Telecommunications ',
                      full_name: 'All Other Telecommunications ',
                      code: '517919',
                      children: [],
                      value: 54.166666666666664
                    },
                    {
                      name: 'Telecommunications Resellers ',
                      full_name: 'Telecommunications Resellers ',
                      code: '517911',
                      children: [],
                      value: 45.83333333333333
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Wired and Wireless Telecommunications Carriers',
              full_name: 'Wired and Wireless Telecommunications Carriers',
              code: '5173',
              children: [
                {
                  name: 'Wired and Wireless Telecommunications Carriers',
                  full_name: 'Wired and Wireless Telecommunications Carriers',
                  code: '51731',
                  children: [
                    {
                      name: 'Wired Telecommunications Carriers ',
                      full_name: 'Wired Telecommunications Carriers ',
                      code: '517311',
                      children: [],
                      value: 77.00534759358288
                    },
                    {
                      name: 'Wireless Telecommunications Carriers (except Satellite)',
                      full_name:
                        'Wireless Telecommunications Carriers (except Satellite)',
                      code: '517312',
                      children: [],
                      value: 22.994652406417114
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Satellite Telecommunications',
              full_name: 'Satellite Telecommunications',
              code: '5174',
              children: [
                {
                  name: 'Satellite Telecommunications',
                  full_name: 'Satellite Telecommunications',
                  code: '51741',
                  children: [
                    {
                      name: 'Satellite Telecommunications',
                      full_name: 'Satellite Telecommunications',
                      code: '517410',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Motion Picture and Sound Recording Industries',
          full_name: 'Motion Picture and Sound Recording Industries',
          code: '512',
          children: [
            {
              name: 'Motion Picture and Video Industries',
              full_name: 'Motion Picture and Video Industries',
              code: '5121',
              children: [
                {
                  name: 'Motion Picture and Video Production',
                  full_name: 'Motion Picture and Video Production',
                  code: '51211',
                  children: [
                    {
                      name: 'Motion Picture and Video Production ',
                      full_name: 'Motion Picture and Video Production ',
                      code: '512110',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Motion Picture and Video Distribution',
                  full_name: 'Motion Picture and Video Distribution',
                  code: '51212',
                  children: [
                    {
                      name: 'Motion Picture and Video Distribution',
                      full_name: 'Motion Picture and Video Distribution',
                      code: '512120',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Postproduction Services and Other Motion Picture and Video Industries',
                  full_name:
                    'Postproduction Services and Other Motion Picture and Video Industries',
                  code: '51219',
                  children: [
                    {
                      name: 'Teleproduction and Other Postproduction Services ',
                      full_name:
                        'Teleproduction and Other Postproduction Services ',
                      code: '512191',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Sound Recording Industries',
              full_name: 'Sound Recording Industries',
              code: '5122',
              children: [
                {
                  name: 'Sound Recording Studios',
                  full_name: 'Sound Recording Studios',
                  code: '51224',
                  children: [
                    {
                      name: 'Sound Recording Studios',
                      full_name: 'Sound Recording Studios',
                      code: '512240',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Other Information Services',
          full_name: 'Other Information Services',
          code: '519',
          children: [
            {
              name: 'Other Information Services',
              full_name: 'Other Information Services',
              code: '5191',
              children: [
                {
                  name: 'Internet Publishing and Broadcasting and Web Search Portals',
                  full_name:
                    'Internet Publishing and Broadcasting and Web Search Portals',
                  code: '51913',
                  children: [
                    {
                      name: 'Internet Publishing and Broadcasting and Web Search Portals',
                      full_name:
                        'Internet Publishing and Broadcasting and Web Search Portals',
                      code: '519130',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Broadcasting (except Internet)',
          full_name: 'Broadcasting (except Internet)',
          code: '515',
          children: [
            {
              name: 'Radio and Television Broadcasting',
              full_name: 'Radio and Television Broadcasting',
              code: '5151',
              children: [
                {
                  name: 'Television Broadcasting',
                  full_name: 'Television Broadcasting',
                  code: '51512',
                  children: [
                    {
                      name: 'Television Broadcasting',
                      full_name: 'Television Broadcasting',
                      code: '515120',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        }
      ],
      value: 0
    },
    {
      name: 'Administrative and Support and Waste Management and Remediation Services',
      full_name:
        'Administrative and Support and Waste Management and Remediation Services',
      code: '56',
      children: [
        {
          name: 'Waste Management and Remediation Services',
          full_name: 'Waste Management and Remediation Services',
          code: '562',
          children: [
            {
              name: 'Remediation and Other Waste Management Services ',
              full_name: 'Remediation and Other Waste Management Services ',
              code: '5629',
              children: [
                {
                  name: 'Materials Recovery Facilities ',
                  full_name: 'Materials Recovery Facilities ',
                  code: '56292',
                  children: [
                    {
                      name: 'Materials Recovery Facilities ',
                      full_name: 'Materials Recovery Facilities ',
                      code: '562920',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Remediation Services ',
                  full_name: 'Remediation Services ',
                  code: '56291',
                  children: [
                    {
                      name: 'Remediation Services ',
                      full_name: 'Remediation Services ',
                      code: '562910',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'All Other Waste Management Services ',
                  full_name: 'All Other Waste Management Services ',
                  code: '56299',
                  children: [
                    {
                      name: 'All Other Miscellaneous Waste Management Services ',
                      full_name:
                        'All Other Miscellaneous Waste Management Services ',
                      code: '562998',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Waste Collection ',
              full_name: 'Waste Collection ',
              code: '5621',
              children: [
                {
                  name: 'Waste Collection ',
                  full_name: 'Waste Collection ',
                  code: '56211',
                  children: [
                    {
                      name: 'Solid Waste Collection ',
                      full_name: 'Solid Waste Collection ',
                      code: '562111',
                      children: [],
                      value: 59.193548387096776
                    },
                    {
                      name: 'Hazardous Waste Collection ',
                      full_name: 'Hazardous Waste Collection ',
                      code: '562112',
                      children: [],
                      value: 28.14516129032258
                    },
                    {
                      name: 'Other Waste Collection ',
                      full_name: 'Other Waste Collection ',
                      code: '562119',
                      children: [],
                      value: 12.661290322580646
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Waste Treatment and Disposal ',
              full_name: 'Waste Treatment and Disposal ',
              code: '5622',
              children: [
                {
                  name: 'Waste Treatment and Disposal ',
                  full_name: 'Waste Treatment and Disposal ',
                  code: '56221',
                  children: [
                    {
                      name: 'Other Nonhazardous Waste Treatment and Disposal ',
                      full_name:
                        'Other Nonhazardous Waste Treatment and Disposal ',
                      code: '562219',
                      children: [],
                      value: 46.86907020872865
                    },
                    {
                      name: 'Hazardous Waste Treatment and Disposal ',
                      full_name: 'Hazardous Waste Treatment and Disposal ',
                      code: '562211',
                      children: [],
                      value: 21.25237191650854
                    },
                    {
                      name: 'Solid Waste Combustors and Incinerators ',
                      full_name: 'Solid Waste Combustors and Incinerators ',
                      code: '562213',
                      children: [],
                      value: 19.35483870967742
                    },
                    {
                      name: 'Solid Waste Landfill ',
                      full_name: 'Solid Waste Landfill ',
                      code: '562212',
                      children: [],
                      value: 12.523719165085389
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Administrative and Support Services',
          full_name: 'Administrative and Support Services',
          code: '561',
          children: [
            {
              name: 'Investigation and Security Services',
              full_name: 'Investigation and Security Services',
              code: '5616',
              children: [
                {
                  name: 'Security Systems Services',
                  full_name: 'Security Systems Services',
                  code: '56162',
                  children: [
                    {
                      name: 'Security Systems Services (except Locksmiths) ',
                      full_name:
                        'Security Systems Services (except Locksmiths) ',
                      code: '561621',
                      children: [],
                      value: 89.89852398523985
                    },
                    {
                      name: 'Locksmiths ',
                      full_name: 'Locksmiths ',
                      code: '561622',
                      children: [],
                      value: 10.101476014760149
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Investigation',
                  full_name: 'Investigation, Guard, and Armored Car Services',
                  code: '56161',
                  children: [
                    {
                      name: 'Security Guards and Patrol Services ',
                      full_name: 'Security Guards and Patrol Services ',
                      code: '561612',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Services to Buildings and Dwellings',
              full_name: 'Services to Buildings and Dwellings',
              code: '5617',
              children: [
                {
                  name: 'Landscaping Services',
                  full_name: 'Landscaping Services',
                  code: '56173',
                  children: [
                    {
                      name: 'Landscaping Services',
                      full_name: 'Landscaping Services',
                      code: '561730',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Janitorial Services',
                  full_name: 'Janitorial Services',
                  code: '56172',
                  children: [
                    {
                      name: 'Janitorial Services ',
                      full_name: 'Janitorial Services ',
                      code: '561720',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Other Services to Buildings and Dwellings',
                  full_name: 'Other Services to Buildings and Dwellings',
                  code: '56179',
                  children: [
                    {
                      name: 'Other Services to Buildings and Dwellings ',
                      full_name: 'Other Services to Buildings and Dwellings ',
                      code: '561790',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Facilities Support Services',
              full_name: 'Facilities Support Services',
              code: '5612',
              children: [
                {
                  name: 'Facilities Support Services',
                  full_name: 'Facilities Support Services',
                  code: '56121',
                  children: [
                    {
                      name: 'Facilities Support Services',
                      full_name: 'Facilities Support Services',
                      code: '561210',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Employment Services',
              full_name: 'Employment Services',
              code: '5613',
              children: [
                {
                  name: 'Temporary Help Services',
                  full_name: 'Temporary Help Services',
                  code: '56132',
                  children: [
                    {
                      name: 'Temporary Help Services',
                      full_name: 'Temporary Help Services',
                      code: '561320',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Employment Placement Agencies and Executive Search Services',
                  full_name:
                    'Employment Placement Agencies and Executive Search Services',
                  code: '56131',
                  children: [
                    {
                      name: 'Employment Placement Agencies ',
                      full_name: 'Employment Placement Agencies ',
                      code: '561311',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Other Support Services',
              full_name: 'Other Support Services',
              code: '5619',
              children: [
                {
                  name: 'Packaging and Labeling Services',
                  full_name: 'Packaging and Labeling Services',
                  code: '56191',
                  children: [
                    {
                      name: 'Packaging and Labeling Services',
                      full_name: 'Packaging and Labeling Services',
                      code: '561910',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Convention and Trade Show Organizers',
                  full_name: 'Convention and Trade Show Organizers',
                  code: '56192',
                  children: [
                    {
                      name: 'Convention and Trade Show Organizers',
                      full_name: 'Convention and Trade Show Organizers',
                      code: '561920',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'All Other Support Services',
                  full_name: 'All Other Support Services',
                  code: '56199',
                  children: [
                    {
                      name: 'All Other Support Services',
                      full_name: 'All Other Support Services',
                      code: '561990',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Travel Arrangement and Reservation Services',
              full_name: 'Travel Arrangement and Reservation Services',
              code: '5615',
              children: [
                {
                  name: 'Travel Agencies',
                  full_name: 'Travel Agencies',
                  code: '56151',
                  children: [
                    {
                      name: 'Travel Agencies',
                      full_name: 'Travel Agencies',
                      code: '561510',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Office Administrative Services',
              full_name: 'Office Administrative Services',
              code: '5611',
              children: [
                {
                  name: 'Office Administrative Services',
                  full_name: 'Office Administrative Services',
                  code: '56111',
                  children: [
                    {
                      name: 'Office Administrative Services',
                      full_name: 'Office Administrative Services',
                      code: '561110',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        }
      ],
      value: 0
    },
    {
      name: 'Accommodation and Food Services',
      full_name: 'Accommodation and Food Services',
      code: '72',
      children: [
        {
          name: 'Food Services and Drinking Places',
          full_name: 'Food Services and Drinking Places',
          code: '722',
          children: [
            {
              name: 'Restaurants and Other Eating Places',
              full_name: 'Restaurants and Other Eating Places',
              code: '7225',
              children: [
                {
                  name: 'Restaurants and Other Eating Places',
                  full_name: 'Restaurants and Other Eating Places',
                  code: '72251',
                  children: [
                    {
                      name: 'Full-Service Restaurants ',
                      full_name: 'Full-Service Restaurants ',
                      code: '722511',
                      children: [],
                      value: 68.06610093791872
                    },
                    {
                      name: 'Limited-Service Restaurants ',
                      full_name: 'Limited-Service Restaurants ',
                      code: '722513',
                      children: [],
                      value: 23.44796784278696
                    },
                    {
                      name: 'Snack and Nonalcoholic Beverage Bars ',
                      full_name: 'Snack and Nonalcoholic Beverage Bars ',
                      code: '722515',
                      children: [],
                      value: 8.485931219294327
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Special Food Services',
              full_name: 'Special Food Services',
              code: '7223',
              children: [
                {
                  name: 'Caterers',
                  full_name: 'Caterers',
                  code: '72232',
                  children: [
                    {
                      name: 'Caterers',
                      full_name: 'Caterers',
                      code: '722320',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Food Service Contractors',
                  full_name: 'Food Service Contractors',
                  code: '72231',
                  children: [
                    {
                      name: 'Food Service Contractors',
                      full_name: 'Food Service Contractors',
                      code: '722310',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Mobile Food Services',
                  full_name: 'Mobile Food Services',
                  code: '72233',
                  children: [
                    {
                      name: 'Mobile Food Services',
                      full_name: 'Mobile Food Services',
                      code: '722330',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Drinking Places (Alcoholic Beverages)',
              full_name: 'Drinking Places (Alcoholic Beverages)',
              code: '7224',
              children: [
                {
                  name: 'Drinking Places (Alcoholic Beverages)',
                  full_name: 'Drinking Places (Alcoholic Beverages)',
                  code: '72241',
                  children: [
                    {
                      name: 'Drinking Places (Alcoholic Beverages) ',
                      full_name: 'Drinking Places (Alcoholic Beverages) ',
                      code: '722410',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Accommodation',
          full_name: 'Accommodation',
          code: '721',
          children: [
            {
              name: 'Traveler Accommodation',
              full_name: 'Traveler Accommodation',
              code: '7211',
              children: [
                {
                  name: 'Hotels (except Casino Hotels) and Motels',
                  full_name: 'Hotels (except Casino Hotels) and Motels',
                  code: '72111',
                  children: [
                    {
                      name: 'Hotels (except Casino Hotels) and Motels ',
                      full_name: 'Hotels (except Casino Hotels) and Motels ',
                      code: '721110',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Other Traveler Accommodation',
                  full_name: 'Other Traveler Accommodation',
                  code: '72119',
                  children: [
                    {
                      name: 'All Other Traveler Accommodation ',
                      full_name: 'All Other Traveler Accommodation ',
                      code: '721199',
                      children: [],
                      value: 80.59071729957806
                    },
                    {
                      name: 'Bed-and-Breakfast Inns ',
                      full_name: 'Bed-and-Breakfast Inns ',
                      code: '721191',
                      children: [],
                      value: 19.40928270042194
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Casino Hotels',
                  full_name: 'Casino Hotels',
                  code: '72112',
                  children: [
                    {
                      name: 'Casino Hotels',
                      full_name: 'Casino Hotels',
                      code: '721120',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        }
      ],
      value: 0
    },
    {
      name: 'Educational Services',
      full_name: 'Educational Services',
      code: '61',
      children: [
        {
          name: 'Educational Services',
          full_name: 'Educational Services',
          code: '611',
          children: [
            {
              name: 'Colleges',
              full_name: 'Colleges, Universities, and Professional Schools',
              code: '6113',
              children: [
                {
                  name: 'Colleges',
                  full_name: 'Colleges, Universities, and Professional Schools',
                  code: '61131',
                  children: [
                    {
                      name: 'Colleges',
                      full_name:
                        'Colleges, Universities, and Professional Schools ',
                      code: '611310',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Business Schools and Computer and Management Training',
              full_name:
                'Business Schools and Computer and Management Training',
              code: '6114',
              children: [
                {
                  name: 'Professional and Management Development Training',
                  full_name: 'Professional and Management Development Training',
                  code: '61143',
                  children: [
                    {
                      name: 'Professional and Management Development Training ',
                      full_name:
                        'Professional and Management Development Training ',
                      code: '611430',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Computer Training',
                  full_name: 'Computer Training',
                  code: '61142',
                  children: [
                    {
                      name: 'Computer Training ',
                      full_name: 'Computer Training ',
                      code: '611420',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Educational Support Services',
              full_name: 'Educational Support Services',
              code: '6117',
              children: [
                {
                  name: 'Educational Support Services',
                  full_name: 'Educational Support Services',
                  code: '61171',
                  children: [
                    {
                      name: 'Educational Support Services',
                      full_name: 'Educational Support Services',
                      code: '611710',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Elementary and Secondary Schools',
              full_name: 'Elementary and Secondary Schools',
              code: '6111',
              children: [
                {
                  name: 'Elementary and Secondary Schools ',
                  full_name: 'Elementary and Secondary Schools ',
                  code: '61111',
                  children: [
                    {
                      name: 'Elementary and Secondary Schools ',
                      full_name: 'Elementary and Secondary Schools ',
                      code: '611110',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Other Schools and Instruction',
              full_name: 'Other Schools and Instruction',
              code: '6116',
              children: [
                {
                  name: 'All Other Schools and Instruction',
                  full_name: 'All Other Schools and Instruction',
                  code: '61169',
                  children: [
                    {
                      name: 'All Other Miscellaneous Schools and Instruction ',
                      full_name:
                        'All Other Miscellaneous Schools and Instruction ',
                      code: '611699',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Sports and Recreation Instruction',
                  full_name: 'Sports and Recreation Instruction',
                  code: '61162',
                  children: [
                    {
                      name: 'Sports and Recreation Instruction ',
                      full_name: 'Sports and Recreation Instruction ',
                      code: '611620',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Fine Arts Schools',
                  full_name: 'Fine Arts Schools',
                  code: '61161',
                  children: [
                    {
                      name: 'Fine Arts Schools ',
                      full_name: 'Fine Arts Schools ',
                      code: '611610',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Technical and Trade Schools ',
              full_name: 'Technical and Trade Schools ',
              code: '6115',
              children: [
                {
                  name: 'Technical and Trade Schools',
                  full_name: 'Technical and Trade Schools',
                  code: '61151',
                  children: [
                    {
                      name: 'Other Technical and Trade Schools ',
                      full_name: 'Other Technical and Trade Schools ',
                      code: '611519',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Junior Colleges',
              full_name: 'Junior Colleges',
              code: '6112',
              children: [
                {
                  name: 'Junior Colleges',
                  full_name: 'Junior Colleges',
                  code: '61121',
                  children: [
                    {
                      name: 'Junior Colleges ',
                      full_name: 'Junior Colleges ',
                      code: '611210',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        }
      ],
      value: 0
    },
    {
      name: 'Health Care and Social Assistance',
      full_name: 'Health Care and Social Assistance',
      code: '62',
      children: [
        {
          name: 'Ambulatory Health Care Services',
          full_name: 'Ambulatory Health Care Services',
          code: '621',
          children: [
            {
              name: 'Offices of Physicians',
              full_name: 'Offices of Physicians',
              code: '6211',
              children: [
                {
                  name: 'Offices of Physicians',
                  full_name: 'Offices of Physicians',
                  code: '62111',
                  children: [
                    {
                      name: 'Offices of Physicians (except Mental Health Specialists) ',
                      full_name:
                        'Offices of Physicians (except Mental Health Specialists) ',
                      code: '621111',
                      children: [],
                      value: 91.91176470588235
                    },
                    {
                      name: 'Offices of Physicians',
                      full_name:
                        'Offices of Physicians, Mental Health Specialists ',
                      code: '621112',
                      children: [],
                      value: 8.088235294117647
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Medical and Diagnostic Laboratories',
              full_name: 'Medical and Diagnostic Laboratories',
              code: '6215',
              children: [
                {
                  name: 'Medical and Diagnostic Laboratories',
                  full_name: 'Medical and Diagnostic Laboratories',
                  code: '62151',
                  children: [
                    {
                      name: 'Medical Laboratories ',
                      full_name: 'Medical Laboratories ',
                      code: '621511',
                      children: [],
                      value: 85.81449727201871
                    },
                    {
                      name: 'Diagnostic Imaging Centers ',
                      full_name: 'Diagnostic Imaging Centers ',
                      code: '621512',
                      children: [],
                      value: 14.185502727981294
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Home Health Care Services',
              full_name: 'Home Health Care Services',
              code: '6216',
              children: [
                {
                  name: 'Home Health Care Services',
                  full_name: 'Home Health Care Services',
                  code: '62161',
                  children: [
                    {
                      name: 'Home Health Care Services',
                      full_name: 'Home Health Care Services',
                      code: '621610',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Other Ambulatory Health Care Services',
              full_name: 'Other Ambulatory Health Care Services',
              code: '6219',
              children: [
                {
                  name: 'All Other Ambulatory Health Care Services',
                  full_name: 'All Other Ambulatory Health Care Services',
                  code: '62199',
                  children: [
                    {
                      name: 'All Other Miscellaneous Ambulatory Health Care Services ',
                      full_name:
                        'All Other Miscellaneous Ambulatory Health Care Services ',
                      code: '621999',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Ambulance Services',
                  full_name: 'Ambulance Services',
                  code: '62191',
                  children: [
                    {
                      name: 'Ambulance Services ',
                      full_name: 'Ambulance Services ',
                      code: '621910',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Offices of Dentists',
              full_name: 'Offices of Dentists',
              code: '6212',
              children: [
                {
                  name: 'Offices of Dentists',
                  full_name: 'Offices of Dentists',
                  code: '62121',
                  children: [
                    {
                      name: 'Offices of Dentists ',
                      full_name: 'Offices of Dentists ',
                      code: '621210',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Offices of Other Health Practitioners',
              full_name: 'Offices of Other Health Practitioners',
              code: '6213',
              children: [
                {
                  name: 'Offices of All Other Health Practitioners',
                  full_name: 'Offices of All Other Health Practitioners',
                  code: '62139',
                  children: [
                    {
                      name: 'Offices of All Other Miscellaneous Health Practitioners ',
                      full_name:
                        'Offices of All Other Miscellaneous Health Practitioners ',
                      code: '621399',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Offices of Mental Health Practitioners (except Physicians)',
                  full_name:
                    'Offices of Mental Health Practitioners (except Physicians)',
                  code: '62133',
                  children: [
                    {
                      name: 'Offices of Mental Health Practitioners (except Physicians) ',
                      full_name:
                        'Offices of Mental Health Practitioners (except Physicians) ',
                      code: '621330',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Offices of Chiropractors',
                  full_name: 'Offices of Chiropractors',
                  code: '62131',
                  children: [
                    {
                      name: 'Offices of Chiropractors ',
                      full_name: 'Offices of Chiropractors ',
                      code: '621310',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Offices of Physical',
                  full_name:
                    'Offices of Physical, Occupational and Speech Therapists, and Audiologists',
                  code: '62134',
                  children: [
                    {
                      name: 'Offices of Physical',
                      full_name:
                        'Offices of Physical, Occupational and Speech Therapists, and Audiologists ',
                      code: '621340',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Offices of Optometrists',
                  full_name: 'Offices of Optometrists',
                  code: '62132',
                  children: [
                    {
                      name: 'Offices of Optometrists',
                      full_name: 'Offices of Optometrists',
                      code: '621320',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Outpatient Care Centers',
              full_name: 'Outpatient Care Centers',
              code: '6214',
              children: [
                {
                  name: 'Other Outpatient Care Centers',
                  full_name: 'Other Outpatient Care Centers',
                  code: '62149',
                  children: [
                    {
                      name: 'All Other Outpatient Care Centers ',
                      full_name: 'All Other Outpatient Care Centers ',
                      code: '621498',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Social Assistance',
          full_name: 'Social Assistance',
          code: '624',
          children: [
            {
              name: 'Individual and Family Services',
              full_name: 'Individual and Family Services',
              code: '6241',
              children: [
                {
                  name: 'Other Individual and Family Services',
                  full_name: 'Other Individual and Family Services',
                  code: '62419',
                  children: [
                    {
                      name: 'Other Individual and Family Services ',
                      full_name: 'Other Individual and Family Services ',
                      code: '624190',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Services for the Elderly and Persons with Disabilities',
                  full_name:
                    'Services for the Elderly and Persons with Disabilities',
                  code: '62412',
                  children: [
                    {
                      name: 'Services for the Elderly and Persons with Disabilities ',
                      full_name:
                        'Services for the Elderly and Persons with Disabilities ',
                      code: '624120',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Child and Youth Services',
                  full_name: 'Child and Youth Services',
                  code: '62411',
                  children: [
                    {
                      name: 'Child and Youth Services ',
                      full_name: 'Child and Youth Services ',
                      code: '624110',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Child Day Care Services',
              full_name: 'Child Day Care Services',
              code: '6244',
              children: [
                {
                  name: 'Child Day Care Services',
                  full_name: 'Child Day Care Services',
                  code: '62441',
                  children: [
                    {
                      name: 'Child Day Care Services ',
                      full_name: 'Child Day Care Services ',
                      code: '624410',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Vocational Rehabilitation Services',
              full_name: 'Vocational Rehabilitation Services',
              code: '6243',
              children: [
                {
                  name: 'Vocational Rehabilitation Services',
                  full_name: 'Vocational Rehabilitation Services',
                  code: '62431',
                  children: [
                    {
                      name: 'Vocational Rehabilitation Services ',
                      full_name: 'Vocational Rehabilitation Services ',
                      code: '624310',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Hospitals',
          full_name: 'Hospitals',
          code: '622',
          children: [
            {
              name: 'General Medical and Surgical Hospitals',
              full_name: 'General Medical and Surgical Hospitals',
              code: '6221',
              children: [
                {
                  name: 'General Medical and Surgical Hospitals',
                  full_name: 'General Medical and Surgical Hospitals',
                  code: '62211',
                  children: [
                    {
                      name: 'General Medical and Surgical Hospitals ',
                      full_name: 'General Medical and Surgical Hospitals ',
                      code: '622110',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Nursing and Residential Care Facilities',
          full_name: 'Nursing and Residential Care Facilities',
          code: '623',
          children: [
            {
              name: 'Nursing Care Facilities (Skilled Nursing Facilities)',
              full_name: 'Nursing Care Facilities (Skilled Nursing Facilities)',
              code: '6231',
              children: [
                {
                  name: 'Nursing Care Facilities (Skilled Nursing Facilities)',
                  full_name:
                    'Nursing Care Facilities (Skilled Nursing Facilities)',
                  code: '62311',
                  children: [
                    {
                      name: 'Nursing Care Facilities (Skilled Nursing Facilities) ',
                      full_name:
                        'Nursing Care Facilities (Skilled Nursing Facilities) ',
                      code: '623110',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        }
      ],
      value: 0
    },
    {
      name: 'Mining',
      full_name: 'Mining, Quarrying, and Oil and Gas Extraction',
      code: '21',
      children: [
        {
          name: 'Support Activities for Mining',
          full_name: 'Support Activities for Mining',
          code: '213',
          children: [
            {
              name: 'Support Activities for Mining',
              full_name: 'Support Activities for Mining',
              code: '2131',
              children: [
                {
                  name: 'Support Activities for Mining',
                  full_name: 'Support Activities for Mining',
                  code: '21311',
                  children: [
                    {
                      name: 'Support Activities for Oil and Gas Operations ',
                      full_name:
                        'Support Activities for Oil and Gas Operations ',
                      code: '213112',
                      children: [],
                      value: 60.436562073669855
                    },
                    {
                      name: 'Drilling Oil and Gas Wells',
                      full_name: 'Drilling Oil and Gas Wells',
                      code: '213111',
                      children: [],
                      value: 19.236016371077763
                    },
                    {
                      name: 'Support Activities for Nonmetallic Minerals (except Fuels) Mining ',
                      full_name:
                        'Support Activities for Nonmetallic Minerals (except Fuels) Mining ',
                      code: '213115',
                      children: [],
                      value: 11.186903137789903
                    },
                    {
                      name: 'Support Activities for Coal Mining ',
                      full_name: 'Support Activities for Coal Mining ',
                      code: '213113',
                      children: [],
                      value: 9.140518417462483
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Oil and Gas Extraction',
          full_name: 'Oil and Gas Extraction',
          code: '211',
          children: [
            {
              name: 'Oil and Gas Extraction',
              full_name: 'Oil and Gas Extraction',
              code: '2111',
              children: [
                {
                  name: 'Crude Petroleum Extraction ',
                  full_name: 'Crude Petroleum Extraction ',
                  code: '21112',
                  children: [
                    {
                      name: 'Crude Petroleum Extraction ',
                      full_name: 'Crude Petroleum Extraction ',
                      code: '211120',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Natural Gas Extraction ',
                  full_name: 'Natural Gas Extraction ',
                  code: '21113',
                  children: [
                    {
                      name: 'Natural Gas Extraction ',
                      full_name: 'Natural Gas Extraction ',
                      code: '211130',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Mining (except Oil and Gas)',
          full_name: 'Mining (except Oil and Gas)',
          code: '212',
          children: [
            {
              name: 'Nonmetallic Mineral Mining and Quarrying',
              full_name: 'Nonmetallic Mineral Mining and Quarrying',
              code: '2123',
              children: [
                {
                  name: 'Sand',
                  full_name:
                    'Sand, Gravel, Clay, and Ceramic and Refractory Minerals Mining and Quarrying',
                  code: '21232',
                  children: [
                    {
                      name: 'Construction Sand and Gravel Mining ',
                      full_name: 'Construction Sand and Gravel Mining ',
                      code: '212321',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Stone Mining and Quarrying',
                  full_name: 'Stone Mining and Quarrying',
                  code: '21231',
                  children: [
                    {
                      name: 'Other Crushed and Broken Stone Mining and Quarrying ',
                      full_name:
                        'Other Crushed and Broken Stone Mining and Quarrying ',
                      code: '212319',
                      children: [],
                      value: 72.72727272727273
                    },
                    {
                      name: 'Crushed and Broken Limestone Mining and Quarrying ',
                      full_name:
                        'Crushed and Broken Limestone Mining and Quarrying ',
                      code: '212312',
                      children: [],
                      value: 27.27272727272727
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Other Nonmetallic Mineral Mining and Quarrying',
                  full_name: 'Other Nonmetallic Mineral Mining and Quarrying',
                  code: '21239',
                  children: [
                    {
                      name: 'All Other Nonmetallic Mineral Mining ',
                      full_name: 'All Other Nonmetallic Mineral Mining ',
                      code: '212399',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Metal Ore Mining',
              full_name: 'Metal Ore Mining',
              code: '2122',
              children: [
                {
                  name: 'Copper',
                  full_name: 'Copper, Nickel, Lead, and Zinc Mining',
                  code: '21223',
                  children: [],
                  value: 21.686746987951807
                },
                {
                  name: 'Iron Ore Mining',
                  full_name: 'Iron Ore Mining',
                  code: '21221',
                  children: [
                    {
                      name: 'Iron Ore Mining',
                      full_name: 'Iron Ore Mining',
                      code: '212210',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Other Metal Ore Mining',
                  full_name: 'Other Metal Ore Mining',
                  code: '21229',
                  children: [
                    {
                      name: 'All Other Metal Ore Mining ',
                      full_name: 'All Other Metal Ore Mining ',
                      code: '212299',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Coal Mining',
              full_name: 'Coal Mining',
              code: '2121',
              children: [
                {
                  name: 'Coal Mining',
                  full_name: 'Coal Mining',
                  code: '21211',
                  children: [
                    {
                      name: 'Bituminous Coal and Lignite Surface Mining ',
                      full_name: 'Bituminous Coal and Lignite Surface Mining ',
                      code: '212111',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        }
      ],
      value: 0
    },
    {
      name: 'Arts',
      full_name: 'Arts, Entertainment, and Recreation',
      code: '71',
      children: [
        {
          name: 'Performing Arts',
          full_name:
            'Performing Arts, Spectator Sports, and Related Industries',
          code: '711',
          children: [
            {
              name: 'Independent Artists',
              full_name: 'Independent Artists, Writers, and Performers',
              code: '7115',
              children: [
                {
                  name: 'Independent Artists',
                  full_name: 'Independent Artists, Writers, and Performers',
                  code: '71151',
                  children: [
                    {
                      name: 'Independent Artists',
                      full_name:
                        'Independent Artists, Writers, and Performers ',
                      code: '711510',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Promoters of Performing Arts',
              full_name:
                'Promoters of Performing Arts, Sports, and Similar Events',
              code: '7113',
              children: [
                {
                  name: 'Promoters of Performing Arts',
                  full_name:
                    'Promoters of Performing Arts, Sports, and Similar Events without Facilities',
                  code: '71132',
                  children: [
                    {
                      name: 'Promoters of Performing Arts',
                      full_name:
                        'Promoters of Performing Arts, Sports, and Similar Events without Facilities ',
                      code: '711320',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Promoters of Performing Arts',
                  full_name:
                    'Promoters of Performing Arts, Sports, and Similar Events with Facilities',
                  code: '71131',
                  children: [
                    {
                      name: 'Promoters of Performing Arts',
                      full_name:
                        'Promoters of Performing Arts, Sports, and Similar Events with Facilities ',
                      code: '711310',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Performing Arts Companies',
              full_name: 'Performing Arts Companies',
              code: '7111',
              children: [
                {
                  name: 'Musical Groups and Artists',
                  full_name: 'Musical Groups and Artists',
                  code: '71113',
                  children: [
                    {
                      name: 'Musical Groups and Artists ',
                      full_name: 'Musical Groups and Artists ',
                      code: '711130',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Other Performing Arts Companies',
                  full_name: 'Other Performing Arts Companies',
                  code: '71119',
                  children: [
                    {
                      name: 'Other Performing Arts Companies ',
                      full_name: 'Other Performing Arts Companies ',
                      code: '711190',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Theater Companies and Dinner Theaters',
                  full_name: 'Theater Companies and Dinner Theaters',
                  code: '71111',
                  children: [
                    {
                      name: 'Theater Companies and Dinner Theaters ',
                      full_name: 'Theater Companies and Dinner Theaters ',
                      code: '711110',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Spectator Sports',
              full_name: 'Spectator Sports',
              code: '7112',
              children: [
                {
                  name: 'Spectator Sports',
                  full_name: 'Spectator Sports',
                  code: '71121',
                  children: [
                    {
                      name: 'Sports Teams and Clubs ',
                      full_name: 'Sports Teams and Clubs ',
                      code: '711211',
                      children: [],
                      value: 57.711442786069654
                    },
                    {
                      name: 'Other Spectator Sports ',
                      full_name: 'Other Spectator Sports ',
                      code: '711219',
                      children: [],
                      value: 42.28855721393035
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Amusement',
          full_name: 'Amusement, Gambling, and Recreation Industries',
          code: '713',
          children: [
            {
              name: 'Other Amusement and Recreation Industries',
              full_name: 'Other Amusement and Recreation Industries',
              code: '7139',
              children: [
                {
                  name: 'All Other Amusement and Recreation Industries',
                  full_name: 'All Other Amusement and Recreation Industries',
                  code: '71399',
                  children: [
                    {
                      name: 'All Other Amusement and Recreation Industries ',
                      full_name:
                        'All Other Amusement and Recreation Industries ',
                      code: '713990',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Fitness and Recreational Sports Centers',
                  full_name: 'Fitness and Recreational Sports Centers',
                  code: '71394',
                  children: [
                    {
                      name: 'Fitness and Recreational Sports Centers ',
                      full_name: 'Fitness and Recreational Sports Centers ',
                      code: '713940',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Marinas',
                  full_name: 'Marinas',
                  code: '71393',
                  children: [
                    {
                      name: 'Marinas',
                      full_name: 'Marinas',
                      code: '713930',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Skiing Facilities',
                  full_name: 'Skiing Facilities',
                  code: '71392',
                  children: [
                    {
                      name: 'Skiing Facilities',
                      full_name: 'Skiing Facilities',
                      code: '713920',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Amusement Parks and Arcades',
              full_name: 'Amusement Parks and Arcades',
              code: '7131',
              children: [
                {
                  name: 'Amusement and Theme Parks',
                  full_name: 'Amusement and Theme Parks',
                  code: '71311',
                  children: [
                    {
                      name: 'Amusement and Theme Parks ',
                      full_name: 'Amusement and Theme Parks ',
                      code: '713110',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Amusement Arcades',
                  full_name: 'Amusement Arcades',
                  code: '71312',
                  children: [
                    {
                      name: 'Amusement Arcades',
                      full_name: 'Amusement Arcades',
                      code: '713120',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Museums',
          full_name: 'Museums, Historical Sites, and Similar Institutions',
          code: '712',
          children: [
            {
              name: 'Museums',
              full_name: 'Museums, Historical Sites, and Similar Institutions',
              code: '7121',
              children: [
                {
                  name: 'Museums',
                  full_name: 'Museums',
                  code: '71211',
                  children: [
                    {
                      name: 'Museums ',
                      full_name: 'Museums ',
                      code: '712110',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Historical Sites',
                  full_name: 'Historical Sites',
                  code: '71212',
                  children: [
                    {
                      name: 'Historical Sites',
                      full_name: 'Historical Sites',
                      code: '712120',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        }
      ],
      value: 0
    },
    {
      name: 'Real Estate and Rental and Leasing',
      full_name: 'Real Estate and Rental and Leasing',
      code: '53',
      children: [
        {
          name: 'Rental and Leasing Services',
          full_name: 'Rental and Leasing Services',
          code: '532',
          children: [
            {
              name: 'Consumer Goods Rental',
              full_name: 'Consumer Goods Rental',
              code: '5322',
              children: [],
              value: 3.101023017902813
            },
            {
              name: 'Automotive Equipment Rental and Leasing',
              full_name: 'Automotive Equipment Rental and Leasing',
              code: '5321',
              children: [
                {
                  name: 'Passenger Car Rental and Leasing',
                  full_name: 'Passenger Car Rental and Leasing',
                  code: '53211',
                  children: [
                    {
                      name: 'Passenger Car Leasing ',
                      full_name: 'Passenger Car Leasing ',
                      code: '532112',
                      children: [],
                      value: 57.84543325526932
                    },
                    {
                      name: 'Passenger Car Rental ',
                      full_name: 'Passenger Car Rental ',
                      code: '532111',
                      children: [],
                      value: 42.15456674473068
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Truck',
                  full_name:
                    'Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing',
                  code: '53212',
                  children: [
                    {
                      name: 'Truck',
                      full_name:
                        'Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing ',
                      code: '532120',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Commercial and Industrial Machinery and Equipment Rental and Leasing',
              full_name:
                'Commercial and Industrial Machinery and Equipment Rental and Leasing',
              code: '5324',
              children: [
                {
                  name: 'Construction',
                  full_name:
                    'Construction, Transportation, Mining, and Forestry Machinery and Equipment Rental and Leasing',
                  code: '53241',
                  children: [
                    {
                      name: 'Construction',
                      full_name:
                        'Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing ',
                      code: '532412',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Other Commercial and Industrial Machinery and Equipment Rental and Leasing',
                  full_name:
                    'Other Commercial and Industrial Machinery and Equipment Rental and Leasing',
                  code: '53249',
                  children: [
                    {
                      name: 'Other Commercial and Industrial Machinery and Equipment Rental and Leasing ',
                      full_name:
                        'Other Commercial and Industrial Machinery and Equipment Rental and Leasing ',
                      code: '532490',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'General Rental Centers',
              full_name: 'General Rental Centers',
              code: '5323',
              children: [
                {
                  name: 'General Rental Centers',
                  full_name: 'General Rental Centers',
                  code: '53231',
                  children: [
                    {
                      name: 'General Rental Centers',
                      full_name: 'General Rental Centers',
                      code: '532310',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Real Estate',
          full_name: 'Real Estate',
          code: '531',
          children: [
            {
              name: 'Offices of Real Estate Agents and Brokers',
              full_name: 'Offices of Real Estate Agents and Brokers',
              code: '5312',
              children: [
                {
                  name: 'Offices of Real Estate Agents and Brokers',
                  full_name: 'Offices of Real Estate Agents and Brokers',
                  code: '53121',
                  children: [
                    {
                      name: 'Offices of Real Estate Agents and Brokers',
                      full_name: 'Offices of Real Estate Agents and Brokers',
                      code: '531210',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Lessors of Real Estate',
              full_name: 'Lessors of Real Estate',
              code: '5311',
              children: [
                {
                  name: 'Lessors of Residential Buildings and Dwellings ',
                  full_name: 'Lessors of Residential Buildings and Dwellings ',
                  code: '53111',
                  children: [
                    {
                      name: 'Lessors of Residential Buildings and Dwellings ',
                      full_name:
                        'Lessors of Residential Buildings and Dwellings ',
                      code: '531110',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Lessors of Nonresidential Buildings (except Miniwarehouses) ',
                  full_name:
                    'Lessors of Nonresidential Buildings (except Miniwarehouses) ',
                  code: '53112',
                  children: [
                    {
                      name: 'Lessors of Nonresidential Buildings (except Miniwarehouses) ',
                      full_name:
                        'Lessors of Nonresidential Buildings (except Miniwarehouses) ',
                      code: '531120',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Lessors of Miniwarehouses and Self-Storage Units ',
                  full_name:
                    'Lessors of Miniwarehouses and Self-Storage Units ',
                  code: '53113',
                  children: [
                    {
                      name: 'Lessors of Miniwarehouses and Self-Storage Units ',
                      full_name:
                        'Lessors of Miniwarehouses and Self-Storage Units ',
                      code: '531130',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Activities Related to Real Estate',
              full_name: 'Activities Related to Real Estate',
              code: '5313',
              children: [
                {
                  name: 'Real Estate Property Managers ',
                  full_name: 'Real Estate Property Managers ',
                  code: '53131',
                  children: [
                    {
                      name: 'Residential Property Managers ',
                      full_name: 'Residential Property Managers ',
                      code: '531311',
                      children: [],
                      value: 78.16091954022988
                    },
                    {
                      name: 'Nonresidential Property Managers ',
                      full_name: 'Nonresidential Property Managers ',
                      code: '531312',
                      children: [],
                      value: 21.839080459770116
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Other Activities Related to Real Estate ',
                  full_name: 'Other Activities Related to Real Estate ',
                  code: '53139',
                  children: [
                    {
                      name: 'Other Activities Related to Real Estate ',
                      full_name: 'Other Activities Related to Real Estate ',
                      code: '531390',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        }
      ],
      value: 0
    },
    {
      name: 'Finance and Insurance',
      full_name: 'Finance and Insurance',
      code: '52',
      children: [
        {
          name: 'Insurance Carriers and Related Activities',
          full_name: 'Insurance Carriers and Related Activities',
          code: '524',
          children: [
            {
              name: 'Insurance Carriers',
              full_name: 'Insurance Carriers',
              code: '5241',
              children: [
                {
                  name: 'Direct Insurance (except Life',
                  full_name:
                    'Direct Insurance (except Life, Health, and Medical) Carriers ',
                  code: '52412',
                  children: [
                    {
                      name: 'Direct Property and Casualty Insurance Carriers ',
                      full_name:
                        'Direct Property and Casualty Insurance Carriers ',
                      code: '524126',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Agencies',
              full_name:
                'Agencies, Brokerages, and Other Insurance Related Activities',
              code: '5242',
              children: [
                {
                  name: 'Insurance Agencies and Brokerages ',
                  full_name: 'Insurance Agencies and Brokerages ',
                  code: '52421',
                  children: [
                    {
                      name: 'Insurance Agencies and Brokerages ',
                      full_name: 'Insurance Agencies and Brokerages ',
                      code: '524210',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Credit Intermediation and Related Activities',
          full_name: 'Credit Intermediation and Related Activities',
          code: '522',
          children: [
            {
              name: 'Nondepository Credit Intermediation ',
              full_name: 'Nondepository Credit Intermediation ',
              code: '5222',
              children: [
                {
                  name: 'Other Nondepository Credit Intermediation ',
                  full_name: 'Other Nondepository Credit Intermediation ',
                  code: '52229',
                  children: [
                    {
                      name: 'Consumer Lending ',
                      full_name: 'Consumer Lending ',
                      code: '522291',
                      children: [],
                      value: 52.94117647058824
                    },
                    {
                      name: 'All Other Nondepository Credit Intermediation ',
                      full_name:
                        'All Other Nondepository Credit Intermediation ',
                      code: '522298',
                      children: [],
                      value: 47.05882352941176
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Activities Related to Credit Intermediation ',
              full_name: 'Activities Related to Credit Intermediation ',
              code: '5223',
              children: [
                {
                  name: 'Financial Transactions Processing',
                  full_name:
                    'Financial Transactions Processing, Reserve, and Clearinghouse Activities ',
                  code: '52232',
                  children: [
                    {
                      name: 'Financial Transactions Processing',
                      full_name:
                        'Financial Transactions Processing, Reserve, and Clearinghouse Activities ',
                      code: '522320',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            },
            {
              name: 'Depository Credit Intermediation ',
              full_name: 'Depository Credit Intermediation ',
              code: '5221',
              children: [
                {
                  name: 'Commercial Banking ',
                  full_name: 'Commercial Banking ',
                  code: '52211',
                  children: [
                    {
                      name: 'Commercial Banking ',
                      full_name: 'Commercial Banking ',
                      code: '522110',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Securities',
          full_name:
            'Securities, Commodity Contracts, and Other Financial Investments and Related Activities',
          code: '523',
          children: [
            {
              name: 'Other Financial Investment Activities',
              full_name: 'Other Financial Investment Activities',
              code: '5239',
              children: [
                {
                  name: 'Portfolio Management ',
                  full_name: 'Portfolio Management ',
                  code: '52392',
                  children: [
                    {
                      name: 'Portfolio Management ',
                      full_name: 'Portfolio Management ',
                      code: '523920',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Investment Advice ',
                  full_name: 'Investment Advice ',
                  code: '52393',
                  children: [
                    {
                      name: 'Investment Advice ',
                      full_name: 'Investment Advice ',
                      code: '523930',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Miscellaneous Intermediation ',
                  full_name: 'Miscellaneous Intermediation ',
                  code: '52391',
                  children: [
                    {
                      name: 'Miscellaneous Intermediation ',
                      full_name: 'Miscellaneous Intermediation ',
                      code: '523910',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        }
      ],
      value: 0
    },
    {
      name: 'Public Administration',
      full_name: 'Public Administration',
      code: '92',
      children: [
        {
          name: 'Executive',
          full_name:
            'Executive, Legislative, and Other General Government Support ',
          code: '921',
          children: [
            {
              name: 'Executive',
              full_name:
                'Executive, Legislative, and Other General Government Support ',
              code: '9211',
              children: [
                {
                  name: 'Legislative Bodies ',
                  full_name: 'Legislative Bodies ',
                  code: '92112',
                  children: [
                    {
                      name: 'Legislative Bodies ',
                      full_name: 'Legislative Bodies ',
                      code: '921120',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Other General Government Support ',
                  full_name: 'Other General Government Support ',
                  code: '92119',
                  children: [
                    {
                      name: 'Other General Government Support ',
                      full_name: 'Other General Government Support ',
                      code: '921190',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Executive Offices ',
                  full_name: 'Executive Offices ',
                  code: '92111',
                  children: [
                    {
                      name: 'Executive Offices ',
                      full_name: 'Executive Offices ',
                      code: '921110',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Justice',
          full_name: 'Justice, Public Order, and Safety Activities ',
          code: '922',
          children: [
            {
              name: 'Justice',
              full_name: 'Justice, Public Order, and Safety Activities ',
              code: '9221',
              children: [
                {
                  name: 'Fire Protection ',
                  full_name: 'Fire Protection ',
                  code: '92216',
                  children: [
                    {
                      name: 'Fire Protection ',
                      full_name: 'Fire Protection ',
                      code: '922160',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                },
                {
                  name: 'Police Protection ',
                  full_name: 'Police Protection ',
                  code: '92212',
                  children: [
                    {
                      name: 'Police Protection ',
                      full_name: 'Police Protection ',
                      code: '922120',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Administration of Environmental Quality Programs ',
          full_name: 'Administration of Environmental Quality Programs ',
          code: '924',
          children: [
            {
              name: 'Administration of Environmental Quality Programs ',
              full_name: 'Administration of Environmental Quality Programs ',
              code: '9241',
              children: [
                {
                  name: 'Administration of Air and Water Resource and Solid Waste Management Programs ',
                  full_name:
                    'Administration of Air and Water Resource and Solid Waste Management Programs ',
                  code: '92411',
                  children: [
                    {
                      name: 'Administration of Air and Water Resource and Solid Waste Management Programs ',
                      full_name:
                        'Administration of Air and Water Resource and Solid Waste Management Programs ',
                      code: '924110',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'National Security and International Affairs ',
          full_name: 'National Security and International Affairs ',
          code: '928',
          children: [
            {
              name: 'National Security and International Affairs ',
              full_name: 'National Security and International Affairs ',
              code: '9281',
              children: [
                {
                  name: 'National Security ',
                  full_name: 'National Security ',
                  code: '92811',
                  children: [
                    {
                      name: 'National Security ',
                      full_name: 'National Security ',
                      code: '928110',
                      children: [],
                      value: 100
                    }
                  ],
                  value: 0
                }
              ],
              value: 0
            }
          ],
          value: 0
        }
      ],
      value: 0
    },
    {
      name: 'Fabricated Metal Product Manufacturing',
      full_name: 'Fabricated Metal Product Manufacturing',
      code: '332',
      children: [
        {
          name: 'Other Fabricated Metal Product Manufacturing',
          full_name: 'Other Fabricated Metal Product Manufacturing',
          code: '3329',
          children: [
            {
              name: 'All Other Fabricated Metal Product Manufacturing',
              full_name: 'All Other Fabricated Metal Product Manufacturing',
              code: '33299',
              children: [
                {
                  name: 'All Other Miscellaneous Fabricated Metal Product Manufacturing ',
                  full_name:
                    'All Other Miscellaneous Fabricated Metal Product Manufacturing ',
                  code: '332999',
                  children: [],
                  value: 92.33284248883122
                },
                {
                  name: 'Fabricated Pipe and Pipe Fitting Manufacturing ',
                  full_name: 'Fabricated Pipe and Pipe Fitting Manufacturing ',
                  code: '332996',
                  children: [],
                  value: 5.67798936780892
                },
                {
                  name: 'Ball and Roller Bearing Manufacturing',
                  full_name: 'Ball and Roller Bearing Manufacturing',
                  code: '332991',
                  children: [],
                  value: 0.873536825816757
                },
                {
                  name: 'Small Arms',
                  full_name:
                    'Small Arms, Ordnance, and Ordnance Accessories Manufacturing ',
                  code: '332994',
                  children: [],
                  value: 0.7811915042304141
                },
                {
                  name: 'Small Arms Ammunition Manufacturing ',
                  full_name: 'Small Arms Ammunition Manufacturing ',
                  code: '332992',
                  children: [],
                  value: 0.20715301869368807
                },
                {
                  name: 'Ammunition (except Small Arms) Manufacturing ',
                  full_name: 'Ammunition (except Small Arms) Manufacturing ',
                  code: '332993',
                  children: [],
                  value: 0.12728679461901315
                }
              ],
              value: 0
            },
            {
              name: 'Metal Valve Manufacturing',
              full_name: 'Metal Valve Manufacturing',
              code: '33291',
              children: [
                {
                  name: 'Industrial Valve Manufacturing ',
                  full_name: 'Industrial Valve Manufacturing ',
                  code: '332911',
                  children: [],
                  value: 32.975871313672926
                },
                {
                  name: 'Fluid Power Valve and Hose Fitting Manufacturing ',
                  full_name:
                    'Fluid Power Valve and Hose Fitting Manufacturing ',
                  code: '332912',
                  children: [],
                  value: 26.081960934507855
                },
                {
                  name: 'Other Metal Valve and Pipe Fitting Manufacturing ',
                  full_name:
                    'Other Metal Valve and Pipe Fitting Manufacturing ',
                  code: '332919',
                  children: [],
                  value: 22.366909230180006
                },
                {
                  name: 'Plumbing Fixture Fitting and Trim Manufacturing ',
                  full_name: 'Plumbing Fixture Fitting and Trim Manufacturing ',
                  code: '332913',
                  children: [],
                  value: 18.575258521639217
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Machine Shops; Turned Product; and Screw',
          full_name:
            'Machine Shops; Turned Product; and Screw, Nut, and Bolt Manufacturing',
          code: '3327',
          children: [
            {
              name: 'Machine Shops',
              full_name: 'Machine Shops',
              code: '33271',
              children: [
                {
                  name: 'Machine Shops',
                  full_name: 'Machine Shops',
                  code: '332710',
                  children: [],
                  value: 100
                }
              ],
              value: 0
            },
            {
              name: 'Turned Product and Screw',
              full_name:
                'Turned Product and Screw, Nut, and Bolt Manufacturing',
              code: '33272',
              children: [
                {
                  name: 'Precision Turned Product Manufacturing ',
                  full_name: 'Precision Turned Product Manufacturing ',
                  code: '332721',
                  children: [],
                  value: 86.50902837489252
                },
                {
                  name: 'Bolt',
                  full_name:
                    'Bolt, Nut, Screw, Rivet, and Washer Manufacturing ',
                  code: '332722',
                  children: [],
                  value: 13.49097162510748
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Architectural and Structural Metals Manufacturing',
          full_name: 'Architectural and Structural Metals Manufacturing',
          code: '3323',
          children: [
            {
              name: 'Plate Work and Fabricated Structural Product Manufacturing',
              full_name:
                'Plate Work and Fabricated Structural Product Manufacturing',
              code: '33231',
              children: [
                {
                  name: 'Fabricated Structural Metal Manufacturing ',
                  full_name: 'Fabricated Structural Metal Manufacturing ',
                  code: '332312',
                  children: [],
                  value: 74.16349743553958
                },
                {
                  name: 'Plate Work Manufacturing ',
                  full_name: 'Plate Work Manufacturing ',
                  code: '332313',
                  children: [],
                  value: 24.269913820173755
                },
                {
                  name: 'Prefabricated Metal Building and Component Manufacturing ',
                  full_name:
                    'Prefabricated Metal Building and Component Manufacturing ',
                  code: '332311',
                  children: [],
                  value: 1.5665887442866615
                }
              ],
              value: 0
            },
            {
              name: 'Ornamental and Architectural Metal Products Manufacturing',
              full_name:
                'Ornamental and Architectural Metal Products Manufacturing',
              code: '33232',
              children: [
                {
                  name: 'Metal Window and Door Manufacturing ',
                  full_name: 'Metal Window and Door Manufacturing ',
                  code: '332321',
                  children: [],
                  value: 47.074490220557635
                },
                {
                  name: 'Sheet Metal Work Manufacturing ',
                  full_name: 'Sheet Metal Work Manufacturing ',
                  code: '332322',
                  children: [],
                  value: 37.19517270079068
                },
                {
                  name: 'Ornamental and Architectural Metal Work Manufacturing ',
                  full_name:
                    'Ornamental and Architectural Metal Work Manufacturing ',
                  code: '332323',
                  children: [],
                  value: 15.730337078651685
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Coating',
          full_name: 'Coating, Engraving, Heat Treating, and Allied Activities',
          code: '3328',
          children: [
            {
              name: 'Coating',
              full_name:
                'Coating, Engraving, Heat Treating, and Allied Activities',
              code: '33281',
              children: [
                {
                  name: 'Metal Coating',
                  full_name:
                    'Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers ',
                  code: '332812',
                  children: [],
                  value: 54.922654329911815
                },
                {
                  name: 'Electroplating',
                  full_name:
                    'Electroplating, Plating, Polishing, Anodizing, and Coloring ',
                  code: '332813',
                  children: [],
                  value: 37.4439786034408
                },
                {
                  name: 'Metal Heat Treating ',
                  full_name: 'Metal Heat Treating ',
                  code: '332811',
                  children: [],
                  value: 7.633367066647391
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Forging and Stamping',
          full_name: 'Forging and Stamping',
          code: '3321',
          children: [
            {
              name: 'Forging and Stamping',
              full_name: 'Forging and Stamping',
              code: '33211',
              children: [
                {
                  name: 'Powder Metallurgy Part Manufacturing ',
                  full_name: 'Powder Metallurgy Part Manufacturing ',
                  code: '332117',
                  children: [],
                  value: 41.48936170212766
                },
                {
                  name: 'Iron and Steel Forging ',
                  full_name: 'Iron and Steel Forging ',
                  code: '332111',
                  children: [],
                  value: 31.721470019342355
                },
                {
                  name: 'Metal Crown',
                  full_name:
                    'Metal Crown, Closure, and Other Metal Stamping (except Automotive) ',
                  code: '332119',
                  children: [],
                  value: 13.346228239845262
                },
                {
                  name: 'Nonferrous Forging ',
                  full_name: 'Nonferrous Forging ',
                  code: '332112',
                  children: [],
                  value: 6.963249516441006
                },
                {
                  name: 'Custom Roll Forming ',
                  full_name: 'Custom Roll Forming ',
                  code: '332114',
                  children: [],
                  value: 6.479690522243714
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Hardware Manufacturing',
          full_name: 'Hardware Manufacturing',
          code: '3325',
          children: [
            {
              name: 'Hardware Manufacturing',
              full_name: 'Hardware Manufacturing',
              code: '33251',
              children: [
                {
                  name: 'Hardware Manufacturing',
                  full_name: 'Hardware Manufacturing',
                  code: '332510',
                  children: [],
                  value: 100
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Cutlery and Handtool Manufacturing',
          full_name: 'Cutlery and Handtool Manufacturing',
          code: '3322',
          children: [
            {
              name: 'Cutlery and Handtool Manufacturing',
              full_name: 'Cutlery and Handtool Manufacturing',
              code: '33221',
              children: [
                {
                  name: 'Saw Blade and Handtool Manufacturing ',
                  full_name: 'Saw Blade and Handtool Manufacturing ',
                  code: '332216',
                  children: [],
                  value: 94.25173439048564
                },
                {
                  name: 'Metal Kitchen Cookware',
                  full_name:
                    'Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing ',
                  code: '332215',
                  children: [],
                  value: 5.7482656095143705
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Boiler',
          full_name: 'Boiler, Tank, and Shipping Container Manufacturing',
          code: '3324',
          children: [
            {
              name: 'Power Boiler and Heat Exchanger Manufacturing',
              full_name: 'Power Boiler and Heat Exchanger Manufacturing',
              code: '33241',
              children: [
                {
                  name: 'Power Boiler and Heat Exchanger Manufacturing',
                  full_name: 'Power Boiler and Heat Exchanger Manufacturing',
                  code: '332410',
                  children: [],
                  value: 100
                }
              ],
              value: 0
            },
            {
              name: 'Metal Can',
              full_name:
                'Metal Can, Box, and Other Metal Container (Light Gauge) Manufacturing',
              code: '33243',
              children: [
                {
                  name: 'Other Metal Container Manufacturing ',
                  full_name: 'Other Metal Container Manufacturing ',
                  code: '332439',
                  children: [],
                  value: 71.11756168359942
                },
                {
                  name: 'Metal Can Manufacturing ',
                  full_name: 'Metal Can Manufacturing ',
                  code: '332431',
                  children: [],
                  value: 28.88243831640058
                }
              ],
              value: 0
            },
            {
              name: 'Metal Tank (Heavy Gauge) Manufacturing',
              full_name: 'Metal Tank (Heavy Gauge) Manufacturing',
              code: '33242',
              children: [
                {
                  name: 'Metal Tank (Heavy Gauge) Manufacturing',
                  full_name: 'Metal Tank (Heavy Gauge) Manufacturing',
                  code: '332420',
                  children: [],
                  value: 100
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Spring and Wire Product Manufacturing',
          full_name: 'Spring and Wire Product Manufacturing',
          code: '3326',
          children: [
            {
              name: 'Spring and Wire Product Manufacturing',
              full_name: 'Spring and Wire Product Manufacturing',
              code: '33261',
              children: [
                {
                  name: 'Other Fabricated Wire Product Manufacturing ',
                  full_name: 'Other Fabricated Wire Product Manufacturing ',
                  code: '332618',
                  children: [],
                  value: 84.54810495626822
                },
                {
                  name: 'Spring Manufacturing ',
                  full_name: 'Spring Manufacturing ',
                  code: '332613',
                  children: [],
                  value: 15.451895043731778
                }
              ],
              value: 0
            }
          ],
          value: 0
        }
      ],
      value: 0
    },
    {
      name: 'Machinery Manufacturing',
      full_name: 'Machinery Manufacturing',
      code: '333',
      children: [
        {
          name: 'Industrial Machinery Manufacturing',
          full_name: 'Industrial Machinery Manufacturing',
          code: '3332',
          children: [
            {
              name: 'Industrial Machinery Manufacturing',
              full_name: 'Industrial Machinery Manufacturing',
              code: '33324',
              children: [
                {
                  name: 'Other Industrial Machinery Manufacturing ',
                  full_name: 'Other Industrial Machinery Manufacturing ',
                  code: '333249',
                  children: [],
                  value: 86.06817431914516
                },
                {
                  name: 'Food Product Machinery Manufacturing ',
                  full_name: 'Food Product Machinery Manufacturing ',
                  code: '333241',
                  children: [],
                  value: 6.982410721084291
                },
                {
                  name: 'Printing Machinery and Equipment Manufacturing ',
                  full_name: 'Printing Machinery and Equipment Manufacturing ',
                  code: '333244',
                  children: [],
                  value: 4.147313383588417
                },
                {
                  name: 'Sawmill',
                  full_name:
                    'Sawmill, Woodworking, and Paper Machinery Manufacturing ',
                  code: '333243',
                  children: [],
                  value: 2.058427878880175
                },
                {
                  name: 'Semiconductor Machinery Manufacturing ',
                  full_name: 'Semiconductor Machinery Manufacturing ',
                  code: '333242',
                  children: [],
                  value: 0.743673697301962
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Other General Purpose Machinery Manufacturing',
          full_name: 'Other General Purpose Machinery Manufacturing',
          code: '3339',
          children: [
            {
              name: 'All Other General Purpose Machinery Manufacturing',
              full_name: 'All Other General Purpose Machinery Manufacturing',
              code: '33399',
              children: [
                {
                  name: 'All Other Miscellaneous General Purpose Machinery Manufacturing ',
                  full_name:
                    'All Other Miscellaneous General Purpose Machinery Manufacturing ',
                  code: '333999',
                  children: [],
                  value: 53.78850657539183
                },
                {
                  name: 'Welding and Soldering Equipment Manufacturing ',
                  full_name: 'Welding and Soldering Equipment Manufacturing ',
                  code: '333992',
                  children: [],
                  value: 14.55233291298865
                },
                {
                  name: 'Fluid Power Cylinder and Actuator Manufacturing ',
                  full_name: 'Fluid Power Cylinder and Actuator Manufacturing ',
                  code: '333995',
                  children: [],
                  value: 9.836065573770492
                },
                {
                  name: 'Fluid Power Pump and Motor Manufacturing ',
                  full_name: 'Fluid Power Pump and Motor Manufacturing ',
                  code: '333996',
                  children: [],
                  value: 9.709962168978564
                },
                {
                  name: 'Packaging Machinery Manufacturing ',
                  full_name: 'Packaging Machinery Manufacturing ',
                  code: '333993',
                  children: [],
                  value: 6.370023419203748
                },
                {
                  name: 'Power-Driven Handtool Manufacturing ',
                  full_name: 'Power-Driven Handtool Manufacturing ',
                  code: '333991',
                  children: [],
                  value: 2.900378310214376
                },
                {
                  name: 'Industrial Process Furnace and Oven Manufacturing ',
                  full_name:
                    'Industrial Process Furnace and Oven Manufacturing ',
                  code: '333994',
                  children: [],
                  value: 1.6861826697892273
                },
                {
                  name: 'Scale and Balance Manufacturing ',
                  full_name: 'Scale and Balance Manufacturing ',
                  code: '333997',
                  children: [],
                  value: 1.1565483696631238
                }
              ],
              value: 0
            },
            {
              name: 'Material Handling Equipment Manufacturing',
              full_name: 'Material Handling Equipment Manufacturing',
              code: '33392',
              children: [
                {
                  name: 'Industrial Truck',
                  full_name:
                    'Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing ',
                  code: '333924',
                  children: [],
                  value: 38.101788170563964
                },
                {
                  name: 'Conveyor and Conveying Equipment Manufacturing ',
                  full_name: 'Conveyor and Conveying Equipment Manufacturing ',
                  code: '333922',
                  children: [],
                  value: 33.93397524071527
                },
                {
                  name: 'Overhead Traveling Crane',
                  full_name:
                    'Overhead Traveling Crane, Hoist, and Monorail System Manufacturing ',
                  code: '333923',
                  children: [],
                  value: 21.43053645116919
                },
                {
                  name: 'Elevator and Moving Stairway Manufacturing ',
                  full_name: 'Elevator and Moving Stairway Manufacturing ',
                  code: '333921',
                  children: [],
                  value: 6.533700137551582
                }
              ],
              value: 0
            },
            {
              name: 'Pump and Compressor Manufacturing',
              full_name: 'Pump and Compressor Manufacturing',
              code: '33391',
              children: [
                {
                  name: 'Air and Gas Compressor Manufacturing ',
                  full_name: 'Air and Gas Compressor Manufacturing ',
                  code: '333912',
                  children: [],
                  value: 92.38921001926782
                },
                {
                  name: 'Measuring',
                  full_name:
                    'Measuring, Dispensing, and Other Pumping Equipment Manufacturing ',
                  code: '333914',
                  children: [],
                  value: 7.610789980732177
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Metalworking Machinery Manufacturing',
          full_name: 'Metalworking Machinery Manufacturing',
          code: '3335',
          children: [
            {
              name: 'Metalworking Machinery Manufacturing',
              full_name: 'Metalworking Machinery Manufacturing',
              code: '33351',
              children: [
                {
                  name: 'Machine Tool Manufacturing ',
                  full_name: 'Machine Tool Manufacturing ',
                  code: '333517',
                  children: [],
                  value: 39.441974551586696
                },
                {
                  name: 'Special Die and Tool',
                  full_name:
                    'Special Die and Tool, Die Set, Jig, and Fixture Manufacturing ',
                  code: '333514',
                  children: [],
                  value: 26.92012877510348
                },
                {
                  name: 'Industrial Mold Manufacturing ',
                  full_name: 'Industrial Mold Manufacturing ',
                  code: '333511',
                  children: [],
                  value: 12.031273953702284
                },
                {
                  name: 'Cutting Tool and Machine Tool Accessory Manufacturing ',
                  full_name:
                    'Cutting Tool and Machine Tool Accessory Manufacturing ',
                  code: '333515',
                  children: [],
                  value: 11.05932852981757
                },
                {
                  name: 'Rolling Mill and Other Metalworking Machinery Manufacturing ',
                  full_name:
                    'Rolling Mill and Other Metalworking Machinery Manufacturing ',
                  code: '333519',
                  children: [],
                  value: 10.547294189789975
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Agriculture',
          full_name:
            'Agriculture, Construction, and Mining Machinery Manufacturing',
          code: '3331',
          children: [
            {
              name: 'Construction Machinery Manufacturing',
              full_name: 'Construction Machinery Manufacturing',
              code: '33312',
              children: [
                {
                  name: 'Construction Machinery Manufacturing',
                  full_name: 'Construction Machinery Manufacturing',
                  code: '333120',
                  children: [],
                  value: 100
                }
              ],
              value: 0
            },
            {
              name: 'Agricultural Implement Manufacturing',
              full_name: 'Agricultural Implement Manufacturing',
              code: '33311',
              children: [
                {
                  name: 'Farm Machinery and Equipment Manufacturing ',
                  full_name: 'Farm Machinery and Equipment Manufacturing ',
                  code: '333111',
                  children: [],
                  value: 76.952770208901
                },
                {
                  name: 'Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing ',
                  full_name:
                    'Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing ',
                  code: '333112',
                  children: [],
                  value: 23.047229791099
                }
              ],
              value: 0
            },
            {
              name: 'Mining and Oil and Gas Field Machinery Manufacturing',
              full_name: 'Mining and Oil and Gas Field Machinery Manufacturing',
              code: '33313',
              children: [
                {
                  name: 'Mining Machinery and Equipment Manufacturing ',
                  full_name: 'Mining Machinery and Equipment Manufacturing ',
                  code: '333131',
                  children: [],
                  value: 60.49601417183348
                },
                {
                  name: 'Oil and Gas Field Machinery and Equipment Manufacturing ',
                  full_name:
                    'Oil and Gas Field Machinery and Equipment Manufacturing ',
                  code: '333132',
                  children: [],
                  value: 39.50398582816652
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Ventilation',
          full_name:
            'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing',
          code: '3334',
          children: [
            {
              name: 'Ventilation',
              full_name:
                'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing',
              code: '33341',
              children: [
                {
                  name: 'Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing ',
                  full_name:
                    'Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing ',
                  code: '333415',
                  children: [],
                  value: 55.50675126077762
                },
                {
                  name: 'Heating Equipment (except Warm Air Furnaces) Manufacturing ',
                  full_name:
                    'Heating Equipment (except Warm Air Furnaces) Manufacturing ',
                  code: '333414',
                  children: [],
                  value: 36.39173580608427
                },
                {
                  name: 'Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing ',
                  full_name:
                    'Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing ',
                  code: '333413',
                  children: [],
                  value: 8.101512933138116
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Engine',
          full_name:
            'Engine, Turbine, and Power Transmission Equipment Manufacturing',
          code: '3336',
          children: [
            {
              name: 'Engine',
              full_name:
                'Engine, Turbine, and Power Transmission Equipment Manufacturing',
              code: '33361',
              children: [
                {
                  name: 'Other Engine Equipment Manufacturing ',
                  full_name: 'Other Engine Equipment Manufacturing ',
                  code: '333618',
                  children: [],
                  value: 36.98687226505522
                },
                {
                  name: 'Turbine and Turbine Generator Set Units Manufacturing ',
                  full_name:
                    'Turbine and Turbine Generator Set Units Manufacturing ',
                  code: '333611',
                  children: [],
                  value: 34.79891644092519
                },
                {
                  name: 'Mechanical Power Transmission Equipment Manufacturing ',
                  full_name:
                    'Mechanical Power Transmission Equipment Manufacturing ',
                  code: '333613',
                  children: [],
                  value: 19.50406334653053
                },
                {
                  name: 'Speed Changer',
                  full_name:
                    'Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing ',
                  code: '333612',
                  children: [],
                  value: 8.71014794748906
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Commercial and Service Industry Machinery Manufacturing',
          full_name: 'Commercial and Service Industry Machinery Manufacturing',
          code: '3333',
          children: [
            {
              name: 'Commercial and Service Industry Machinery Manufacturing',
              full_name:
                'Commercial and Service Industry Machinery Manufacturing',
              code: '33331',
              children: [
                {
                  name: 'Other Commercial and Service Industry Machinery Manufacturing ',
                  full_name:
                    'Other Commercial and Service Industry Machinery Manufacturing ',
                  code: '333318',
                  children: [],
                  value: 66.79904686258936
                },
                {
                  name: 'Optical Instrument and Lens Manufacturing ',
                  full_name: 'Optical Instrument and Lens Manufacturing ',
                  code: '333314',
                  children: [],
                  value: 29.46783161239079
                },
                {
                  name: 'Photographic and Photocopying Equipment Manufacturing ',
                  full_name:
                    'Photographic and Photocopying Equipment Manufacturing ',
                  code: '333316',
                  children: [],
                  value: 3.733121525019857
                }
              ],
              value: 0
            }
          ],
          value: 0
        }
      ],
      value: 0
    },
    {
      name: 'Transportation Equipment Manufacturing',
      full_name: 'Transportation Equipment Manufacturing',
      code: '336',
      children: [
        {
          name: 'Motor Vehicle Manufacturing',
          full_name: 'Motor Vehicle Manufacturing',
          code: '3361',
          children: [
            {
              name: 'Automobile and Light Duty Motor Vehicle Manufacturing',
              full_name:
                'Automobile and Light Duty Motor Vehicle Manufacturing',
              code: '33611',
              children: [
                {
                  name: 'Automobile Manufacturing ',
                  full_name: 'Automobile Manufacturing ',
                  code: '336111',
                  children: [],
                  value: 89.53927223527737
                },
                {
                  name: 'Light Truck and Utility Vehicle Manufacturing ',
                  full_name: 'Light Truck and Utility Vehicle Manufacturing ',
                  code: '336112',
                  children: [],
                  value: 10.460727764722638
                }
              ],
              value: 0
            },
            {
              name: 'Heavy Duty Truck Manufacturing',
              full_name: 'Heavy Duty Truck Manufacturing',
              code: '33612',
              children: [
                {
                  name: 'Heavy Duty Truck Manufacturing',
                  full_name: 'Heavy Duty Truck Manufacturing',
                  code: '336120',
                  children: [],
                  value: 100
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Aerospace Product and Parts Manufacturing',
          full_name: 'Aerospace Product and Parts Manufacturing',
          code: '3364',
          children: [
            {
              name: 'Aerospace Product and Parts Manufacturing',
              full_name: 'Aerospace Product and Parts Manufacturing',
              code: '33641',
              children: [
                {
                  name: 'Aircraft Manufacturing ',
                  full_name: 'Aircraft Manufacturing ',
                  code: '336411',
                  children: [],
                  value: 44.330102907383356
                },
                {
                  name: 'Aircraft Engine and Engine Parts Manufacturing ',
                  full_name: 'Aircraft Engine and Engine Parts Manufacturing ',
                  code: '336412',
                  children: [],
                  value: 24.857628134678787
                },
                {
                  name: 'Other Aircraft Parts and Auxiliary Equipment Manufacturing ',
                  full_name:
                    'Other Aircraft Parts and Auxiliary Equipment Manufacturing ',
                  code: '336413',
                  children: [],
                  value: 21.160955140373662
                },
                {
                  name: 'Guided Missile and Space Vehicle Manufacturing ',
                  full_name: 'Guided Missile and Space Vehicle Manufacturing ',
                  code: '336414',
                  children: [],
                  value: 6.144469977020681
                },
                {
                  name: 'Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing ',
                  full_name:
                    'Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing ',
                  code: '336415',
                  children: [],
                  value: 2.337895893695674
                },
                {
                  name: 'Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing ',
                  full_name:
                    'Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing ',
                  code: '336419',
                  children: [],
                  value: 1.168947946847837
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Motor Vehicle Parts Manufacturing',
          full_name: 'Motor Vehicle Parts Manufacturing',
          code: '3363',
          children: [
            {
              name: 'Other Motor Vehicle Parts Manufacturing',
              full_name: 'Other Motor Vehicle Parts Manufacturing',
              code: '33639',
              children: [
                {
                  name: 'Other Motor Vehicle Parts Manufacturing',
                  full_name: 'Other Motor Vehicle Parts Manufacturing',
                  code: '336390',
                  children: [],
                  value: 100
                }
              ],
              value: 0
            },
            {
              name: 'Motor Vehicle Electrical and Electronic Equipment Manufacturing',
              full_name:
                'Motor Vehicle Electrical and Electronic Equipment Manufacturing',
              code: '33632',
              children: [
                {
                  name: 'Motor Vehicle Electrical and Electronic Equipment Manufacturing',
                  full_name:
                    'Motor Vehicle Electrical and Electronic Equipment Manufacturing',
                  code: '336320',
                  children: [],
                  value: 100
                }
              ],
              value: 0
            },
            {
              name: 'Motor Vehicle Gasoline Engine and Engine Parts Manufacturing',
              full_name:
                'Motor Vehicle Gasoline Engine and Engine Parts Manufacturing',
              code: '33631',
              children: [
                {
                  name: 'Motor Vehicle Gasoline Engine and Engine Parts Manufacturing',
                  full_name:
                    'Motor Vehicle Gasoline Engine and Engine Parts Manufacturing',
                  code: '336310',
                  children: [],
                  value: 100
                }
              ],
              value: 0
            },
            {
              name: 'Motor Vehicle Transmission and Power Train Parts Manufacturing',
              full_name:
                'Motor Vehicle Transmission and Power Train Parts Manufacturing',
              code: '33635',
              children: [
                {
                  name: 'Motor Vehicle Transmission and Power Train Parts Manufacturing',
                  full_name:
                    'Motor Vehicle Transmission and Power Train Parts Manufacturing',
                  code: '336350',
                  children: [],
                  value: 100
                }
              ],
              value: 0
            },
            {
              name: 'Motor Vehicle Seating and Interior Trim Manufacturing',
              full_name:
                'Motor Vehicle Seating and Interior Trim Manufacturing',
              code: '33636',
              children: [
                {
                  name: 'Motor Vehicle Seating and Interior Trim Manufacturing',
                  full_name:
                    'Motor Vehicle Seating and Interior Trim Manufacturing',
                  code: '336360',
                  children: [],
                  value: 100
                }
              ],
              value: 0
            },
            {
              name: 'Motor Vehicle Metal Stamping',
              full_name: 'Motor Vehicle Metal Stamping',
              code: '33637',
              children: [
                {
                  name: 'Motor Vehicle Metal Stamping',
                  full_name: 'Motor Vehicle Metal Stamping',
                  code: '336370',
                  children: [],
                  value: 100
                }
              ],
              value: 0
            },
            {
              name: 'Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing',
              full_name:
                'Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing',
              code: '33633',
              children: [
                {
                  name: 'Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing',
                  full_name:
                    'Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing',
                  code: '336330',
                  children: [],
                  value: 100
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Ship and Boat Building',
          full_name: 'Ship and Boat Building',
          code: '3366',
          children: [
            {
              name: 'Ship and Boat Building',
              full_name: 'Ship and Boat Building',
              code: '33661',
              children: [
                {
                  name: 'Ship Building and Repairing ',
                  full_name: 'Ship Building and Repairing ',
                  code: '336611',
                  children: [],
                  value: 63.11662028838857
                },
                {
                  name: 'Boat Building ',
                  full_name: 'Boat Building ',
                  code: '336612',
                  children: [],
                  value: 36.88337971161143
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Railroad Rolling Stock Manufacturing',
          full_name: 'Railroad Rolling Stock Manufacturing',
          code: '3365',
          children: [
            {
              name: 'Railroad Rolling Stock Manufacturing',
              full_name: 'Railroad Rolling Stock Manufacturing',
              code: '33651',
              children: [
                {
                  name: 'Railroad Rolling Stock Manufacturing',
                  full_name: 'Railroad Rolling Stock Manufacturing',
                  code: '336510',
                  children: [],
                  value: 100
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Other Transportation Equipment Manufacturing',
          full_name: 'Other Transportation Equipment Manufacturing',
          code: '3369',
          children: [
            {
              name: 'Other Transportation Equipment Manufacturing',
              full_name: 'Other Transportation Equipment Manufacturing',
              code: '33699',
              children: [
                {
                  name: 'All Other Transportation Equipment Manufacturing ',
                  full_name:
                    'All Other Transportation Equipment Manufacturing ',
                  code: '336999',
                  children: [],
                  value: 49.12006257332812
                },
                {
                  name: 'Motorcycle',
                  full_name: 'Motorcycle, Bicycle, and Parts Manufacturing ',
                  code: '336991',
                  children: [],
                  value: 33.94603050449746
                },
                {
                  name: 'Military Armored Vehicle',
                  full_name:
                    'Military Armored Vehicle, Tank, and Tank Component Manufacturing ',
                  code: '336992',
                  children: [],
                  value: 16.933906922174423
                }
              ],
              value: 0
            }
          ],
          value: 0
        },
        {
          name: 'Motor Vehicle Body and Trailer Manufacturing',
          full_name: 'Motor Vehicle Body and Trailer Manufacturing',
          code: '3362',
          children: [
            {
              name: 'Motor Vehicle Body and Trailer Manufacturing',
              full_name: 'Motor Vehicle Body and Trailer Manufacturing',
              code: '33621',
              children: [
                {
                  name: 'Motor Vehicle Body Manufacturing ',
                  full_name: 'Motor Vehicle Body Manufacturing ',
                  code: '336211',
                  children: [],
                  value: 48.717948717948715
                },
                {
                  name: 'Truck Trailer Manufacturing ',
                  full_name: 'Truck Trailer Manufacturing ',
                  code: '336212',
                  children: [],
                  value: 35.83638583638584
                },
                {
                  name: 'Travel Trailer and Camper Manufacturing ',
                  full_name: 'Travel Trailer and Camper Manufacturing ',
                  code: '336214',
                  children: [],
                  value: 9.706959706959708
                },
                {
                  name: 'Motor Home Manufacturing ',
                  full_name: 'Motor Home Manufacturing ',
                  code: '336213',
                  children: [],
                  value: 5.738705738705739
                }
              ],
              value: 0
            }
          ],
          value: 0
        }
      ],
      value: 0
    }
  ],
  value: 0
};
