import { useQuery } from '@tanstack/react-query';
import { getCrmBoosterAvailableRows } from 'actions/dashboardActions';
import { queryKeys } from 'enums';

export const useCRMBoosterAvailableRows = (dashboardId?: string | null) =>
  useQuery({
    enabled: !!dashboardId,
    queryKey: queryKeys.crmBoosterAvailableRows,
    queryFn: () => getCrmBoosterAvailableRows(dashboardId || ''),
    refetchOnWindowFocus: false
  });
