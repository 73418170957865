import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { ReactComponent as CongratsIcon } from 'assets/congrats.svg';
import { ReactComponent as ErrorIcon } from 'assets/error.svg';
import { Card, Loader, SubTitle, Title } from 'components';
import { Routes } from 'enums';
import { useReportConfirmation } from 'hooks/api';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import styles from './styles.module.scss';

export const ReportPurchaseConfirm: FC = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const sessionId = searchParams.get('session_id');

  if (!sessionId) navigate(Routes.NewChat);

  const getTrackPlace = () => {
    const { pathname } = window.location;
    let place = '-';

    if (pathname.includes('chat')) place = 'Chat';
    if (pathname.includes('dashboard')) place = 'Dashboard';

    return place;
  };

  const onSuccess = () => {
    mixpanel?.track(MixpanelEvents.SuccessfulRequestForFullReport, {
      Place: getTrackPlace()
    });
  };

  const onError = () => {
    mixpanel?.track(MixpanelEvents.UnsuccessfulRequestForFullReport, {
      Place: getTrackPlace()
    });
  };

  const {
    isError,
    isPending,
    mutate: getOneTimePaymentConfirmation
  } = useReportConfirmation(sessionId || '', onSuccess, onError);

  const hasError = !sessionId || isError;

  const onClick = () => {
    if (hasError) {
      mixpanel?.track(MixpanelEvents.UnsuccessfulRequestForFullReportCTAClick, {
        Place: getTrackPlace()
      });
      navigate(-1);
    } else {
      mixpanel?.track(MixpanelEvents.SuccessfulRequestForFullReportCTAClick, {
        Place: getTrackPlace()
      });
      navigate(Routes.Reports);
    }
  };

  const { icon, title, subtitle, buttonLabel } = hasError
    ? {
        title: 'Page.ReportPaymentConfirm.ErrorTitle',
        subtitle: 'Page.ReportPaymentConfirm.ErrorSubtitle',
        buttonLabel: 'Page.ReportPaymentConfirm.Back',
        icon: <ErrorIcon />
      }
    : {
        title: 'Page.ReportPaymentConfirm.SuccessTitle',
        subtitle: 'Page.ReportPaymentConfirm.SuccessSubtitle',
        buttonLabel: 'Page.ReportPaymentConfirm.GoToReportsPage',
        icon: <CongratsIcon />
      };

  useEffect(() => {
    getOneTimePaymentConfirmation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles.container}>
        {isPending ? (
          <Loader loaderClassName={styles.loader} />
        ) : (
          <Card>
            {icon}
            <Title className={styles.title} title={t(title)} />
            <SubTitle className={styles.subtitle}>{t(subtitle)}</SubTitle>

            <Button
              size="medium"
              color="primary"
              variant="contained"
              onClick={onClick}
            >
              {t(buttonLabel)}
            </Button>
          </Card>
        )}
      </div>
    </>
  );
};
