import { NotificationsFilters } from 'actions/notificationsActions';

import { ApiBase } from '../ApiBase';

class NotificationsApi extends ApiBase {
  fetchNotifications(filters?: NotificationsFilters) {
    return this.client.get<Notification[]>('', {
      params: filters || {}
    });
  }

  updateNotificationsStatus() {
    return this.client.patch<Notification[]>('/viewed');
  }
}

const instance = new NotificationsApi('/notifications');

export { instance as NotificationsApi };
