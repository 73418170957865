import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Collapse } from '@mui/material';

import { QuestionCard } from './QuestionCard';

import styles from './styles.module.scss';

interface Props {
  questions: Array<{
    title: string;
    description: string;
    button?: string;
  }>;
}

export const Questions: FC<Props> = ({ questions }) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState<boolean>(false);

  const toggleQuestions = useCallback(() => {
    setExpanded((value) => !value);
  }, []);

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>{t('Page.Pricing.Questions.Title')}</h2>
      <div className={styles.cards}>
        {questions.slice(0, 4).map(({ title, description, button }) => (
          <QuestionCard
            key={title}
            isExpanded
            title={t(title)}
            description={t(description)}
            buttonTitle={button ? t(button) : ''}
          />
        ))}
        <Collapse
          className={styles.collapse}
          unmountOnExit
          in={expanded}
          timeout="auto"
          classes={{
            wrapperInner: styles.collapse
          }}
        >
          {questions.slice(4).map(({ title, description, button }) => (
            <QuestionCard
              key={title}
              title={t(title)}
              description={t(description)}
              buttonTitle={button ? t(button) : ''}
            />
          ))}
        </Collapse>
        {questions?.length > 4 && (
          <Button
            size="medium"
            color="primary"
            variant="outlined"
            onClick={toggleQuestions}
            className={styles.button}
          >
            {t(expanded ? 'Common.ShowLess' : 'Common.ShowMore')}
          </Button>
        )}
      </div>
    </div>
  );
};
