import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider
} from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import cn from 'classnames';
import { CheckoutFooter, Loader } from 'components';
import { useSidebarContext } from 'hooks';
import { useCreateCRMBooster } from 'hooks/api';
import { initializeStripe } from 'utils/stripeUtils';

import styles from './styles.module.scss';

let stripePromise: Promise<Stripe | null> | null = null;

export const CRMBoosterCheckout: FC = () => {
  const [searchParams] = useSearchParams();
  const [clientSecret, setClientSecret] = useState('');
  const { open: isSidebarOpened } = useSidebarContext();

  const industry = searchParams.get('industry');
  const question = searchParams.get('question');
  const dashboardId = searchParams.get('dashboardId');

  const onSuccess = (secret: string) => {
    setClientSecret(secret);
  };

  const { mutate: createCRMBooster, isPending } =
    useCreateCRMBooster(onSuccess);

  useEffect(() => {
    stripePromise = initializeStripe(stripePromise);
  }, []);

  useEffect(() => {
    if (industry || question) {
      createCRMBooster({
        industry: industry || '',
        question: question || '',
        dashboardId: dashboardId || ''
      });
    }
  }, [industry, question, createCRMBooster, dashboardId]);

  const options = { clientSecret };

  return (
    <div
      className={cn(styles.wrapper, isSidebarOpened && styles['with-offset'])}
    >
      <div className={styles.container}>
        {isPending && <Loader loaderClassName={styles.loader} />}
        {clientSecret && (
          <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>

      <CheckoutFooter />
    </div>
  );
};
