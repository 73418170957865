import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Collapse } from '@mui/material';
import cn from 'classnames';
import { Tooltip } from 'components';
import { Routes } from 'enums/Routes';
import { usePaginatedChats } from 'hooks/api/usePaginatedChats';
import { DEFAULT_SIDEBAR_CHATS_FILTERS } from 'utils/constants';

import styles from './styles.module.scss';

interface Props {
  open: boolean;
}

export const ResearchItemContent: FC<Props> = ({ open }) => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const currentChatId = useMemo(() => {
    const splittedUrl = location.pathname.split('/');

    return splittedUrl?.[2];
  }, [location]);

  const { data } = usePaginatedChats(DEFAULT_SIDEBAR_CHATS_FILTERS);

  const goToChat = (id: string) => {
    navigate(Routes.Chat.replace(':chatId', id));
  };

  const goToHistory = () => {
    navigate(Routes.ResearchHistory);
  };

  if (!data?.pages?.length) {
    return null;
  }

  const isRouteMatch =
    [Routes.NewChat, Routes.Chat, Routes.ResearchHistory].find((route) =>
      route.includes(location.pathname)
    ) || location.pathname.includes('chats');

  return (
    <Collapse
      unmountOnExit
      in={isRouteMatch && open}
      timeout={{
        enter: 0
      }}
    >
      <div className={styles.rows}>
        {(data?.pages?.[0] || []).slice(0, 3).map((chat) => (
          <div
            key={chat.id}
            className={cn(
              styles.row,
              currentChatId && currentChatId === chat.id && styles.active
            )}
            onClick={() => goToChat(chat.id)}
          >
            <div className={styles['indicator-container']}>
              <div className={styles.indicator} />
            </div>
            <Tooltip title={chat.title || ''}>
              <div className={styles.title}>{chat.title}</div>
            </Tooltip>
          </div>
        ))}
        {!!data?.pages?.[0]?.length && (
          <div
            className={cn(
              styles.row,
              styles['history-row'],
              location.pathname === Routes.ResearchHistory && styles.active
            )}
            onClick={goToHistory}
          >
            <div className={styles['indicator-container']}>
              <div className={styles.indicator} />
            </div>
            <div className={styles.title}>
              {t('Common.Sidebar.SearchHistory')}
            </div>
          </div>
        )}
      </div>
    </Collapse>
  );
};
