import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import requestReportImage from 'assets/request-report.png';
import { RequestReportModal } from 'components';

import styles from './styles.module.scss';

export const RequestReportCard = () => {
  const [isReportModalOpen, setReportModalOpen] = useState(false);

  const { t } = useTranslation();

  const toggleReportModal = () => {
    setReportModalOpen((value) => !value);
  };

  return (
    <div className={styles.container}>
      <RequestReportModal
        question=""
        industry=""
        hasValidation
        disabled={false}
        isOpen={isReportModalOpen}
        onClose={toggleReportModal}
      />
      <div>
        <h3 className={styles.title}>
          {t('Page.Report.RequestReportCard.Title')}
        </h3>
        <img
          alt="requestReportImage"
          src={requestReportImage}
          className={styles.image}
        />
        <div className={styles.description}>
          {t('Page.Report.RequestReportCard.Description')}
        </div>
      </div>
      <Button
        fullWidth
        size="medium"
        type="submit"
        color="primary"
        variant="contained"
        onClick={toggleReportModal}
      >
        {t('Page.Report.RequestReportCard.RequestReport')}
      </Button>
    </div>
  );
};
