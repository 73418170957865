import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { DashboardContextProvider } from 'hooks/useDashboardContext';

const DASHBOARD_INDENTATIONS = {
  top: -100,
  bottom: 48
};

export const DashboardProvider: FC<PropsWithChildren> = ({ children }) => {
  const expertWidgetRef = useRef<HTMLDivElement>(null);
  const [hideFloatingButton, setHideFloatingButton] = useState<boolean>(true);

  useEffect(() => {
    const handleScroll = () => {
      if (expertWidgetRef.current) {
        const rect = expertWidgetRef.current.getBoundingClientRect();
        setHideFloatingButton(
          rect.top >= DASHBOARD_INDENTATIONS.top &&
            rect.bottom + DASHBOARD_INDENTATIONS.bottom <= window.innerHeight
        );
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <DashboardContextProvider
      value={{
        expertWidgetRef,
        hideExpertButton: hideFloatingButton
      }}
    >
      {children}
    </DashboardContextProvider>
  );
};
