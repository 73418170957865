import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckmarkBlackIcon } from 'assets/checkmark-black.svg';

import styles from './styles.module.scss';

const planData = {
  firstHeading: 'Page.Pricing.Plans.Free.FirstHeading',
  secondHeading: 'Page.Pricing.Plans.Free.SecondHeading',
  features: [
    {
      title: 'Page.Pricing.Plans.Free.Features.First',
      icon: <CheckmarkBlackIcon className={styles['feature-icon']} />
    },
    {
      title: 'Page.Pricing.Plans.Free.Features.Second',
      icon: <CheckmarkBlackIcon className={styles['feature-icon']} />
    },
    {
      title: 'Page.Pricing.Plans.Free.Features.Third',
      icon: <CheckmarkBlackIcon className={styles['feature-icon']} />
    },
    {
      title: 'Page.Pricing.Plans.Free.Features.Fourth',
      icon: <CheckmarkBlackIcon className={styles['feature-icon']} />
    },
    {
      title: 'Page.Pricing.Plans.Free.Features.Fifth',
      icon: <CheckmarkBlackIcon className={styles['feature-icon']} />
    },
    {
      title: 'Page.Pricing.Plans.Free.Features.Sixth',
      icon: <CheckmarkBlackIcon className={styles['feature-icon']} />
    }
  ]
};

export const BasePlanCard = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{t('Common.SubscriptionPlans.Free')}</h3>
      <div className={styles['description-container']}>
        <p className={styles.description}>{t(planData.secondHeading)}</p>
        <p className={styles.subdescription}>{t(planData.firstHeading)}</p>
      </div>
      <div className={styles.features}>
        {planData.features.map(({ title, icon }) => (
          <div key={title} className={styles.feature}>
            {icon}
            <span>{t(title)}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
