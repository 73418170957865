import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import cn from 'classnames';
import { Card, RequestReportModal } from 'components';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import { Stepper } from '../Stepper';

import styles from './styles.module.scss';

const steps = [
  {
    title: 'Page.Pricing.Report.Free.Steps.First.Title',
    description: 'Page.Pricing.Report.Free.Steps.First.Description'
  },
  {
    title: 'Page.Pricing.Report.Free.Steps.Second.Title',
    description: 'Page.Pricing.Report.Free.Steps.Second.Description'
  },
  {
    title: 'Page.Pricing.Report.Free.Steps.Third.Title',
    description: 'Page.Pricing.Report.Free.Steps.Third.Description'
  }
];

export const FreeReportCard = () => {
  const [isReportModalOpen, setReportModalOpen] = useState(false);

  const { t } = useTranslation();

  const toggleReportModal = () => {
    setReportModalOpen((value) => !value);
  };

  const handleRequestFreeSnippet = () => {
    mixpanel?.track(MixpanelEvents.PricingPageRequestFreeSnippet);

    toggleReportModal();
  };

  return (
    <Card className={cn(styles.card)}>
      <RequestReportModal
        question=""
        industry=""
        hasValidation
        disabled={false}
        isOpen={isReportModalOpen}
        onClose={toggleReportModal}
      />
      <div>
        <div className={styles['header-container']}>
          <h3 className={styles.title}>
            {t('Page.Pricing.Report.Free.Title')}
          </h3>
        </div>
        <div className={styles['description-container']}>
          <p className={styles.description}>
            {t('Page.Pricing.Report.Free.Price')}
          </p>
          <p className={styles.subdescription}>
            {t('Page.Pricing.Report.Free.Subtitle')}
          </p>
        </div>
      </div>

      <div className={styles.stepper}>
        <Stepper steps={steps} />
      </div>

      <Button
        size="large"
        color="primary"
        variant="outlined"
        className={styles.button}
        onClick={handleRequestFreeSnippet}
      >
        {t('Page.Pricing.Report.Free.GetFreeSnippet')}
      </Button>
    </Card>
  );
};
