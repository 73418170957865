export const mockedMapData: any = {
  id: 'f9e6d686-0fec-4944-b349-a52418a09af3',
  title: null,
  type: 'map_dots',
  filters: ['Found Companies'],
  params: {
    title: 'Map',
    data: [
      {
        label: 'Found Companies',
        value: [
          [50.4699, 30.432, 2009, 10],
          [50.4699, 30.432, 1998, 26],
          [48.3018, 25.0398, 2024, 50],
          [50.2587, 29.8723, 1997, 10000],
          [50.4477, 30.4247, 2001, 27],
          [48.8447, 37.5478, 2024, 52],
          [49.1925, 32.6454, 2014, 10],
          [50.4699, 30.432, 2011, 100],
          [48.3018, 25.0398, 2015, 100],
          [50.4699, 30.432, 2005, 100],
          [49.978, 36.231, 2008, 100],
          [48.4408, 35.0215, 2003, 11],
          [46.4587, 30.7284, 1989, 50],
          [50.4399, 30.4887, 1981, 500],
          [50.4699, 30.432, 2010, 50],
          [50.0265, 36.3388, 1990, 30],
          [50.0149, 36.22, 2007, 75],
          [49.1925, 32.6454, 2019, 50],
          [49.978, 36.231, 2024, 1500],
          [50.4467, 30.4296, 2018, 112],
          [48.3018, 25.0398, 2008, 50],
          [50.1422, 30.7478, 2017, 150],
          [50.4327, 30.3946, 2011, 27],
          [49.978, 36.231, 1949, 1100],
          [50.4699, 30.432, 2024, 11],
          [50.4477, 30.4247, 2019, 50],
          [48.4408, 35.0215, 2024, 100],
          [49.978, 36.231, 2013, 212],
          [47.8263, 35.1669, 2015, 10],
          [50.4699, 30.432, 2013, 100],
          [48.4408, 35.0215, 2006, 11],
          [50.5212, 30.8182, 1912, 50],
          [50.7766, 29.2852, 2006, 25],
          [49.978, 36.231, 2010, 15],
          [49.1925, 32.6454, 2008, 50],
          [48.618, 22.3001, 2015, 50],
          [50.4699, 30.432, 2020, 10000],
          [46.9385, 35.7724, 2021, 10],
          [49.8416, 24.0314, 2015, 15],
          [50.4699, 30.432, 2009, 100],
          [50.0149, 36.22, 2013, 10],
          [50.4359, 30.3413, 2012, 100],
          [49.1925, 32.6454, 2003, 35],
          [49.4866, 29.534, 1967, 1000],
          [50.5449, 30.2267, 2001, 20],
          [50.4699, 30.432, 2017, 26],
          [50.4477, 30.4247, 2006, 50],
          [47.825, 35.2096, 2009, 500],
          [50.0577, 23.9786, 2014, 10],
          [48.4408, 35.0215, 1997, 100],
          [46.6551, 32.6035, 2000, 38],
          [49.5954, 34.5479, 2023, 50],
          [49.978, 36.231, 1996, 180],
          [49.978, 36.231, 2024, 50],
          [50.2586, 28.6911, 2006, 25],
          [50.5176, 30.808, 2007, 50],
          [50.4699, 30.432, 2003, 10000],
          [49.9074, 24.6023, 2019, 48],
          [49.0781, 33.4168, 2021, 50],
          [51.4991, 31.2768, 1994, 25],
          [49.1925, 32.6454, 2013, 50],
          [50.4699, 30.432, 2015, 100],
          [48.2701, 25.9268, 2019, 25],
          [49.1925, 32.6454, 2007, 60],
          [49.551, 25.5921, 2024, 1000],
          [49.978, 36.231, 2024, 25],
          [50.4477, 30.4247, 2014, 50],
          [46.4587, 30.7284, 1951, 50],
          [50.4699, 30.432, 2000, 50],
          [48.9031, 24.7128, 2013, 50],
          [49.1925, 32.6454, 2004, 50],
          [49.9793, 24.0748, 1955, 500],
          [50.8784, 33.2308, 2005, 25],
          [50.3521, 30.9576, 2019, 58],
          [49.7563, 23.8956, 2012, 50],
          [49.978, 36.231, 2015, 50],
          [49.1925, 32.6454, 1946, 20000],
          [48.4408, 35.0215, 2005, 100],
          [49.978, 36.231, 2019, 100],
          [50.4699, 30.432, 2003, 150],
          [50.5449, 30.2267, 2007, 50],
          [49.1925, 32.6454, 2013, 100],
          [49.1925, 32.6454, 1993, 20000],
          [50.0149, 36.22, 2006, 50],
          [48.4408, 35.0215, 2018, 11],
          [49.978, 36.231, 1990, 150],
          [49.1925, 32.6454, 1980, 60],
          [49.1925, 32.6454, 2015, 120],
          [49.8416, 24.0314, 2011, 30],
          [49.1925, 32.6454, 1995, 100],
          [46.6543, 32.6032, 1928, 38],
          [49.4239, 32.053, 2007, 500],
          [47.81, 35.1918, 1996, 500],
          [50.4699, 30.432, 2005, 50],
          [50.4699, 30.432, 1993, 3152],
          [49.978, 36.231, 2001, 25],
          [49.2542, 23.841, 2014, 30],
          [49.978, 36.231, 2007, 10],
          [48.4408, 35.0215, 2003, 100],
          [50.0149, 36.22, 2012, 100],
          [50.0149, 36.22, 2009, 150],
          [49.1925, 32.6454, 1996, 100],
          [50.4413, 30.5595, 2010, 10],
          [48.7747, 35.3703, 1991, 100],
          [49.978, 36.231, 2010, 50],
          [50.4699, 30.432, 1992, 50],
          [46.4587, 30.7284, 1982, 500],
          [49.8252, 24.0187, 2018, 20]
        ]
      }
    ],
    name: 'Map',
    type: 'map',
    max_amount: 111,
    displayed_amount: 111,
    codes: [
      'UA,1,kyiv',
      'UA,1,kharkiv',
      'UA,1,cherkasy',
      'UA,1,lviv',
      'UA,1,dnipropetrovsk',
      'UA,1,zaporizhzhia',
      'UA,1,ivano-frankivsk',
      'UA,1,odessa',
      'UA,1,zhytomyr',
      'UA,1,kherson',
      'UA,1,poltava',
      'UA,1,donetsk',
      'UA,1,sumy',
      'UA,1,ternopil',
      'UA,1,vinnytsia',
      'UA,1,zakarpattia',
      'UA,1,chernivtsi',
      'UA,1,chernihiv'
    ]
  },
  hasFeedback: false,
  createdAt: '2024-10-22T12:44:35.306Z',
  mapType: 'Dots map'
};
