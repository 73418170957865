import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as InfoIcon } from 'assets/InfoIcon.svg';
import cn from 'classnames';
import { CommonTooltip } from 'components';
import { BackdropOverlay } from 'components/BackdropOverlay';
import { ChatStatement } from 'components/Chat/ChatStatement';
import { Pie } from 'components/Pie';
import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardParams } from 'entities/DashboardParams.entity';
import { InsightsType, MessageTypes, queryKeys, Routes } from 'enums';
import { useExternalDashboardLink, useIsExternalUserDashboard } from 'hooks';
import {
  useCreatedNestedDashboard,
  useUserCoins,
  useUserInfo
} from 'hooks/api';
import mixpanel, { MixpanelEvents } from 'mixpanel';
import type { SelectedFilterItem } from 'types';
import { DashboardFilterModal } from 'views/DashboardV2/DashboardFilterModal';

import { DashboardVariationLimitModal } from '../DashboardVariationLimitModal';

import styles from './styles.module.scss';

interface Props {
  note?: string;
  title?: string;
  summary?: string;
  data?: DashboardParams;
  isTextWidgetsProcessing?: boolean;
}

export const PieChart: FC<Props> = ({
  data,
  summary,
  title,
  note,
  isTextWidgetsProcessing
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isExternalUserDashboard = useIsExternalUserDashboard();
  const { chatId, dashboardId, messageId } = useParams();

  const [activeIndex, setActiveIndex] = useState<number>();
  const [isApplyFilterModalOpen, setApplyFilterModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<SelectedFilterItem>(null);
  const [isVariationLimitModalOpen, setVariationLimitModalOpen] =
    useState(false);

  const selectItem = (data: SelectedFilterItem) => {
    setSelectedItem(data);
  };

  const { data: dashboardData } = useQuery<DashboardData>({
    queryKey: queryKeys.dashboardDetails(dashboardId! || messageId!)
  });

  const { data: userInfo } = useUserInfo();
  const { data: userCoins } = useUserCoins();

  const externalDashboardLink = useExternalDashboardLink();

  const openVariationLimitModal = useCallback(
    () => setVariationLimitModalOpen(true),
    []
  );

  const closeVariationLimitModal = useCallback(
    () => setVariationLimitModalOpen(false),
    []
  );

  const { mutate: createNestedDashboard, isPending } =
    useCreatedNestedDashboard({
      showVariationLimitModal: openVariationLimitModal
    });

  const showFilter = dashboardData?.revision && dashboardData.revision > 2;

  const onApplyFilter = (filterItem: { name: string; code: string }) => {
    createNestedDashboard({
      dashboardId: dashboardId || '',
      chatId: chatId || '',
      originDashboardId: dashboardData?.originDashboardId,
      data: [
        ...(dashboardData?.filters || []),
        {
          type: InsightsType.PieChart,
          value: filterItem?.code || '',
          name: filterItem?.name || '',
          typeName: data?.type || ''
        }
      ]
    });
  };

  const handleRemoveTooltip = useCallback((event: MouseEvent) => {
    const isPieChartSector = (
      event?.target as SVGPathElement
    )?.classList?.contains('recharts-sector');

    if (!isPieChartSector) {
      setActiveIndex(undefined);
    }
  }, []);

  const hasCoins = userInfo?.isPremiumUser ? true : userCoins?.count;

  const sendMixpanelEvent = (mixpanelEvent: MixpanelEvents) => {
    mixpanel?.track(mixpanelEvent, {
      'Dashboard ID': dashboardId,
      'Widget type': data?.type,
      'Filter value': selectedItem?.name,
      'Dashboard link': externalDashboardLink
    });
  };

  const handleApplyFilterModalClose = () => {
    if (!hasCoins) {
      sendMixpanelEvent(MixpanelEvents.CancelGoPricingClick);
    } else {
      sendMixpanelEvent(MixpanelEvents.CancelFilterClick);
    }

    setApplyFilterModalOpen(false);
  };

  const handleApplyFilterModalConfirm = () => {
    if (!hasCoins) {
      navigate(Routes.SubscriptionPlans, {
        state: { scrollToTop: true }
      });

      sendMixpanelEvent(MixpanelEvents.GoPricingClick);

      return;
    }

    sendMixpanelEvent(MixpanelEvents.ConfirmFilterClick);

    setApplyFilterModalOpen(false);

    if (selectedItem?.code) {
      onApplyFilter(selectedItem);
    }
  };

  const onClick = (selectedItemData: SelectedFilterItem) => {
    if (
      !showFilter ||
      isExternalUserDashboard ||
      userInfo?.deactivatedAt ||
      !selectedItemData
    )
      return null;

    selectItem(selectedItemData);

    if (userInfo?.isFreeUser) {
      setApplyFilterModalOpen(true);
    } else {
      onApplyFilter(selectedItemData);
    }
  };

  useEffect(() => {
    document.body.addEventListener('mouseover', handleRemoveTooltip);

    return () => {
      document.body.removeEventListener('mouseover', handleRemoveTooltip);
    };
  }, [handleRemoveTooltip]);

  return (
    <div className={styles.container}>
      {isPending && <BackdropOverlay isPending={isPending} />}

      <DashboardVariationLimitModal
        onClose={closeVariationLimitModal}
        isOpen={isVariationLimitModalOpen}
      />
      <DashboardFilterModal
        hasCoins={!!hasCoins}
        isOpen={isApplyFilterModalOpen}
        industry={selectedItem?.name || ''}
        onClose={handleApplyFilterModalClose}
        onConfirm={handleApplyFilterModalConfirm}
      />
      <div>
        <CommonTooltip
          className={cn(styles.title, 'overflowed-text-multiline')}
          title={title || t('Page.DashboardV2.PieChart.Title')}
        />
        <div className={styles.note}>
          <InfoIcon />

          {note}
        </div>
      </div>
      <Pie
        data={data}
        setSelectedItem={onClick}
        activeIndex={activeIndex}
        selectedItem={selectedItem}
        changeActiveIndex={setActiveIndex}
      />
      {(isTextWidgetsProcessing || summary) && (
        <ChatStatement
          showAvatar={false}
          maxLettersAmount={700}
          isTextWidgetsProcessing={isTextWidgetsProcessing}
          data={{ type: MessageTypes.answer, statement: summary }}
        />
      )}
    </div>
  );
};
