import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Skeleton } from '@mui/material';
import crmBoosterLoadingImage from 'assets/crm-booster-loading.png';
import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import moreQuestionsImage from 'assets/more-questions.png';
import cn from 'classnames';
import { Tooltip } from 'components';
import { CRMBooster } from 'entities/CRMBooster.entity';
import { Routes } from 'enums';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import styles from './styles.module.scss';

interface Props {
  data: CRMBooster;
  loading?: boolean;
}

export const CRMBoosterCard: FC<Props> = ({ data, loading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToCRMBooster = () => {
    navigate(Routes.CRMBoosterDetails.replace(':id', data.id));
  };

  const handleDownloadCRMBooster = () => {
    mixpanel?.track(MixpanelEvents.DownloadCRMBoosterClick, {
      'Report URL': data.filename
    });

    window.open(
      `${process.env.REACT_APP_BASE_URL}/crm-boosters/${data.id}/download`
    );
  };

  const crmBoosterTitle = loading
    ? t('Page.CRMBooster.LoadingCRMBoosterCard.Title')
    : data.title || '';

  const cardSkeleton = (
    <>
      <Skeleton variant="text" className={cn(styles.skeleton, styles.small)} />
      <Skeleton variant="text" className={styles.skeleton} />
    </>
  );

  return (
    <div className={styles.container}>
      <div className={styles['title-container']}>
        <Tooltip
          title={
            loading
              ? t('Page.CRMBooster.LoadingCRMBoosterCard.Title')
              : data.title || ''
          }
        >
          <h3 className={styles.title}>{crmBoosterTitle}</h3>
        </Tooltip>
        {!loading && (
          <DownloadIcon
            className={styles.download}
            onClick={handleDownloadCRMBooster}
          />
        )}
      </div>
      <div className={styles['big-numbers']}>
        <div className={styles['big-number']}>
          {loading ? (
            cardSkeleton
          ) : (
            <>
              <div className={styles.label}>
                {t('Page.CRMBooster.Companies')}
              </div>
              <div className={styles.value}>{data.companyCount || '-'}</div>
            </>
          )}
        </div>
        <div className={styles['big-number']}>
          {loading ? (
            cardSkeleton
          ) : (
            <>
              <div className={styles.label}>
                {t('Page.CRMBooster.Employees')}
              </div>
              <div className={styles.value}>{data.employeeCount || '-'}</div>
            </>
          )}
        </div>
      </div>
      <img
        alt="crm-booster"
        className={styles.image}
        src={loading ? crmBoosterLoadingImage : moreQuestionsImage}
      />
      <div className={styles['footer-container']}>
        {loading ? (
          t('Page.CRMBooster.LoadingCRMBoosterCard.Description')
        ) : (
          <Button
            fullWidth
            size="medium"
            type="submit"
            color="primary"
            variant="outlined"
            onClick={goToCRMBooster}
          >
            {t('Page.CRMBooster.EditCRMBooster')}
          </Button>
        )}
      </div>
    </div>
  );
};
