export const mockedTreemapData: any = {
  name: 'All machines',
  children: [
    {
      name: 'Mazak',
      children: [
        {
          name: 'Milling',
          children: [
            {
              name: 'Integrex',
              children: [
                {
                  name: 'Integrex E-1600V/10',
                  value: 7,
                  code: 'Mazak,Milling,Integrex,Integrex E-1600V/10',
                  absolute_sum: 7,
                  relative_sum: 0.36726128016789084
                },
                {
                  name: 'Integrex I-100 Bartac-S',
                  value: 27,
                  code: 'Mazak,Milling,Integrex,Integrex I-100 Bartac-S',
                  absolute_sum: 27,
                  relative_sum: 1.4165792235047219
                },
                {
                  name: 'Integrex I-100',
                  value: 77,
                  code: 'Mazak,Milling,Integrex,Integrex I-100',
                  absolute_sum: 77,
                  relative_sum: 4.0398740818468
                },
                {
                  name: 'Integrex I-100S',
                  value: 11,
                  code: 'Mazak,Milling,Integrex,Integrex I-100S',
                  absolute_sum: 11,
                  relative_sum: 0.577124868835257
                },
                {
                  name: 'Integrex 200-Iv St-1000U',
                  value: 48,
                  code: 'Mazak,Milling,Integrex,Integrex 200-Iv St-1000U',
                  absolute_sum: 48,
                  relative_sum: 2.5183630640083945
                },
                {
                  name: 'Integrex E-1060V/8 Ii',
                  value: 19,
                  code: 'Mazak,Milling,Integrex,Integrex E-1060V/8 Ii',
                  absolute_sum: 19,
                  relative_sum: 0.9968520461699895
                },
                {
                  name: 'Integrex E-1060 V/8',
                  value: 9,
                  code: 'Mazak,Milling,Integrex,Integrex E-1060 V/8',
                  absolute_sum: 9,
                  relative_sum: 0.472193074501574
                },
                {
                  name: 'Integrex E-1850V/12',
                  value: 9,
                  code: 'Mazak,Milling,Integrex,Integrex E-1850V/12',
                  absolute_sum: 9,
                  relative_sum: 0.472193074501574
                },
                {
                  name: 'Integrex E-Ramtec V/10',
                  value: 4,
                  code: 'Mazak,Milling,Integrex,Integrex E-Ramtec V/10',
                  absolute_sum: 4,
                  relative_sum: 0.2098635886673662
                },
                {
                  name: 'Integrex E-1250V/8 Ag',
                  value: 8,
                  code: 'Mazak,Milling,Integrex,Integrex E-1250V/8 Ag',
                  absolute_sum: 8,
                  relative_sum: 0.4197271773347324
                },
                {
                  name: 'Integrex I-150',
                  value: 17,
                  code: 'Mazak,Milling,Integrex,Integrex I-150',
                  absolute_sum: 17,
                  relative_sum: 0.8919202518363064
                },
                {
                  name: 'Integrex I-200-1500U',
                  value: 30,
                  code: 'Mazak,Milling,Integrex,Integrex I-200-1500U',
                  absolute_sum: 30,
                  relative_sum: 1.5739769150052465
                },
                {
                  name: 'Integrex I-400S-1500U',
                  value: 16,
                  code: 'Mazak,Milling,Integrex,Integrex I-400S-1500U',
                  absolute_sum: 16,
                  relative_sum: 0.8394543546694648
                },
                {
                  name: 'Integrex E-1850V/25S Ii',
                  value: 8,
                  code: 'Mazak,Milling,Integrex,Integrex E-1850V/25S Ii',
                  absolute_sum: 8,
                  relative_sum: 0.4197271773347324
                },
                {
                  name: 'Integrex I-200',
                  value: 37,
                  code: 'Mazak,Milling,Integrex,Integrex I-200',
                  absolute_sum: 37,
                  relative_sum: 1.9412381951731374
                },
                {
                  name: 'Integrex J-200',
                  value: 145,
                  code: 'Mazak,Milling,Integrex,Integrex J-200',
                  absolute_sum: 145,
                  relative_sum: 7.607555089192025
                },
                {
                  name: 'Integrex J-200S',
                  value: 56,
                  code: 'Mazak,Milling,Integrex,Integrex J-200S',
                  absolute_sum: 56,
                  relative_sum: 2.9380902413431267
                },
                {
                  name: 'Integrex I-250H St',
                  value: 5,
                  code: 'Mazak,Milling,Integrex,Integrex I-250H St',
                  absolute_sum: 5,
                  relative_sum: 0.26232948583420773
                },
                {
                  name: 'Integrex I-250Hs',
                  value: 14,
                  code: 'Mazak,Milling,Integrex,Integrex I-250Hs',
                  absolute_sum: 14,
                  relative_sum: 0.7345225603357817
                },
                {
                  name: 'Integrex I-300S-2500U',
                  value: 2,
                  code: 'Mazak,Milling,Integrex,Integrex I-300S-2500U',
                  absolute_sum: 2,
                  relative_sum: 0.1049317943336831
                },
                {
                  name: 'Integrex 50Y/2500U',
                  value: 6,
                  code: 'Mazak,Milling,Integrex,Integrex 50Y/2500U',
                  absolute_sum: 6,
                  relative_sum: 0.3147953830010493
                },
                {
                  name: 'Integrex I-500S-2500U',
                  value: 29,
                  code: 'Mazak,Milling,Integrex,Integrex I-500S-2500U',
                  absolute_sum: 29,
                  relative_sum: 1.521511017838405
                },
                {
                  name: 'Integrex 30',
                  value: 51,
                  code: 'Mazak,Milling,Integrex,Integrex 30',
                  absolute_sum: 51,
                  relative_sum: 2.6757607555089193
                },
                {
                  name: 'Integrex I-300',
                  value: 8,
                  code: 'Mazak,Milling,Integrex,Integrex I-300',
                  absolute_sum: 8,
                  relative_sum: 0.4197271773347324
                },
                {
                  name: 'Integrex I-400',
                  value: 66,
                  code: 'Mazak,Milling,Integrex,Integrex I-400',
                  absolute_sum: 66,
                  relative_sum: 3.4627492130115427
                },
                {
                  name: 'Integrex E-420H Ii',
                  value: 13,
                  code: 'Mazak,Milling,Integrex,Integrex E-420H Ii',
                  absolute_sum: 13,
                  relative_sum: 0.6820566631689402
                },
                {
                  name: 'Integrex I-500V/5',
                  value: 13,
                  code: 'Mazak,Milling,Integrex,Integrex I-500V/5',
                  absolute_sum: 13,
                  relative_sum: 0.6820566631689402
                },
                {
                  name: 'Integrex 50',
                  value: 47,
                  code: 'Mazak,Milling,Integrex,Integrex 50',
                  absolute_sum: 47,
                  relative_sum: 2.465897166841553
                },
                {
                  name: 'Integrex E-500H',
                  value: 53,
                  code: 'Mazak,Milling,Integrex,Integrex E-500H',
                  absolute_sum: 53,
                  relative_sum: 2.7806925498426023
                },
                {
                  name: 'Integrex I-500St',
                  value: 5,
                  code: 'Mazak,Milling,Integrex,Integrex I-500St',
                  absolute_sum: 5,
                  relative_sum: 0.26232948583420773
                },
                {
                  name: 'Integrex I-300S',
                  value: 52,
                  code: 'Mazak,Milling,Integrex,Integrex I-300S',
                  absolute_sum: 52,
                  relative_sum: 2.728226652675761
                },
                {
                  name: 'Integrex I-630V/6',
                  value: 48,
                  code: 'Mazak,Milling,Integrex,Integrex I-630V/6',
                  absolute_sum: 48,
                  relative_sum: 2.5183630640083945
                },
                {
                  name: 'Integrex E-670H',
                  value: 745,
                  code: 'Mazak,Milling,Integrex,Integrex E-670H',
                  absolute_sum: 745,
                  relative_sum: 39.08709338929695
                },
                {
                  name: 'Integrex E-800H-8000U',
                  value: 42,
                  code: 'Mazak,Milling,Integrex,Integrex E-800H-8000U',
                  absolute_sum: 42,
                  relative_sum: 2.2035676810073452
                },
                {
                  name: 'Integrex Ag Series',
                  value: 14,
                  code: 'Mazak,Milling,Integrex,Integrex Ag Series',
                  absolute_sum: 14,
                  relative_sum: 0.7345225603357817
                },
                {
                  name: 'Integrex I-V Series',
                  value: 9,
                  code: 'Mazak,Milling,Integrex,Integrex I-V Series',
                  absolute_sum: 9,
                  relative_sum: 0.472193074501574
                },
                {
                  name: 'Integrex',
                  value: 156,
                  code: 'Mazak,Milling,Integrex,Integrex',
                  absolute_sum: 156,
                  relative_sum: 8.184679958027283
                }
              ],
              code: 'Mazak,Milling,Integrex',
              absolute_sum: 1906,
              relative_sum: 13.088861420134599
            },
            {
              name: 'Face',
              children: [
                {
                  name: 'Fjv 5 Face-100/120',
                  value: 17,
                  code: 'Mazak,Milling,Face,Fjv 5 Face-100/120',
                  absolute_sum: 17,
                  relative_sum: 27.86885245901639
                },
                {
                  name: 'Fjv 5 Face-35/60',
                  value: 19,
                  code: 'Mazak,Milling,Face,Fjv 5 Face-35/60',
                  absolute_sum: 19,
                  relative_sum: 31.147540983606557
                },
                {
                  name: 'Fjv 5 Face-35/80',
                  value: 10,
                  code: 'Mazak,Milling,Face,Fjv 5 Face-35/80',
                  absolute_sum: 10,
                  relative_sum: 16.39344262295082
                },
                {
                  name: 'Fjv 5 Face-60/80',
                  value: 15,
                  code: 'Mazak,Milling,Face,Fjv 5 Face-60/80',
                  absolute_sum: 15,
                  relative_sum: 24.59016393442623
                }
              ],
              code: 'Mazak,Milling,Face',
              absolute_sum: 61,
              relative_sum: 0.41889850295289105
            },
            {
              name: 'Fjv',
              children: [
                {
                  name: 'Fjv 5 Face-100/120',
                  value: 17,
                  code: 'Mazak,Milling,Fjv,Fjv 5 Face-100/120',
                  absolute_sum: 17,
                  relative_sum: 4.415584415584416
                },
                {
                  name: 'Fjv-100/120',
                  value: 8,
                  code: 'Mazak,Milling,Fjv,Fjv-100/120',
                  absolute_sum: 8,
                  relative_sum: 2.0779220779220777
                },
                {
                  name: 'Fjv-100/160',
                  value: 3,
                  code: 'Mazak,Milling,Fjv,Fjv-100/160',
                  absolute_sum: 3,
                  relative_sum: 0.7792207792207793
                },
                {
                  name: 'Fjv-35/120',
                  value: 23,
                  code: 'Mazak,Milling,Fjv,Fjv-35/120',
                  absolute_sum: 23,
                  relative_sum: 5.974025974025974
                },
                {
                  name: 'Fjv 60/120',
                  value: 32,
                  code: 'Mazak,Milling,Fjv,Fjv 60/120',
                  absolute_sum: 32,
                  relative_sum: 8.311688311688311
                },
                {
                  name: 'Fjv 200',
                  value: 89,
                  code: 'Mazak,Milling,Fjv,Fjv 200',
                  absolute_sum: 89,
                  relative_sum: 23.116883116883116
                },
                {
                  name: 'Fjv 250',
                  value: 47,
                  code: 'Mazak,Milling,Fjv,Fjv 250',
                  absolute_sum: 47,
                  relative_sum: 12.207792207792208
                },
                {
                  name: 'Fjv 5 Face-35/60',
                  value: 19,
                  code: 'Mazak,Milling,Fjv,Fjv 5 Face-35/60',
                  absolute_sum: 19,
                  relative_sum: 4.935064935064935
                },
                {
                  name: 'Fjv 5 Face-35/80',
                  value: 10,
                  code: 'Mazak,Milling,Fjv,Fjv 5 Face-35/80',
                  absolute_sum: 10,
                  relative_sum: 2.5974025974025974
                },
                {
                  name: 'Fjv 35/60',
                  value: 47,
                  code: 'Mazak,Milling,Fjv,Fjv 35/60',
                  absolute_sum: 47,
                  relative_sum: 12.207792207792208
                },
                {
                  name: 'Fjv 35/80',
                  value: 25,
                  code: 'Mazak,Milling,Fjv,Fjv 35/80',
                  absolute_sum: 25,
                  relative_sum: 6.493506493506493
                },
                {
                  name: 'Fjv 5 Face-60/80',
                  value: 15,
                  code: 'Mazak,Milling,Fjv,Fjv 5 Face-60/80',
                  absolute_sum: 15,
                  relative_sum: 3.896103896103896
                },
                {
                  name: 'Fjv 60/80',
                  value: 43,
                  code: 'Mazak,Milling,Fjv,Fjv 60/80',
                  absolute_sum: 43,
                  relative_sum: 11.168831168831169
                },
                {
                  name: 'Fjv',
                  value: 7,
                  code: 'Mazak,Milling,Fjv,Fjv',
                  absolute_sum: 7,
                  relative_sum: 1.8181818181818181
                }
              ],
              code: 'Mazak,Milling,Fjv',
              absolute_sum: 385,
              relative_sum: 2.6438676006043127
            },
            {
              name: 'Axis',
              children: [
                {
                  name: 'Versatech V-100N200 5-Axis',
                  value: 40,
                  code: 'Mazak,Milling,Axis,Versatech V-100N200 5-Axis',
                  absolute_sum: 40,
                  relative_sum: 3.546099290780142
                },
                {
                  name: 'Variaxis I-1050T',
                  value: 7,
                  code: 'Mazak,Milling,Axis,Variaxis I-1050T',
                  absolute_sum: 7,
                  relative_sum: 0.6205673758865249
                },
                {
                  name: 'V414 3-Axis Cnc Vmc',
                  value: 158,
                  code: 'Mazak,Milling,Axis,V414 3-Axis Cnc Vmc',
                  absolute_sum: 158,
                  relative_sum: 14.00709219858156
                },
                {
                  name: 'Variaxis I-300 Awc',
                  value: 12,
                  code: 'Mazak,Milling,Axis,Variaxis I-300 Awc',
                  absolute_sum: 12,
                  relative_sum: 1.0638297872340425
                },
                {
                  name: 'Htc-400 4-Axis Cnc Hmc',
                  value: 119,
                  code: 'Mazak,Milling,Axis,Htc-400 4-Axis Cnc Hmc',
                  absolute_sum: 119,
                  relative_sum: 10.549645390070921
                },
                {
                  name: 'Variaxis J-500/5X',
                  value: 37,
                  code: 'Mazak,Milling,Axis,Variaxis J-500/5X',
                  absolute_sum: 37,
                  relative_sum: 3.280141843971631
                },
                {
                  name: 'Variaxis 500-5X',
                  value: 30,
                  code: 'Mazak,Milling,Axis,Variaxis 500-5X',
                  absolute_sum: 30,
                  relative_sum: 2.6595744680851063
                },
                {
                  name: 'Variaxis J-600/5X',
                  value: 13,
                  code: 'Mazak,Milling,Axis,Variaxis J-600/5X',
                  absolute_sum: 13,
                  relative_sum: 1.152482269503546
                },
                {
                  name: 'Variaxis 630-5X Ii',
                  value: 36,
                  code: 'Mazak,Milling,Axis,Variaxis 630-5X Ii',
                  absolute_sum: 36,
                  relative_sum: 3.1914893617021276
                },
                {
                  name: 'Variaxis 630-5X',
                  value: 61,
                  code: 'Mazak,Milling,Axis,Variaxis 630-5X',
                  absolute_sum: 61,
                  relative_sum: 5.407801418439717
                },
                {
                  name: 'Variaxis 730-5X Ii',
                  value: 29,
                  code: 'Mazak,Milling,Axis,Variaxis 730-5X Ii',
                  absolute_sum: 29,
                  relative_sum: 2.5709219858156027
                },
                {
                  name: 'Variaxis 730-5X',
                  value: 31,
                  code: 'Mazak,Milling,Axis,Variaxis 730-5X',
                  absolute_sum: 31,
                  relative_sum: 2.74822695035461
                },
                {
                  name: 'Vcc 5 Axis High Speed Vmc',
                  value: 93,
                  code: 'Mazak,Milling,Axis,Vcc 5 Axis High Speed Vmc',
                  absolute_sum: 93,
                  relative_sum: 8.24468085106383
                },
                {
                  name: 'Variaxis I-500',
                  value: 19,
                  code: 'Mazak,Milling,Axis,Variaxis I-500',
                  absolute_sum: 19,
                  relative_sum: 1.6843971631205674
                },
                {
                  name: 'Variaxis J-500',
                  value: 78,
                  code: 'Mazak,Milling,Axis,Variaxis J-500',
                  absolute_sum: 78,
                  relative_sum: 6.914893617021277
                },
                {
                  name: 'Variaxis C-600',
                  value: 40,
                  code: 'Mazak,Milling,Axis,Variaxis C-600',
                  absolute_sum: 40,
                  relative_sum: 3.546099290780142
                },
                {
                  name: 'Variaxis I-600',
                  value: 53,
                  code: 'Mazak,Milling,Axis,Variaxis I-600',
                  absolute_sum: 53,
                  relative_sum: 4.698581560283688
                },
                {
                  name: 'Variaxis 630',
                  value: 49,
                  code: 'Mazak,Milling,Axis,Variaxis 630',
                  absolute_sum: 49,
                  relative_sum: 4.343971631205674
                },
                {
                  name: 'Variaxis I-800 Neo',
                  value: 3,
                  code: 'Mazak,Milling,Axis,Variaxis I-800 Neo',
                  absolute_sum: 3,
                  relative_sum: 0.26595744680851063
                },
                {
                  name: 'Variaxis I-700T',
                  value: 28,
                  code: 'Mazak,Milling,Axis,Variaxis I-700T',
                  absolute_sum: 28,
                  relative_sum: 2.4822695035460995
                },
                {
                  name: 'Variaxis I-700',
                  value: 51,
                  code: 'Mazak,Milling,Axis,Variaxis I-700',
                  absolute_sum: 51,
                  relative_sum: 4.521276595744681
                },
                {
                  name: 'Variaxis I-800T',
                  value: 61,
                  code: 'Mazak,Milling,Axis,Variaxis I-800T',
                  absolute_sum: 61,
                  relative_sum: 5.407801418439717
                },
                {
                  name: 'Variaxis I-800',
                  value: 46,
                  code: 'Mazak,Milling,Axis,Variaxis I-800',
                  absolute_sum: 46,
                  relative_sum: 4.078014184397164
                },
                {
                  name: 'Variaxis I',
                  value: 34,
                  code: 'Mazak,Milling,Axis,Variaxis I',
                  absolute_sum: 34,
                  relative_sum: 3.0141843971631204
                }
              ],
              code: 'Mazak,Milling,Axis',
              absolute_sum: 1128,
              relative_sum: 7.746188710341986
            },
            {
              name: 'Iv',
              children: [
                {
                  name: 'Integrex 200-Iv St-1000U',
                  value: 48,
                  code: 'Mazak,Milling,Iv,Integrex 200-Iv St-1000U',
                  absolute_sum: 48,
                  relative_sum: 11.294117647058824
                },
                {
                  name: 'Vertical Center Universal 500A/2Pc',
                  value: 17,
                  code: 'Mazak,Milling,Iv,Vertical Center Universal 500A/2Pc',
                  absolute_sum: 17,
                  relative_sum: 4
                },
                {
                  name: 'Vertical Center Universal 300A 5X',
                  value: 10,
                  code: 'Mazak,Milling,Iv,Vertical Center Universal 300A 5X',
                  absolute_sum: 10,
                  relative_sum: 2.3529411764705883
                },
                {
                  name: 'Vertical Center Universal 400B',
                  value: 20,
                  code: 'Mazak,Milling,Iv,Vertical Center Universal 400B',
                  absolute_sum: 20,
                  relative_sum: 4.705882352941177
                },
                {
                  name: 'Vertical Center Universal 400T',
                  value: 15,
                  code: 'Mazak,Milling,Iv,Vertical Center Universal 400T',
                  absolute_sum: 15,
                  relative_sum: 3.5294117647058822
                },
                {
                  name: 'Horizontal Center Universal 4000',
                  value: 290,
                  code: 'Mazak,Milling,Iv,Horizontal Center Universal 4000',
                  absolute_sum: 290,
                  relative_sum: 68.23529411764706
                },
                {
                  name: 'Vertical Center Universal 500A 5X',
                  value: 25,
                  code: 'Mazak,Milling,Iv,Vertical Center Universal 500A 5X',
                  absolute_sum: 25,
                  relative_sum: 5.88235294117647
                }
              ],
              code: 'Mazak,Milling,Iv',
              absolute_sum: 425,
              relative_sum: 2.9185551435242414
            },
            {
              name: 'St',
              children: [
                {
                  name: 'Integrex 200-Iv St-1000U',
                  value: 48,
                  code: 'Mazak,Milling,St,Integrex 200-Iv St-1000U',
                  absolute_sum: 48,
                  relative_sum: 82.75862068965517
                },
                {
                  name: 'Integrex I-250H St',
                  value: 5,
                  code: 'Mazak,Milling,St,Integrex I-250H St',
                  absolute_sum: 5,
                  relative_sum: 8.620689655172415
                },
                {
                  name: 'Integrex I-500St',
                  value: 5,
                  code: 'Mazak,Milling,St,Integrex I-500St',
                  absolute_sum: 5,
                  relative_sum: 8.620689655172415
                }
              ],
              code: 'Mazak,Milling,St',
              absolute_sum: 58,
              relative_sum: 0.3982969372338965
            },
            {
              name: 'Variaxis',
              children: [
                {
                  name: 'Variaxis I-1050T',
                  value: 7,
                  code: 'Mazak,Milling,Variaxis,Variaxis I-1050T',
                  absolute_sum: 7,
                  relative_sum: 0.9749303621169917
                },
                {
                  name: 'Variaxis I-300 Awc',
                  value: 12,
                  code: 'Mazak,Milling,Variaxis,Variaxis I-300 Awc',
                  absolute_sum: 12,
                  relative_sum: 1.6713091922005572
                },
                {
                  name: 'Variaxis J-500/5X',
                  value: 37,
                  code: 'Mazak,Milling,Variaxis,Variaxis J-500/5X',
                  absolute_sum: 37,
                  relative_sum: 5.153203342618385
                },
                {
                  name: 'Variaxis 500-5X',
                  value: 30,
                  code: 'Mazak,Milling,Variaxis,Variaxis 500-5X',
                  absolute_sum: 30,
                  relative_sum: 4.178272980501393
                },
                {
                  name: 'Variaxis J-600/5X',
                  value: 13,
                  code: 'Mazak,Milling,Variaxis,Variaxis J-600/5X',
                  absolute_sum: 13,
                  relative_sum: 1.8105849582172702
                },
                {
                  name: 'Variaxis 630-5X Ii',
                  value: 36,
                  code: 'Mazak,Milling,Variaxis,Variaxis 630-5X Ii',
                  absolute_sum: 36,
                  relative_sum: 5.013927576601671
                },
                {
                  name: 'Variaxis 630-5X',
                  value: 61,
                  code: 'Mazak,Milling,Variaxis,Variaxis 630-5X',
                  absolute_sum: 61,
                  relative_sum: 8.495821727019498
                },
                {
                  name: 'Variaxis 730-5X Ii',
                  value: 29,
                  code: 'Mazak,Milling,Variaxis,Variaxis 730-5X Ii',
                  absolute_sum: 29,
                  relative_sum: 4.03899721448468
                },
                {
                  name: 'Variaxis 730-5X',
                  value: 31,
                  code: 'Mazak,Milling,Variaxis,Variaxis 730-5X',
                  absolute_sum: 31,
                  relative_sum: 4.3175487465181055
                },
                {
                  name: 'Variaxis I-500',
                  value: 19,
                  code: 'Mazak,Milling,Variaxis,Variaxis I-500',
                  absolute_sum: 19,
                  relative_sum: 2.6462395543175488
                },
                {
                  name: 'Variaxis J-500',
                  value: 78,
                  code: 'Mazak,Milling,Variaxis,Variaxis J-500',
                  absolute_sum: 78,
                  relative_sum: 10.86350974930362
                },
                {
                  name: 'Variaxis C-600',
                  value: 40,
                  code: 'Mazak,Milling,Variaxis,Variaxis C-600',
                  absolute_sum: 40,
                  relative_sum: 5.571030640668524
                },
                {
                  name: 'Variaxis I-600',
                  value: 53,
                  code: 'Mazak,Milling,Variaxis,Variaxis I-600',
                  absolute_sum: 53,
                  relative_sum: 7.381615598885793
                },
                {
                  name: 'Variaxis 630',
                  value: 49,
                  code: 'Mazak,Milling,Variaxis,Variaxis 630',
                  absolute_sum: 49,
                  relative_sum: 6.8245125348189415
                },
                {
                  name: 'Variaxis I-800 Neo',
                  value: 3,
                  code: 'Mazak,Milling,Variaxis,Variaxis I-800 Neo',
                  absolute_sum: 3,
                  relative_sum: 0.4178272980501393
                },
                {
                  name: 'Variaxis I-700T',
                  value: 28,
                  code: 'Mazak,Milling,Variaxis,Variaxis I-700T',
                  absolute_sum: 28,
                  relative_sum: 3.8997214484679668
                },
                {
                  name: 'Variaxis I-700',
                  value: 51,
                  code: 'Mazak,Milling,Variaxis,Variaxis I-700',
                  absolute_sum: 51,
                  relative_sum: 7.103064066852367
                },
                {
                  name: 'Variaxis I-800T',
                  value: 61,
                  code: 'Mazak,Milling,Variaxis,Variaxis I-800T',
                  absolute_sum: 61,
                  relative_sum: 8.495821727019498
                },
                {
                  name: 'Variaxis I-800',
                  value: 46,
                  code: 'Mazak,Milling,Variaxis,Variaxis I-800',
                  absolute_sum: 46,
                  relative_sum: 6.406685236768802
                },
                {
                  name: 'Variaxis I',
                  value: 34,
                  code: 'Mazak,Milling,Variaxis,Variaxis I',
                  absolute_sum: 34,
                  relative_sum: 4.735376044568245
                }
              ],
              code: 'Mazak,Milling,Variaxis',
              absolute_sum: 718,
              relative_sum: 4.930641395412718
            },
            {
              name: 'Horizontal',
              children: [
                {
                  name: 'Horizontal Center Nexus 4000-Ii',
                  value: 150,
                  code: 'Mazak,Milling,Horizontal,Horizontal Center Nexus 4000-Ii',
                  absolute_sum: 150,
                  relative_sum: 33.25942350332594
                },
                {
                  name: 'Vortex Horizontal Profiler 160 Xp',
                  value: 11,
                  code: 'Mazak,Milling,Horizontal,Vortex Horizontal Profiler 160 Xp',
                  absolute_sum: 11,
                  relative_sum: 2.4390243902439024
                },
                {
                  name: 'Horizontal Center Universal 4000',
                  value: 290,
                  code: 'Mazak,Milling,Horizontal,Horizontal Center Universal 4000',
                  absolute_sum: 290,
                  relative_sum: 64.30155210643017
                }
              ],
              code: 'Mazak,Milling,Horizontal',
              absolute_sum: 451,
              relative_sum: 3.0971020464221946
            },
            {
              name: 'Nexus',
              children: [
                {
                  name: 'Horizontal Center Nexus 4000-Ii',
                  value: 150,
                  code: 'Mazak,Milling,Nexus,Horizontal Center Nexus 4000-Ii',
                  absolute_sum: 150,
                  relative_sum: 8.766803039158386
                },
                {
                  name: 'Nexus 510C-Ii Mill',
                  value: 201,
                  code: 'Mazak,Milling,Nexus,Nexus 510C-Ii Mill',
                  absolute_sum: 201,
                  relative_sum: 11.747516072472239
                },
                {
                  name: 'Vertical Center Nexus 700D/40-Ii Hs',
                  value: 4,
                  code: 'Mazak,Milling,Nexus,Vertical Center Nexus 700D/40-Ii Hs',
                  absolute_sum: 4,
                  relative_sum: 0.23378141437755698
                },
                {
                  name: 'Nexus 700E/40-Ii',
                  value: 44,
                  code: 'Mazak,Milling,Nexus,Nexus 700E/40-Ii',
                  absolute_sum: 44,
                  relative_sum: 2.571595558153127
                },
                {
                  name: 'Nexus 4000-Ii',
                  value: 36,
                  code: 'Mazak,Milling,Nexus,Nexus 4000-Ii',
                  absolute_sum: 36,
                  relative_sum: 2.104032729398013
                },
                {
                  name: 'Nexus 410A-Ii',
                  value: 74,
                  code: 'Mazak,Milling,Nexus,Nexus 410A-Ii',
                  absolute_sum: 74,
                  relative_sum: 4.324956165984804
                },
                {
                  name: 'Vertical Center Nexus 410A-Ⅱ',
                  value: 21,
                  code: 'Mazak,Milling,Nexus,Vertical Center Nexus 410A-Ⅱ',
                  absolute_sum: 21,
                  relative_sum: 1.2273524254821742
                },
                {
                  name: 'Nexus 410A',
                  value: 108,
                  code: 'Mazak,Milling,Nexus,Nexus 410A',
                  absolute_sum: 108,
                  relative_sum: 6.312098188194039
                },
                {
                  name: 'Nexus 410B-Ii',
                  value: 7,
                  code: 'Mazak,Milling,Nexus,Nexus 410B-Ii',
                  absolute_sum: 7,
                  relative_sum: 0.4091174751607247
                },
                {
                  name: 'Vertical Center Nexus 410B-Ii',
                  value: 51,
                  code: 'Mazak,Milling,Nexus,Vertical Center Nexus 410B-Ii',
                  absolute_sum: 51,
                  relative_sum: 2.9807130333138514
                },
                {
                  name: 'Nexus 410B',
                  value: 59,
                  code: 'Mazak,Milling,Nexus,Nexus 410B',
                  absolute_sum: 59,
                  relative_sum: 3.4482758620689653
                },
                {
                  name: 'Nexus 510C-Ii 5X',
                  value: 31,
                  code: 'Mazak,Milling,Nexus,Nexus 510C-Ii 5X',
                  absolute_sum: 31,
                  relative_sum: 1.8118059614260666
                },
                {
                  name: 'Vertical Center Nexus Compact 5X',
                  value: 19,
                  code: 'Mazak,Milling,Nexus,Vertical Center Nexus Compact 5X',
                  absolute_sum: 19,
                  relative_sum: 1.1104617182933958
                },
                {
                  name: 'Nexus 510C-Ii',
                  value: 104,
                  code: 'Mazak,Milling,Nexus,Nexus 510C-Ii',
                  absolute_sum: 104,
                  relative_sum: 6.078316773816481
                },
                {
                  name: 'Vertical Center Nexus 700D/50-Ii',
                  value: 46,
                  code: 'Mazak,Milling,Nexus,Vertical Center Nexus 700D/50-Ii',
                  absolute_sum: 46,
                  relative_sum: 2.6884862653419055
                },
                {
                  name: 'Vertical Center Nexus 700E/50-Ii',
                  value: 1,
                  code: 'Mazak,Milling,Nexus,Vertical Center Nexus 700E/50-Ii',
                  absolute_sum: 1,
                  relative_sum: 0.058445353594389245
                },
                {
                  name: 'Nexus 5000-Ii',
                  value: 138,
                  code: 'Mazak,Milling,Nexus,Nexus 5000-Ii',
                  absolute_sum: 138,
                  relative_sum: 8.065458796025716
                },
                {
                  name: 'Nexus 510C',
                  value: 203,
                  code: 'Mazak,Milling,Nexus,Nexus 510C',
                  absolute_sum: 203,
                  relative_sum: 11.864406779661017
                },
                {
                  name: 'Vertical Center Nexus 510C-Ii',
                  value: 160,
                  code: 'Mazak,Milling,Nexus,Vertical Center Nexus 510C-Ii',
                  absolute_sum: 160,
                  relative_sum: 9.35125657510228
                },
                {
                  name: 'Vertical Center Nexus 510C',
                  value: 144,
                  code: 'Mazak,Milling,Nexus,Vertical Center Nexus 510C',
                  absolute_sum: 144,
                  relative_sum: 8.416130917592051
                },
                {
                  name: 'Vertical Center Nexus 530C-Ii Hs',
                  value: 71,
                  code: 'Mazak,Milling,Nexus,Vertical Center Nexus 530C-Ii Hs',
                  absolute_sum: 71,
                  relative_sum: 4.149620105201636
                },
                {
                  name: 'Nexus Vcn 530Cii Hs',
                  value: 15,
                  code: 'Mazak,Milling,Nexus,Nexus Vcn 530Cii Hs',
                  absolute_sum: 15,
                  relative_sum: 0.8766803039158386
                },
                {
                  name: 'Nexus',
                  value: 24,
                  code: 'Mazak,Milling,Nexus,Nexus',
                  absolute_sum: 24,
                  relative_sum: 1.402688486265342
                }
              ],
              code: 'Mazak,Milling,Nexus',
              absolute_sum: 1711,
              relative_sum: 11.749759648399944
            },
            {
              name: 'Mega',
              children: [
                {
                  name: 'Mega Turn A-12',
                  value: 40,
                  code: 'Mazak,Milling,Mega,Mega Turn A-12',
                  absolute_sum: 40,
                  relative_sum: 68.96551724137932
                },
                {
                  name: 'Mega Turn A-16',
                  value: 18,
                  code: 'Mazak,Milling,Mega,Mega Turn A-16',
                  absolute_sum: 18,
                  relative_sum: 31.03448275862069
                }
              ],
              code: 'Mazak,Milling,Mega',
              absolute_sum: 58,
              relative_sum: 0.3982969372338965
            },
            {
              name: 'Turn',
              children: [
                {
                  name: 'Mega Turn A-12',
                  value: 40,
                  code: 'Mazak,Milling,Turn,Mega Turn A-12',
                  absolute_sum: 40,
                  relative_sum: 68.96551724137932
                },
                {
                  name: 'Mega Turn A-16',
                  value: 18,
                  code: 'Mazak,Milling,Turn,Mega Turn A-16',
                  absolute_sum: 18,
                  relative_sum: 31.03448275862069
                }
              ],
              code: 'Mazak,Milling,Turn',
              absolute_sum: 58,
              relative_sum: 0.3982969372338965
            },
            {
              name: 'Vortex',
              children: [
                {
                  name: 'Vortex 815/120-Ii',
                  value: 18,
                  code: 'Mazak,Milling,Vortex,Vortex 815/120-Ii',
                  absolute_sum: 18,
                  relative_sum: 30.508474576271187
                },
                {
                  name: 'Vortex 1400/160-Ii',
                  value: 30,
                  code: 'Mazak,Milling,Vortex,Vortex 1400/160-Ii',
                  absolute_sum: 30,
                  relative_sum: 50.847457627118644
                },
                {
                  name: 'Vortex Horizontal Profiler 160 Xp',
                  value: 11,
                  code: 'Mazak,Milling,Vortex,Vortex Horizontal Profiler 160 Xp',
                  absolute_sum: 11,
                  relative_sum: 18.64406779661017
                }
              ],
              code: 'Mazak,Milling,Vortex',
              absolute_sum: 59,
              relative_sum: 0.40516412580689465
            },
            {
              name: 'Mtv',
              children: [
                {
                  name: 'Mtv-815/120',
                  value: 36,
                  code: 'Mazak,Milling,Mtv,Mtv-815/120',
                  absolute_sum: 36,
                  relative_sum: 24.82758620689655
                },
                {
                  name: 'Mtv-515/40N',
                  value: 4,
                  code: 'Mazak,Milling,Mtv,Mtv-515/40N',
                  absolute_sum: 4,
                  relative_sum: 2.7586206896551726
                },
                {
                  name: 'Mtv515',
                  value: 14,
                  code: 'Mazak,Milling,Mtv,Mtv515',
                  absolute_sum: 14,
                  relative_sum: 9.655172413793103
                },
                {
                  name: 'Mtv-655/60',
                  value: 23,
                  code: 'Mazak,Milling,Mtv,Mtv-655/60',
                  absolute_sum: 23,
                  relative_sum: 15.862068965517242
                },
                {
                  name: 'Mtv 655/60N',
                  value: 48,
                  code: 'Mazak,Milling,Mtv,Mtv 655/60N',
                  absolute_sum: 48,
                  relative_sum: 33.10344827586207
                },
                {
                  name: 'Mtv-815/80',
                  value: 17,
                  code: 'Mazak,Milling,Mtv,Mtv-815/80',
                  absolute_sum: 17,
                  relative_sum: 11.724137931034482
                },
                {
                  name: 'Mtv',
                  value: 3,
                  code: 'Mazak,Milling,Mtv,Mtv',
                  absolute_sum: 3,
                  relative_sum: 2.0689655172413794
                }
              ],
              code: 'Mazak,Milling,Mtv',
              absolute_sum: 145,
              relative_sum: 0.9957423430847412
            },
            {
              name: 'Ag',
              children: [
                {
                  name: 'Integrex E-1250V/8 Ag',
                  value: 8,
                  code: 'Mazak,Milling,Ag,Integrex E-1250V/8 Ag',
                  absolute_sum: 8,
                  relative_sum: 36.36363636363637
                },
                {
                  name: 'Integrex Ag Series',
                  value: 14,
                  code: 'Mazak,Milling,Ag,Integrex Ag Series',
                  absolute_sum: 14,
                  relative_sum: 63.63636363636363
                }
              ],
              code: 'Mazak,Milling,Ag',
              absolute_sum: 22,
              relative_sum: 0.15107814860596072
            },
            {
              name: 'Mazatech',
              children: [
                {
                  name: 'Mazatech Fh-12800',
                  value: 8,
                  code: 'Mazak,Milling,Mazatech,Mazatech Fh-12800',
                  absolute_sum: 8,
                  relative_sum: 2.8368794326241136
                },
                {
                  name: 'Mazatech V-414',
                  value: 89,
                  code: 'Mazak,Milling,Mazatech,Mazatech V-414',
                  absolute_sum: 89,
                  relative_sum: 31.560283687943265
                },
                {
                  name: 'Mazatech V-655/60 Ii',
                  value: 92,
                  code: 'Mazak,Milling,Mazatech,Mazatech V-655/60 Ii',
                  absolute_sum: 92,
                  relative_sum: 32.62411347517731
                },
                {
                  name: 'Mazatech H-630',
                  value: 60,
                  code: 'Mazak,Milling,Mazatech,Mazatech H-630',
                  absolute_sum: 60,
                  relative_sum: 21.27659574468085
                },
                {
                  name: 'Mazatech H-630N',
                  value: 33,
                  code: 'Mazak,Milling,Mazatech,Mazatech H-630N',
                  absolute_sum: 33,
                  relative_sum: 11.702127659574469
                }
              ],
              code: 'Mazak,Milling,Mazatech',
              absolute_sum: 282,
              relative_sum: 1.9365471775854965
            },
            {
              name: 'Fh',
              children: [
                {
                  name: 'Mazatech Fh-12800',
                  value: 8,
                  code: 'Mazak,Milling,Fh,Mazatech Fh-12800',
                  absolute_sum: 8,
                  relative_sum: 1.4084507042253522
                },
                {
                  name: 'Fh4000',
                  value: 31,
                  code: 'Mazak,Milling,Fh,Fh4000',
                  absolute_sum: 31,
                  relative_sum: 5.457746478873239
                },
                {
                  name: 'Fh-480',
                  value: 73,
                  code: 'Mazak,Milling,Fh,Fh-480',
                  absolute_sum: 73,
                  relative_sum: 12.852112676056338
                },
                {
                  name: 'Fh-4800',
                  value: 105,
                  code: 'Mazak,Milling,Fh,Fh-4800',
                  absolute_sum: 105,
                  relative_sum: 18.485915492957748
                },
                {
                  name: 'Pfh 4800',
                  value: 73,
                  code: 'Mazak,Milling,Fh,Pfh 4800',
                  absolute_sum: 73,
                  relative_sum: 12.852112676056338
                },
                {
                  name: 'Fh5800',
                  value: 99,
                  code: 'Mazak,Milling,Fh,Fh5800',
                  absolute_sum: 99,
                  relative_sum: 17.429577464788732
                },
                {
                  name: 'Fh8800-6 Apc',
                  value: 15,
                  code: 'Mazak,Milling,Fh,Fh8800-6 Apc',
                  absolute_sum: 15,
                  relative_sum: 2.640845070422535
                },
                {
                  name: 'Fh680',
                  value: 21,
                  code: 'Mazak,Milling,Fh,Fh680',
                  absolute_sum: 21,
                  relative_sum: 3.697183098591549
                },
                {
                  name: 'Fh-680X',
                  value: 41,
                  code: 'Mazak,Milling,Fh,Fh-680X',
                  absolute_sum: 41,
                  relative_sum: 7.21830985915493
                },
                {
                  name: 'Fh 6800',
                  value: 61,
                  code: 'Mazak,Milling,Fh,Fh 6800',
                  absolute_sum: 61,
                  relative_sum: 10.73943661971831
                },
                {
                  name: 'Fh 8800',
                  value: 41,
                  code: 'Mazak,Milling,Fh,Fh 8800',
                  absolute_sum: 41,
                  relative_sum: 7.21830985915493
                }
              ],
              code: 'Mazak,Milling,Fh',
              absolute_sum: 568,
              relative_sum: 3.900563109462986
            },
            {
              name: 'Vtc',
              children: [
                {
                  name: 'Vtc-16B',
                  value: 194,
                  code: 'Mazak,Milling,Vtc,Vtc-16B',
                  absolute_sum: 194,
                  relative_sum: 11.213872832369942
                },
                {
                  name: 'Vtc 800/20Sr',
                  value: 9,
                  code: 'Mazak,Milling,Vtc,Vtc 800/20Sr',
                  absolute_sum: 9,
                  relative_sum: 0.5202312138728323
                },
                {
                  name: 'Vtc-820/20',
                  value: 5,
                  code: 'Mazak,Milling,Vtc,Vtc-820/20',
                  absolute_sum: 5,
                  relative_sum: 0.2890173410404624
                },
                {
                  name: 'Vtc-20B',
                  value: 257,
                  code: 'Mazak,Milling,Vtc,Vtc-20B',
                  absolute_sum: 257,
                  relative_sum: 14.85549132947977
                },
                {
                  name: 'Vtc 20C',
                  value: 43,
                  code: 'Mazak,Milling,Vtc,Vtc 20C',
                  absolute_sum: 43,
                  relative_sum: 2.485549132947977
                },
                {
                  name: 'Vtc 20',
                  value: 157,
                  code: 'Mazak,Milling,Vtc,Vtc 20',
                  absolute_sum: 157,
                  relative_sum: 9.07514450867052
                },
                {
                  name: 'Vtc 200B',
                  value: 172,
                  code: 'Mazak,Milling,Vtc,Vtc 200B',
                  absolute_sum: 172,
                  relative_sum: 9.942196531791907
                },
                {
                  name: 'Vtc 200C',
                  value: 154,
                  code: 'Mazak,Milling,Vtc,Vtc 200C',
                  absolute_sum: 154,
                  relative_sum: 8.901734104046243
                },
                {
                  name: 'Vtc 200',
                  value: 14,
                  code: 'Mazak,Milling,Vtc,Vtc 200',
                  absolute_sum: 14,
                  relative_sum: 0.8092485549132947
                },
                {
                  name: 'Vtc-250D/50',
                  value: 147,
                  code: 'Mazak,Milling,Vtc,Vtc-250D/50',
                  absolute_sum: 147,
                  relative_sum: 8.497109826589595
                },
                {
                  name: 'Vtc-800G-30S',
                  value: 20,
                  code: 'Mazak,Milling,Vtc,Vtc-800G-30S',
                  absolute_sum: 20,
                  relative_sum: 1.1560693641618496
                },
                {
                  name: 'Vtc 800/30Sr',
                  value: 36,
                  code: 'Mazak,Milling,Vtc,Vtc 800/30Sr',
                  absolute_sum: 36,
                  relative_sum: 2.0809248554913293
                },
                {
                  name: 'Vtc 820/30',
                  value: 30,
                  code: 'Mazak,Milling,Vtc,Vtc 820/30',
                  absolute_sum: 30,
                  relative_sum: 1.7341040462427744
                },
                {
                  name: 'Vtc 30C',
                  value: 26,
                  code: 'Mazak,Milling,Vtc,Vtc 30C',
                  absolute_sum: 26,
                  relative_sum: 1.5028901734104045
                },
                {
                  name: 'Vtc 300C Ii',
                  value: 67,
                  code: 'Mazak,Milling,Vtc,Vtc 300C Ii',
                  absolute_sum: 67,
                  relative_sum: 3.8728323699421967
                },
                {
                  name: 'Vtc 300C',
                  value: 147,
                  code: 'Mazak,Milling,Vtc,Vtc 300C',
                  absolute_sum: 147,
                  relative_sum: 8.497109826589595
                },
                {
                  name: 'Vtc 300',
                  value: 37,
                  code: 'Mazak,Milling,Vtc,Vtc 300',
                  absolute_sum: 37,
                  relative_sum: 2.138728323699422
                },
                {
                  name: 'Vtc-41L',
                  value: 55,
                  code: 'Mazak,Milling,Vtc,Vtc-41L',
                  absolute_sum: 55,
                  relative_sum: 3.1791907514450863
                },
                {
                  name: 'Vtc 530C',
                  value: 64,
                  code: 'Mazak,Milling,Vtc,Vtc 530C',
                  absolute_sum: 64,
                  relative_sum: 3.6994219653179194
                },
                {
                  name: 'Vtc-800 Series',
                  value: 17,
                  code: 'Mazak,Milling,Vtc,Vtc-800 Series',
                  absolute_sum: 17,
                  relative_sum: 0.9826589595375723
                },
                {
                  name: 'Vtc 800',
                  value: 25,
                  code: 'Mazak,Milling,Vtc,Vtc 800',
                  absolute_sum: 25,
                  relative_sum: 1.4450867052023122
                },
                {
                  name: 'Vtc-805E',
                  value: 33,
                  code: 'Mazak,Milling,Vtc,Vtc-805E',
                  absolute_sum: 33,
                  relative_sum: 1.907514450867052
                },
                {
                  name: 'Vtc',
                  value: 21,
                  code: 'Mazak,Milling,Vtc,Vtc',
                  absolute_sum: 21,
                  relative_sum: 1.2138728323699421
                }
              ],
              code: 'Mazak,Milling,Vtc',
              absolute_sum: 1730,
              relative_sum: 11.880236231286911
            },
            {
              name: 'Ajv',
              children: [
                {
                  name: 'Ajv-60/160',
                  value: 128,
                  code: 'Mazak,Milling,Ajv,Ajv-60/160',
                  absolute_sum: 128,
                  relative_sum: 50.79365079365079
                },
                {
                  name: 'Ajv 25/404',
                  value: 38,
                  code: 'Mazak,Milling,Ajv,Ajv 25/404',
                  absolute_sum: 38,
                  relative_sum: 15.079365079365079
                },
                {
                  name: 'Ajv-25/405',
                  value: 75,
                  code: 'Mazak,Milling,Ajv,Ajv-25/405',
                  absolute_sum: 75,
                  relative_sum: 29.761904761904763
                },
                {
                  name: 'Ajv 50/80',
                  value: 11,
                  code: 'Mazak,Milling,Ajv,Ajv 50/80',
                  absolute_sum: 11,
                  relative_sum: 4.365079365079365
                }
              ],
              code: 'Mazak,Milling,Ajv',
              absolute_sum: 252,
              relative_sum: 1.73053152039555
            },
            {
              name: 'Universal',
              children: [
                {
                  name: 'Vertical Center Universal 500A/2Pc',
                  value: 17,
                  code: 'Mazak,Milling,Universal,Vertical Center Universal 500A/2Pc',
                  absolute_sum: 17,
                  relative_sum: 4.509283819628647
                },
                {
                  name: 'Vertical Center Universal 300A 5X',
                  value: 10,
                  code: 'Mazak,Milling,Universal,Vertical Center Universal 300A 5X',
                  absolute_sum: 10,
                  relative_sum: 2.6525198938992043
                },
                {
                  name: 'Vertical Center Universal 400B',
                  value: 20,
                  code: 'Mazak,Milling,Universal,Vertical Center Universal 400B',
                  absolute_sum: 20,
                  relative_sum: 5.305039787798409
                },
                {
                  name: 'Vertical Center Universal 400T',
                  value: 15,
                  code: 'Mazak,Milling,Universal,Vertical Center Universal 400T',
                  absolute_sum: 15,
                  relative_sum: 3.978779840848806
                },
                {
                  name: 'Horizontal Center Universal 4000',
                  value: 290,
                  code: 'Mazak,Milling,Universal,Horizontal Center Universal 4000',
                  absolute_sum: 290,
                  relative_sum: 76.92307692307693
                },
                {
                  name: 'Vertical Center Universal 500A 5X',
                  value: 25,
                  code: 'Mazak,Milling,Universal,Vertical Center Universal 500A 5X',
                  absolute_sum: 25,
                  relative_sum: 6.631299734748011
                }
              ],
              code: 'Mazak,Milling,Universal',
              absolute_sum: 377,
              relative_sum: 2.588930092020327
            },
            {
              name: 'Vertical',
              children: [
                {
                  name: 'Vertical Center Universal 500A/2Pc',
                  value: 17,
                  code: 'Mazak,Milling,Vertical,Vertical Center Universal 500A/2Pc',
                  absolute_sum: 17,
                  relative_sum: 1.693227091633466
                },
                {
                  name: 'Vertical Center Universal 300A 5X',
                  value: 10,
                  code: 'Mazak,Milling,Vertical,Vertical Center Universal 300A 5X',
                  absolute_sum: 10,
                  relative_sum: 0.9960159362549801
                },
                {
                  name: 'Vertical Center Nexus 700D/40-Ii Hs',
                  value: 4,
                  code: 'Mazak,Milling,Vertical,Vertical Center Nexus 700D/40-Ii Hs',
                  absolute_sum: 4,
                  relative_sum: 0.398406374501992
                },
                {
                  name: 'Vertical Center Universal 400B',
                  value: 20,
                  code: 'Mazak,Milling,Vertical,Vertical Center Universal 400B',
                  absolute_sum: 20,
                  relative_sum: 1.9920318725099602
                },
                {
                  name: 'Vertical Center Universal 400T',
                  value: 15,
                  code: 'Mazak,Milling,Vertical,Vertical Center Universal 400T',
                  absolute_sum: 15,
                  relative_sum: 1.4940239043824701
                },
                {
                  name: 'Vertical Center Nexus 410A-Ⅱ',
                  value: 21,
                  code: 'Mazak,Milling,Vertical,Vertical Center Nexus 410A-Ⅱ',
                  absolute_sum: 21,
                  relative_sum: 2.091633466135458
                },
                {
                  name: 'Vertical Center Nexus 410B-Ii',
                  value: 51,
                  code: 'Mazak,Milling,Vertical,Vertical Center Nexus 410B-Ii',
                  absolute_sum: 51,
                  relative_sum: 5.079681274900398
                },
                {
                  name: 'Vertical Center Smart 430A',
                  value: 33,
                  code: 'Mazak,Milling,Vertical,Vertical Center Smart 430A',
                  absolute_sum: 33,
                  relative_sum: 3.286852589641434
                },
                {
                  name: 'Vertical Center Universal 500A 5X',
                  value: 25,
                  code: 'Mazak,Milling,Vertical,Vertical Center Universal 500A 5X',
                  absolute_sum: 25,
                  relative_sum: 2.49003984063745
                },
                {
                  name: 'Vertical Center Nexus Compact 5X',
                  value: 19,
                  code: 'Mazak,Milling,Vertical,Vertical Center Nexus Compact 5X',
                  absolute_sum: 19,
                  relative_sum: 1.8924302788844622
                },
                {
                  name: 'V5 Vertical',
                  value: 367,
                  code: 'Mazak,Milling,Vertical,V5 Vertical',
                  absolute_sum: 367,
                  relative_sum: 36.55378486055777
                },
                {
                  name: 'Vertical Center Nexus 700D/50-Ii',
                  value: 46,
                  code: 'Mazak,Milling,Vertical,Vertical Center Nexus 700D/50-Ii',
                  absolute_sum: 46,
                  relative_sum: 4.581673306772909
                },
                {
                  name: 'Vertical Center Nexus 700E/50-Ii',
                  value: 1,
                  code: 'Mazak,Milling,Vertical,Vertical Center Nexus 700E/50-Ii',
                  absolute_sum: 1,
                  relative_sum: 0.099601593625498
                },
                {
                  name: 'Vertical Center Nexus 510C-Ii',
                  value: 160,
                  code: 'Mazak,Milling,Vertical,Vertical Center Nexus 510C-Ii',
                  absolute_sum: 160,
                  relative_sum: 15.936254980079681
                },
                {
                  name: 'Vertical Center Nexus 510C',
                  value: 144,
                  code: 'Mazak,Milling,Vertical,Vertical Center Nexus 510C',
                  absolute_sum: 144,
                  relative_sum: 14.342629482071715
                },
                {
                  name: 'Vertical Center Nexus 530C-Ii Hs',
                  value: 71,
                  code: 'Mazak,Milling,Vertical,Vertical Center Nexus 530C-Ii Hs',
                  absolute_sum: 71,
                  relative_sum: 7.071713147410359
                }
              ],
              code: 'Mazak,Milling,Vertical',
              absolute_sum: 1004,
              relative_sum: 6.894657327290207
            },
            {
              name: 'Vc',
              children: [
                {
                  name: 'Vc-500C/2Pc',
                  value: 58,
                  code: 'Mazak,Milling,Vc,Vc-500C/2Pc',
                  absolute_sum: 58,
                  relative_sum: 4.563335955940205
                },
                {
                  name: 'Vcc-5X 20K',
                  value: 19,
                  code: 'Mazak,Milling,Vc,Vcc-5X 20K',
                  absolute_sum: 19,
                  relative_sum: 1.4948859166011015
                },
                {
                  name: 'Svc-2000L/200',
                  value: 28,
                  code: 'Mazak,Milling,Vc,Svc-2000L/200',
                  absolute_sum: 28,
                  relative_sum: 2.2029897718332023
                },
                {
                  name: 'Svc-2000L',
                  value: 14,
                  code: 'Mazak,Milling,Vc,Svc-2000L',
                  absolute_sum: 14,
                  relative_sum: 1.1014948859166012
                },
                {
                  name: 'Vc-300A/5X',
                  value: 24,
                  code: 'Mazak,Milling,Vc,Vc-300A/5X',
                  absolute_sum: 24,
                  relative_sum: 1.8882769472856018
                },
                {
                  name: 'Vcu-400A 5X',
                  value: 23,
                  code: 'Mazak,Milling,Vc,Vcu-400A 5X',
                  absolute_sum: 23,
                  relative_sum: 1.8095987411487018
                },
                {
                  name: 'Vcu-400 5X',
                  value: 32,
                  code: 'Mazak,Milling,Vc,Vcu-400 5X',
                  absolute_sum: 32,
                  relative_sum: 2.5177025963808024
                },
                {
                  name: 'Vcs 430A',
                  value: 225,
                  code: 'Mazak,Milling,Vc,Vcs 430A',
                  absolute_sum: 225,
                  relative_sum: 17.702596380802518
                },
                {
                  name: 'Vc Smart 430A',
                  value: 7,
                  code: 'Mazak,Milling,Vc,Vc Smart 430A',
                  absolute_sum: 7,
                  relative_sum: 0.5507474429583006
                },
                {
                  name: 'Vc-500A/5X',
                  value: 114,
                  code: 'Mazak,Milling,Vc,Vc-500A/5X',
                  absolute_sum: 114,
                  relative_sum: 8.969315499606608
                },
                {
                  name: 'Vcn510C-Ii 5A',
                  value: 49,
                  code: 'Mazak,Milling,Vc,Vcn510C-Ii 5A',
                  absolute_sum: 49,
                  relative_sum: 3.8552321007081036
                },
                {
                  name: 'Vcc 5 Axis High Speed Vmc',
                  value: 93,
                  code: 'Mazak,Milling,Vc,Vcc 5 Axis High Speed Vmc',
                  absolute_sum: 93,
                  relative_sum: 7.317073170731707
                },
                {
                  name: 'Vcn 510C-Ii',
                  value: 83,
                  code: 'Mazak,Milling,Vc,Vcn 510C-Ii',
                  absolute_sum: 83,
                  relative_sum: 6.530291109362707
                },
                {
                  name: 'Vce-500',
                  value: 27,
                  code: 'Mazak,Milling,Vc,Vce-500',
                  absolute_sum: 27,
                  relative_sum: 2.1243115656963023
                },
                {
                  name: 'Vcn 510C',
                  value: 147,
                  code: 'Mazak,Milling,Vc,Vcn 510C',
                  absolute_sum: 147,
                  relative_sum: 11.565696302124312
                },
                {
                  name: 'Vcn 530C',
                  value: 121,
                  code: 'Mazak,Milling,Vc,Vcn 530C',
                  absolute_sum: 121,
                  relative_sum: 9.520062942564909
                },
                {
                  name: 'Vcs 530C',
                  value: 17,
                  code: 'Mazak,Milling,Vc,Vcs 530C',
                  absolute_sum: 17,
                  relative_sum: 1.3375295043273014
                },
                {
                  name: 'Nexus Vcn 530Cii Hs',
                  value: 15,
                  code: 'Mazak,Milling,Vc,Nexus Vcn 530Cii Hs',
                  absolute_sum: 15,
                  relative_sum: 1.1801730920535012
                },
                {
                  name: 'Vcn-530Cii Hs',
                  value: 11,
                  code: 'Mazak,Milling,Vc,Vcn-530Cii Hs',
                  absolute_sum: 11,
                  relative_sum: 0.865460267505901
                },
                {
                  name: 'Vcn-570C',
                  value: 44,
                  code: 'Mazak,Milling,Vc,Vcn-570C',
                  absolute_sum: 44,
                  relative_sum: 3.461841070023604
                },
                {
                  name: 'Vcn-600',
                  value: 12,
                  code: 'Mazak,Milling,Vc,Vcn-600',
                  absolute_sum: 12,
                  relative_sum: 0.9441384736428009
                },
                {
                  name: 'Vcn-700D',
                  value: 42,
                  code: 'Mazak,Milling,Vc,Vcn-700D',
                  absolute_sum: 42,
                  relative_sum: 3.304484657749803
                },
                {
                  name: 'Vcn-700E',
                  value: 47,
                  code: 'Mazak,Milling,Vc,Vcn-700E',
                  absolute_sum: 47,
                  relative_sum: 3.6978756884343036
                },
                {
                  name: 'Vcn-700',
                  value: 8,
                  code: 'Mazak,Milling,Vc,Vcn-700',
                  absolute_sum: 8,
                  relative_sum: 0.6294256490952006
                },
                {
                  name: 'Vcn-705D',
                  value: 5,
                  code: 'Mazak,Milling,Vc,Vcn-705D',
                  absolute_sum: 5,
                  relative_sum: 0.3933910306845004
                },
                {
                  name: 'Vcn',
                  value: 6,
                  code: 'Mazak,Milling,Vc,Vcn',
                  absolute_sum: 6,
                  relative_sum: 0.47206923682140045
                }
              ],
              code: 'Mazak,Milling,Vc',
              absolute_sum: 1271,
              relative_sum: 8.728196676280731
            },
            {
              name: 'Super',
              children: [
                {
                  name: 'Super Velocity Center 2000L/200-Ii',
                  value: 15,
                  code: 'Mazak,Milling,Super,Super Velocity Center 2000L/200-Ii',
                  absolute_sum: 15,
                  relative_sum: 100
                }
              ],
              code: 'Mazak,Milling,Super',
              absolute_sum: 15,
              relative_sum: 0.10300782859497322
            },
            {
              name: 'Hs',
              children: [
                {
                  name: 'Integrex I-250Hs',
                  value: 14,
                  code: 'Mazak,Milling,Hs,Integrex I-250Hs',
                  absolute_sum: 14,
                  relative_sum: 12.173913043478262
                },
                {
                  name: 'Vertical Center Nexus 700D/40-Ii Hs',
                  value: 4,
                  code: 'Mazak,Milling,Hs,Vertical Center Nexus 700D/40-Ii Hs',
                  absolute_sum: 4,
                  relative_sum: 3.4782608695652173
                },
                {
                  name: 'Vertical Center Nexus 530C-Ii Hs',
                  value: 71,
                  code: 'Mazak,Milling,Hs,Vertical Center Nexus 530C-Ii Hs',
                  absolute_sum: 71,
                  relative_sum: 61.73913043478261
                },
                {
                  name: 'Nexus Vcn 530Cii Hs',
                  value: 15,
                  code: 'Mazak,Milling,Hs,Nexus Vcn 530Cii Hs',
                  absolute_sum: 15,
                  relative_sum: 13.043478260869565
                },
                {
                  name: 'Vcn-530Cii Hs',
                  value: 11,
                  code: 'Mazak,Milling,Hs,Vcn-530Cii Hs',
                  absolute_sum: 11,
                  relative_sum: 9.565217391304348
                }
              ],
              code: 'Mazak,Milling,Hs',
              absolute_sum: 115,
              relative_sum: 0.7897266858947948
            },
            {
              name: 'Hcn',
              children: [
                {
                  name: 'Hcn-4000',
                  value: 49,
                  code: 'Mazak,Milling,Hcn,Hcn-4000',
                  absolute_sum: 49,
                  relative_sum: 5.426356589147287
                },
                {
                  name: 'Hcn 4000-Ii',
                  value: 32,
                  code: 'Mazak,Milling,Hcn,Hcn 4000-Ii',
                  absolute_sum: 32,
                  relative_sum: 3.5437430786267994
                },
                {
                  name: 'Hcn-4000Iii',
                  value: 25,
                  code: 'Mazak,Milling,Hcn,Hcn-4000Iii',
                  absolute_sum: 25,
                  relative_sum: 2.768549280177187
                },
                {
                  name: 'Hcn-4000 Neo',
                  value: 29,
                  code: 'Mazak,Milling,Hcn,Hcn-4000 Neo',
                  absolute_sum: 29,
                  relative_sum: 3.211517165005537
                },
                {
                  name: 'Hcn-5000',
                  value: 124,
                  code: 'Mazak,Milling,Hcn,Hcn-5000',
                  absolute_sum: 124,
                  relative_sum: 13.732004429678849
                },
                {
                  name: 'Hcn 5000 Ii',
                  value: 89,
                  code: 'Mazak,Milling,Hcn,Hcn 5000 Ii',
                  absolute_sum: 89,
                  relative_sum: 9.856035437430787
                },
                {
                  name: 'Hcn-5000S',
                  value: 224,
                  code: 'Mazak,Milling,Hcn,Hcn-5000S',
                  absolute_sum: 224,
                  relative_sum: 24.8062015503876
                },
                {
                  name: 'Hcn 5000 Iii',
                  value: 38,
                  code: 'Mazak,Milling,Hcn,Hcn 5000 Iii',
                  absolute_sum: 38,
                  relative_sum: 4.208194905869324
                },
                {
                  name: 'Hcn-6000',
                  value: 57,
                  code: 'Mazak,Milling,Hcn,Hcn-6000',
                  absolute_sum: 57,
                  relative_sum: 6.312292358803987
                },
                {
                  name: 'Hcn 6800',
                  value: 80,
                  code: 'Mazak,Milling,Hcn,Hcn 6800',
                  absolute_sum: 80,
                  relative_sum: 8.859357696566999
                },
                {
                  name: 'Hcn-6800 Neo',
                  value: 76,
                  code: 'Mazak,Milling,Hcn,Hcn-6800 Neo',
                  absolute_sum: 76,
                  relative_sum: 8.416389811738648
                },
                {
                  name: 'Hcn-8800',
                  value: 38,
                  code: 'Mazak,Milling,Hcn,Hcn-8800',
                  absolute_sum: 38,
                  relative_sum: 4.208194905869324
                },
                {
                  name: 'Hcn 8800 Ii',
                  value: 42,
                  code: 'Mazak,Milling,Hcn,Hcn 8800 Ii',
                  absolute_sum: 42,
                  relative_sum: 4.651162790697675
                }
              ],
              code: 'Mazak,Milling,Hcn',
              absolute_sum: 903,
              relative_sum: 6.201071281417388
            },
            {
              name: 'Neo',
              children: [
                {
                  name: 'Hcn-4000 Neo',
                  value: 29,
                  code: 'Mazak,Milling,Neo,Hcn-4000 Neo',
                  absolute_sum: 29,
                  relative_sum: 26.851851851851855
                },
                {
                  name: 'Hcn-6800 Neo',
                  value: 76,
                  code: 'Mazak,Milling,Neo,Hcn-6800 Neo',
                  absolute_sum: 76,
                  relative_sum: 70.37037037037037
                },
                {
                  name: 'Variaxis I-800 Neo',
                  value: 3,
                  code: 'Mazak,Milling,Neo,Variaxis I-800 Neo',
                  absolute_sum: 3,
                  relative_sum: 2.7777777777777777
                }
              ],
              code: 'Mazak,Milling,Neo',
              absolute_sum: 108,
              relative_sum: 0.7416563658838072
            },
            {
              name: 'Smart',
              children: [
                {
                  name: 'Vertical Center Smart 430A',
                  value: 33,
                  code: 'Mazak,Milling,Smart,Vertical Center Smart 430A',
                  absolute_sum: 33,
                  relative_sum: 39.75903614457831
                },
                {
                  name: 'Vc Smart 430A',
                  value: 7,
                  code: 'Mazak,Milling,Smart,Vc Smart 430A',
                  absolute_sum: 7,
                  relative_sum: 8.433734939759036
                },
                {
                  name: 'Smart 530C',
                  value: 43,
                  code: 'Mazak,Milling,Smart,Smart 530C',
                  absolute_sum: 43,
                  relative_sum: 51.80722891566265
                }
              ],
              code: 'Mazak,Milling,Smart',
              absolute_sum: 83,
              relative_sum: 0.5699766515588518
            },
            {
              name: 'Vcn',
              children: [
                {
                  name: 'Vcn510C-Ii 5A',
                  value: 49,
                  code: 'Mazak,Milling,Vcn,Vcn510C-Ii 5A',
                  absolute_sum: 49,
                  relative_sum: 8.305084745762711
                },
                {
                  name: 'Vcn 510C-Ii',
                  value: 83,
                  code: 'Mazak,Milling,Vcn,Vcn 510C-Ii',
                  absolute_sum: 83,
                  relative_sum: 14.067796610169491
                },
                {
                  name: 'Vcn 510C',
                  value: 147,
                  code: 'Mazak,Milling,Vcn,Vcn 510C',
                  absolute_sum: 147,
                  relative_sum: 24.91525423728814
                },
                {
                  name: 'Vcn 530C',
                  value: 121,
                  code: 'Mazak,Milling,Vcn,Vcn 530C',
                  absolute_sum: 121,
                  relative_sum: 20.508474576271183
                },
                {
                  name: 'Nexus Vcn 530Cii Hs',
                  value: 15,
                  code: 'Mazak,Milling,Vcn,Nexus Vcn 530Cii Hs',
                  absolute_sum: 15,
                  relative_sum: 2.5423728813559325
                },
                {
                  name: 'Vcn-530Cii Hs',
                  value: 11,
                  code: 'Mazak,Milling,Vcn,Vcn-530Cii Hs',
                  absolute_sum: 11,
                  relative_sum: 1.864406779661017
                },
                {
                  name: 'Vcn-570C',
                  value: 44,
                  code: 'Mazak,Milling,Vcn,Vcn-570C',
                  absolute_sum: 44,
                  relative_sum: 7.457627118644068
                },
                {
                  name: 'Vcn-600',
                  value: 12,
                  code: 'Mazak,Milling,Vcn,Vcn-600',
                  absolute_sum: 12,
                  relative_sum: 2.0338983050847457
                },
                {
                  name: 'Vcn-700D',
                  value: 42,
                  code: 'Mazak,Milling,Vcn,Vcn-700D',
                  absolute_sum: 42,
                  relative_sum: 7.118644067796611
                },
                {
                  name: 'Vcn-700E',
                  value: 47,
                  code: 'Mazak,Milling,Vcn,Vcn-700E',
                  absolute_sum: 47,
                  relative_sum: 7.966101694915253
                },
                {
                  name: 'Vcn-700',
                  value: 8,
                  code: 'Mazak,Milling,Vcn,Vcn-700',
                  absolute_sum: 8,
                  relative_sum: 1.3559322033898304
                },
                {
                  name: 'Vcn-705D',
                  value: 5,
                  code: 'Mazak,Milling,Vcn,Vcn-705D',
                  absolute_sum: 5,
                  relative_sum: 0.847457627118644
                },
                {
                  name: 'Vcn',
                  value: 6,
                  code: 'Mazak,Milling,Vcn,Vcn',
                  absolute_sum: 6,
                  relative_sum: 1.0169491525423728
                }
              ],
              code: 'Mazak,Milling,Vcn',
              absolute_sum: 590,
              relative_sum: 4.051641258068947
            },
            {
              name: 'Compact',
              children: [
                {
                  name: 'Vertical Center Nexus Compact 5X',
                  value: 19,
                  code: 'Mazak,Milling,Compact,Vertical Center Nexus Compact 5X',
                  absolute_sum: 19,
                  relative_sum: 100
                }
              ],
              code: 'Mazak,Milling,Compact',
              absolute_sum: 19,
              relative_sum: 0.13047658288696606
            },
            {
              name: 'Series',
              children: [
                {
                  name: 'Vtc-800 Series',
                  value: 17,
                  code: 'Mazak,Milling,Series,Vtc-800 Series',
                  absolute_sum: 17,
                  relative_sum: 42.5
                },
                {
                  name: 'Integrex Ag Series',
                  value: 14,
                  code: 'Mazak,Milling,Series,Integrex Ag Series',
                  absolute_sum: 14,
                  relative_sum: 35
                },
                {
                  name: 'Integrex I-V Series',
                  value: 9,
                  code: 'Mazak,Milling,Series,Integrex I-V Series',
                  absolute_sum: 9,
                  relative_sum: 22.5
                }
              ],
              code: 'Mazak,Milling,Series',
              absolute_sum: 40,
              relative_sum: 0.2746875429199286
            },
            {
              name: 'Gear',
              children: [
                {
                  name: 'Tube Gear',
                  value: 20,
                  code: 'Mazak,Milling,Gear,Tube Gear',
                  absolute_sum: 20,
                  relative_sum: 100
                }
              ],
              code: 'Mazak,Milling,Gear',
              absolute_sum: 20,
              relative_sum: 0.1373437714599643
            }
          ],
          code: 'Mazak,Milling',
          absolute_sum: 14562,
          relative_sum: 33.739573679332715
        },
        {
          name: 'Turning',
          children: [
            {
              name: 'Ag',
              children: [
                {
                  name: 'E-1600V/10 Ag',
                  value: 1,
                  code: 'Mazak,Turning,Ag,E-1600V/10 Ag',
                  absolute_sum: 1,
                  relative_sum: 33.33333333333333
                },
                {
                  name: 'Integrex I-250H S Ag',
                  value: 2,
                  code: 'Mazak,Turning,Ag,Integrex I-250H S Ag',
                  absolute_sum: 2,
                  relative_sum: 66.66666666666666
                }
              ],
              code: 'Mazak,Turning,Ag',
              absolute_sum: 3,
              relative_sum: 0.011902400317397342
            },
            {
              name: 'Quick',
              children: [
                {
                  name: 'Quick Turn 10',
                  value: 82,
                  code: 'Mazak,Turning,Quick,Quick Turn 10',
                  absolute_sum: 82,
                  relative_sum: 1.8627896410722398
                },
                {
                  name: 'Quick Turn Nexus 100-Ii Ms',
                  value: 3,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 100-Ii Ms',
                  absolute_sum: 3,
                  relative_sum: 0.06815084052703317
                },
                {
                  name: 'Quick Turn Nexus 100-Ii',
                  value: 51,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 100-Ii',
                  absolute_sum: 51,
                  relative_sum: 1.1585642889595638
                },
                {
                  name: 'Quick Turn Nexus 100 Ii Msy',
                  value: 38,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 100 Ii Msy',
                  absolute_sum: 38,
                  relative_sum: 0.8632439800090868
                },
                {
                  name: 'Quick Turn Nexus 100',
                  value: 55,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 100',
                  absolute_sum: 55,
                  relative_sum: 1.2494320763289415
                },
                {
                  name: 'Quick Turn Smart 100M S',
                  value: 1,
                  code: 'Mazak,Turning,Quick,Quick Turn Smart 100M S',
                  absolute_sum: 1,
                  relative_sum: 0.022716946842344387
                },
                {
                  name: 'Quick Turn 100My',
                  value: 6,
                  code: 'Mazak,Turning,Quick,Quick Turn 100My',
                  absolute_sum: 6,
                  relative_sum: 0.13630168105406634
                },
                {
                  name: 'Quick Turn Primos 100',
                  value: 8,
                  code: 'Mazak,Turning,Quick,Quick Turn Primos 100',
                  absolute_sum: 8,
                  relative_sum: 0.1817355747387551
                },
                {
                  name: 'Quick Turn Smart 100S',
                  value: 22,
                  code: 'Mazak,Turning,Quick,Quick Turn Smart 100S',
                  absolute_sum: 22,
                  relative_sum: 0.49977283053157656
                },
                {
                  name: 'Quick Turn Smart 100',
                  value: 13,
                  code: 'Mazak,Turning,Quick,Quick Turn Smart 100',
                  absolute_sum: 13,
                  relative_sum: 0.29532030895047706
                },
                {
                  name: 'Quick Turn Nexus 200 M',
                  value: 22,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 200 M',
                  absolute_sum: 22,
                  relative_sum: 0.49977283053157656
                },
                {
                  name: 'Quick Turn Smart 200M-1000U',
                  value: 4,
                  code: 'Mazak,Turning,Quick,Quick Turn Smart 200M-1000U',
                  absolute_sum: 4,
                  relative_sum: 0.09086778736937755
                },
                {
                  name: 'Quick Turn Nexus 250-Ii M-1000U',
                  value: 9,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 250-Ii M-1000U',
                  absolute_sum: 9,
                  relative_sum: 0.2044525215810995
                },
                {
                  name: 'Quick Turn Smart 250-1000U',
                  value: 8,
                  code: 'Mazak,Turning,Quick,Quick Turn Smart 250-1000U',
                  absolute_sum: 8,
                  relative_sum: 0.1817355747387551
                },
                {
                  name: 'Quick Turn Nexus 400-Ii-1000U',
                  value: 7,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 400-Ii-1000U',
                  absolute_sum: 7,
                  relative_sum: 0.15901862789641072
                },
                {
                  name: 'Quick Turn Nexus 400M-1000U',
                  value: 26,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 400M-1000U',
                  absolute_sum: 26,
                  relative_sum: 0.5906406179009541
                },
                {
                  name: 'Quick Turn Universal 200My-12',
                  value: 4,
                  code: 'Mazak,Turning,Quick,Quick Turn Universal 200My-12',
                  absolute_sum: 4,
                  relative_sum: 0.09086778736937755
                },
                {
                  name: 'Quick Turn Nexus 300-Ii M-1200U',
                  value: 11,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 300-Ii M-1200U',
                  absolute_sum: 11,
                  relative_sum: 0.24988641526578828
                },
                {
                  name: 'Quick Turn Nexus 300-Ii-1200U',
                  value: 7,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 300-Ii-1200U',
                  absolute_sum: 7,
                  relative_sum: 0.15901862789641072
                },
                {
                  name: 'Quick Turn 15N',
                  value: 106,
                  code: 'Mazak,Turning,Quick,Quick Turn 15N',
                  absolute_sum: 106,
                  relative_sum: 2.407996365288505
                },
                {
                  name: 'Quick Turn 15',
                  value: 43,
                  code: 'Mazak,Turning,Quick,Quick Turn 15',
                  absolute_sum: 43,
                  relative_sum: 0.9768287142208087
                },
                {
                  name: 'Quick Turn 15 Nu',
                  value: 43,
                  code: 'Mazak,Turning,Quick,Quick Turn 15 Nu',
                  absolute_sum: 43,
                  relative_sum: 0.9768287142208087
                },
                {
                  name: 'Quick Turn Smart 150 S',
                  value: 4,
                  code: 'Mazak,Turning,Quick,Quick Turn Smart 150 S',
                  absolute_sum: 4,
                  relative_sum: 0.09086778736937755
                },
                {
                  name: 'Quick Turn Nexus 350-Ii My-1500U',
                  value: 9,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 350-Ii My-1500U',
                  absolute_sum: 9,
                  relative_sum: 0.2044525215810995
                },
                {
                  name: 'Super Quick Turn 15M Mark Ii',
                  value: 45,
                  code: 'Mazak,Turning,Quick,Super Quick Turn 15M Mark Ii',
                  absolute_sum: 45,
                  relative_sum: 1.0222626079054975
                },
                {
                  name: 'Quick Turn Universal 200My-20',
                  value: 33,
                  code: 'Mazak,Turning,Quick,Quick Turn Universal 200My-20',
                  absolute_sum: 33,
                  relative_sum: 0.7496592457973649
                },
                {
                  name: 'Quick Turn Universal 200-20',
                  value: 4,
                  code: 'Mazak,Turning,Quick,Quick Turn Universal 200-20',
                  absolute_sum: 4,
                  relative_sum: 0.09086778736937755
                },
                {
                  name: 'Quick Turn Nexus 250My',
                  value: 90,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 250My',
                  absolute_sum: 90,
                  relative_sum: 2.044525215810995
                },
                {
                  name: 'Quick Turn Universal 250M-20',
                  value: 8,
                  code: 'Mazak,Turning,Quick,Quick Turn Universal 250M-20',
                  absolute_sum: 8,
                  relative_sum: 0.1817355747387551
                },
                {
                  name: 'Quick Turn Universal 250-20',
                  value: 47,
                  code: 'Mazak,Turning,Quick,Quick Turn Universal 250-20',
                  absolute_sum: 47,
                  relative_sum: 1.0676965015901863
                },
                {
                  name: 'Quick Turn 20',
                  value: 220,
                  code: 'Mazak,Turning,Quick,Quick Turn 20',
                  absolute_sum: 220,
                  relative_sum: 4.997728305315766
                },
                {
                  name: 'Quick Turn Nexus 200-Ii My',
                  value: 20,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 200-Ii My',
                  absolute_sum: 20,
                  relative_sum: 0.4543389368468878
                },
                {
                  name: 'Quick Turn Nexus 200-Ii',
                  value: 32,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 200-Ii',
                  absolute_sum: 32,
                  relative_sum: 0.7269422989550204
                },
                {
                  name: 'Quick Turn Smart 200-500U',
                  value: 4,
                  code: 'Mazak,Turning,Quick,Quick Turn Smart 200-500U',
                  absolute_sum: 4,
                  relative_sum: 0.09086778736937755
                },
                {
                  name: 'Quick Turn Nexus 200-Ii My-60',
                  value: 8,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 200-Ii My-60',
                  absolute_sum: 8,
                  relative_sum: 0.1817355747387551
                },
                {
                  name: 'Quick Turn Nexus 200Ms',
                  value: 57,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 200Ms',
                  absolute_sum: 57,
                  relative_sum: 1.29486597001363
                },
                {
                  name: 'Quick Turn Nexus 200 Ii Msy',
                  value: 55,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 200 Ii Msy',
                  absolute_sum: 55,
                  relative_sum: 1.2494320763289415
                },
                {
                  name: 'Quick Turn 200',
                  value: 43,
                  code: 'Mazak,Turning,Quick,Quick Turn 200',
                  absolute_sum: 43,
                  relative_sum: 0.9768287142208087
                },
                {
                  name: 'Quick Turn 200 Ms',
                  value: 20,
                  code: 'Mazak,Turning,Quick,Quick Turn 200 Ms',
                  absolute_sum: 20,
                  relative_sum: 0.4543389368468878
                },
                {
                  name: 'Super Quick Turn 200',
                  value: 27,
                  code: 'Mazak,Turning,Quick,Super Quick Turn 200',
                  absolute_sum: 27,
                  relative_sum: 0.6133575647432985
                },
                {
                  name: 'Quick Turn 200Msy',
                  value: 21,
                  code: 'Mazak,Turning,Quick,Quick Turn 200Msy',
                  absolute_sum: 21,
                  relative_sum: 0.47705588368923213
                },
                {
                  name: 'Quick Turn 200My',
                  value: 12,
                  code: 'Mazak,Turning,Quick,Quick Turn 200My',
                  absolute_sum: 12,
                  relative_sum: 0.2726033621081327
                },
                {
                  name: 'Quick Turn Smart 200',
                  value: 61,
                  code: 'Mazak,Turning,Quick,Quick Turn Smart 200',
                  absolute_sum: 61,
                  relative_sum: 1.3857337573830077
                },
                {
                  name: 'Quick Turn 250 Hp',
                  value: 84,
                  code: 'Mazak,Turning,Quick,Quick Turn 250 Hp',
                  absolute_sum: 84,
                  relative_sum: 1.9082235347569285
                },
                {
                  name: 'Quick Turn 250 Hp-Univ',
                  value: 27,
                  code: 'Mazak,Turning,Quick,Quick Turn 250 Hp-Univ',
                  absolute_sum: 27,
                  relative_sum: 0.6133575647432985
                },
                {
                  name: 'Quick Turn Nexus 250-Ii Msy',
                  value: 177,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 250-Ii Msy',
                  absolute_sum: 177,
                  relative_sum: 4.020899591094957
                },
                {
                  name: 'Quick Turn Nexus 250-Ii',
                  value: 108,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 250-Ii',
                  absolute_sum: 108,
                  relative_sum: 2.453430258973194
                },
                {
                  name: 'Super Quick Turn 250',
                  value: 71,
                  code: 'Mazak,Turning,Quick,Super Quick Turn 250',
                  absolute_sum: 71,
                  relative_sum: 1.6129032258064515
                },
                {
                  name: 'Quick Turn 250Mal',
                  value: 8,
                  code: 'Mazak,Turning,Quick,Quick Turn 250Mal',
                  absolute_sum: 8,
                  relative_sum: 0.1817355747387551
                },
                {
                  name: 'Quick Turn 250Msy',
                  value: 78,
                  code: 'Mazak,Turning,Quick,Quick Turn 250Msy',
                  absolute_sum: 78,
                  relative_sum: 1.7719218537028625
                },
                {
                  name: 'Quick Turn Universal 250Msy',
                  value: 25,
                  code: 'Mazak,Turning,Quick,Quick Turn Universal 250Msy',
                  absolute_sum: 25,
                  relative_sum: 0.5679236710586097
                },
                {
                  name: 'Quick Turn 250My',
                  value: 181,
                  code: 'Mazak,Turning,Quick,Quick Turn 250My',
                  absolute_sum: 181,
                  relative_sum: 4.111767378464334
                },
                {
                  name: 'Super Quick Turn 10M',
                  value: 105,
                  code: 'Mazak,Turning,Quick,Super Quick Turn 10M',
                  absolute_sum: 105,
                  relative_sum: 2.385279418446161
                },
                {
                  name: 'Quick Turn Nexus 250',
                  value: 33,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 250',
                  absolute_sum: 33,
                  relative_sum: 0.7496592457973649
                },
                {
                  name: 'Quick Turn Smart 250M',
                  value: 153,
                  code: 'Mazak,Turning,Quick,Quick Turn Smart 250M',
                  absolute_sum: 153,
                  relative_sum: 3.4756928668786915
                },
                {
                  name: 'Quick Turn 250',
                  value: 230,
                  code: 'Mazak,Turning,Quick,Quick Turn 250',
                  absolute_sum: 230,
                  relative_sum: 5.224897773739209
                },
                {
                  name: 'Quick Turn 8N',
                  value: 40,
                  code: 'Mazak,Turning,Quick,Quick Turn 8N',
                  absolute_sum: 40,
                  relative_sum: 0.9086778736937756
                },
                {
                  name: 'Quick Turn 18N',
                  value: 162,
                  code: 'Mazak,Turning,Quick,Quick Turn 18N',
                  absolute_sum: 162,
                  relative_sum: 3.6801453884597906
                },
                {
                  name: 'Quick Turn 30',
                  value: 79,
                  code: 'Mazak,Turning,Quick,Quick Turn 30',
                  absolute_sum: 79,
                  relative_sum: 1.7946388005452067
                },
                {
                  name: 'Quick Turn Smart 300M',
                  value: 7,
                  code: 'Mazak,Turning,Quick,Quick Turn Smart 300M',
                  absolute_sum: 7,
                  relative_sum: 0.15901862789641072
                },
                {
                  name: 'Quick Turn Nexus 200',
                  value: 68,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 200',
                  absolute_sum: 68,
                  relative_sum: 1.5447523852794183
                },
                {
                  name: 'Quick Turn 300My',
                  value: 13,
                  code: 'Mazak,Turning,Quick,Quick Turn 300My',
                  absolute_sum: 13,
                  relative_sum: 0.29532030895047706
                },
                {
                  name: 'Quick Turn Smart 300',
                  value: 27,
                  code: 'Mazak,Turning,Quick,Quick Turn Smart 300',
                  absolute_sum: 27,
                  relative_sum: 0.6133575647432985
                },
                {
                  name: 'Quick Turn 300',
                  value: 43,
                  code: 'Mazak,Turning,Quick,Quick Turn 300',
                  absolute_sum: 43,
                  relative_sum: 0.9768287142208087
                },
                {
                  name: 'Quick Turn Nexus 450-Ii',
                  value: 150,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 450-Ii',
                  absolute_sum: 150,
                  relative_sum: 3.4075420263516585
                },
                {
                  name: 'Quick Turn 35',
                  value: 56,
                  code: 'Mazak,Turning,Quick,Quick Turn 35',
                  absolute_sum: 56,
                  relative_sum: 1.2721490231712858
                },
                {
                  name: 'Quick Turn 350M',
                  value: 83,
                  code: 'Mazak,Turning,Quick,Quick Turn 350M',
                  absolute_sum: 83,
                  relative_sum: 1.8855065879145845
                },
                {
                  name: 'Quick Turn Nexus 350-Ii My',
                  value: 73,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 350-Ii My',
                  absolute_sum: 73,
                  relative_sum: 1.6583371194911403
                },
                {
                  name: 'Quick Turn Nexus 350-Ii',
                  value: 131,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 350-Ii',
                  absolute_sum: 131,
                  relative_sum: 2.975920036347115
                },
                {
                  name: 'Quick Turn 350 Msy',
                  value: 57,
                  code: 'Mazak,Turning,Quick,Quick Turn 350 Msy',
                  absolute_sum: 57,
                  relative_sum: 1.29486597001363
                },
                {
                  name: 'Quick Turn 350My',
                  value: 38,
                  code: 'Mazak,Turning,Quick,Quick Turn 350My',
                  absolute_sum: 38,
                  relative_sum: 0.8632439800090868
                },
                {
                  name: 'Quick Turn Nexus 350',
                  value: 83,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 350',
                  absolute_sum: 83,
                  relative_sum: 1.8855065879145845
                },
                {
                  name: 'Quick Turn Smart 350',
                  value: 54,
                  code: 'Mazak,Turning,Quick,Quick Turn Smart 350',
                  absolute_sum: 54,
                  relative_sum: 1.226715129486597
                },
                {
                  name: 'Quick Turn 350',
                  value: 45,
                  code: 'Mazak,Turning,Quick,Quick Turn 350',
                  absolute_sum: 45,
                  relative_sum: 1.0222626079054975
                },
                {
                  name: 'Quick Turn 40',
                  value: 37,
                  code: 'Mazak,Turning,Quick,Quick Turn 40',
                  absolute_sum: 37,
                  relative_sum: 0.8405270331667424
                },
                {
                  name: 'Quick Turn 400-80',
                  value: 13,
                  code: 'Mazak,Turning,Quick,Quick Turn 400-80',
                  absolute_sum: 13,
                  relative_sum: 0.29532030895047706
                },
                {
                  name: 'Quick Turn 25',
                  value: 107,
                  code: 'Mazak,Turning,Quick,Quick Turn 25',
                  absolute_sum: 107,
                  relative_sum: 2.4307133121308495
                },
                {
                  name: 'Quick Turn 450',
                  value: 49,
                  code: 'Mazak,Turning,Quick,Quick Turn 450',
                  absolute_sum: 49,
                  relative_sum: 1.113130395274875
                },
                {
                  name: 'Quick Turn Nexus 500My',
                  value: 12,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus 500My',
                  absolute_sum: 12,
                  relative_sum: 0.2726033621081327
                },
                {
                  name: 'Quick Turn 6G, 6T',
                  value: 8,
                  code: 'Mazak,Turning,Quick,Quick Turn 6G, 6T',
                  absolute_sum: 8,
                  relative_sum: 0.1817355747387551
                },
                {
                  name: 'Quick Turn 6G',
                  value: 9,
                  code: 'Mazak,Turning,Quick,Quick Turn 6G',
                  absolute_sum: 9,
                  relative_sum: 0.2044525215810995
                },
                {
                  name: 'Quick Turn 6T',
                  value: 103,
                  code: 'Mazak,Turning,Quick,Quick Turn 6T',
                  absolute_sum: 103,
                  relative_sum: 2.339845524761472
                },
                {
                  name: 'Quick Turn 6',
                  value: 72,
                  code: 'Mazak,Turning,Quick,Quick Turn 6',
                  absolute_sum: 72,
                  relative_sum: 1.635620172648796
                },
                {
                  name: 'Quick Turn 8',
                  value: 36,
                  code: 'Mazak,Turning,Quick,Quick Turn 8',
                  absolute_sum: 36,
                  relative_sum: 0.817810086324398
                },
                {
                  name: 'Quick Turn 8Nsp',
                  value: 16,
                  code: 'Mazak,Turning,Quick,Quick Turn 8Nsp',
                  absolute_sum: 16,
                  relative_sum: 0.3634711494775102
                },
                {
                  name: 'Quick Turn Nexus',
                  value: 6,
                  code: 'Mazak,Turning,Quick,Quick Turn Nexus',
                  absolute_sum: 6,
                  relative_sum: 0.13630168105406634
                },
                {
                  name: 'Quick Turn Smart',
                  value: 8,
                  code: 'Mazak,Turning,Quick,Quick Turn Smart',
                  absolute_sum: 8,
                  relative_sum: 0.1817355747387551
                },
                {
                  name: 'Quick Turn',
                  value: 48,
                  code: 'Mazak,Turning,Quick,Quick Turn',
                  absolute_sum: 48,
                  relative_sum: 1.0904134484325307
                }
              ],
              code: 'Mazak,Turning,Quick',
              absolute_sum: 4402,
              relative_sum: 17.464788732394364
            },
            {
              name: 'Turn',
              children: [
                {
                  name: 'Quick Turn 10',
                  value: 82,
                  code: 'Mazak,Turning,Turn,Quick Turn 10',
                  absolute_sum: 82,
                  relative_sum: 1.4120888582744964
                },
                {
                  name: 'Quick Turn Nexus 100-Ii Ms',
                  value: 3,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 100-Ii Ms',
                  absolute_sum: 3,
                  relative_sum: 0.051661787497847424
                },
                {
                  name: 'Quick Turn Nexus 100-Ii',
                  value: 51,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 100-Ii',
                  absolute_sum: 51,
                  relative_sum: 0.8782503874634062
                },
                {
                  name: 'Quick Turn Nexus 100 Ii Msy',
                  value: 38,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 100 Ii Msy',
                  absolute_sum: 38,
                  relative_sum: 0.6543826416394007
                },
                {
                  name: 'Quick Turn Nexus 100',
                  value: 55,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 100',
                  absolute_sum: 55,
                  relative_sum: 0.9471327707938695
                },
                {
                  name: 'Quick Turn Smart 100M S',
                  value: 1,
                  code: 'Mazak,Turning,Turn,Quick Turn Smart 100M S',
                  absolute_sum: 1,
                  relative_sum: 0.017220595832615808
                },
                {
                  name: 'Quick Turn 100My',
                  value: 6,
                  code: 'Mazak,Turning,Turn,Quick Turn 100My',
                  absolute_sum: 6,
                  relative_sum: 0.10332357499569485
                },
                {
                  name: 'Quick Turn Primos 100',
                  value: 8,
                  code: 'Mazak,Turning,Turn,Quick Turn Primos 100',
                  absolute_sum: 8,
                  relative_sum: 0.13776476666092646
                },
                {
                  name: 'Quick Turn Smart 100S',
                  value: 22,
                  code: 'Mazak,Turning,Turn,Quick Turn Smart 100S',
                  absolute_sum: 22,
                  relative_sum: 0.37885310831754776
                },
                {
                  name: 'Quick Turn Smart 100',
                  value: 13,
                  code: 'Mazak,Turning,Turn,Quick Turn Smart 100',
                  absolute_sum: 13,
                  relative_sum: 0.22386774582400548
                },
                {
                  name: 'Quick Turn Nexus 200 M',
                  value: 22,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 200 M',
                  absolute_sum: 22,
                  relative_sum: 0.37885310831754776
                },
                {
                  name: 'Quick Turn Smart 200M-1000U',
                  value: 4,
                  code: 'Mazak,Turning,Turn,Quick Turn Smart 200M-1000U',
                  absolute_sum: 4,
                  relative_sum: 0.06888238333046323
                },
                {
                  name: 'Quick Turn Nexus 250-Ii M-1000U',
                  value: 9,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 250-Ii M-1000U',
                  absolute_sum: 9,
                  relative_sum: 0.15498536249354228
                },
                {
                  name: 'Quick Turn Smart 250-1000U',
                  value: 8,
                  code: 'Mazak,Turning,Turn,Quick Turn Smart 250-1000U',
                  absolute_sum: 8,
                  relative_sum: 0.13776476666092646
                },
                {
                  name: 'Quick Turn Nexus 400-Ii-1000U',
                  value: 7,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 400-Ii-1000U',
                  absolute_sum: 7,
                  relative_sum: 0.12054417082831066
                },
                {
                  name: 'Quick Turn Nexus 400M-1000U',
                  value: 26,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 400M-1000U',
                  absolute_sum: 26,
                  relative_sum: 0.44773549164801096
                },
                {
                  name: 'Quick Turn Universal 200My-12',
                  value: 4,
                  code: 'Mazak,Turning,Turn,Quick Turn Universal 200My-12',
                  absolute_sum: 4,
                  relative_sum: 0.06888238333046323
                },
                {
                  name: 'Turning Center M-5N-120',
                  value: 28,
                  code: 'Mazak,Turning,Turn,Turning Center M-5N-120',
                  absolute_sum: 28,
                  relative_sum: 0.48217668331324265
                },
                {
                  name: 'Quick Turn Nexus 300-Ii M-1200U',
                  value: 11,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 300-Ii M-1200U',
                  absolute_sum: 11,
                  relative_sum: 0.18942655415877388
                },
                {
                  name: 'Quick Turn Nexus 300-Ii-1200U',
                  value: 7,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 300-Ii-1200U',
                  absolute_sum: 7,
                  relative_sum: 0.12054417082831066
                },
                {
                  name: 'Quick Turn 15N',
                  value: 106,
                  code: 'Mazak,Turning,Turn,Quick Turn 15N',
                  absolute_sum: 106,
                  relative_sum: 1.8253831582572755
                },
                {
                  name: 'Quick Turn 15',
                  value: 43,
                  code: 'Mazak,Turning,Turn,Quick Turn 15',
                  absolute_sum: 43,
                  relative_sum: 0.7404856208024797
                },
                {
                  name: 'Quick Turn 15 Nu',
                  value: 43,
                  code: 'Mazak,Turning,Turn,Quick Turn 15 Nu',
                  absolute_sum: 43,
                  relative_sum: 0.7404856208024797
                },
                {
                  name: 'Quick Turn Smart 150 S',
                  value: 4,
                  code: 'Mazak,Turning,Turn,Quick Turn Smart 150 S',
                  absolute_sum: 4,
                  relative_sum: 0.06888238333046323
                },
                {
                  name: 'Quick Turn Nexus 350-Ii My-1500U',
                  value: 9,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 350-Ii My-1500U',
                  absolute_sum: 9,
                  relative_sum: 0.15498536249354228
                },
                {
                  name: 'Megaturn Nexus 1600M',
                  value: 31,
                  code: 'Mazak,Turning,Turn,Megaturn Nexus 1600M',
                  absolute_sum: 31,
                  relative_sum: 0.53383847081109
                },
                {
                  name: 'Super Quick Turn 15M Mark Ii',
                  value: 45,
                  code: 'Mazak,Turning,Turn,Super Quick Turn 15M Mark Ii',
                  absolute_sum: 45,
                  relative_sum: 0.7749268124677114
                },
                {
                  name: 'Quick Turn Universal 200My-20',
                  value: 33,
                  code: 'Mazak,Turning,Turn,Quick Turn Universal 200My-20',
                  absolute_sum: 33,
                  relative_sum: 0.5682796624763218
                },
                {
                  name: 'Quick Turn Universal 200-20',
                  value: 4,
                  code: 'Mazak,Turning,Turn,Quick Turn Universal 200-20',
                  absolute_sum: 4,
                  relative_sum: 0.06888238333046323
                },
                {
                  name: 'Quick Turn Nexus 250My',
                  value: 90,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 250My',
                  absolute_sum: 90,
                  relative_sum: 1.549853624935423
                },
                {
                  name: 'Quick Turn Universal 250M-20',
                  value: 8,
                  code: 'Mazak,Turning,Turn,Quick Turn Universal 250M-20',
                  absolute_sum: 8,
                  relative_sum: 0.13776476666092646
                },
                {
                  name: 'Quick Turn Universal 250-20',
                  value: 47,
                  code: 'Mazak,Turning,Turn,Quick Turn Universal 250-20',
                  absolute_sum: 47,
                  relative_sum: 0.809368004132943
                },
                {
                  name: 'Quick Turn 20',
                  value: 220,
                  code: 'Mazak,Turning,Turn,Quick Turn 20',
                  absolute_sum: 220,
                  relative_sum: 3.788531083175478
                },
                {
                  name: 'Quick Turn Nexus 200-Ii My',
                  value: 20,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 200-Ii My',
                  absolute_sum: 20,
                  relative_sum: 0.3444119166523162
                },
                {
                  name: 'Quick Turn Nexus 200-Ii',
                  value: 32,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 200-Ii',
                  absolute_sum: 32,
                  relative_sum: 0.5510590666437059
                },
                {
                  name: 'Quick Turn Smart 200-500U',
                  value: 4,
                  code: 'Mazak,Turning,Turn,Quick Turn Smart 200-500U',
                  absolute_sum: 4,
                  relative_sum: 0.06888238333046323
                },
                {
                  name: 'Quick Turn Nexus 200-Ii My-60',
                  value: 8,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 200-Ii My-60',
                  absolute_sum: 8,
                  relative_sum: 0.13776476666092646
                },
                {
                  name: 'Dual Turn 200',
                  value: 66,
                  code: 'Mazak,Turning,Turn,Dual Turn 200',
                  absolute_sum: 66,
                  relative_sum: 1.1365593249526436
                },
                {
                  name: 'Quick Turn Nexus 200Ms',
                  value: 57,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 200Ms',
                  absolute_sum: 57,
                  relative_sum: 0.9815739624591011
                },
                {
                  name: 'Quick Turn Nexus 200 Ii Msy',
                  value: 55,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 200 Ii Msy',
                  absolute_sum: 55,
                  relative_sum: 0.9471327707938695
                },
                {
                  name: 'Quick Turn 200',
                  value: 43,
                  code: 'Mazak,Turning,Turn,Quick Turn 200',
                  absolute_sum: 43,
                  relative_sum: 0.7404856208024797
                },
                {
                  name: 'Quick Turn 200 Ms',
                  value: 20,
                  code: 'Mazak,Turning,Turn,Quick Turn 200 Ms',
                  absolute_sum: 20,
                  relative_sum: 0.3444119166523162
                },
                {
                  name: 'Super Quick Turn 200',
                  value: 27,
                  code: 'Mazak,Turning,Turn,Super Quick Turn 200',
                  absolute_sum: 27,
                  relative_sum: 0.4649560874806269
                },
                {
                  name: 'Quick Turn 200Msy',
                  value: 21,
                  code: 'Mazak,Turning,Turn,Quick Turn 200Msy',
                  absolute_sum: 21,
                  relative_sum: 0.361632512484932
                },
                {
                  name: 'Quick Turn 200My',
                  value: 12,
                  code: 'Mazak,Turning,Turn,Quick Turn 200My',
                  absolute_sum: 12,
                  relative_sum: 0.2066471499913897
                },
                {
                  name: 'Quick Turn Smart 200',
                  value: 61,
                  code: 'Mazak,Turning,Turn,Quick Turn Smart 200',
                  absolute_sum: 61,
                  relative_sum: 1.0504563457895644
                },
                {
                  name: 'Slant Turn 50N',
                  value: 55,
                  code: 'Mazak,Turning,Turn,Slant Turn 50N',
                  absolute_sum: 55,
                  relative_sum: 0.9471327707938695
                },
                {
                  name: 'Slant Turn 50Nx-2000U',
                  value: 7,
                  code: 'Mazak,Turning,Turn,Slant Turn 50Nx-2000U',
                  absolute_sum: 7,
                  relative_sum: 0.12054417082831066
                },
                {
                  name: 'Slant Turn Nexus 550-2000U',
                  value: 1,
                  code: 'Mazak,Turning,Turn,Slant Turn Nexus 550-2000U',
                  absolute_sum: 1,
                  relative_sum: 0.017220595832615808
                },
                {
                  name: 'Quick Turn 250 Hp',
                  value: 84,
                  code: 'Mazak,Turning,Turn,Quick Turn 250 Hp',
                  absolute_sum: 84,
                  relative_sum: 1.446530049939728
                },
                {
                  name: 'Quick Turn 250 Hp-Univ',
                  value: 27,
                  code: 'Mazak,Turning,Turn,Quick Turn 250 Hp-Univ',
                  absolute_sum: 27,
                  relative_sum: 0.4649560874806269
                },
                {
                  name: 'Quick Turn Nexus 250-Ii Msy',
                  value: 177,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 250-Ii Msy',
                  absolute_sum: 177,
                  relative_sum: 3.0480454623729982
                },
                {
                  name: 'Quick Turn Nexus 250-Ii',
                  value: 108,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 250-Ii',
                  absolute_sum: 108,
                  relative_sum: 1.8598243499225076
                },
                {
                  name: 'Super Quick Turn 250',
                  value: 71,
                  code: 'Mazak,Turning,Turn,Super Quick Turn 250',
                  absolute_sum: 71,
                  relative_sum: 1.2226623041157225
                },
                {
                  name: 'Quick Turn 250Mal',
                  value: 8,
                  code: 'Mazak,Turning,Turn,Quick Turn 250Mal',
                  absolute_sum: 8,
                  relative_sum: 0.13776476666092646
                },
                {
                  name: 'Quick Turn 250Msy',
                  value: 78,
                  code: 'Mazak,Turning,Turn,Quick Turn 250Msy',
                  absolute_sum: 78,
                  relative_sum: 1.3432064749440331
                },
                {
                  name: 'Quick Turn Universal 250Msy',
                  value: 25,
                  code: 'Mazak,Turning,Turn,Quick Turn Universal 250Msy',
                  absolute_sum: 25,
                  relative_sum: 0.4305148958153952
                },
                {
                  name: 'Quick Turn 250My',
                  value: 181,
                  code: 'Mazak,Turning,Turn,Quick Turn 250My',
                  absolute_sum: 181,
                  relative_sum: 3.1169278457034615
                },
                {
                  name: 'Super Quick Turn 10M',
                  value: 105,
                  code: 'Mazak,Turning,Turn,Super Quick Turn 10M',
                  absolute_sum: 105,
                  relative_sum: 1.80816256242466
                },
                {
                  name: 'Quick Turn Nexus 250',
                  value: 33,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 250',
                  absolute_sum: 33,
                  relative_sum: 0.5682796624763218
                },
                {
                  name: 'Qt 250 Turning Center',
                  value: 332,
                  code: 'Mazak,Turning,Turn,Qt 250 Turning Center',
                  absolute_sum: 332,
                  relative_sum: 5.717237816428448
                },
                {
                  name: 'Quick Turn Smart 250M',
                  value: 153,
                  code: 'Mazak,Turning,Turn,Quick Turn Smart 250M',
                  absolute_sum: 153,
                  relative_sum: 2.6347511623902187
                },
                {
                  name: 'Quick Turn 250',
                  value: 230,
                  code: 'Mazak,Turning,Turn,Quick Turn 250',
                  absolute_sum: 230,
                  relative_sum: 3.960737041501636
                },
                {
                  name: 'Quick Turn 8N',
                  value: 40,
                  code: 'Mazak,Turning,Turn,Quick Turn 8N',
                  absolute_sum: 40,
                  relative_sum: 0.6888238333046324
                },
                {
                  name: 'Quick Turn 18N',
                  value: 162,
                  code: 'Mazak,Turning,Turn,Quick Turn 18N',
                  absolute_sum: 162,
                  relative_sum: 2.789736524883761
                },
                {
                  name: 'Quick Turn 30',
                  value: 79,
                  code: 'Mazak,Turning,Turn,Quick Turn 30',
                  absolute_sum: 79,
                  relative_sum: 1.360427070776649
                },
                {
                  name: 'Quick Turn Smart 300M',
                  value: 7,
                  code: 'Mazak,Turning,Turn,Quick Turn Smart 300M',
                  absolute_sum: 7,
                  relative_sum: 0.12054417082831066
                },
                {
                  name: 'Quick Turn Nexus 200',
                  value: 68,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 200',
                  absolute_sum: 68,
                  relative_sum: 1.171000516617875
                },
                {
                  name: 'Quick Turn 300My',
                  value: 13,
                  code: 'Mazak,Turning,Turn,Quick Turn 300My',
                  absolute_sum: 13,
                  relative_sum: 0.22386774582400548
                },
                {
                  name: 'Quick Turn Smart 300',
                  value: 27,
                  code: 'Mazak,Turning,Turn,Quick Turn Smart 300',
                  absolute_sum: 27,
                  relative_sum: 0.4649560874806269
                },
                {
                  name: 'Quick Turn 300',
                  value: 43,
                  code: 'Mazak,Turning,Turn,Quick Turn 300',
                  absolute_sum: 43,
                  relative_sum: 0.7404856208024797
                },
                {
                  name: 'Quick Turn Nexus 450-Ii',
                  value: 150,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 450-Ii',
                  absolute_sum: 150,
                  relative_sum: 2.583089374892371
                },
                {
                  name: 'Cybertech Turn 4500M/3000',
                  value: 11,
                  code: 'Mazak,Turning,Turn,Cybertech Turn 4500M/3000',
                  absolute_sum: 11,
                  relative_sum: 0.18942655415877388
                },
                {
                  name: 'Slant Turn 550M-3000U',
                  value: 12,
                  code: 'Mazak,Turning,Turn,Slant Turn 550M-3000U',
                  absolute_sum: 12,
                  relative_sum: 0.2066471499913897
                },
                {
                  name: 'Quick Turn 35',
                  value: 56,
                  code: 'Mazak,Turning,Turn,Quick Turn 35',
                  absolute_sum: 56,
                  relative_sum: 0.9643533666264853
                },
                {
                  name: 'Quick Turn 350M',
                  value: 83,
                  code: 'Mazak,Turning,Turn,Quick Turn 350M',
                  absolute_sum: 83,
                  relative_sum: 1.429309454107112
                },
                {
                  name: 'Quick Turn Nexus 350-Ii My',
                  value: 73,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 350-Ii My',
                  absolute_sum: 73,
                  relative_sum: 1.257103495780954
                },
                {
                  name: 'Quick Turn Nexus 350-Ii',
                  value: 131,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 350-Ii',
                  absolute_sum: 131,
                  relative_sum: 2.255898054072671
                },
                {
                  name: 'Quick Turn 350 Msy',
                  value: 57,
                  code: 'Mazak,Turning,Turn,Quick Turn 350 Msy',
                  absolute_sum: 57,
                  relative_sum: 0.9815739624591011
                },
                {
                  name: 'Quick Turn 350My',
                  value: 38,
                  code: 'Mazak,Turning,Turn,Quick Turn 350My',
                  absolute_sum: 38,
                  relative_sum: 0.6543826416394007
                },
                {
                  name: 'Quick Turn Nexus 350',
                  value: 83,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 350',
                  absolute_sum: 83,
                  relative_sum: 1.429309454107112
                },
                {
                  name: 'Quick Turn Smart 350',
                  value: 54,
                  code: 'Mazak,Turning,Turn,Quick Turn Smart 350',
                  absolute_sum: 54,
                  relative_sum: 0.9299121749612538
                },
                {
                  name: 'Quick Turn 350',
                  value: 45,
                  code: 'Mazak,Turning,Turn,Quick Turn 350',
                  absolute_sum: 45,
                  relative_sum: 0.7749268124677114
                },
                {
                  name: 'Quick Turn 40',
                  value: 37,
                  code: 'Mazak,Turning,Turn,Quick Turn 40',
                  absolute_sum: 37,
                  relative_sum: 0.6371620458067849
                },
                {
                  name: 'Quick Turn 400-80',
                  value: 13,
                  code: 'Mazak,Turning,Turn,Quick Turn 400-80',
                  absolute_sum: 13,
                  relative_sum: 0.22386774582400548
                },
                {
                  name: 'Slant Turn Nexus 600M-4000U',
                  value: 5,
                  code: 'Mazak,Turning,Turn,Slant Turn Nexus 600M-4000U',
                  absolute_sum: 5,
                  relative_sum: 0.08610297916307905
                },
                {
                  name: 'Quick Turn 25',
                  value: 107,
                  code: 'Mazak,Turning,Turn,Quick Turn 25',
                  absolute_sum: 107,
                  relative_sum: 1.8426037540898914
                },
                {
                  name: 'Quick Turn 450',
                  value: 49,
                  code: 'Mazak,Turning,Turn,Quick Turn 450',
                  absolute_sum: 49,
                  relative_sum: 0.8438091957981746
                },
                {
                  name: 'Cybertech Turn 4500M',
                  value: 61,
                  code: 'Mazak,Turning,Turn,Cybertech Turn 4500M',
                  absolute_sum: 61,
                  relative_sum: 1.0504563457895644
                },
                {
                  name: 'Turning Center M-5',
                  value: 46,
                  code: 'Mazak,Turning,Turn,Turning Center M-5',
                  absolute_sum: 46,
                  relative_sum: 0.7921474083003273
                },
                {
                  name: 'Slant Turn 50',
                  value: 60,
                  code: 'Mazak,Turning,Turn,Slant Turn 50',
                  absolute_sum: 60,
                  relative_sum: 1.0332357499569484
                },
                {
                  name: 'Mega Turn 500M',
                  value: 12,
                  code: 'Mazak,Turning,Turn,Mega Turn 500M',
                  absolute_sum: 12,
                  relative_sum: 0.2066471499913897
                },
                {
                  name: 'Slant Turn 500M',
                  value: 53,
                  code: 'Mazak,Turning,Turn,Slant Turn 500M',
                  absolute_sum: 53,
                  relative_sum: 0.9126915791286377
                },
                {
                  name: 'Quick Turn Nexus 500My',
                  value: 12,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus 500My',
                  absolute_sum: 12,
                  relative_sum: 0.2066471499913897
                },
                {
                  name: 'Slant Turn 550M',
                  value: 25,
                  code: 'Mazak,Turning,Turn,Slant Turn 550M',
                  absolute_sum: 25,
                  relative_sum: 0.4305148958153952
                },
                {
                  name: 'Slant Turn Nexus 550',
                  value: 32,
                  code: 'Mazak,Turning,Turn,Slant Turn Nexus 550',
                  absolute_sum: 32,
                  relative_sum: 0.5510590666437059
                },
                {
                  name: 'Slant Turn 550',
                  value: 18,
                  code: 'Mazak,Turning,Turn,Slant Turn 550',
                  absolute_sum: 18,
                  relative_sum: 0.30997072498708456
                },
                {
                  name: 'Quick Turn 6G, 6T',
                  value: 8,
                  code: 'Mazak,Turning,Turn,Quick Turn 6G, 6T',
                  absolute_sum: 8,
                  relative_sum: 0.13776476666092646
                },
                {
                  name: 'Quick Turn 6G',
                  value: 9,
                  code: 'Mazak,Turning,Turn,Quick Turn 6G',
                  absolute_sum: 9,
                  relative_sum: 0.15498536249354228
                },
                {
                  name: 'Quick Turn 6T',
                  value: 103,
                  code: 'Mazak,Turning,Turn,Quick Turn 6T',
                  absolute_sum: 103,
                  relative_sum: 1.7737213707594284
                },
                {
                  name: 'Quick Turn 6',
                  value: 72,
                  code: 'Mazak,Turning,Turn,Quick Turn 6',
                  absolute_sum: 72,
                  relative_sum: 1.2398828999483382
                },
                {
                  name: 'Slant Turn 60N',
                  value: 26,
                  code: 'Mazak,Turning,Turn,Slant Turn 60N',
                  absolute_sum: 26,
                  relative_sum: 0.44773549164801096
                },
                {
                  name: 'Mega Turn 600S',
                  value: 20,
                  code: 'Mazak,Turning,Turn,Mega Turn 600S',
                  absolute_sum: 20,
                  relative_sum: 0.3444119166523162
                },
                {
                  name: 'Slant Turn 600',
                  value: 82,
                  code: 'Mazak,Turning,Turn,Slant Turn 600',
                  absolute_sum: 82,
                  relative_sum: 1.4120888582744964
                },
                {
                  name: 'Quick Turn 8',
                  value: 36,
                  code: 'Mazak,Turning,Turn,Quick Turn 8',
                  absolute_sum: 36,
                  relative_sum: 0.6199414499741691
                },
                {
                  name: 'Quick Turn 8Nsp',
                  value: 16,
                  code: 'Mazak,Turning,Turn,Quick Turn 8Nsp',
                  absolute_sum: 16,
                  relative_sum: 0.2755295333218529
                },
                {
                  name: 'Megaturn Nexus 900',
                  value: 57,
                  code: 'Mazak,Turning,Turn,Megaturn Nexus 900',
                  absolute_sum: 57,
                  relative_sum: 0.9815739624591011
                },
                {
                  name: 'Dual Turn',
                  value: 11,
                  code: 'Mazak,Turning,Turn,Dual Turn',
                  absolute_sum: 11,
                  relative_sum: 0.18942655415877388
                },
                {
                  name: 'Quick Turn Nexus',
                  value: 6,
                  code: 'Mazak,Turning,Turn,Quick Turn Nexus',
                  absolute_sum: 6,
                  relative_sum: 0.10332357499569485
                },
                {
                  name: 'Quick Turn Smart',
                  value: 8,
                  code: 'Mazak,Turning,Turn,Quick Turn Smart',
                  absolute_sum: 8,
                  relative_sum: 0.13776476666092646
                },
                {
                  name: 'Quick Turn',
                  value: 48,
                  code: 'Mazak,Turning,Turn,Quick Turn',
                  absolute_sum: 48,
                  relative_sum: 0.8265885999655588
                },
                {
                  name: 'Slant Turn',
                  value: 48,
                  code: 'Mazak,Turning,Turn,Slant Turn',
                  absolute_sum: 48,
                  relative_sum: 0.8265885999655588
                },
                {
                  name: 'Cnc Turning Center',
                  value: 306,
                  code: 'Mazak,Turning,Turn,Cnc Turning Center',
                  absolute_sum: 306,
                  relative_sum: 5.269502324780437
                }
              ],
              code: 'Mazak,Turning,Turn',
              absolute_sum: 5807,
              relative_sum: 23.039079547708788
            },
            {
              name: 'Qt',
              children: [
                {
                  name: 'Qt 10',
                  value: 140,
                  code: 'Mazak,Turning,Qt,Qt 10',
                  absolute_sum: 140,
                  relative_sum: 4.366812227074235
                },
                {
                  name: 'Qte-100M Sg',
                  value: 7,
                  code: 'Mazak,Turning,Qt,Qte-100M Sg',
                  absolute_sum: 7,
                  relative_sum: 0.21834061135371177
                },
                {
                  name: 'Sqt 100Ms',
                  value: 24,
                  code: 'Mazak,Turning,Qt,Sqt 100Ms',
                  absolute_sum: 24,
                  relative_sum: 0.7485963817841547
                },
                {
                  name: 'Qt-Primos 100',
                  value: 69,
                  code: 'Mazak,Turning,Qt,Qt-Primos 100',
                  absolute_sum: 69,
                  relative_sum: 2.152214597629445
                },
                {
                  name: 'Qtn 100',
                  value: 64,
                  code: 'Mazak,Turning,Qt,Qtn 100',
                  absolute_sum: 64,
                  relative_sum: 1.9962570180910792
                },
                {
                  name: 'Sqt 15Ms',
                  value: 179,
                  code: 'Mazak,Turning,Qt,Sqt 15Ms',
                  absolute_sum: 179,
                  relative_sum: 5.583281347473487
                },
                {
                  name: 'Qt 15',
                  value: 238,
                  code: 'Mazak,Turning,Qt,Qt 15',
                  absolute_sum: 238,
                  relative_sum: 7.423580786026202
                },
                {
                  name: 'Qt-Primos 150 Sg',
                  value: 7,
                  code: 'Mazak,Turning,Qt,Qt-Primos 150 Sg',
                  absolute_sum: 7,
                  relative_sum: 0.21834061135371177
                },
                {
                  name: 'Qtn-250 2Ky',
                  value: 14,
                  code: 'Mazak,Turning,Qt,Qtn-250 2Ky',
                  absolute_sum: 14,
                  relative_sum: 0.43668122270742354
                },
                {
                  name: 'Qt 20N',
                  value: 113,
                  code: 'Mazak,Turning,Qt,Qt 20N',
                  absolute_sum: 113,
                  relative_sum: 3.524641297567062
                },
                {
                  name: 'Qt20',
                  value: 145,
                  code: 'Mazak,Turning,Qt,Qt20',
                  absolute_sum: 145,
                  relative_sum: 4.522769806612601
                },
                {
                  name: 'Qt-Compact 200M L',
                  value: 17,
                  code: 'Mazak,Turning,Qt,Qt-Compact 200M L',
                  absolute_sum: 17,
                  relative_sum: 0.5302557704304429
                },
                {
                  name: 'Qt-Compact 200Msy L',
                  value: 25,
                  code: 'Mazak,Turning,Qt,Qt-Compact 200Msy L',
                  absolute_sum: 25,
                  relative_sum: 0.7797878976918279
                },
                {
                  name: 'Qtn-200M',
                  value: 70,
                  code: 'Mazak,Turning,Qt,Qtn-200M',
                  absolute_sum: 70,
                  relative_sum: 2.1834061135371177
                },
                {
                  name: 'Qtu-200My',
                  value: 37,
                  code: 'Mazak,Turning,Qt,Qtu-200My',
                  absolute_sum: 37,
                  relative_sum: 1.154086088583905
                },
                {
                  name: 'Qt 200',
                  value: 145,
                  code: 'Mazak,Turning,Qt,Qt 200',
                  absolute_sum: 145,
                  relative_sum: 4.522769806612601
                },
                {
                  name: 'Qtn 200',
                  value: 56,
                  code: 'Mazak,Turning,Qt,Qtn 200',
                  absolute_sum: 56,
                  relative_sum: 1.7467248908296942
                },
                {
                  name: 'Qts 200',
                  value: 39,
                  code: 'Mazak,Turning,Qt,Qts 200',
                  absolute_sum: 39,
                  relative_sum: 1.2164691203992515
                },
                {
                  name: 'Sqt 200',
                  value: 65,
                  code: 'Mazak,Turning,Qt,Sqt 200',
                  absolute_sum: 65,
                  relative_sum: 2.0274485339987525
                },
                {
                  name: 'Nexus Qtn 450Iimy/2000',
                  value: 16,
                  code: 'Mazak,Turning,Qt,Nexus Qtn 450Iimy/2000',
                  absolute_sum: 16,
                  relative_sum: 0.4990642545227698
                },
                {
                  name: 'Qt-25',
                  value: 85,
                  code: 'Mazak,Turning,Qt,Qt-25',
                  absolute_sum: 85,
                  relative_sum: 2.6512788521522146
                },
                {
                  name: 'Qtn 250 Hp',
                  value: 62,
                  code: 'Mazak,Turning,Qt,Qtn 250 Hp',
                  absolute_sum: 62,
                  relative_sum: 1.9338739862757333
                },
                {
                  name: 'Qtn 250-Ii',
                  value: 75,
                  code: 'Mazak,Turning,Qt,Qtn 250-Ii',
                  absolute_sum: 75,
                  relative_sum: 2.3393636930754833
                },
                {
                  name: 'Qt250My',
                  value: 76,
                  code: 'Mazak,Turning,Qt,Qt250My',
                  absolute_sum: 76,
                  relative_sum: 2.3705552089831565
                },
                {
                  name: 'Qtu 250My',
                  value: 93,
                  code: 'Mazak,Turning,Qt,Qtu 250My',
                  absolute_sum: 93,
                  relative_sum: 2.9008109794135994
                },
                {
                  name: 'Qt250',
                  value: 116,
                  code: 'Mazak,Turning,Qt,Qt250',
                  absolute_sum: 116,
                  relative_sum: 3.6182158452900812
                },
                {
                  name: 'Qt 250 Turning Center',
                  value: 332,
                  code: 'Mazak,Turning,Qt,Qt 250 Turning Center',
                  absolute_sum: 332,
                  relative_sum: 10.355583281347473
                },
                {
                  name: 'Qtn 250',
                  value: 67,
                  code: 'Mazak,Turning,Qt,Qtn 250',
                  absolute_sum: 67,
                  relative_sum: 2.089831565814099
                },
                {
                  name: 'Qts 250',
                  value: 98,
                  code: 'Mazak,Turning,Qt,Qts 250',
                  absolute_sum: 98,
                  relative_sum: 3.056768558951965
                },
                {
                  name: 'Sqt 250',
                  value: 33,
                  code: 'Mazak,Turning,Qt,Sqt 250',
                  absolute_sum: 33,
                  relative_sum: 1.0293200249532126
                },
                {
                  name: 'Qt-Compact 300Ms L',
                  value: 11,
                  code: 'Mazak,Turning,Qt,Qt-Compact 300Ms L',
                  absolute_sum: 11,
                  relative_sum: 0.3431066749844042
                },
                {
                  name: 'Qt-Compact 300 Ml',
                  value: 40,
                  code: 'Mazak,Turning,Qt,Qt-Compact 300 Ml',
                  absolute_sum: 40,
                  relative_sum: 1.2476606363069247
                },
                {
                  name: 'Qts 300',
                  value: 48,
                  code: 'Mazak,Turning,Qt,Qts 300',
                  absolute_sum: 48,
                  relative_sum: 1.4971927635683093
                },
                {
                  name: 'Qtn 350 My',
                  value: 235,
                  code: 'Mazak,Turning,Qt,Qtn 350 My',
                  absolute_sum: 235,
                  relative_sum: 7.330006238303182
                },
                {
                  name: 'Qt-8-U Iv',
                  value: 24,
                  code: 'Mazak,Turning,Qt,Qt-8-U Iv',
                  absolute_sum: 24,
                  relative_sum: 0.7485963817841547
                },
                {
                  name: 'Qt8',
                  value: 116,
                  code: 'Mazak,Turning,Qt,Qt8',
                  absolute_sum: 116,
                  relative_sum: 3.6182158452900812
                },
                {
                  name: 'Qt-Compact',
                  value: 225,
                  code: 'Mazak,Turning,Qt,Qt-Compact',
                  absolute_sum: 225,
                  relative_sum: 7.01809107922645
                }
              ],
              code: 'Mazak,Turning,Qt',
              absolute_sum: 3206,
              relative_sum: 12.719698472525293
            },
            {
              name: 'Integrex',
              children: [
                {
                  name: 'Integrex I-100 Bartac-St',
                  value: 10,
                  code: 'Mazak,Turning,Integrex,Integrex I-100 Bartac-St',
                  absolute_sum: 10,
                  relative_sum: 1.3003901170351104
                },
                {
                  name: 'Integrex I-100St',
                  value: 43,
                  code: 'Mazak,Turning,Integrex,Integrex I-100St',
                  absolute_sum: 43,
                  relative_sum: 5.591677503250976
                },
                {
                  name: 'Integrex 200-Iv',
                  value: 63,
                  code: 'Mazak,Turning,Integrex,Integrex 200-Iv',
                  absolute_sum: 63,
                  relative_sum: 8.192457737321195
                },
                {
                  name: 'Integrex I-200-1000U',
                  value: 7,
                  code: 'Mazak,Turning,Integrex,Integrex I-200-1000U',
                  absolute_sum: 7,
                  relative_sum: 0.9102730819245773
                },
                {
                  name: 'Integrex I-200 St Universal 1500',
                  value: 14,
                  code: 'Mazak,Turning,Integrex,Integrex I-200 St Universal 1500',
                  absolute_sum: 14,
                  relative_sum: 1.8205461638491547
                },
                {
                  name: 'Integrex 300-2Y',
                  value: 27,
                  code: 'Mazak,Turning,Integrex,Integrex 300-2Y',
                  absolute_sum: 27,
                  relative_sum: 3.5110533159947983
                },
                {
                  name: 'Integrex J-200-500U',
                  value: 6,
                  code: 'Mazak,Turning,Integrex,Integrex J-200-500U',
                  absolute_sum: 6,
                  relative_sum: 0.7802340702210664
                },
                {
                  name: 'Integrex I-200St',
                  value: 92,
                  code: 'Mazak,Turning,Integrex,Integrex I-200St',
                  absolute_sum: 92,
                  relative_sum: 11.963589076723016
                },
                {
                  name: 'Integrex I-200S',
                  value: 69,
                  code: 'Mazak,Turning,Integrex,Integrex I-200S',
                  absolute_sum: 69,
                  relative_sum: 8.972691807542263
                },
                {
                  name: 'Integrex 200-Iiis',
                  value: 24,
                  code: 'Mazak,Turning,Integrex,Integrex 200-Iiis',
                  absolute_sum: 24,
                  relative_sum: 3.1209362808842656
                },
                {
                  name: 'Integrex 200 Iiist',
                  value: 27,
                  code: 'Mazak,Turning,Integrex,Integrex 200 Iiist',
                  absolute_sum: 27,
                  relative_sum: 3.5110533159947983
                },
                {
                  name: 'Integrex 200Sy',
                  value: 26,
                  code: 'Mazak,Turning,Integrex,Integrex 200Sy',
                  absolute_sum: 26,
                  relative_sum: 3.3810143042912877
                },
                {
                  name: 'Integrex 200Y',
                  value: 30,
                  code: 'Mazak,Turning,Integrex,Integrex 200Y',
                  absolute_sum: 30,
                  relative_sum: 3.901170351105332
                },
                {
                  name: 'Integrex I-250H S Ag',
                  value: 2,
                  code: 'Mazak,Turning,Integrex,Integrex I-250H S Ag',
                  absolute_sum: 2,
                  relative_sum: 0.26007802340702213
                },
                {
                  name: 'Integrex J300',
                  value: 29,
                  code: 'Mazak,Turning,Integrex,Integrex J300',
                  absolute_sum: 29,
                  relative_sum: 3.7711313394018204
                },
                {
                  name: 'Integrex 300 Sy',
                  value: 34,
                  code: 'Mazak,Turning,Integrex,Integrex 300 Sy',
                  absolute_sum: 34,
                  relative_sum: 4.421326397919375
                },
                {
                  name: 'Integrex 300 Y',
                  value: 56,
                  code: 'Mazak,Turning,Integrex,Integrex 300 Y',
                  absolute_sum: 56,
                  relative_sum: 7.282184655396619
                },
                {
                  name: 'Integrex 35',
                  value: 49,
                  code: 'Mazak,Turning,Integrex,Integrex 35',
                  absolute_sum: 49,
                  relative_sum: 6.371911573472041
                },
                {
                  name: 'Integrex 400-Iv',
                  value: 26,
                  code: 'Mazak,Turning,Integrex,Integrex 400-Iv',
                  absolute_sum: 26,
                  relative_sum: 3.3810143042912877
                },
                {
                  name: 'Integrex J-400',
                  value: 61,
                  code: 'Mazak,Turning,Integrex,Integrex J-400',
                  absolute_sum: 61,
                  relative_sum: 7.932379713914174
                },
                {
                  name: 'Integrex E-410H',
                  value: 42,
                  code: 'Mazak,Turning,Integrex,Integrex E-410H',
                  absolute_sum: 42,
                  relative_sum: 5.461638491547465
                },
                {
                  name: 'Integrex E-650H',
                  value: 32,
                  code: 'Mazak,Turning,Integrex,Integrex E-650H',
                  absolute_sum: 32,
                  relative_sum: 4.161248374512354
                }
              ],
              code: 'Mazak,Turning,Integrex',
              absolute_sum: 769,
              relative_sum: 3.0509819480261853
            },
            {
              name: 'St',
              children: [
                {
                  name: 'Integrex I-100 Bartac-St',
                  value: 10,
                  code: 'Mazak,Turning,St,Integrex I-100 Bartac-St',
                  absolute_sum: 10,
                  relative_sum: 2.512562814070352
                },
                {
                  name: 'Integrex I-100St',
                  value: 43,
                  code: 'Mazak,Turning,St,Integrex I-100St',
                  absolute_sum: 43,
                  relative_sum: 10.804020100502512
                },
                {
                  name: 'Integrex I-200 St Universal 1500',
                  value: 14,
                  code: 'Mazak,Turning,St,Integrex I-200 St Universal 1500',
                  absolute_sum: 14,
                  relative_sum: 3.5175879396984926
                },
                {
                  name: 'Integrex I-200St',
                  value: 92,
                  code: 'Mazak,Turning,St,Integrex I-200St',
                  absolute_sum: 92,
                  relative_sum: 23.115577889447238
                },
                {
                  name: 'Integrex 200 Iiist',
                  value: 27,
                  code: 'Mazak,Turning,St,Integrex 200 Iiist',
                  absolute_sum: 27,
                  relative_sum: 6.78391959798995
                },
                {
                  name: 'Powermaster 2500',
                  value: 21,
                  code: 'Mazak,Turning,St,Powermaster 2500',
                  absolute_sum: 21,
                  relative_sum: 5.276381909547738
                },
                {
                  name: 'Power Master N Universal-3000U',
                  value: 6,
                  code: 'Mazak,Turning,St,Power Master N Universal-3000U',
                  absolute_sum: 6,
                  relative_sum: 1.507537688442211
                },
                {
                  name: 'Powermaster 3000',
                  value: 28,
                  code: 'Mazak,Turning,St,Powermaster 3000',
                  absolute_sum: 28,
                  relative_sum: 7.035175879396985
                },
                {
                  name: 'St-50N',
                  value: 17,
                  code: 'Mazak,Turning,St,St-50N',
                  absolute_sum: 17,
                  relative_sum: 4.271356783919598
                },
                {
                  name: 'Power Master N Chucker',
                  value: 52,
                  code: 'Mazak,Turning,St,Power Master N Chucker',
                  absolute_sum: 52,
                  relative_sum: 13.06532663316583
                },
                {
                  name: 'Power Master',
                  value: 41,
                  code: 'Mazak,Turning,St,Power Master',
                  absolute_sum: 41,
                  relative_sum: 10.301507537688442
                },
                {
                  name: 'Powermaster',
                  value: 47,
                  code: 'Mazak,Turning,St,Powermaster',
                  absolute_sum: 47,
                  relative_sum: 11.809045226130653
                }
              ],
              code: 'Mazak,Turning,St',
              absolute_sum: 398,
              relative_sum: 1.5790517754413806
            },
            {
              name: 'Ms',
              children: [
                {
                  name: 'Hqr-100Msy',
                  value: 37,
                  code: 'Mazak,Turning,Ms,Hqr-100Msy',
                  absolute_sum: 37,
                  relative_sum: 3.510436432637571
                },
                {
                  name: 'Quick Turn Nexus 100-Ii Ms',
                  value: 3,
                  code: 'Mazak,Turning,Ms,Quick Turn Nexus 100-Ii Ms',
                  absolute_sum: 3,
                  relative_sum: 0.2846299810246679
                },
                {
                  name: 'Quick Turn Nexus 100 Ii Msy',
                  value: 38,
                  code: 'Mazak,Turning,Ms,Quick Turn Nexus 100 Ii Msy',
                  absolute_sum: 38,
                  relative_sum: 3.6053130929791273
                },
                {
                  name: 'Sqt 100Ms',
                  value: 24,
                  code: 'Mazak,Turning,Ms,Sqt 100Ms',
                  absolute_sum: 24,
                  relative_sum: 2.2770398481973433
                },
                {
                  name: 'Sqt 15Ms',
                  value: 179,
                  code: 'Mazak,Turning,Ms,Sqt 15Ms',
                  absolute_sum: 179,
                  relative_sum: 16.98292220113852
                },
                {
                  name: 'Hqr150Msy',
                  value: 16,
                  code: 'Mazak,Turning,Ms,Hqr150Msy',
                  absolute_sum: 16,
                  relative_sum: 1.5180265654648957
                },
                {
                  name: 'Hyper Quadrex 150 Msy',
                  value: 40,
                  code: 'Mazak,Turning,Ms,Hyper Quadrex 150 Msy',
                  absolute_sum: 40,
                  relative_sum: 3.795066413662239
                },
                {
                  name: 'Qt-Compact 200Msy L',
                  value: 25,
                  code: 'Mazak,Turning,Ms,Qt-Compact 200Msy L',
                  absolute_sum: 25,
                  relative_sum: 2.3719165085388996
                },
                {
                  name: 'Hqr 200 Msy',
                  value: 83,
                  code: 'Mazak,Turning,Ms,Hqr 200 Msy',
                  absolute_sum: 83,
                  relative_sum: 7.874762808349146
                },
                {
                  name: 'Quick Turn Nexus 200Ms',
                  value: 57,
                  code: 'Mazak,Turning,Ms,Quick Turn Nexus 200Ms',
                  absolute_sum: 57,
                  relative_sum: 5.407969639468691
                },
                {
                  name: 'Quick Turn Nexus 200 Ii Msy',
                  value: 55,
                  code: 'Mazak,Turning,Ms,Quick Turn Nexus 200 Ii Msy',
                  absolute_sum: 55,
                  relative_sum: 5.218216318785579
                },
                {
                  name: 'Quick Turn 200 Ms',
                  value: 20,
                  code: 'Mazak,Turning,Ms,Quick Turn 200 Ms',
                  absolute_sum: 20,
                  relative_sum: 1.8975332068311195
                },
                {
                  name: 'Quick Turn 200Msy',
                  value: 21,
                  code: 'Mazak,Turning,Ms,Quick Turn 200Msy',
                  absolute_sum: 21,
                  relative_sum: 1.9924098671726755
                },
                {
                  name: 'Hqr 250Msy',
                  value: 82,
                  code: 'Mazak,Turning,Ms,Hqr 250Msy',
                  absolute_sum: 82,
                  relative_sum: 7.7798861480075905
                },
                {
                  name: 'Quick Turn Nexus 250-Ii Msy',
                  value: 177,
                  code: 'Mazak,Turning,Ms,Quick Turn Nexus 250-Ii Msy',
                  absolute_sum: 177,
                  relative_sum: 16.793168880455408
                },
                {
                  name: '250Msy',
                  value: 26,
                  code: 'Mazak,Turning,Ms,250Msy',
                  absolute_sum: 26,
                  relative_sum: 2.4667931688804554
                },
                {
                  name: 'Quick Turn 250Msy',
                  value: 78,
                  code: 'Mazak,Turning,Ms,Quick Turn 250Msy',
                  absolute_sum: 78,
                  relative_sum: 7.400379506641366
                },
                {
                  name: 'Quick Turn Universal 250Msy',
                  value: 25,
                  code: 'Mazak,Turning,Ms,Quick Turn Universal 250Msy',
                  absolute_sum: 25,
                  relative_sum: 2.3719165085388996
                },
                {
                  name: 'Qt-Compact 300Ms L',
                  value: 11,
                  code: 'Mazak,Turning,Ms,Qt-Compact 300Ms L',
                  absolute_sum: 11,
                  relative_sum: 1.0436432637571158
                },
                {
                  name: 'Quick Turn 350 Msy',
                  value: 57,
                  code: 'Mazak,Turning,Ms,Quick Turn 350 Msy',
                  absolute_sum: 57,
                  relative_sum: 5.407969639468691
                }
              ],
              code: 'Mazak,Turning,Ms',
              absolute_sum: 1054,
              relative_sum: 4.181709978178932
            },
            {
              name: 'Msy',
              children: [
                {
                  name: 'Hqr-100Msy',
                  value: 37,
                  code: 'Mazak,Turning,Msy,Hqr-100Msy',
                  absolute_sum: 37,
                  relative_sum: 4.868421052631579
                },
                {
                  name: 'Quick Turn Nexus 100 Ii Msy',
                  value: 38,
                  code: 'Mazak,Turning,Msy,Quick Turn Nexus 100 Ii Msy',
                  absolute_sum: 38,
                  relative_sum: 5
                },
                {
                  name: 'Hqr150Msy',
                  value: 16,
                  code: 'Mazak,Turning,Msy,Hqr150Msy',
                  absolute_sum: 16,
                  relative_sum: 2.1052631578947367
                },
                {
                  name: 'Hyper Quadrex 150 Msy',
                  value: 40,
                  code: 'Mazak,Turning,Msy,Hyper Quadrex 150 Msy',
                  absolute_sum: 40,
                  relative_sum: 5.263157894736842
                },
                {
                  name: 'Qt-Compact 200Msy L',
                  value: 25,
                  code: 'Mazak,Turning,Msy,Qt-Compact 200Msy L',
                  absolute_sum: 25,
                  relative_sum: 3.289473684210526
                },
                {
                  name: 'Hqr 200 Msy',
                  value: 83,
                  code: 'Mazak,Turning,Msy,Hqr 200 Msy',
                  absolute_sum: 83,
                  relative_sum: 10.921052631578949
                },
                {
                  name: 'Quick Turn Nexus 200 Ii Msy',
                  value: 55,
                  code: 'Mazak,Turning,Msy,Quick Turn Nexus 200 Ii Msy',
                  absolute_sum: 55,
                  relative_sum: 7.236842105263158
                },
                {
                  name: 'Quick Turn 200Msy',
                  value: 21,
                  code: 'Mazak,Turning,Msy,Quick Turn 200Msy',
                  absolute_sum: 21,
                  relative_sum: 2.763157894736842
                },
                {
                  name: 'Hqr 250Msy',
                  value: 82,
                  code: 'Mazak,Turning,Msy,Hqr 250Msy',
                  absolute_sum: 82,
                  relative_sum: 10.789473684210527
                },
                {
                  name: 'Quick Turn Nexus 250-Ii Msy',
                  value: 177,
                  code: 'Mazak,Turning,Msy,Quick Turn Nexus 250-Ii Msy',
                  absolute_sum: 177,
                  relative_sum: 23.289473684210527
                },
                {
                  name: '250Msy',
                  value: 26,
                  code: 'Mazak,Turning,Msy,250Msy',
                  absolute_sum: 26,
                  relative_sum: 3.421052631578948
                },
                {
                  name: 'Quick Turn 250Msy',
                  value: 78,
                  code: 'Mazak,Turning,Msy,Quick Turn 250Msy',
                  absolute_sum: 78,
                  relative_sum: 10.263157894736842
                },
                {
                  name: 'Quick Turn Universal 250Msy',
                  value: 25,
                  code: 'Mazak,Turning,Msy,Quick Turn Universal 250Msy',
                  absolute_sum: 25,
                  relative_sum: 3.289473684210526
                },
                {
                  name: 'Quick Turn 350 Msy',
                  value: 57,
                  code: 'Mazak,Turning,Msy,Quick Turn 350 Msy',
                  absolute_sum: 57,
                  relative_sum: 7.5
                }
              ],
              code: 'Mazak,Turning,Msy',
              absolute_sum: 760,
              relative_sum: 3.0152747470739936
            },
            {
              name: 'Hqr',
              children: [
                {
                  name: 'Hqr-100Msy',
                  value: 37,
                  code: 'Mazak,Turning,Hqr,Hqr-100Msy',
                  absolute_sum: 37,
                  relative_sum: 16.972477064220186
                },
                {
                  name: 'Hqr150Msy',
                  value: 16,
                  code: 'Mazak,Turning,Hqr,Hqr150Msy',
                  absolute_sum: 16,
                  relative_sum: 7.339449541284404
                },
                {
                  name: 'Hqr 200 Msy',
                  value: 83,
                  code: 'Mazak,Turning,Hqr,Hqr 200 Msy',
                  absolute_sum: 83,
                  relative_sum: 38.07339449541284
                },
                {
                  name: 'Hqr 250Msy',
                  value: 82,
                  code: 'Mazak,Turning,Hqr,Hqr 250Msy',
                  absolute_sum: 82,
                  relative_sum: 37.61467889908257
                }
              ],
              code: 'Mazak,Turning,Hqr',
              absolute_sum: 218,
              relative_sum: 0.8649077563975401
            },
            {
              name: 'Nexus',
              children: [
                {
                  name: 'Quick Turn Nexus 100-Ii Ms',
                  value: 3,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 100-Ii Ms',
                  absolute_sum: 3,
                  relative_sum: 0.14457831325301204
                },
                {
                  name: 'Quick Turn Nexus 100-Ii',
                  value: 51,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 100-Ii',
                  absolute_sum: 51,
                  relative_sum: 2.457831325301205
                },
                {
                  name: 'Quick Turn Nexus 100 Ii Msy',
                  value: 38,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 100 Ii Msy',
                  absolute_sum: 38,
                  relative_sum: 1.8313253012048194
                },
                {
                  name: 'Quick Turn Nexus 100',
                  value: 55,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 100',
                  absolute_sum: 55,
                  relative_sum: 2.6506024096385543
                },
                {
                  name: 'Quick Turn Nexus 200 M',
                  value: 22,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 200 M',
                  absolute_sum: 22,
                  relative_sum: 1.0602409638554215
                },
                {
                  name: 'Quick Turn Nexus 250-Ii M-1000U',
                  value: 9,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 250-Ii M-1000U',
                  absolute_sum: 9,
                  relative_sum: 0.43373493975903615
                },
                {
                  name: 'Quick Turn Nexus 400-Ii-1000U',
                  value: 7,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 400-Ii-1000U',
                  absolute_sum: 7,
                  relative_sum: 0.3373493975903614
                },
                {
                  name: 'Quick Turn Nexus 400M-1000U',
                  value: 26,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 400M-1000U',
                  absolute_sum: 26,
                  relative_sum: 1.253012048192771
                },
                {
                  name: 'Quick Turn Nexus 300-Ii M-1200U',
                  value: 11,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 300-Ii M-1200U',
                  absolute_sum: 11,
                  relative_sum: 0.5301204819277108
                },
                {
                  name: 'Quick Turn Nexus 300-Ii-1200U',
                  value: 7,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 300-Ii-1200U',
                  absolute_sum: 7,
                  relative_sum: 0.3373493975903614
                },
                {
                  name: 'Quick Turn Nexus 350-Ii My-1500U',
                  value: 9,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 350-Ii My-1500U',
                  absolute_sum: 9,
                  relative_sum: 0.43373493975903615
                },
                {
                  name: 'Megaturn Nexus 1600M',
                  value: 31,
                  code: 'Mazak,Turning,Nexus,Megaturn Nexus 1600M',
                  absolute_sum: 31,
                  relative_sum: 1.493975903614458
                },
                {
                  name: 'Quick Turn Nexus 250My',
                  value: 90,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 250My',
                  absolute_sum: 90,
                  relative_sum: 4.337349397590361
                },
                {
                  name: 'Quick Turn Nexus 200-Ii My',
                  value: 20,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 200-Ii My',
                  absolute_sum: 20,
                  relative_sum: 0.9638554216867471
                },
                {
                  name: 'Quick Turn Nexus 200-Ii',
                  value: 32,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 200-Ii',
                  absolute_sum: 32,
                  relative_sum: 1.542168674698795
                },
                {
                  name: 'Quick Turn Nexus 200-Ii My-60',
                  value: 8,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 200-Ii My-60',
                  absolute_sum: 8,
                  relative_sum: 0.38554216867469876
                },
                {
                  name: 'Quick Turn Nexus 200Ms',
                  value: 57,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 200Ms',
                  absolute_sum: 57,
                  relative_sum: 2.746987951807229
                },
                {
                  name: 'Quick Turn Nexus 200 Ii Msy',
                  value: 55,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 200 Ii Msy',
                  absolute_sum: 55,
                  relative_sum: 2.6506024096385543
                },
                {
                  name: 'Nexus 200',
                  value: 244,
                  code: 'Mazak,Turning,Nexus,Nexus 200',
                  absolute_sum: 244,
                  relative_sum: 11.759036144578314
                },
                {
                  name: 'Nexus Qtn 450Iimy/2000',
                  value: 16,
                  code: 'Mazak,Turning,Nexus,Nexus Qtn 450Iimy/2000',
                  absolute_sum: 16,
                  relative_sum: 0.7710843373493975
                },
                {
                  name: 'Slant Turn Nexus 550-2000U',
                  value: 1,
                  code: 'Mazak,Turning,Nexus,Slant Turn Nexus 550-2000U',
                  absolute_sum: 1,
                  relative_sum: 0.048192771084337345
                },
                {
                  name: 'Quick Turn Nexus 250-Ii Msy',
                  value: 177,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 250-Ii Msy',
                  absolute_sum: 177,
                  relative_sum: 8.53012048192771
                },
                {
                  name: 'Quick Turn Nexus 250-Ii',
                  value: 108,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 250-Ii',
                  absolute_sum: 108,
                  relative_sum: 5.204819277108434
                },
                {
                  name: 'Nexus 250M',
                  value: 169,
                  code: 'Mazak,Turning,Nexus,Nexus 250M',
                  absolute_sum: 169,
                  relative_sum: 8.144578313253012
                },
                {
                  name: 'Nexus 250',
                  value: 77,
                  code: 'Mazak,Turning,Nexus,Nexus 250',
                  absolute_sum: 77,
                  relative_sum: 3.710843373493976
                },
                {
                  name: 'Quick Turn Nexus 250',
                  value: 33,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 250',
                  absolute_sum: 33,
                  relative_sum: 1.5903614457831325
                },
                {
                  name: 'Quick Turn Nexus 200',
                  value: 68,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 200',
                  absolute_sum: 68,
                  relative_sum: 3.2771084337349397
                },
                {
                  name: 'Quick Turn Nexus 450-Ii',
                  value: 150,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 450-Ii',
                  absolute_sum: 150,
                  relative_sum: 7.228915662650602
                },
                {
                  name: 'Quick Turn Nexus 350-Ii My',
                  value: 73,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 350-Ii My',
                  absolute_sum: 73,
                  relative_sum: 3.5180722891566263
                },
                {
                  name: 'Quick Turn Nexus 350-Ii',
                  value: 131,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 350-Ii',
                  absolute_sum: 131,
                  relative_sum: 6.313253012048192
                },
                {
                  name: 'Nexus 350 My',
                  value: 102,
                  code: 'Mazak,Turning,Nexus,Nexus 350 My',
                  absolute_sum: 102,
                  relative_sum: 4.91566265060241
                },
                {
                  name: 'Quick Turn Nexus 350',
                  value: 83,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 350',
                  absolute_sum: 83,
                  relative_sum: 4
                },
                {
                  name: 'Slant Turn Nexus 600M-4000U',
                  value: 5,
                  code: 'Mazak,Turning,Nexus,Slant Turn Nexus 600M-4000U',
                  absolute_sum: 5,
                  relative_sum: 0.24096385542168677
                },
                {
                  name: 'Quick Turn Nexus 500My',
                  value: 12,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus 500My',
                  absolute_sum: 12,
                  relative_sum: 0.5783132530120482
                },
                {
                  name: 'Slant Turn Nexus 550',
                  value: 32,
                  code: 'Mazak,Turning,Nexus,Slant Turn Nexus 550',
                  absolute_sum: 32,
                  relative_sum: 1.542168674698795
                },
                {
                  name: 'Megaturn Nexus 900',
                  value: 57,
                  code: 'Mazak,Turning,Nexus,Megaturn Nexus 900',
                  absolute_sum: 57,
                  relative_sum: 2.746987951807229
                },
                {
                  name: 'Quick Turn Nexus',
                  value: 6,
                  code: 'Mazak,Turning,Nexus,Quick Turn Nexus',
                  absolute_sum: 6,
                  relative_sum: 0.2891566265060241
                }
              ],
              code: 'Mazak,Turning,Nexus',
              absolute_sum: 2075,
              relative_sum: 8.232493552866496
            },
            {
              name: 'Iv',
              children: [
                {
                  name: 'Integrex 200-Iv',
                  value: 63,
                  code: 'Mazak,Turning,Iv,Integrex 200-Iv',
                  absolute_sum: 63,
                  relative_sum: 19.32515337423313
                },
                {
                  name: 'Quick Turn Universal 200My-12',
                  value: 4,
                  code: 'Mazak,Turning,Iv,Quick Turn Universal 200My-12',
                  absolute_sum: 4,
                  relative_sum: 1.2269938650306749
                },
                {
                  name: 'Integrex I-200 St Universal 1500',
                  value: 14,
                  code: 'Mazak,Turning,Iv,Integrex I-200 St Universal 1500',
                  absolute_sum: 14,
                  relative_sum: 4.294478527607362
                },
                {
                  name: 'Quick Turn Universal 200My-20',
                  value: 33,
                  code: 'Mazak,Turning,Iv,Quick Turn Universal 200My-20',
                  absolute_sum: 33,
                  relative_sum: 10.122699386503067
                },
                {
                  name: 'Quick Turn Universal 200-20',
                  value: 4,
                  code: 'Mazak,Turning,Iv,Quick Turn Universal 200-20',
                  absolute_sum: 4,
                  relative_sum: 1.2269938650306749
                },
                {
                  name: 'Quick Turn Universal 250M-20',
                  value: 8,
                  code: 'Mazak,Turning,Iv,Quick Turn Universal 250M-20',
                  absolute_sum: 8,
                  relative_sum: 2.4539877300613497
                },
                {
                  name: 'Quick Turn Universal 250-20',
                  value: 47,
                  code: 'Mazak,Turning,Iv,Quick Turn Universal 250-20',
                  absolute_sum: 47,
                  relative_sum: 14.417177914110429
                },
                {
                  name: 'Quick Turn 250 Hp-Univ',
                  value: 27,
                  code: 'Mazak,Turning,Iv,Quick Turn 250 Hp-Univ',
                  absolute_sum: 27,
                  relative_sum: 8.282208588957054
                },
                {
                  name: 'Quick Turn Universal 250Msy',
                  value: 25,
                  code: 'Mazak,Turning,Iv,Quick Turn Universal 250Msy',
                  absolute_sum: 25,
                  relative_sum: 7.668711656441718
                },
                {
                  name: 'Ivs-300M',
                  value: 4,
                  code: 'Mazak,Turning,Iv,Ivs-300M',
                  absolute_sum: 4,
                  relative_sum: 1.2269938650306749
                },
                {
                  name: 'Power Master N Universal-3000U',
                  value: 6,
                  code: 'Mazak,Turning,Iv,Power Master N Universal-3000U',
                  absolute_sum: 6,
                  relative_sum: 1.8404907975460123
                },
                {
                  name: 'Integrex 400-Iv',
                  value: 26,
                  code: 'Mazak,Turning,Iv,Integrex 400-Iv',
                  absolute_sum: 26,
                  relative_sum: 7.975460122699387
                },
                {
                  name: 'Ivs-400 Ii',
                  value: 26,
                  code: 'Mazak,Turning,Iv,Ivs-400 Ii',
                  absolute_sum: 26,
                  relative_sum: 7.975460122699387
                },
                {
                  name: 'Qt-8-U Iv',
                  value: 24,
                  code: 'Mazak,Turning,Iv,Qt-8-U Iv',
                  absolute_sum: 24,
                  relative_sum: 7.361963190184049
                },
                {
                  name: 'Ivs Series',
                  value: 15,
                  code: 'Mazak,Turning,Iv,Ivs Series',
                  absolute_sum: 15,
                  relative_sum: 4.601226993865031
                }
              ],
              code: 'Mazak,Turning,Iv',
              absolute_sum: 326,
              relative_sum: 1.2933941678238445
            },
            {
              name: 'Sg',
              children: [
                {
                  name: 'Qte-100M Sg',
                  value: 7,
                  code: 'Mazak,Turning,Sg,Qte-100M Sg',
                  absolute_sum: 7,
                  relative_sum: 50
                },
                {
                  name: 'Qt-Primos 150 Sg',
                  value: 7,
                  code: 'Mazak,Turning,Sg,Qt-Primos 150 Sg',
                  absolute_sum: 7,
                  relative_sum: 50
                }
              ],
              code: 'Mazak,Turning,Sg',
              absolute_sum: 14,
              relative_sum: 0.05554453481452093
            },
            {
              name: 'Smart',
              children: [
                {
                  name: 'Quick Turn Smart 100M S',
                  value: 1,
                  code: 'Mazak,Turning,Smart,Quick Turn Smart 100M S',
                  absolute_sum: 1,
                  relative_sum: 0.273224043715847
                },
                {
                  name: 'Quick Turn Smart 100S',
                  value: 22,
                  code: 'Mazak,Turning,Smart,Quick Turn Smart 100S',
                  absolute_sum: 22,
                  relative_sum: 6.0109289617486334
                },
                {
                  name: 'Quick Turn Smart 100',
                  value: 13,
                  code: 'Mazak,Turning,Smart,Quick Turn Smart 100',
                  absolute_sum: 13,
                  relative_sum: 3.551912568306011
                },
                {
                  name: 'Quick Turn Smart 200M-1000U',
                  value: 4,
                  code: 'Mazak,Turning,Smart,Quick Turn Smart 200M-1000U',
                  absolute_sum: 4,
                  relative_sum: 1.092896174863388
                },
                {
                  name: 'Quick Turn Smart 250-1000U',
                  value: 8,
                  code: 'Mazak,Turning,Smart,Quick Turn Smart 250-1000U',
                  absolute_sum: 8,
                  relative_sum: 2.185792349726776
                },
                {
                  name: 'Quick Turn Smart 150 S',
                  value: 4,
                  code: 'Mazak,Turning,Smart,Quick Turn Smart 150 S',
                  absolute_sum: 4,
                  relative_sum: 1.092896174863388
                },
                {
                  name: 'Quick Turn Smart 200-500U',
                  value: 4,
                  code: 'Mazak,Turning,Smart,Quick Turn Smart 200-500U',
                  absolute_sum: 4,
                  relative_sum: 1.092896174863388
                },
                {
                  name: 'Quick Turn Smart 200',
                  value: 61,
                  code: 'Mazak,Turning,Smart,Quick Turn Smart 200',
                  absolute_sum: 61,
                  relative_sum: 16.666666666666664
                },
                {
                  name: 'Quick Turn Smart 250M',
                  value: 153,
                  code: 'Mazak,Turning,Smart,Quick Turn Smart 250M',
                  absolute_sum: 153,
                  relative_sum: 41.80327868852459
                },
                {
                  name: 'Quick Turn Smart 300M',
                  value: 7,
                  code: 'Mazak,Turning,Smart,Quick Turn Smart 300M',
                  absolute_sum: 7,
                  relative_sum: 1.912568306010929
                },
                {
                  name: 'Quick Turn Smart 300',
                  value: 27,
                  code: 'Mazak,Turning,Smart,Quick Turn Smart 300',
                  absolute_sum: 27,
                  relative_sum: 7.377049180327869
                },
                {
                  name: 'Quick Turn Smart 350',
                  value: 54,
                  code: 'Mazak,Turning,Smart,Quick Turn Smart 350',
                  absolute_sum: 54,
                  relative_sum: 14.754098360655737
                },
                {
                  name: 'Quick Turn Smart',
                  value: 8,
                  code: 'Mazak,Turning,Smart,Quick Turn Smart',
                  absolute_sum: 8,
                  relative_sum: 2.185792349726776
                }
              ],
              code: 'Mazak,Turning,Smart',
              absolute_sum: 366,
              relative_sum: 1.4520928387224756
            },
            {
              name: 'Sqt',
              children: [
                {
                  name: 'Sqt 100Ms',
                  value: 24,
                  code: 'Mazak,Turning,Sqt,Sqt 100Ms',
                  absolute_sum: 24,
                  relative_sum: 7.973421926910299
                },
                {
                  name: 'Sqt 15Ms',
                  value: 179,
                  code: 'Mazak,Turning,Sqt,Sqt 15Ms',
                  absolute_sum: 179,
                  relative_sum: 59.46843853820598
                },
                {
                  name: 'Sqt 200',
                  value: 65,
                  code: 'Mazak,Turning,Sqt,Sqt 200',
                  absolute_sum: 65,
                  relative_sum: 21.59468438538206
                },
                {
                  name: 'Sqt 250',
                  value: 33,
                  code: 'Mazak,Turning,Sqt,Sqt 250',
                  absolute_sum: 33,
                  relative_sum: 10.96345514950166
                }
              ],
              code: 'Mazak,Turning,Sqt',
              absolute_sum: 301,
              relative_sum: 1.1942074985122
            },
            {
              name: 'My',
              children: [
                {
                  name: 'Quick Turn 100My',
                  value: 6,
                  code: 'Mazak,Turning,My,Quick Turn 100My',
                  absolute_sum: 6,
                  relative_sum: 0.5249343832020997
                },
                {
                  name: 'Quick Turn Universal 200My-12',
                  value: 4,
                  code: 'Mazak,Turning,My,Quick Turn Universal 200My-12',
                  absolute_sum: 4,
                  relative_sum: 0.3499562554680665
                },
                {
                  name: 'Quick Turn Nexus 350-Ii My-1500U',
                  value: 9,
                  code: 'Mazak,Turning,My,Quick Turn Nexus 350-Ii My-1500U',
                  absolute_sum: 9,
                  relative_sum: 0.7874015748031495
                },
                {
                  name: 'Quick Turn Universal 200My-20',
                  value: 33,
                  code: 'Mazak,Turning,My,Quick Turn Universal 200My-20',
                  absolute_sum: 33,
                  relative_sum: 2.8871391076115485
                },
                {
                  name: 'Quick Turn Nexus 250My',
                  value: 90,
                  code: 'Mazak,Turning,My,Quick Turn Nexus 250My',
                  absolute_sum: 90,
                  relative_sum: 7.874015748031496
                },
                {
                  name: 'Quick Turn Nexus 200-Ii My',
                  value: 20,
                  code: 'Mazak,Turning,My,Quick Turn Nexus 200-Ii My',
                  absolute_sum: 20,
                  relative_sum: 1.7497812773403325
                },
                {
                  name: 'Quick Turn Nexus 200-Ii My-60',
                  value: 8,
                  code: 'Mazak,Turning,My,Quick Turn Nexus 200-Ii My-60',
                  absolute_sum: 8,
                  relative_sum: 0.699912510936133
                },
                {
                  name: 'Qtu-200My',
                  value: 37,
                  code: 'Mazak,Turning,My,Qtu-200My',
                  absolute_sum: 37,
                  relative_sum: 3.2370953630796153
                },
                {
                  name: 'Quick Turn 200My',
                  value: 12,
                  code: 'Mazak,Turning,My,Quick Turn 200My',
                  absolute_sum: 12,
                  relative_sum: 1.0498687664041995
                },
                {
                  name: 'Nexus Qtn 450Iimy/2000',
                  value: 16,
                  code: 'Mazak,Turning,My,Nexus Qtn 450Iimy/2000',
                  absolute_sum: 16,
                  relative_sum: 1.399825021872266
                },
                {
                  name: 'Qt250My',
                  value: 76,
                  code: 'Mazak,Turning,My,Qt250My',
                  absolute_sum: 76,
                  relative_sum: 6.649168853893263
                },
                {
                  name: 'Qtu 250My',
                  value: 93,
                  code: 'Mazak,Turning,My,Qtu 250My',
                  absolute_sum: 93,
                  relative_sum: 8.136482939632545
                },
                {
                  name: 'Quick Turn 250My',
                  value: 181,
                  code: 'Mazak,Turning,My,Quick Turn 250My',
                  absolute_sum: 181,
                  relative_sum: 15.835520559930009
                },
                {
                  name: 'Qt-Compact 300 Ml',
                  value: 40,
                  code: 'Mazak,Turning,My,Qt-Compact 300 Ml',
                  absolute_sum: 40,
                  relative_sum: 3.499562554680665
                },
                {
                  name: 'Quick Turn 300My',
                  value: 13,
                  code: 'Mazak,Turning,My,Quick Turn 300My',
                  absolute_sum: 13,
                  relative_sum: 1.1373578302712162
                },
                {
                  name: 'Quick Turn Nexus 350-Ii My',
                  value: 73,
                  code: 'Mazak,Turning,My,Quick Turn Nexus 350-Ii My',
                  absolute_sum: 73,
                  relative_sum: 6.386701662292213
                },
                {
                  name: 'Qtn 350 My',
                  value: 235,
                  code: 'Mazak,Turning,My,Qtn 350 My',
                  absolute_sum: 235,
                  relative_sum: 20.559930008748907
                },
                {
                  name: 'Nexus 350 My',
                  value: 102,
                  code: 'Mazak,Turning,My,Nexus 350 My',
                  absolute_sum: 102,
                  relative_sum: 8.923884514435695
                },
                {
                  name: 'Quick Turn 350My',
                  value: 38,
                  code: 'Mazak,Turning,My,Quick Turn 350My',
                  absolute_sum: 38,
                  relative_sum: 3.3245844269466316
                },
                {
                  name: 'Hyper Quadrex 450My',
                  value: 45,
                  code: 'Mazak,Turning,My,Hyper Quadrex 450My',
                  absolute_sum: 45,
                  relative_sum: 3.937007874015748
                },
                {
                  name: 'Quick Turn Nexus 500My',
                  value: 12,
                  code: 'Mazak,Turning,My,Quick Turn Nexus 500My',
                  absolute_sum: 12,
                  relative_sum: 1.0498687664041995
                }
              ],
              code: 'Mazak,Turning,My',
              absolute_sum: 1143,
              relative_sum: 4.534814520928387
            },
            {
              name: 'Primos',
              children: [
                {
                  name: 'Qt-Primos 100',
                  value: 69,
                  code: 'Mazak,Turning,Primos,Qt-Primos 100',
                  absolute_sum: 69,
                  relative_sum: 82.14285714285714
                },
                {
                  name: 'Quick Turn Primos 100',
                  value: 8,
                  code: 'Mazak,Turning,Primos,Quick Turn Primos 100',
                  absolute_sum: 8,
                  relative_sum: 9.523809523809524
                },
                {
                  name: 'Qt-Primos 150 Sg',
                  value: 7,
                  code: 'Mazak,Turning,Primos,Qt-Primos 150 Sg',
                  absolute_sum: 7,
                  relative_sum: 8.333333333333332
                }
              ],
              code: 'Mazak,Turning,Primos',
              absolute_sum: 84,
              relative_sum: 0.3332672088871256
            },
            {
              name: 'Qtn',
              children: [
                {
                  name: 'Qtn 100',
                  value: 64,
                  code: 'Mazak,Turning,Qtn,Qtn 100',
                  absolute_sum: 64,
                  relative_sum: 9.711684370257966
                },
                {
                  name: 'Qtn-250 2Ky',
                  value: 14,
                  code: 'Mazak,Turning,Qtn,Qtn-250 2Ky',
                  absolute_sum: 14,
                  relative_sum: 2.12443095599393
                },
                {
                  name: 'Qtn-200M',
                  value: 70,
                  code: 'Mazak,Turning,Qtn,Qtn-200M',
                  absolute_sum: 70,
                  relative_sum: 10.62215477996965
                },
                {
                  name: 'Qtn 200',
                  value: 56,
                  code: 'Mazak,Turning,Qtn,Qtn 200',
                  absolute_sum: 56,
                  relative_sum: 8.49772382397572
                },
                {
                  name: 'Nexus Qtn 450Iimy/2000',
                  value: 16,
                  code: 'Mazak,Turning,Qtn,Nexus Qtn 450Iimy/2000',
                  absolute_sum: 16,
                  relative_sum: 2.4279210925644916
                },
                {
                  name: 'Qtn 250 Hp',
                  value: 62,
                  code: 'Mazak,Turning,Qtn,Qtn 250 Hp',
                  absolute_sum: 62,
                  relative_sum: 9.408194233687405
                },
                {
                  name: 'Qtn 250-Ii',
                  value: 75,
                  code: 'Mazak,Turning,Qtn,Qtn 250-Ii',
                  absolute_sum: 75,
                  relative_sum: 11.380880121396055
                },
                {
                  name: 'Qtn 250',
                  value: 67,
                  code: 'Mazak,Turning,Qtn,Qtn 250',
                  absolute_sum: 67,
                  relative_sum: 10.166919575113809
                },
                {
                  name: 'Qtn 350 My',
                  value: 235,
                  code: 'Mazak,Turning,Qtn,Qtn 350 My',
                  absolute_sum: 235,
                  relative_sum: 35.66009104704097
                }
              ],
              code: 'Mazak,Turning,Qtn',
              absolute_sum: 659,
              relative_sum: 2.6145606030549495
            },
            {
              name: 'Universal',
              children: [
                {
                  name: 'Quick Turn Universal 200My-12',
                  value: 4,
                  code: 'Mazak,Turning,Universal,Quick Turn Universal 200My-12',
                  absolute_sum: 4,
                  relative_sum: 2.8368794326241136
                },
                {
                  name: 'Integrex I-200 St Universal 1500',
                  value: 14,
                  code: 'Mazak,Turning,Universal,Integrex I-200 St Universal 1500',
                  absolute_sum: 14,
                  relative_sum: 9.929078014184398
                },
                {
                  name: 'Quick Turn Universal 200My-20',
                  value: 33,
                  code: 'Mazak,Turning,Universal,Quick Turn Universal 200My-20',
                  absolute_sum: 33,
                  relative_sum: 23.404255319148938
                },
                {
                  name: 'Quick Turn Universal 200-20',
                  value: 4,
                  code: 'Mazak,Turning,Universal,Quick Turn Universal 200-20',
                  absolute_sum: 4,
                  relative_sum: 2.8368794326241136
                },
                {
                  name: 'Quick Turn Universal 250M-20',
                  value: 8,
                  code: 'Mazak,Turning,Universal,Quick Turn Universal 250M-20',
                  absolute_sum: 8,
                  relative_sum: 5.673758865248227
                },
                {
                  name: 'Quick Turn Universal 250-20',
                  value: 47,
                  code: 'Mazak,Turning,Universal,Quick Turn Universal 250-20',
                  absolute_sum: 47,
                  relative_sum: 33.33333333333333
                },
                {
                  name: 'Quick Turn Universal 250Msy',
                  value: 25,
                  code: 'Mazak,Turning,Universal,Quick Turn Universal 250Msy',
                  absolute_sum: 25,
                  relative_sum: 17.73049645390071
                },
                {
                  name: 'Power Master N Universal-3000U',
                  value: 6,
                  code: 'Mazak,Turning,Universal,Power Master N Universal-3000U',
                  absolute_sum: 6,
                  relative_sum: 4.25531914893617
                }
              ],
              code: 'Mazak,Turning,Universal',
              absolute_sum: 141,
              relative_sum: 0.559412814917675
            },
            {
              name: 'Turning',
              children: [
                {
                  name: 'Turning Center M-5N-120',
                  value: 28,
                  code: 'Mazak,Turning,Turning,Turning Center M-5N-120',
                  absolute_sum: 28,
                  relative_sum: 3.932584269662921
                },
                {
                  name: 'Qt 250 Turning Center',
                  value: 332,
                  code: 'Mazak,Turning,Turning,Qt 250 Turning Center',
                  absolute_sum: 332,
                  relative_sum: 46.62921348314607
                },
                {
                  name: 'Turning Center M-5',
                  value: 46,
                  code: 'Mazak,Turning,Turning,Turning Center M-5',
                  absolute_sum: 46,
                  relative_sum: 6.460674157303371
                },
                {
                  name: 'Cnc Turning Center',
                  value: 306,
                  code: 'Mazak,Turning,Turning,Cnc Turning Center',
                  absolute_sum: 306,
                  relative_sum: 42.97752808988764
                }
              ],
              code: 'Mazak,Turning,Turning',
              absolute_sum: 712,
              relative_sum: 2.8248363419956357
            },
            {
              name: 'Hyper',
              children: [
                {
                  name: 'Hyper Quadrex 150 Msy',
                  value: 40,
                  code: 'Mazak,Turning,Hyper,Hyper Quadrex 150 Msy',
                  absolute_sum: 40,
                  relative_sum: 35.714285714285715
                },
                {
                  name: 'Hyper Quadrex 450M',
                  value: 27,
                  code: 'Mazak,Turning,Hyper,Hyper Quadrex 450M',
                  absolute_sum: 27,
                  relative_sum: 24.107142857142858
                },
                {
                  name: 'Hyper Quadrex 450My',
                  value: 45,
                  code: 'Mazak,Turning,Hyper,Hyper Quadrex 450My',
                  absolute_sum: 45,
                  relative_sum: 40.17857142857143
                }
              ],
              code: 'Mazak,Turning,Hyper',
              absolute_sum: 112,
              relative_sum: 0.44435627851616744
            },
            {
              name: 'Quadrex',
              children: [
                {
                  name: 'Hyper Quadrex 150 Msy',
                  value: 40,
                  code: 'Mazak,Turning,Quadrex,Hyper Quadrex 150 Msy',
                  absolute_sum: 40,
                  relative_sum: 32.78688524590164
                },
                {
                  name: 'Super Quadrex 250M',
                  value: 10,
                  code: 'Mazak,Turning,Quadrex,Super Quadrex 250M',
                  absolute_sum: 10,
                  relative_sum: 8.19672131147541
                },
                {
                  name: 'Hyper Quadrex 450M',
                  value: 27,
                  code: 'Mazak,Turning,Quadrex,Hyper Quadrex 450M',
                  absolute_sum: 27,
                  relative_sum: 22.131147540983605
                },
                {
                  name: 'Hyper Quadrex 450My',
                  value: 45,
                  code: 'Mazak,Turning,Quadrex,Hyper Quadrex 450My',
                  absolute_sum: 45,
                  relative_sum: 36.885245901639344
                }
              ],
              code: 'Mazak,Turning,Quadrex',
              absolute_sum: 122,
              relative_sum: 0.48403094624082527
            },
            {
              name: 'Mega',
              children: [
                {
                  name: 'Megaturn Nexus 1600M',
                  value: 31,
                  code: 'Mazak,Turning,Mega,Megaturn Nexus 1600M',
                  absolute_sum: 31,
                  relative_sum: 25.833333333333336
                },
                {
                  name: 'Mega Turn 500M',
                  value: 12,
                  code: 'Mazak,Turning,Mega,Mega Turn 500M',
                  absolute_sum: 12,
                  relative_sum: 10
                },
                {
                  name: 'Mega Turn 600S',
                  value: 20,
                  code: 'Mazak,Turning,Mega,Mega Turn 600S',
                  absolute_sum: 20,
                  relative_sum: 16.666666666666664
                },
                {
                  name: 'Megaturn Nexus 900',
                  value: 57,
                  code: 'Mazak,Turning,Mega,Megaturn Nexus 900',
                  absolute_sum: 57,
                  relative_sum: 47.5
                }
              ],
              code: 'Mazak,Turning,Mega',
              absolute_sum: 120,
              relative_sum: 0.4760960126958937
            },
            {
              name: 'Megaturn',
              children: [
                {
                  name: 'Megaturn Nexus 1600M',
                  value: 31,
                  code: 'Mazak,Turning,Megaturn,Megaturn Nexus 1600M',
                  absolute_sum: 31,
                  relative_sum: 35.22727272727273
                },
                {
                  name: 'Megaturn Nexus 900',
                  value: 57,
                  code: 'Mazak,Turning,Megaturn,Megaturn Nexus 900',
                  absolute_sum: 57,
                  relative_sum: 64.77272727272727
                }
              ],
              code: 'Mazak,Turning,Megaturn',
              absolute_sum: 88,
              relative_sum: 0.3491370759769887
            },
            {
              name: 'Super',
              children: [
                {
                  name: 'Super Quick Turn 15M Mark Ii',
                  value: 45,
                  code: 'Mazak,Turning,Super,Super Quick Turn 15M Mark Ii',
                  absolute_sum: 45,
                  relative_sum: 17.441860465116278
                },
                {
                  name: 'Super Quick Turn 200',
                  value: 27,
                  code: 'Mazak,Turning,Super,Super Quick Turn 200',
                  absolute_sum: 27,
                  relative_sum: 10.465116279069768
                },
                {
                  name: 'Super Quadrex 250M',
                  value: 10,
                  code: 'Mazak,Turning,Super,Super Quadrex 250M',
                  absolute_sum: 10,
                  relative_sum: 3.875968992248062
                },
                {
                  name: 'Super Quick Turn 250',
                  value: 71,
                  code: 'Mazak,Turning,Super,Super Quick Turn 250',
                  absolute_sum: 71,
                  relative_sum: 27.51937984496124
                },
                {
                  name: 'Super Quick Turn 10M',
                  value: 105,
                  code: 'Mazak,Turning,Super,Super Quick Turn 10M',
                  absolute_sum: 105,
                  relative_sum: 40.69767441860465
                }
              ],
              code: 'Mazak,Turning,Super',
              absolute_sum: 258,
              relative_sum: 1.0236064272961714
            },
            {
              name: 'Compact',
              children: [
                {
                  name: 'Qt-Compact 200M L',
                  value: 17,
                  code: 'Mazak,Turning,Compact,Qt-Compact 200M L',
                  absolute_sum: 17,
                  relative_sum: 5.345911949685535
                },
                {
                  name: 'Qt-Compact 200Msy L',
                  value: 25,
                  code: 'Mazak,Turning,Compact,Qt-Compact 200Msy L',
                  absolute_sum: 25,
                  relative_sum: 7.861635220125786
                },
                {
                  name: 'Qt-Compact 300Ms L',
                  value: 11,
                  code: 'Mazak,Turning,Compact,Qt-Compact 300Ms L',
                  absolute_sum: 11,
                  relative_sum: 3.459119496855346
                },
                {
                  name: 'Qt-Compact 300 Ml',
                  value: 40,
                  code: 'Mazak,Turning,Compact,Qt-Compact 300 Ml',
                  absolute_sum: 40,
                  relative_sum: 12.578616352201259
                },
                {
                  name: 'Qt-Compact',
                  value: 225,
                  code: 'Mazak,Turning,Compact,Qt-Compact',
                  absolute_sum: 225,
                  relative_sum: 70.75471698113208
                }
              ],
              code: 'Mazak,Turning,Compact',
              absolute_sum: 318,
              relative_sum: 1.2616544336441182
            },
            {
              name: 'Multiplex',
              children: [
                {
                  name: 'Multiplex W200',
                  value: 10,
                  code: 'Mazak,Turning,Multiplex,Multiplex W200',
                  absolute_sum: 10,
                  relative_sum: 2.865329512893983
                },
                {
                  name: 'Multiplex W-200Y',
                  value: 37,
                  code: 'Mazak,Turning,Multiplex,Multiplex W-200Y',
                  absolute_sum: 37,
                  relative_sum: 10.601719197707736
                },
                {
                  name: 'Multiplex W-300',
                  value: 3,
                  code: 'Mazak,Turning,Multiplex,Multiplex W-300',
                  absolute_sum: 3,
                  relative_sum: 0.8595988538681949
                },
                {
                  name: 'Multiplex 6100Y',
                  value: 24,
                  code: 'Mazak,Turning,Multiplex,Multiplex 6100Y',
                  absolute_sum: 24,
                  relative_sum: 6.876790830945559
                },
                {
                  name: 'Multiplex 620 Mark Ii',
                  value: 53,
                  code: 'Mazak,Turning,Multiplex,Multiplex 620 Mark Ii',
                  absolute_sum: 53,
                  relative_sum: 15.18624641833811
                },
                {
                  name: 'Multiplex 6200 Gantry',
                  value: 22,
                  code: 'Mazak,Turning,Multiplex,Multiplex 6200 Gantry',
                  absolute_sum: 22,
                  relative_sum: 6.303724928366762
                },
                {
                  name: 'Multiplex 6200',
                  value: 12,
                  code: 'Mazak,Turning,Multiplex,Multiplex 6200',
                  absolute_sum: 12,
                  relative_sum: 3.4383954154727796
                },
                {
                  name: 'Multiplex 6200Y',
                  value: 128,
                  code: 'Mazak,Turning,Multiplex,Multiplex 6200Y',
                  absolute_sum: 128,
                  relative_sum: 36.67621776504298
                },
                {
                  name: 'Multiplex 6300',
                  value: 60,
                  code: 'Mazak,Turning,Multiplex,Multiplex 6300',
                  absolute_sum: 60,
                  relative_sum: 17.191977077363894
                }
              ],
              code: 'Mazak,Turning,Multiplex',
              absolute_sum: 349,
              relative_sum: 1.3846459035905574
            },
            {
              name: 'Qts',
              children: [
                {
                  name: 'Qts 200',
                  value: 39,
                  code: 'Mazak,Turning,Qts,Qts 200',
                  absolute_sum: 39,
                  relative_sum: 21.08108108108108
                },
                {
                  name: 'Qts 250',
                  value: 98,
                  code: 'Mazak,Turning,Qts,Qts 250',
                  absolute_sum: 98,
                  relative_sum: 52.972972972972975
                },
                {
                  name: 'Qts 300',
                  value: 48,
                  code: 'Mazak,Turning,Qts,Qts 300',
                  absolute_sum: 48,
                  relative_sum: 25.945945945945947
                }
              ],
              code: 'Mazak,Turning,Qts',
              absolute_sum: 185,
              relative_sum: 0.7339813529061694
            },
            {
              name: 'Slant',
              children: [
                {
                  name: 'Slant Turn 50N',
                  value: 55,
                  code: 'Mazak,Turning,Slant,Slant Turn 50N',
                  absolute_sum: 55,
                  relative_sum: 12.971698113207546
                },
                {
                  name: 'Slant Turn 50Nx-2000U',
                  value: 7,
                  code: 'Mazak,Turning,Slant,Slant Turn 50Nx-2000U',
                  absolute_sum: 7,
                  relative_sum: 1.650943396226415
                },
                {
                  name: 'Slant Turn Nexus 550-2000U',
                  value: 1,
                  code: 'Mazak,Turning,Slant,Slant Turn Nexus 550-2000U',
                  absolute_sum: 1,
                  relative_sum: 0.2358490566037736
                },
                {
                  name: 'Slant Turn 550M-3000U',
                  value: 12,
                  code: 'Mazak,Turning,Slant,Slant Turn 550M-3000U',
                  absolute_sum: 12,
                  relative_sum: 2.8301886792452833
                },
                {
                  name: 'Slant Turn Nexus 600M-4000U',
                  value: 5,
                  code: 'Mazak,Turning,Slant,Slant Turn Nexus 600M-4000U',
                  absolute_sum: 5,
                  relative_sum: 1.179245283018868
                },
                {
                  name: 'Slant Turn 50',
                  value: 60,
                  code: 'Mazak,Turning,Slant,Slant Turn 50',
                  absolute_sum: 60,
                  relative_sum: 14.150943396226415
                },
                {
                  name: 'Slant Turn 500M',
                  value: 53,
                  code: 'Mazak,Turning,Slant,Slant Turn 500M',
                  absolute_sum: 53,
                  relative_sum: 12.5
                },
                {
                  name: 'Slant Turn 550M',
                  value: 25,
                  code: 'Mazak,Turning,Slant,Slant Turn 550M',
                  absolute_sum: 25,
                  relative_sum: 5.89622641509434
                },
                {
                  name: 'Slant Turn Nexus 550',
                  value: 32,
                  code: 'Mazak,Turning,Slant,Slant Turn Nexus 550',
                  absolute_sum: 32,
                  relative_sum: 7.547169811320755
                },
                {
                  name: 'Slant Turn 550',
                  value: 18,
                  code: 'Mazak,Turning,Slant,Slant Turn 550',
                  absolute_sum: 18,
                  relative_sum: 4.245283018867925
                },
                {
                  name: 'Slant Turn 60N',
                  value: 26,
                  code: 'Mazak,Turning,Slant,Slant Turn 60N',
                  absolute_sum: 26,
                  relative_sum: 6.132075471698113
                },
                {
                  name: 'Slant Turn 600',
                  value: 82,
                  code: 'Mazak,Turning,Slant,Slant Turn 600',
                  absolute_sum: 82,
                  relative_sum: 19.339622641509436
                },
                {
                  name: 'Slant Turn',
                  value: 48,
                  code: 'Mazak,Turning,Slant,Slant Turn',
                  absolute_sum: 48,
                  relative_sum: 11.320754716981133
                }
              ],
              code: 'Mazak,Turning,Slant',
              absolute_sum: 424,
              relative_sum: 1.682205911525491
            },
            {
              name: 'Hp',
              children: [
                {
                  name: 'Qtn 250 Hp',
                  value: 62,
                  code: 'Mazak,Turning,Hp,Qtn 250 Hp',
                  absolute_sum: 62,
                  relative_sum: 35.83815028901734
                },
                {
                  name: 'Quick Turn 250 Hp',
                  value: 84,
                  code: 'Mazak,Turning,Hp,Quick Turn 250 Hp',
                  absolute_sum: 84,
                  relative_sum: 48.554913294797686
                },
                {
                  name: 'Quick Turn 250 Hp-Univ',
                  value: 27,
                  code: 'Mazak,Turning,Hp,Quick Turn 250 Hp-Univ',
                  absolute_sum: 27,
                  relative_sum: 15.606936416184972
                }
              ],
              code: 'Mazak,Turning,Hp',
              absolute_sum: 173,
              relative_sum: 0.68637175163658
            },
            {
              name: 'Powermaster',
              children: [
                {
                  name: 'Powermaster 2500',
                  value: 21,
                  code: 'Mazak,Turning,Powermaster,Powermaster 2500',
                  absolute_sum: 21,
                  relative_sum: 21.875
                },
                {
                  name: 'Powermaster 3000',
                  value: 28,
                  code: 'Mazak,Turning,Powermaster,Powermaster 3000',
                  absolute_sum: 28,
                  relative_sum: 29.166666666666668
                },
                {
                  name: 'Powermaster',
                  value: 47,
                  code: 'Mazak,Turning,Powermaster,Powermaster',
                  absolute_sum: 47,
                  relative_sum: 48.95833333333333
                }
              ],
              code: 'Mazak,Turning,Powermaster',
              absolute_sum: 96,
              relative_sum: 0.38087681015671493
            },
            {
              name: 'Master',
              children: [
                {
                  name: 'Powermaster 2500',
                  value: 21,
                  code: 'Mazak,Turning,Master,Powermaster 2500',
                  absolute_sum: 21,
                  relative_sum: 10.76923076923077
                },
                {
                  name: 'Power Master N Universal-3000U',
                  value: 6,
                  code: 'Mazak,Turning,Master,Power Master N Universal-3000U',
                  absolute_sum: 6,
                  relative_sum: 3.076923076923077
                },
                {
                  name: 'Powermaster 3000',
                  value: 28,
                  code: 'Mazak,Turning,Master,Powermaster 3000',
                  absolute_sum: 28,
                  relative_sum: 14.358974358974358
                },
                {
                  name: 'Power Master N Chucker',
                  value: 52,
                  code: 'Mazak,Turning,Master,Power Master N Chucker',
                  absolute_sum: 52,
                  relative_sum: 26.666666666666668
                },
                {
                  name: 'Power Master',
                  value: 41,
                  code: 'Mazak,Turning,Master,Power Master',
                  absolute_sum: 41,
                  relative_sum: 21.025641025641026
                },
                {
                  name: 'Powermaster',
                  value: 47,
                  code: 'Mazak,Turning,Master,Powermaster',
                  absolute_sum: 47,
                  relative_sum: 24.102564102564102
                }
              ],
              code: 'Mazak,Turning,Master',
              absolute_sum: 195,
              relative_sum: 0.7736560206308273
            },
            {
              name: 'Power',
              children: [
                {
                  name: 'Powermaster 2500',
                  value: 21,
                  code: 'Mazak,Turning,Power,Powermaster 2500',
                  absolute_sum: 21,
                  relative_sum: 10.76923076923077
                },
                {
                  name: 'Power Master N Universal-3000U',
                  value: 6,
                  code: 'Mazak,Turning,Power,Power Master N Universal-3000U',
                  absolute_sum: 6,
                  relative_sum: 3.076923076923077
                },
                {
                  name: 'Powermaster 3000',
                  value: 28,
                  code: 'Mazak,Turning,Power,Powermaster 3000',
                  absolute_sum: 28,
                  relative_sum: 14.358974358974358
                },
                {
                  name: 'Power Master N Chucker',
                  value: 52,
                  code: 'Mazak,Turning,Power,Power Master N Chucker',
                  absolute_sum: 52,
                  relative_sum: 26.666666666666668
                },
                {
                  name: 'Power Master',
                  value: 41,
                  code: 'Mazak,Turning,Power,Power Master',
                  absolute_sum: 41,
                  relative_sum: 21.025641025641026
                },
                {
                  name: 'Powermaster',
                  value: 47,
                  code: 'Mazak,Turning,Power,Powermaster',
                  absolute_sum: 47,
                  relative_sum: 24.102564102564102
                }
              ],
              code: 'Mazak,Turning,Power',
              absolute_sum: 195,
              relative_sum: 0.7736560206308273
            },
            {
              name: 'Ivs',
              children: [
                {
                  name: 'Ivs-300M',
                  value: 4,
                  code: 'Mazak,Turning,Ivs,Ivs-300M',
                  absolute_sum: 4,
                  relative_sum: 8.88888888888889
                },
                {
                  name: 'Ivs-400 Ii',
                  value: 26,
                  code: 'Mazak,Turning,Ivs,Ivs-400 Ii',
                  absolute_sum: 26,
                  relative_sum: 57.77777777777777
                },
                {
                  name: 'Ivs Series',
                  value: 15,
                  code: 'Mazak,Turning,Ivs,Ivs Series',
                  absolute_sum: 15,
                  relative_sum: 33.33333333333333
                }
              ],
              code: 'Mazak,Turning,Ivs',
              absolute_sum: 45,
              relative_sum: 0.17853600476096013
            },
            {
              name: 'Cybertech',
              children: [
                {
                  name: 'Cybertech Turn 4500M/3000',
                  value: 11,
                  code: 'Mazak,Turning,Cybertech,Cybertech Turn 4500M/3000',
                  absolute_sum: 11,
                  relative_sum: 15.277777777777779
                },
                {
                  name: 'Cybertech Turn 4500M',
                  value: 61,
                  code: 'Mazak,Turning,Cybertech,Cybertech Turn 4500M',
                  absolute_sum: 61,
                  relative_sum: 84.72222222222221
                }
              ],
              code: 'Mazak,Turning,Cybertech',
              absolute_sum: 72,
              relative_sum: 0.2856576076175362
            },
            {
              name: 'Series',
              children: [
                {
                  name: 'Ivs Series',
                  value: 15,
                  code: 'Mazak,Turning,Series,Ivs Series',
                  absolute_sum: 15,
                  relative_sum: 100
                }
              ],
              code: 'Mazak,Turning,Series',
              absolute_sum: 15,
              relative_sum: 0.05951200158698671
            }
          ],
          code: 'Mazak,Turning',
          absolute_sum: 25205,
          relative_sum: 58.39898053753475
        },
        {
          name: 'Laser Cutting',
          children: [
            {
              name: 'Gear',
              children: [
                {
                  name: 'Fabrigear 150',
                  value: 25,
                  code: 'Mazak,Laser Cutting,Gear,Fabrigear 150',
                  absolute_sum: 25,
                  relative_sum: 14.792899408284024
                },
                {
                  name: '3D Fabri Gear 220 Ii',
                  value: 48,
                  code: 'Mazak,Laser Cutting,Gear,3D Fabri Gear 220 Ii',
                  absolute_sum: 48,
                  relative_sum: 28.402366863905325
                },
                {
                  name: 'Fabrigear 300',
                  value: 3,
                  code: 'Mazak,Laser Cutting,Gear,Fabrigear 300',
                  absolute_sum: 3,
                  relative_sum: 1.7751479289940828
                },
                {
                  name: 'Space Gear U44',
                  value: 38,
                  code: 'Mazak,Laser Cutting,Gear,Space Gear U44',
                  absolute_sum: 38,
                  relative_sum: 22.485207100591715
                },
                {
                  name: 'Space Gear 510 Mk Ii',
                  value: 29,
                  code: 'Mazak,Laser Cutting,Gear,Space Gear 510 Mk Ii',
                  absolute_sum: 29,
                  relative_sum: 17.159763313609467
                },
                {
                  name: 'Hyper Gear',
                  value: 13,
                  code: 'Mazak,Laser Cutting,Gear,Hyper Gear',
                  absolute_sum: 13,
                  relative_sum: 7.6923076923076925
                },
                {
                  name: 'Space Gear',
                  value: 13,
                  code: 'Mazak,Laser Cutting,Gear,Space Gear',
                  absolute_sum: 13,
                  relative_sum: 7.6923076923076925
                }
              ],
              code: 'Mazak,Laser Cutting,Gear',
              absolute_sum: 169,
              relative_sum: 4.995566065622229
            },
            {
              name: 'Fiber',
              children: [
                {
                  name: 'Ft-150 Fiber',
                  value: 17,
                  code: 'Mazak,Laser Cutting,Fiber,Ft-150 Fiber',
                  absolute_sum: 17,
                  relative_sum: 4.156479217603912
                },
                {
                  name: 'Super Turbo-X 2412 Fiber',
                  value: 80,
                  code: 'Mazak,Laser Cutting,Fiber,Super Turbo-X 2412 Fiber',
                  absolute_sum: 80,
                  relative_sum: 19.559902200489
                },
                {
                  name: 'Optiplex 3015 Fiber Iii',
                  value: 18,
                  code: 'Mazak,Laser Cutting,Fiber,Optiplex 3015 Fiber Iii',
                  absolute_sum: 18,
                  relative_sum: 4.400977995110025
                },
                {
                  name: 'Optiplex Nexus 3015 Fiber',
                  value: 79,
                  code: 'Mazak,Laser Cutting,Fiber,Optiplex Nexus 3015 Fiber',
                  absolute_sum: 79,
                  relative_sum: 19.315403422982886
                },
                {
                  name: 'Optiplex 4020 Fiber Iii',
                  value: 76,
                  code: 'Mazak,Laser Cutting,Fiber,Optiplex 4020 Fiber Iii',
                  absolute_sum: 76,
                  relative_sum: 18.581907090464547
                },
                {
                  name: 'Optiplex Fiber Iii',
                  value: 48,
                  code: 'Mazak,Laser Cutting,Fiber,Optiplex Fiber Iii',
                  absolute_sum: 48,
                  relative_sum: 11.7359413202934
                },
                {
                  name: 'Optiplex Nexus Fiber',
                  value: 22,
                  code: 'Mazak,Laser Cutting,Fiber,Optiplex Nexus Fiber',
                  absolute_sum: 22,
                  relative_sum: 5.378973105134474
                },
                {
                  name: 'Super Turbo-X Fiber',
                  value: 69,
                  code: 'Mazak,Laser Cutting,Fiber,Super Turbo-X Fiber',
                  absolute_sum: 69,
                  relative_sum: 16.87041564792176
                }
              ],
              code: 'Mazak,Laser Cutting,Fiber',
              absolute_sum: 409,
              relative_sum: 12.089861070056163
            },
            {
              name: 'Optiplex',
              children: [
                {
                  name: 'Optiplex 3015 Co2',
                  value: 74,
                  code: 'Mazak,Laser Cutting,Optiplex,Optiplex 3015 Co2',
                  absolute_sum: 74,
                  relative_sum: 14.041745730550284
                },
                {
                  name: 'Optiplex Nexus 3015 S7',
                  value: 31,
                  code: 'Mazak,Laser Cutting,Optiplex,Optiplex Nexus 3015 S7',
                  absolute_sum: 31,
                  relative_sum: 5.88235294117647
                },
                {
                  name: 'Optiplex 3015 Ii',
                  value: 9,
                  code: 'Mazak,Laser Cutting,Optiplex,Optiplex 3015 Ii',
                  absolute_sum: 9,
                  relative_sum: 1.7077798861480076
                },
                {
                  name: 'Optiplex 3015 Fiber Iii',
                  value: 18,
                  code: 'Mazak,Laser Cutting,Optiplex,Optiplex 3015 Fiber Iii',
                  absolute_sum: 18,
                  relative_sum: 3.415559772296015
                },
                {
                  name: 'Optiplex Nexus 3015 Fiber',
                  value: 79,
                  code: 'Mazak,Laser Cutting,Optiplex,Optiplex Nexus 3015 Fiber',
                  absolute_sum: 79,
                  relative_sum: 14.990512333965844
                },
                {
                  name: 'Optiplex 3015 Neo',
                  value: 105,
                  code: 'Mazak,Laser Cutting,Optiplex,Optiplex 3015 Neo',
                  absolute_sum: 105,
                  relative_sum: 19.924098671726757
                },
                {
                  name: 'Optiplex Nexus 3015',
                  value: 38,
                  code: 'Mazak,Laser Cutting,Optiplex,Optiplex Nexus 3015',
                  absolute_sum: 38,
                  relative_sum: 7.2106261859582546
                },
                {
                  name: 'Optiplex 4020 Fiber Iii',
                  value: 76,
                  code: 'Mazak,Laser Cutting,Optiplex,Optiplex 4020 Fiber Iii',
                  absolute_sum: 76,
                  relative_sum: 14.421252371916509
                },
                {
                  name: 'Optiplex Fiber Iii',
                  value: 48,
                  code: 'Mazak,Laser Cutting,Optiplex,Optiplex Fiber Iii',
                  absolute_sum: 48,
                  relative_sum: 9.108159392789373
                },
                {
                  name: 'Optiplex Nexus Fiber',
                  value: 22,
                  code: 'Mazak,Laser Cutting,Optiplex,Optiplex Nexus Fiber',
                  absolute_sum: 22,
                  relative_sum: 4.174573055028463
                },
                {
                  name: 'Optiplex Nexus',
                  value: 21,
                  code: 'Mazak,Laser Cutting,Optiplex,Optiplex Nexus',
                  absolute_sum: 21,
                  relative_sum: 3.984819734345351
                },
                {
                  name: 'Optiplex',
                  value: 6,
                  code: 'Mazak,Laser Cutting,Optiplex,Optiplex',
                  absolute_sum: 6,
                  relative_sum: 1.1385199240986716
                }
              ],
              code: 'Mazak,Laser Cutting,Optiplex',
              absolute_sum: 527,
              relative_sum: 15.577889447236181
            },
            {
              name: 'Turbo',
              children: [
                {
                  name: 'Super Turbo-X 2412 Fiber',
                  value: 80,
                  code: 'Mazak,Laser Cutting,Turbo,Super Turbo-X 2412 Fiber',
                  absolute_sum: 80,
                  relative_sum: 17.24137931034483
                },
                {
                  name: 'Super Turbo-X48 Laser',
                  value: 157,
                  code: 'Mazak,Laser Cutting,Turbo,Super Turbo-X48 Laser',
                  absolute_sum: 157,
                  relative_sum: 33.83620689655172
                },
                {
                  name: 'Super Turbo X510 Mkii',
                  value: 39,
                  code: 'Mazak,Laser Cutting,Turbo,Super Turbo X510 Mkii',
                  absolute_sum: 39,
                  relative_sum: 8.405172413793103
                },
                {
                  name: 'Super Turbo X510Hp/Sc Laser',
                  value: 96,
                  code: 'Mazak,Laser Cutting,Turbo,Super Turbo X510Hp/Sc Laser',
                  absolute_sum: 96,
                  relative_sum: 20.689655172413794
                },
                {
                  name: 'Hyper Turbo-X 510',
                  value: 23,
                  code: 'Mazak,Laser Cutting,Turbo,Hyper Turbo-X 510',
                  absolute_sum: 23,
                  relative_sum: 4.956896551724138
                },
                {
                  name: 'Super Turbo-X Fiber',
                  value: 69,
                  code: 'Mazak,Laser Cutting,Turbo,Super Turbo-X Fiber',
                  absolute_sum: 69,
                  relative_sum: 14.870689655172415
                }
              ],
              code: 'Mazak,Laser Cutting,Turbo',
              absolute_sum: 464,
              relative_sum: 13.715637008572273
            },
            {
              name: 'Super',
              children: [
                {
                  name: 'Super Turbo-X 2412 Fiber',
                  value: 80,
                  code: 'Mazak,Laser Cutting,Super,Super Turbo-X 2412 Fiber',
                  absolute_sum: 80,
                  relative_sum: 18.140589569160998
                },
                {
                  name: 'Super Turbo-X48 Laser',
                  value: 157,
                  code: 'Mazak,Laser Cutting,Super,Super Turbo-X48 Laser',
                  absolute_sum: 157,
                  relative_sum: 35.600907029478456
                },
                {
                  name: 'Super Turbo X510 Mkii',
                  value: 39,
                  code: 'Mazak,Laser Cutting,Super,Super Turbo X510 Mkii',
                  absolute_sum: 39,
                  relative_sum: 8.843537414965986
                },
                {
                  name: 'Super Turbo X510Hp/Sc Laser',
                  value: 96,
                  code: 'Mazak,Laser Cutting,Super,Super Turbo X510Hp/Sc Laser',
                  absolute_sum: 96,
                  relative_sum: 21.768707482993197
                },
                {
                  name: 'Super Turbo-X Fiber',
                  value: 69,
                  code: 'Mazak,Laser Cutting,Super,Super Turbo-X Fiber',
                  absolute_sum: 69,
                  relative_sum: 15.646258503401361
                }
              ],
              code: 'Mazak,Laser Cutting,Super',
              absolute_sum: 441,
              relative_sum: 13.035767070647356
            },
            {
              name: 'Nexus',
              children: [
                {
                  name: 'Optiplex Nexus 3015 S7',
                  value: 31,
                  code: 'Mazak,Laser Cutting,Nexus,Optiplex Nexus 3015 S7',
                  absolute_sum: 31,
                  relative_sum: 16.230366492146597
                },
                {
                  name: 'Optiplex Nexus 3015 Fiber',
                  value: 79,
                  code: 'Mazak,Laser Cutting,Nexus,Optiplex Nexus 3015 Fiber',
                  absolute_sum: 79,
                  relative_sum: 41.361256544502616
                },
                {
                  name: 'Optiplex Nexus 3015',
                  value: 38,
                  code: 'Mazak,Laser Cutting,Nexus,Optiplex Nexus 3015',
                  absolute_sum: 38,
                  relative_sum: 19.895287958115183
                },
                {
                  name: 'Optiplex Nexus Fiber',
                  value: 22,
                  code: 'Mazak,Laser Cutting,Nexus,Optiplex Nexus Fiber',
                  absolute_sum: 22,
                  relative_sum: 11.518324607329843
                },
                {
                  name: 'Optiplex Nexus',
                  value: 21,
                  code: 'Mazak,Laser Cutting,Nexus,Optiplex Nexus',
                  absolute_sum: 21,
                  relative_sum: 10.99476439790576
                }
              ],
              code: 'Mazak,Laser Cutting,Nexus',
              absolute_sum: 191,
              relative_sum: 5.645876441028673
            },
            {
              name: 'Neo',
              children: [
                {
                  name: 'Optiplex 3015 Neo',
                  value: 105,
                  code: 'Mazak,Laser Cutting,Neo,Optiplex 3015 Neo',
                  absolute_sum: 105,
                  relative_sum: 100
                }
              ],
              code: 'Mazak,Laser Cutting,Neo',
              absolute_sum: 105,
              relative_sum: 3.1037540644398462
            },
            {
              name: 'Space',
              children: [
                {
                  name: 'Space Gear U44',
                  value: 38,
                  code: 'Mazak,Laser Cutting,Space,Space Gear U44',
                  absolute_sum: 38,
                  relative_sum: 47.5
                },
                {
                  name: 'Space Gear 510 Mk Ii',
                  value: 29,
                  code: 'Mazak,Laser Cutting,Space,Space Gear 510 Mk Ii',
                  absolute_sum: 29,
                  relative_sum: 36.25
                },
                {
                  name: 'Space Gear',
                  value: 13,
                  code: 'Mazak,Laser Cutting,Space,Space Gear',
                  absolute_sum: 13,
                  relative_sum: 16.25
                }
              ],
              code: 'Mazak,Laser Cutting,Space',
              absolute_sum: 80,
              relative_sum: 2.364765001477978
            },
            {
              name: 'St',
              children: [
                {
                  name: 'Stx48 Hipro Cnc Laser',
                  value: 306,
                  code: 'Mazak,Laser Cutting,St,Stx48 Hipro Cnc Laser',
                  absolute_sum: 306,
                  relative_sum: 100
                }
              ],
              code: 'Mazak,Laser Cutting,St',
              absolute_sum: 306,
              relative_sum: 9.045226130653267
            },
            {
              name: 'Laser',
              children: [
                {
                  name: 'Stx48 Hipro Cnc Laser',
                  value: 306,
                  code: 'Mazak,Laser Cutting,Laser,Stx48 Hipro Cnc Laser',
                  absolute_sum: 306,
                  relative_sum: 54.74060822898033
                },
                {
                  name: 'Super Turbo-X48 Laser',
                  value: 157,
                  code: 'Mazak,Laser Cutting,Laser,Super Turbo-X48 Laser',
                  absolute_sum: 157,
                  relative_sum: 28.085867620751344
                },
                {
                  name: 'Super Turbo X510Hp/Sc Laser',
                  value: 96,
                  code: 'Mazak,Laser Cutting,Laser,Super Turbo X510Hp/Sc Laser',
                  absolute_sum: 96,
                  relative_sum: 17.173524150268335
                }
              ],
              code: 'Mazak,Laser Cutting,Laser',
              absolute_sum: 559,
              relative_sum: 16.52379544782737
            },
            {
              name: 'Hp',
              children: [
                {
                  name: 'Super Turbo X510Hp/Sc Laser',
                  value: 96,
                  code: 'Mazak,Laser Cutting,Hp,Super Turbo X510Hp/Sc Laser',
                  absolute_sum: 96,
                  relative_sum: 100
                }
              ],
              code: 'Mazak,Laser Cutting,Hp',
              absolute_sum: 96,
              relative_sum: 2.837718001773574
            },
            {
              name: 'Hyper',
              children: [
                {
                  name: 'Hyper Turbo-X 510',
                  value: 23,
                  code: 'Mazak,Laser Cutting,Hyper,Hyper Turbo-X 510',
                  absolute_sum: 23,
                  relative_sum: 63.888888888888886
                },
                {
                  name: 'Hyper Gear',
                  value: 13,
                  code: 'Mazak,Laser Cutting,Hyper,Hyper Gear',
                  absolute_sum: 13,
                  relative_sum: 36.11111111111111
                }
              ],
              code: 'Mazak,Laser Cutting,Hyper',
              absolute_sum: 36,
              relative_sum: 1.06414425066509
            }
          ],
          code: 'Mazak,Laser Cutting',
          absolute_sum: 3383,
          relative_sum: 7.838276181649675
        },
        {
          name: 'Cutting',
          children: [
            {
              name: 'Optiplex',
              children: [
                {
                  name: 'Optiplex 3015 Ddl',
                  value: 10,
                  code: 'Mazak,Cutting,Optiplex,Optiplex 3015 Ddl',
                  absolute_sum: 10,
                  relative_sum: 100
                }
              ],
              code: 'Mazak,Cutting,Optiplex',
              absolute_sum: 10,
              relative_sum: 100
            }
          ],
          code: 'Mazak,Cutting',
          absolute_sum: 10,
          relative_sum: 0.023169601482854494
        }
      ],
      total_value: 43160,
      code: 'Mazak',
      absolute_sum: 43160,
      relative_sum: 37.915524632792184
    },
    {
      name: 'Haas',
      children: [
        {
          name: 'Milling',
          children: [
            {
              name: 'Vf',
              children: [
                {
                  name: 'Vf-0',
                  value: 79,
                  code: 'Haas,Milling,Vf,Vf-0',
                  absolute_sum: 79,
                  relative_sum: 0.9760316283666914
                },
                {
                  name: 'Vf-1',
                  value: 226,
                  code: 'Haas,Milling,Vf,Vf-1',
                  absolute_sum: 226,
                  relative_sum: 2.7921917469730664
                },
                {
                  name: 'Vf1Yt',
                  value: 89,
                  code: 'Haas,Milling,Vf,Vf1Yt',
                  absolute_sum: 89,
                  relative_sum: 1.0995799357548801
                },
                {
                  name: 'Vf-10/50',
                  value: 14,
                  code: 'Haas,Milling,Vf,Vf-10/50',
                  absolute_sum: 14,
                  relative_sum: 0.1729676303434643
                },
                {
                  name: 'Vf-10',
                  value: 60,
                  code: 'Haas,Milling,Vf,Vf-10',
                  absolute_sum: 60,
                  relative_sum: 0.7412898443291327
                },
                {
                  name: 'Vf-11/40',
                  value: 11,
                  code: 'Haas,Milling,Vf,Vf-11/40',
                  absolute_sum: 11,
                  relative_sum: 0.13590313812700766
                },
                {
                  name: 'Vf-11/50',
                  value: 26,
                  code: 'Haas,Milling,Vf,Vf-11/50',
                  absolute_sum: 26,
                  relative_sum: 0.32122559920929084
                },
                {
                  name: 'Vf-11',
                  value: 63,
                  code: 'Haas,Milling,Vf,Vf-11',
                  absolute_sum: 63,
                  relative_sum: 0.7783543365455894
                },
                {
                  name: 'Vf-12/40',
                  value: 30,
                  code: 'Haas,Milling,Vf,Vf-12/40',
                  absolute_sum: 30,
                  relative_sum: 0.37064492216456635
                },
                {
                  name: 'Vf-2 Vertical Machining Center',
                  value: 584,
                  code: 'Haas,Milling,Vf,Vf-2 Vertical Machining Center',
                  absolute_sum: 584,
                  relative_sum: 7.2152211514702245
                },
                {
                  name: 'Vf-2Ss',
                  value: 1004,
                  code: 'Haas,Milling,Vf,Vf-2Ss',
                  absolute_sum: 1004,
                  relative_sum: 12.404250061774153
                },
                {
                  name: 'Vf-2Ssyt',
                  value: 140,
                  code: 'Haas,Milling,Vf,Vf-2Ssyt',
                  absolute_sum: 140,
                  relative_sum: 1.7296763034346427
                },
                {
                  name: 'Vf-2Ssyt-Eu',
                  value: 52,
                  code: 'Haas,Milling,Vf,Vf-2Ssyt-Eu',
                  absolute_sum: 52,
                  relative_sum: 0.6424511984185817
                },
                {
                  name: 'Vf-2Tr',
                  value: 21,
                  code: 'Haas,Milling,Vf,Vf-2Tr',
                  absolute_sum: 21,
                  relative_sum: 0.25945144551519644
                },
                {
                  name: 'Vf2',
                  value: 897,
                  code: 'Haas,Milling,Vf,Vf2',
                  absolute_sum: 897,
                  relative_sum: 11.082283172720533
                },
                {
                  name: 'Vf-2 Vmc',
                  value: 95,
                  code: 'Haas,Milling,Vf,Vf-2 Vmc',
                  absolute_sum: 95,
                  relative_sum: 1.1737089201877933
                },
                {
                  name: 'Vf-2Yt',
                  value: 73,
                  code: 'Haas,Milling,Vf,Vf-2Yt',
                  absolute_sum: 73,
                  relative_sum: 0.9019026439337781
                },
                {
                  name: 'Vf-3Yt/50',
                  value: 121,
                  code: 'Haas,Milling,Vf,Vf-3Yt/50',
                  absolute_sum: 121,
                  relative_sum: 1.4949345193970842
                },
                {
                  name: 'Vf-3Ss',
                  value: 218,
                  code: 'Haas,Milling,Vf,Vf-3Ss',
                  absolute_sum: 218,
                  relative_sum: 2.6933531010625154
                },
                {
                  name: 'Vf-3Ssyt',
                  value: 175,
                  code: 'Haas,Milling,Vf,Vf-3Ssyt',
                  absolute_sum: 175,
                  relative_sum: 2.1620953792933038
                },
                {
                  name: 'Vf-3',
                  value: 780,
                  code: 'Haas,Milling,Vf,Vf-3',
                  absolute_sum: 780,
                  relative_sum: 9.636767976278724
                },
                {
                  name: 'Vf-3Yt',
                  value: 91,
                  code: 'Haas,Milling,Vf,Vf-3Yt',
                  absolute_sum: 91,
                  relative_sum: 1.124289597232518
                },
                {
                  name: 'Vf-4 Mill',
                  value: 289,
                  code: 'Haas,Milling,Vf,Vf-4 Mill',
                  absolute_sum: 289,
                  relative_sum: 3.570546083518656
                },
                {
                  name: 'Vf-4Ss',
                  value: 403,
                  code: 'Haas,Milling,Vf,Vf-4Ss',
                  absolute_sum: 403,
                  relative_sum: 4.978996787744007
                },
                {
                  name: 'Vf-4',
                  value: 720,
                  code: 'Haas,Milling,Vf,Vf-4',
                  absolute_sum: 720,
                  relative_sum: 8.895478131949593
                },
                {
                  name: 'Vf-5/40',
                  value: 36,
                  code: 'Haas,Milling,Vf,Vf-5/40',
                  absolute_sum: 36,
                  relative_sum: 0.4447739065974796
                },
                {
                  name: 'Vf-5/40Xt',
                  value: 57,
                  code: 'Haas,Milling,Vf,Vf-5/40Xt',
                  absolute_sum: 57,
                  relative_sum: 0.7042253521126761
                },
                {
                  name: 'Vf-5/40Tr',
                  value: 4,
                  code: 'Haas,Milling,Vf,Vf-5/40Tr',
                  absolute_sum: 4,
                  relative_sum: 0.04941932295527551
                },
                {
                  name: 'Vf-6/40',
                  value: 34,
                  code: 'Haas,Milling,Vf,Vf-6/40',
                  absolute_sum: 34,
                  relative_sum: 0.4200642451198419
                },
                {
                  name: 'Vf-7/40',
                  value: 29,
                  code: 'Haas,Milling,Vf,Vf-7/40',
                  absolute_sum: 29,
                  relative_sum: 0.35829009142574747
                },
                {
                  name: 'Vf-8/40',
                  value: 4,
                  code: 'Haas,Milling,Vf,Vf-8/40',
                  absolute_sum: 4,
                  relative_sum: 0.04941932295527551
                },
                {
                  name: 'Vf-9/40',
                  value: 24,
                  code: 'Haas,Milling,Vf,Vf-9/40',
                  absolute_sum: 24,
                  relative_sum: 0.2965159377316531
                },
                {
                  name: 'Vf-5/50Tr',
                  value: 103,
                  code: 'Haas,Milling,Vf,Vf-5/50Tr',
                  absolute_sum: 103,
                  relative_sum: 1.2725475660983445
                },
                {
                  name: 'Vf-5/50',
                  value: 34,
                  code: 'Haas,Milling,Vf,Vf-5/50',
                  absolute_sum: 34,
                  relative_sum: 0.4200642451198419
                },
                {
                  name: 'Vf-5/50Xt',
                  value: 18,
                  code: 'Haas,Milling,Vf,Vf-5/50Xt',
                  absolute_sum: 18,
                  relative_sum: 0.2223869532987398
                },
                {
                  name: 'Vf-5Ss',
                  value: 87,
                  code: 'Haas,Milling,Vf,Vf-5Ss',
                  absolute_sum: 87,
                  relative_sum: 1.0748702742772425
                },
                {
                  name: 'Vf-5',
                  value: 393,
                  code: 'Haas,Milling,Vf,Vf-5',
                  absolute_sum: 393,
                  relative_sum: 4.855448480355819
                },
                {
                  name: 'Vf-5Xt',
                  value: 81,
                  code: 'Haas,Milling,Vf,Vf-5Xt',
                  absolute_sum: 81,
                  relative_sum: 1.000741289844329
                },
                {
                  name: 'Vf-6/50Tr',
                  value: 27,
                  code: 'Haas,Milling,Vf,Vf-6/50Tr',
                  absolute_sum: 27,
                  relative_sum: 0.3335804299481097
                },
                {
                  name: 'Vf-6/50',
                  value: 78,
                  code: 'Haas,Milling,Vf,Vf-6/50',
                  absolute_sum: 78,
                  relative_sum: 0.9636767976278725
                },
                {
                  name: 'Vf-7/50',
                  value: 9,
                  code: 'Haas,Milling,Vf,Vf-7/50',
                  absolute_sum: 9,
                  relative_sum: 0.1111934766493699
                },
                {
                  name: 'Vf-8/50',
                  value: 8,
                  code: 'Haas,Milling,Vf,Vf-8/50',
                  absolute_sum: 8,
                  relative_sum: 0.09883864591055103
                },
                {
                  name: 'Vf-9/50',
                  value: 41,
                  code: 'Haas,Milling,Vf,Vf-9/50',
                  absolute_sum: 41,
                  relative_sum: 0.506548060291574
                },
                {
                  name: 'Vf-6Ss',
                  value: 107,
                  code: 'Haas,Milling,Vf,Vf-6Ss',
                  absolute_sum: 107,
                  relative_sum: 1.3219668890536198
                },
                {
                  name: 'Vf-6',
                  value: 339,
                  code: 'Haas,Milling,Vf,Vf-6',
                  absolute_sum: 339,
                  relative_sum: 4.1882876204596
                },
                {
                  name: 'Vf-7',
                  value: 121,
                  code: 'Haas,Milling,Vf,Vf-7',
                  absolute_sum: 121,
                  relative_sum: 1.4949345193970842
                },
                {
                  name: 'Vf-8',
                  value: 71,
                  code: 'Haas,Milling,Vf,Vf-8',
                  absolute_sum: 71,
                  relative_sum: 0.8771929824561403
                },
                {
                  name: 'Vf-9',
                  value: 128,
                  code: 'Haas,Milling,Vf,Vf-9',
                  absolute_sum: 128,
                  relative_sum: 1.5814183345688164
                }
              ],
              code: 'Haas,Milling,Vf',
              absolute_sum: 8094,
              relative_sum: 51.082360366046075
            },
            {
              name: 'Tm',
              children: [
                {
                  name: 'Tm-1P',
                  value: 73,
                  code: 'Haas,Milling,Tm,Tm-1P',
                  absolute_sum: 73,
                  relative_sum: 22.25609756097561
                },
                {
                  name: 'Tm1',
                  value: 70,
                  code: 'Haas,Milling,Tm,Tm1',
                  absolute_sum: 70,
                  relative_sum: 21.341463414634145
                },
                {
                  name: 'Tm-2P',
                  value: 55,
                  code: 'Haas,Milling,Tm,Tm-2P',
                  absolute_sum: 55,
                  relative_sum: 16.76829268292683
                },
                {
                  name: 'Tm-2',
                  value: 61,
                  code: 'Haas,Milling,Tm,Tm-2',
                  absolute_sum: 61,
                  relative_sum: 18.597560975609756
                },
                {
                  name: 'Tm3P',
                  value: 54,
                  code: 'Haas,Milling,Tm,Tm3P',
                  absolute_sum: 54,
                  relative_sum: 16.463414634146343
                },
                {
                  name: 'Tm-3',
                  value: 15,
                  code: 'Haas,Milling,Tm,Tm-3',
                  absolute_sum: 15,
                  relative_sum: 4.573170731707317
                }
              ],
              code: 'Haas,Milling,Tm',
              absolute_sum: 328,
              relative_sum: 2.0700536446828655
            },
            {
              name: 'Yt',
              children: [
                {
                  name: 'Vf1Yt',
                  value: 89,
                  code: 'Haas,Milling,Yt,Vf1Yt',
                  absolute_sum: 89,
                  relative_sum: 12.010796221322536
                },
                {
                  name: 'Vf-2Ssyt',
                  value: 140,
                  code: 'Haas,Milling,Yt,Vf-2Ssyt',
                  absolute_sum: 140,
                  relative_sum: 18.89338731443995
                },
                {
                  name: 'Vf-2Ssyt-Eu',
                  value: 52,
                  code: 'Haas,Milling,Yt,Vf-2Ssyt-Eu',
                  absolute_sum: 52,
                  relative_sum: 7.017543859649122
                },
                {
                  name: 'Vf-2Yt',
                  value: 73,
                  code: 'Haas,Milling,Yt,Vf-2Yt',
                  absolute_sum: 73,
                  relative_sum: 9.851551956815115
                },
                {
                  name: 'Vf-3Yt/50',
                  value: 121,
                  code: 'Haas,Milling,Yt,Vf-3Yt/50',
                  absolute_sum: 121,
                  relative_sum: 16.32928475033738
                },
                {
                  name: 'Vf-3Ssyt',
                  value: 175,
                  code: 'Haas,Milling,Yt,Vf-3Ssyt',
                  absolute_sum: 175,
                  relative_sum: 23.616734143049932
                },
                {
                  name: 'Vf-3Yt',
                  value: 91,
                  code: 'Haas,Milling,Yt,Vf-3Yt',
                  absolute_sum: 91,
                  relative_sum: 12.280701754385964
                }
              ],
              code: 'Haas,Milling,Yt',
              absolute_sum: 741,
              relative_sum: 4.676554118018302
            },
            {
              name: 'Tr',
              children: [
                {
                  name: 'Trt100',
                  value: 9,
                  code: 'Haas,Milling,Tr,Trt100',
                  absolute_sum: 9,
                  relative_sum: 3.643724696356275
                },
                {
                  name: 'Tr160-2',
                  value: 26,
                  code: 'Haas,Milling,Tr,Tr160-2',
                  absolute_sum: 26,
                  relative_sum: 10.526315789473683
                },
                {
                  name: 'Trt160',
                  value: 22,
                  code: 'Haas,Milling,Tr,Trt160',
                  absolute_sum: 22,
                  relative_sum: 8.906882591093117
                },
                {
                  name: 'Vf-2Tr',
                  value: 21,
                  code: 'Haas,Milling,Tr,Vf-2Tr',
                  absolute_sum: 21,
                  relative_sum: 8.502024291497975
                },
                {
                  name: 'Tr210',
                  value: 27,
                  code: 'Haas,Milling,Tr,Tr210',
                  absolute_sum: 27,
                  relative_sum: 10.931174089068826
                },
                {
                  name: 'Trt210',
                  value: 8,
                  code: 'Haas,Milling,Tr,Trt210',
                  absolute_sum: 8,
                  relative_sum: 3.2388663967611335
                },
                {
                  name: 'Vf-5/40Tr',
                  value: 4,
                  code: 'Haas,Milling,Tr,Vf-5/40Tr',
                  absolute_sum: 4,
                  relative_sum: 1.6194331983805668
                },
                {
                  name: 'Vf-5/50Tr',
                  value: 103,
                  code: 'Haas,Milling,Tr,Vf-5/50Tr',
                  absolute_sum: 103,
                  relative_sum: 41.70040485829959
                },
                {
                  name: 'Vf-6/50Tr',
                  value: 27,
                  code: 'Haas,Milling,Tr,Vf-6/50Tr',
                  absolute_sum: 27,
                  relative_sum: 10.931174089068826
                }
              ],
              code: 'Haas,Milling,Tr',
              absolute_sum: 247,
              relative_sum: 1.5588513726727675
            },
            {
              name: 'Trt',
              children: [
                {
                  name: 'Trt100',
                  value: 9,
                  code: 'Haas,Milling,Trt,Trt100',
                  absolute_sum: 9,
                  relative_sum: 23.076923076923077
                },
                {
                  name: 'Trt160',
                  value: 22,
                  code: 'Haas,Milling,Trt,Trt160',
                  absolute_sum: 22,
                  relative_sum: 56.41025641025641
                },
                {
                  name: 'Trt210',
                  value: 8,
                  code: 'Haas,Milling,Trt,Trt210',
                  absolute_sum: 8,
                  relative_sum: 20.51282051282051
                }
              ],
              code: 'Haas,Milling,Trt',
              absolute_sum: 39,
              relative_sum: 0.24613442726412116
            },
            {
              name: 'Umc',
              children: [
                {
                  name: 'Umc-1000Ss-P',
                  value: 12,
                  code: 'Haas,Milling,Umc,Umc-1000Ss-P',
                  absolute_sum: 12,
                  relative_sum: 2.166064981949458
                },
                {
                  name: 'Umc-1000Ss',
                  value: 39,
                  code: 'Haas,Milling,Umc,Umc-1000Ss',
                  absolute_sum: 39,
                  relative_sum: 7.039711191335741
                },
                {
                  name: 'Umc-1000',
                  value: 22,
                  code: 'Haas,Milling,Umc,Umc-1000',
                  absolute_sum: 22,
                  relative_sum: 3.9711191335740073
                },
                {
                  name: 'Umc-1250Ss',
                  value: 6,
                  code: 'Haas,Milling,Umc,Umc-1250Ss',
                  absolute_sum: 6,
                  relative_sum: 1.083032490974729
                },
                {
                  name: 'Umc-1250',
                  value: 7,
                  code: 'Haas,Milling,Umc,Umc-1250',
                  absolute_sum: 7,
                  relative_sum: 1.263537906137184
                },
                {
                  name: 'Umc 1500 Duo',
                  value: 9,
                  code: 'Haas,Milling,Umc,Umc 1500 Duo',
                  absolute_sum: 9,
                  relative_sum: 1.6245487364620936
                },
                {
                  name: 'Umc-500Ss',
                  value: 56,
                  code: 'Haas,Milling,Umc,Umc-500Ss',
                  absolute_sum: 56,
                  relative_sum: 10.108303249097473
                },
                {
                  name: 'Umc-500',
                  value: 83,
                  code: 'Haas,Milling,Umc,Umc-500',
                  absolute_sum: 83,
                  relative_sum: 14.981949458483754
                },
                {
                  name: 'Umc-750Ss',
                  value: 147,
                  code: 'Haas,Milling,Umc,Umc-750Ss',
                  absolute_sum: 147,
                  relative_sum: 26.534296028880867
                },
                {
                  name: 'Umc-750',
                  value: 173,
                  code: 'Haas,Milling,Umc,Umc-750',
                  absolute_sum: 173,
                  relative_sum: 31.227436823104693
                }
              ],
              code: 'Haas,Milling,Umc',
              absolute_sum: 554,
              relative_sum: 3.4963710949826443
            },
            {
              name: 'Ss',
              children: [
                {
                  name: 'Umc-1000Ss-P',
                  value: 12,
                  code: 'Haas,Milling,Ss,Umc-1000Ss-P',
                  absolute_sum: 12,
                  relative_sum: 0.487408610885459
                },
                {
                  name: 'Umc-1000Ss',
                  value: 39,
                  code: 'Haas,Milling,Ss,Umc-1000Ss',
                  absolute_sum: 39,
                  relative_sum: 1.5840779853777416
                },
                {
                  name: 'Umc-1250Ss',
                  value: 6,
                  code: 'Haas,Milling,Ss,Umc-1250Ss',
                  absolute_sum: 6,
                  relative_sum: 0.2437043054427295
                },
                {
                  name: 'Vf-2Ss',
                  value: 1004,
                  code: 'Haas,Milling,Ss,Vf-2Ss',
                  absolute_sum: 1004,
                  relative_sum: 40.77985377741673
                },
                {
                  name: 'Vf-2Ssyt',
                  value: 140,
                  code: 'Haas,Milling,Ss,Vf-2Ssyt',
                  absolute_sum: 140,
                  relative_sum: 5.686433793663688
                },
                {
                  name: 'Vf-2Ssyt-Eu',
                  value: 52,
                  code: 'Haas,Milling,Ss,Vf-2Ssyt-Eu',
                  absolute_sum: 52,
                  relative_sum: 2.1121039805036554
                },
                {
                  name: 'Vf-3Ss',
                  value: 218,
                  code: 'Haas,Milling,Ss,Vf-3Ss',
                  absolute_sum: 218,
                  relative_sum: 8.85458976441917
                },
                {
                  name: 'Vf-3Ssyt',
                  value: 175,
                  code: 'Haas,Milling,Ss,Vf-3Ssyt',
                  absolute_sum: 175,
                  relative_sum: 7.10804224207961
                },
                {
                  name: 'Hrt310Ss',
                  value: 16,
                  code: 'Haas,Milling,Ss,Hrt310Ss',
                  absolute_sum: 16,
                  relative_sum: 0.6498781478472786
                },
                {
                  name: 'Vf-4Ss',
                  value: 403,
                  code: 'Haas,Milling,Ss,Vf-4Ss',
                  absolute_sum: 403,
                  relative_sum: 16.36880584890333
                },
                {
                  name: 'Vf-5Ss',
                  value: 87,
                  code: 'Haas,Milling,Ss,Vf-5Ss',
                  absolute_sum: 87,
                  relative_sum: 3.5337124289195776
                },
                {
                  name: 'Umc-500Ss',
                  value: 56,
                  code: 'Haas,Milling,Ss,Umc-500Ss',
                  absolute_sum: 56,
                  relative_sum: 2.274573517465475
                },
                {
                  name: 'Vf-6Ss',
                  value: 107,
                  code: 'Haas,Milling,Ss,Vf-6Ss',
                  absolute_sum: 107,
                  relative_sum: 4.346060113728676
                },
                {
                  name: 'Umc-750Ss',
                  value: 147,
                  code: 'Haas,Milling,Ss,Umc-750Ss',
                  absolute_sum: 147,
                  relative_sum: 5.970755483346872
                }
              ],
              code: 'Haas,Milling,Ss',
              absolute_sum: 2462,
              relative_sum: 15.538024613442728
            },
            {
              name: 'Hrt',
              children: [
                {
                  name: 'Hrt160Sp',
                  value: 9,
                  code: 'Haas,Milling,Hrt,Hrt160Sp',
                  absolute_sum: 9,
                  relative_sum: 6.521739130434782
                },
                {
                  name: 'Hrt210',
                  value: 44,
                  code: 'Haas,Milling,Hrt,Hrt210',
                  absolute_sum: 44,
                  relative_sum: 31.88405797101449
                },
                {
                  name: 'Hrt210M',
                  value: 44,
                  code: 'Haas,Milling,Hrt,Hrt210M',
                  absolute_sum: 44,
                  relative_sum: 31.88405797101449
                },
                {
                  name: 'Hrt310',
                  value: 19,
                  code: 'Haas,Milling,Hrt,Hrt310',
                  absolute_sum: 19,
                  relative_sum: 13.768115942028986
                },
                {
                  name: 'Hrt310Ss',
                  value: 16,
                  code: 'Haas,Milling,Hrt,Hrt310Ss',
                  absolute_sum: 16,
                  relative_sum: 11.594202898550725
                },
                {
                  name: 'Hrt450',
                  value: 6,
                  code: 'Haas,Milling,Hrt,Hrt450',
                  absolute_sum: 6,
                  relative_sum: 4.3478260869565215
                }
              ],
              code: 'Haas,Milling,Hrt',
              absolute_sum: 138,
              relative_sum: 0.8709372041653519
            },
            {
              name: 'Ec',
              children: [
                {
                  name: 'Ec-1600Zt-5Ax',
                  value: 17,
                  code: 'Haas,Milling,Ec,Ec-1600Zt-5Ax',
                  absolute_sum: 17,
                  relative_sum: 4.722222222222222
                },
                {
                  name: 'Ec-1600',
                  value: 90,
                  code: 'Haas,Milling,Ec,Ec-1600',
                  absolute_sum: 90,
                  relative_sum: 25
                },
                {
                  name: 'Ec1600Zt',
                  value: 71,
                  code: 'Haas,Milling,Ec,Ec1600Zt',
                  absolute_sum: 71,
                  relative_sum: 19.72222222222222
                },
                {
                  name: 'Ec-400',
                  value: 145,
                  code: 'Haas,Milling,Ec,Ec-400',
                  absolute_sum: 145,
                  relative_sum: 40.27777777777778
                },
                {
                  name: 'Ec-500/50',
                  value: 12,
                  code: 'Haas,Milling,Ec,Ec-500/50',
                  absolute_sum: 12,
                  relative_sum: 3.3333333333333335
                },
                {
                  name: 'Ec-500',
                  value: 25,
                  code: 'Haas,Milling,Ec,Ec-500',
                  absolute_sum: 25,
                  relative_sum: 6.944444444444445
                }
              ],
              code: 'Haas,Milling,Ec',
              absolute_sum: 360,
              relative_sum: 2.272010097822657
            },
            {
              name: 'Mini',
              children: [
                {
                  name: 'Mini Mill 2',
                  value: 30,
                  code: 'Haas,Milling,Mini,Mini Mill 2',
                  absolute_sum: 30,
                  relative_sum: 4.335260115606936
                },
                {
                  name: 'Super Mini Mill 2',
                  value: 154,
                  code: 'Haas,Milling,Mini,Super Mini Mill 2',
                  absolute_sum: 154,
                  relative_sum: 22.254335260115607
                },
                {
                  name: 'Mini Mill',
                  value: 321,
                  code: 'Haas,Milling,Mini,Mini Mill',
                  absolute_sum: 321,
                  relative_sum: 46.38728323699422
                },
                {
                  name: 'Super Mini Mill',
                  value: 153,
                  code: 'Haas,Milling,Mini,Super Mini Mill',
                  absolute_sum: 153,
                  relative_sum: 22.109826589595375
                },
                {
                  name: 'Minimill',
                  value: 34,
                  code: 'Haas,Milling,Mini,Minimill',
                  absolute_sum: 34,
                  relative_sum: 4.913294797687861
                }
              ],
              code: 'Haas,Milling,Mini',
              absolute_sum: 692,
              relative_sum: 4.367308299147997
            },
            {
              name: 'Mill',
              children: [
                {
                  name: 'Mini Mill 2',
                  value: 30,
                  code: 'Haas,Milling,Mill,Mini Mill 2',
                  absolute_sum: 30,
                  relative_sum: 2.5
                },
                {
                  name: 'Super Mini Mill 2',
                  value: 154,
                  code: 'Haas,Milling,Mill,Super Mini Mill 2',
                  absolute_sum: 154,
                  relative_sum: 12.833333333333332
                },
                {
                  name: 'Vf-4 Mill',
                  value: 289,
                  code: 'Haas,Milling,Mill,Vf-4 Mill',
                  absolute_sum: 289,
                  relative_sum: 24.083333333333336
                },
                {
                  name: 'Desktop Mill',
                  value: 219,
                  code: 'Haas,Milling,Mill,Desktop Mill',
                  absolute_sum: 219,
                  relative_sum: 18.25
                },
                {
                  name: 'Mini Mill',
                  value: 321,
                  code: 'Haas,Milling,Mill,Mini Mill',
                  absolute_sum: 321,
                  relative_sum: 26.75
                },
                {
                  name: 'Super Mini Mill',
                  value: 153,
                  code: 'Haas,Milling,Mill,Super Mini Mill',
                  absolute_sum: 153,
                  relative_sum: 12.75
                },
                {
                  name: 'Minimill',
                  value: 34,
                  code: 'Haas,Milling,Mill,Minimill',
                  absolute_sum: 34,
                  relative_sum: 2.833333333333333
                }
              ],
              code: 'Haas,Milling,Mill',
              absolute_sum: 1200,
              relative_sum: 7.573366992742189
            },
            {
              name: 'Ssyt',
              children: [
                {
                  name: 'Vf-2Ssyt',
                  value: 140,
                  code: 'Haas,Milling,Ssyt,Vf-2Ssyt',
                  absolute_sum: 140,
                  relative_sum: 38.14713896457766
                },
                {
                  name: 'Vf-2Ssyt-Eu',
                  value: 52,
                  code: 'Haas,Milling,Ssyt,Vf-2Ssyt-Eu',
                  absolute_sum: 52,
                  relative_sum: 14.168937329700274
                },
                {
                  name: 'Vf-3Ssyt',
                  value: 175,
                  code: 'Haas,Milling,Ssyt,Vf-3Ssyt',
                  absolute_sum: 175,
                  relative_sum: 47.68392370572207
                }
              ],
              code: 'Haas,Milling,Ssyt',
              absolute_sum: 367,
              relative_sum: 2.3161880719469865
            },
            {
              name: 'Vm',
              children: [
                {
                  name: 'Vf-2 Vmc',
                  value: 95,
                  code: 'Haas,Milling,Vm,Vf-2 Vmc',
                  absolute_sum: 95,
                  relative_sum: 27.37752161383285
                },
                {
                  name: 'Vm2',
                  value: 86,
                  code: 'Haas,Milling,Vm,Vm2',
                  absolute_sum: 86,
                  relative_sum: 24.78386167146974
                },
                {
                  name: 'Vm3',
                  value: 132,
                  code: 'Haas,Milling,Vm,Vm3',
                  absolute_sum: 132,
                  relative_sum: 38.04034582132565
                },
                {
                  name: 'Vm6',
                  value: 34,
                  code: 'Haas,Milling,Vm,Vm6',
                  absolute_sum: 34,
                  relative_sum: 9.798270893371757
                }
              ],
              code: 'Haas,Milling,Vm',
              absolute_sum: 347,
              relative_sum: 2.1899652887346166
            },
            {
              name: 'Ha',
              children: [
                {
                  name: 'Ha5C3-T',
                  value: 6,
                  code: 'Haas,Milling,Ha,Ha5C3-T',
                  absolute_sum: 6,
                  relative_sum: 5
                },
                {
                  name: 'Ha5C4',
                  value: 9,
                  code: 'Haas,Milling,Ha,Ha5C4',
                  absolute_sum: 9,
                  relative_sum: 7.5
                },
                {
                  name: 'Ha5C4-T',
                  value: 59,
                  code: 'Haas,Milling,Ha,Ha5C4-T',
                  absolute_sum: 59,
                  relative_sum: 49.166666666666664
                },
                {
                  name: 'Ha5C',
                  value: 26,
                  code: 'Haas,Milling,Ha,Ha5C',
                  absolute_sum: 26,
                  relative_sum: 21.666666666666668
                },
                {
                  name: 'Ha5Cs',
                  value: 20,
                  code: 'Haas,Milling,Ha,Ha5Cs',
                  absolute_sum: 20,
                  relative_sum: 16.666666666666664
                }
              ],
              code: 'Haas,Milling,Ha',
              absolute_sum: 120,
              relative_sum: 0.757336699274219
            },
            {
              name: 'Xt',
              children: [
                {
                  name: 'Vf-5/40Xt',
                  value: 57,
                  code: 'Haas,Milling,Xt,Vf-5/40Xt',
                  absolute_sum: 57,
                  relative_sum: 36.53846153846153
                },
                {
                  name: 'Vf-5/50Xt',
                  value: 18,
                  code: 'Haas,Milling,Xt,Vf-5/50Xt',
                  absolute_sum: 18,
                  relative_sum: 11.538461538461538
                },
                {
                  name: 'Vf-5Xt',
                  value: 81,
                  code: 'Haas,Milling,Xt,Vf-5Xt',
                  absolute_sum: 81,
                  relative_sum: 51.92307692307693
                }
              ],
              code: 'Haas,Milling,Xt',
              absolute_sum: 156,
              relative_sum: 0.9845377090564846
            }
          ],
          code: 'Haas,Milling',
          absolute_sum: 15845,
          relative_sum: 82.03893548721135
        },
        {
          name: 'Turning',
          children: [
            {
              name: 'Tl',
              children: [
                {
                  name: 'Tl-1-Edu',
                  value: 32,
                  code: 'Haas,Turning,Tl,Tl-1-Edu',
                  absolute_sum: 32,
                  relative_sum: 7.5829383886255926
                },
                {
                  name: 'Tl-1',
                  value: 131,
                  code: 'Haas,Turning,Tl,Tl-1',
                  absolute_sum: 131,
                  relative_sum: 31.04265402843602
                },
                {
                  name: 'Tl-2',
                  value: 181,
                  code: 'Haas,Turning,Tl,Tl-2',
                  absolute_sum: 181,
                  relative_sum: 42.89099526066351
                },
                {
                  name: 'Tl3',
                  value: 78,
                  code: 'Haas,Turning,Tl,Tl3',
                  absolute_sum: 78,
                  relative_sum: 18.48341232227488
                }
              ],
              code: 'Haas,Turning,Tl',
              absolute_sum: 422,
              relative_sum: 12.408115260217583
            },
            {
              name: 'St',
              children: [
                {
                  name: 'St-10 Through St-28',
                  value: 8,
                  code: 'Haas,Turning,St,St-10 Through St-28',
                  absolute_sum: 8,
                  relative_sum: 0.4944375772558714
                },
                {
                  name: 'St-10L',
                  value: 79,
                  code: 'Haas,Turning,St,St-10L',
                  absolute_sum: 79,
                  relative_sum: 4.882571075401731
                },
                {
                  name: 'St-10',
                  value: 186,
                  code: 'Haas,Turning,St,St-10',
                  absolute_sum: 186,
                  relative_sum: 11.495673671199011
                },
                {
                  name: 'St-10Y',
                  value: 45,
                  code: 'Haas,Turning,St,St-10Y',
                  absolute_sum: 45,
                  relative_sum: 2.781211372064277
                },
                {
                  name: 'St-15L',
                  value: 10,
                  code: 'Haas,Turning,St,St-15L',
                  absolute_sum: 10,
                  relative_sum: 0.6180469715698393
                },
                {
                  name: 'St-15',
                  value: 53,
                  code: 'Haas,Turning,St,St-15',
                  absolute_sum: 53,
                  relative_sum: 3.2756489493201486
                },
                {
                  name: 'St-15Y',
                  value: 30,
                  code: 'Haas,Turning,St,St-15Y',
                  absolute_sum: 30,
                  relative_sum: 1.8541409147095178
                },
                {
                  name: 'St-20',
                  value: 183,
                  code: 'Haas,Turning,St,St-20',
                  absolute_sum: 183,
                  relative_sum: 11.31025957972806
                },
                {
                  name: 'St-20Y',
                  value: 203,
                  code: 'Haas,Turning,St,St-20Y',
                  absolute_sum: 203,
                  relative_sum: 12.546353522867737
                },
                {
                  name: 'St-25',
                  value: 54,
                  code: 'Haas,Turning,St,St-25',
                  absolute_sum: 54,
                  relative_sum: 3.3374536464771323
                },
                {
                  name: 'St-25Y',
                  value: 66,
                  code: 'Haas,Turning,St,St-25Y',
                  absolute_sum: 66,
                  relative_sum: 4.07911001236094
                },
                {
                  name: 'St-28Y',
                  value: 3,
                  code: 'Haas,Turning,St,St-28Y',
                  absolute_sum: 3,
                  relative_sum: 0.1854140914709518
                },
                {
                  name: 'St-30 And St-35',
                  value: 74,
                  code: 'Haas,Turning,St,St-30 And St-35',
                  absolute_sum: 74,
                  relative_sum: 4.573547589616811
                },
                {
                  name: 'St-30Ly',
                  value: 119,
                  code: 'Haas,Turning,St,St-30Ly',
                  absolute_sum: 119,
                  relative_sum: 7.354758961681088
                },
                {
                  name: 'St-30',
                  value: 193,
                  code: 'Haas,Turning,St,St-30',
                  absolute_sum: 193,
                  relative_sum: 11.928306551297899
                },
                {
                  name: 'St-30Y',
                  value: 80,
                  code: 'Haas,Turning,St,St-30Y',
                  absolute_sum: 80,
                  relative_sum: 4.944375772558715
                },
                {
                  name: 'St-35',
                  value: 25,
                  code: 'Haas,Turning,St,St-35',
                  absolute_sum: 25,
                  relative_sum: 1.5451174289245984
                },
                {
                  name: 'St-35Y',
                  value: 52,
                  code: 'Haas,Turning,St,St-35Y',
                  absolute_sum: 52,
                  relative_sum: 3.2138442521631645
                },
                {
                  name: 'St-40L',
                  value: 17,
                  code: 'Haas,Turning,St,St-40L',
                  absolute_sum: 17,
                  relative_sum: 1.0506798516687268
                },
                {
                  name: 'St-40',
                  value: 67,
                  code: 'Haas,Turning,St,St-40',
                  absolute_sum: 67,
                  relative_sum: 4.140914709517923
                },
                {
                  name: 'St-40Y',
                  value: 50,
                  code: 'Haas,Turning,St,St-40Y',
                  absolute_sum: 50,
                  relative_sum: 3.0902348578491967
                },
                {
                  name: 'St-45',
                  value: 21,
                  code: 'Haas,Turning,St,St-45',
                  absolute_sum: 21,
                  relative_sum: 1.2978986402966626
                }
              ],
              code: 'Haas,Turning,St',
              absolute_sum: 1618,
              relative_sum: 47.5742428697442
            },
            {
              name: 'Sl',
              children: [
                {
                  name: 'Sl-10',
                  value: 173,
                  code: 'Haas,Turning,Sl,Sl-10',
                  absolute_sum: 173,
                  relative_sum: 12.711241734019104
                },
                {
                  name: 'Sl20 Lathe',
                  value: 570,
                  code: 'Haas,Turning,Sl,Sl20 Lathe',
                  absolute_sum: 570,
                  relative_sum: 41.88096987509184
                },
                {
                  name: 'Sl-20',
                  value: 266,
                  code: 'Haas,Turning,Sl,Sl-20',
                  absolute_sum: 266,
                  relative_sum: 19.544452608376194
                },
                {
                  name: 'Sl-30',
                  value: 277,
                  code: 'Haas,Turning,Sl,Sl-30',
                  absolute_sum: 277,
                  relative_sum: 20.35268185157972
                },
                {
                  name: 'Sl-40',
                  value: 75,
                  code: 'Haas,Turning,Sl,Sl-40',
                  absolute_sum: 75,
                  relative_sum: 5.510653930933137
                }
              ],
              code: 'Haas,Turning,Sl',
              absolute_sum: 1361,
              relative_sum: 40.017641870038226
            }
          ],
          code: 'Haas,Turning',
          absolute_sum: 3401,
          relative_sum: 17.60898829864347
        },
        {
          name: 'Other',
          children: [
            {
              name: 'Hrt',
              children: [
                {
                  name: 'Hrt 160',
                  value: 15,
                  code: 'Haas,Other,Hrt,Hrt 160',
                  absolute_sum: 15,
                  relative_sum: 100
                }
              ],
              code: 'Haas,Other,Hrt',
              absolute_sum: 15,
              relative_sum: 22.058823529411764
            },
            {
              name: 'Ha',
              children: [
                {
                  name: 'Ha5C-T',
                  value: 53,
                  code: 'Haas,Other,Ha,Ha5C-T',
                  absolute_sum: 53,
                  relative_sum: 100
                }
              ],
              code: 'Haas,Other,Ha',
              absolute_sum: 53,
              relative_sum: 77.94117647058823
            }
          ],
          code: 'Haas,Other',
          absolute_sum: 68,
          relative_sum: 0.35207621414517964
        }
      ],
      total_value: 19314,
      code: 'Haas',
      absolute_sum: 19314,
      relative_sum: 16.96710942441493
    }
  ],
  absolute_sum: 113832
};
