import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import moreQuestionsImage from 'assets/more-questions.png';
import { Routes } from 'enums';

import styles from './styles.module.scss';

export const RequestCRMBoosterCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToNewChat = () => {
    navigate(Routes.NewChat);
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>
        {t('Page.CRMBooster.MoreQuestionsCard.Title')}
      </h3>
      <img
        alt="moreQuestionsImage"
        src={moreQuestionsImage}
        className={styles.image}
      />
      <div className={styles.description}>
        {t('Page.CRMBooster.MoreQuestionsCard.Description')}
      </div>
      <Button
        fullWidth
        size="medium"
        type="submit"
        color="primary"
        variant="contained"
        onClick={goToNewChat}
      >
        {t('Page.CRMBooster.MoreQuestionsCard.RequestCRMBooster')}
      </Button>
    </div>
  );
};
