import { useMutation } from '@tanstack/react-query';
import { getCRMBoosterConfirmation } from 'actions/paymentsActions';
import { ApiError } from 'entities/ApiError.entity';

import { useSnackbar } from '../useSnackbar';

export const useCRMBoosterConfirmation = (
  sessionId: string,
  onSuccess?: () => void,
  onError?: () => void
) => {
  const snackbar = useSnackbar();

  return useMutation<void, ApiError>({
    mutationFn: () => getCRMBoosterConfirmation(sessionId),
    onError(error) {
      if (onError) onError();

      snackbar.error.commonError(error);
    },
    onSuccess() {
      if (onSuccess) {
        onSuccess();
      }
    }
  });
};
