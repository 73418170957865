import { useMutation } from '@tanstack/react-query';
import { getCRMBoosterPaymentSecret } from 'actions/paymentsActions';
import { ApiError } from 'entities/ApiError.entity';
import { useSnackbar } from 'hooks/useSnackbar';

export const useCreateCRMBooster = (onSuccess: (secret: string) => void) => {
  const snackbar = useSnackbar();

  return useMutation<
    { clientSecret: string },
    ApiError,
    {
      industry?: string;
      question?: string;
      dashboardId: string;
    }
  >({
    mutationFn: ({ industry, question, dashboardId }) =>
      getCRMBoosterPaymentSecret({ industry, question, dashboardId }),
    onSuccess(data) {
      onSuccess(data.clientSecret);
    },
    onError(error) {
      snackbar.error.commonError(error);
    }
  });
};
