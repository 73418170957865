import { AxiosResponse } from 'axios';
import { CRMBooster } from 'entities/CRMBooster.entity';

import { ApiBase } from '../ApiBase';
import { mockedCRMBoosterCompanies } from './mocks';

export interface FetchCRMBoostersFilters {
  title?: string | null;
  limit?: number;
  offset?: number;
}

export interface FetchCRMBoosterCompaniesFilters {
  query?: string | null;
  limit?: number;
  offset?: number;
  region?: string | null;
  industry?: string | null;
  material?: string | null;
  technology?: string | null;
  machinesAmount?: number | null;
  employeeCount?: number | null;
}

class CRMBoosterApi extends ApiBase {
  fetchCRMBoosters(
    filters: FetchCRMBoostersFilters
  ): Promise<AxiosResponse<CRMBooster[]>> {
    return this.client.get<CRMBooster[]>('', {
      params: filters
    });
  }

  // eslint-disable-next-line class-methods-use-this
  fetchCRMBoosterCompanies(filters?: FetchCRMBoosterCompaniesFilters) {
    // return this.client.get<CRMBoosterCompany[]>('', {
    //   params: filters
    // });

    console.log('filters', filters);

    let index = 0;

    if (Number(filters?.offset) > 0) {
      index = 1;
    }

    return { data: mockedCRMBoosterCompanies[index] };
  }
}

const instance = new CRMBoosterApi('/crm-boosters');

export { instance as CRMBoosterApi };
