import { NotificationsFilters } from 'actions/notificationsActions';
import { FetchChatsFilters } from 'services/API/Chats/ChatsApi';
import {
  FetchCRMBoosterCompaniesFilters,
  FetchCRMBoostersFilters
} from 'services/API/CRMBooster/CRMBoosterApi';
import { FetchReportsFilters } from 'services/API/Reports/ReportsApi';

export const queryKeys = {
  updateEmailConfirm: ['updateEmailConfirm'],
  notifications: (filters?: NotificationsFilters) => ['notifications', filters],
  paginatedNotifications: ['notifications', 'paginated'],
  subscriptionPaymentsConfirmation: [
    'payments',
    'subscription',
    'confirmation'
  ],
  oneTimePaymentConfirmation: ['payments', 'oneTime', 'confirmation'],
  subscriptions: ['subscriptions'],
  activeSubscriptionDetails: ['subscription', 'active'],
  userInfo: ['user', 'info'],
  userAvatar: ['user', 'avatar'],
  userCoins: ['user', 'coins'],
  chats: ['chats'],
  paginatedChats: (filters?: FetchChatsFilters) => [
    'chats',
    'paginated',
    filters
  ],
  paginatedReports: (filters?: FetchReportsFilters) => [
    'reports',
    'paginated',
    filters
  ],
  paginatedCRMBoosters: (filters?: FetchCRMBoostersFilters) => [
    'crm-boosters',
    'paginated',
    filters
  ],
  CRMBoosterCompanies: (filters?: FetchCRMBoosterCompaniesFilters) => [
    'crm-booster-companies',
    filters
  ],
  chatsAnnouncement: ['chats', 'announcement'],
  dashboard: ['dashboard'],
  dashboardOtherMapData: ['dashboard', 'map', 'otherData'],
  dashboardRegionsMapData: ['dashboard', 'map', 'regions'],
  dashboards: ['dashboards'],
  messages: ['messages'],
  questions: ['questions'],
  dashboardDetails: (messageId: string): string[] => [
    ...queryKeys.dashboard,
    'details',
    messageId
  ],
  chatMessages: (chatId: string): string[] => [
    ...queryKeys.chats,
    chatId,
    ...queryKeys.messages
  ],
  chatDetails: (chatId: string): string[] => [...queryKeys.chats, chatId],
  nestedDashboards: (dashboardId: string): string[] => [
    ...queryKeys.dashboards,
    dashboardId,
    'filtered'
  ],
  freeSnippetAvailability: ['freeSnippet', 'available'],
  crmBoosterAvailableRows: ['crmBooster', 'available', 'rows']
};
