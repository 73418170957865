import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from 'assets/InfoIcon.svg';
import cn from 'classnames';
import { Pie } from 'components/Pie';
import type { SelectedFilterItem } from 'types';

import { mockedMaterialsData } from './mocks';

import styles from './styles.module.scss';

const chartMeasurements = {
  activeSector: {
    innerRadiusIndentation: 42,
    outerRadiusIndentation: 48
  },
  full: {
    textY: 10,
    startAngle: -360,
    cy: '50%',
    innerRadius: 150,
    outerRadius: 190
  },
  half: {
    textY: -10,
    startAngle: 180,
    cy: '70%',
    innerRadius: 75,
    outerRadius: 105
  }
};

interface Props {
  isActive: boolean;
  selectedMaterial: SelectedFilterItem;
  setSelectedMaterial: Dispatch<SetStateAction<SelectedFilterItem>>;
}

export const CRMBoosterDetailsMaterialsTab: FC<Props> = ({
  isActive,
  selectedMaterial,
  setSelectedMaterial
}) => {
  const [activeIndex, setActiveIndex] = useState<number>();

  const { t } = useTranslation();

  return (
    <div className={cn(styles.container, isActive && styles.active)}>
      <h2 className={styles.title}>
        {t('Page.CRMBoosterDetails.Tabs.Materials.Title')}
      </h2>

      <div className={styles.note}>
        <InfoIcon />

        {selectedMaterial ? (
          <Trans
            i18nKey="Page.CRMBoosterDetails.Tabs.Materials.Filter.Chart.Notes.Active"
            values={{ material: selectedMaterial?.name }}
          >
            Click Recalculate to apply the
            <span className={styles.highlighted}>{selectedMaterial?.name}</span>
            filter.
          </Trans>
        ) : (
          t('Page.CRMBoosterDetails.Tabs.Materials.Filter.Chart.Notes.Inactive')
        )}
      </div>

      <Pie
        fullWidth
        activeIndex={activeIndex}
        data={mockedMaterialsData}
        chartClassname={styles.chart}
        selectedItem={selectedMaterial}
        changeActiveIndex={setActiveIndex}
        chartMeasurements={chartMeasurements}
        setSelectedItem={setSelectedMaterial}
      />
    </div>
  );
};
