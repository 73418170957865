import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FilterItem,
  FloatingFilter
} from 'components/FloatingFilter/FloatingFilter';
import { useMobile, useTablet } from 'hooks';

import { CRMBoosterAppliedFilters } from '../CRMBoosterDetails';
import { BigNumberCard } from './BigNumberCard';
import { CRMBoosterDetailsTable } from './CRMBoosterDetailsTable';

import styles from './styles.module.scss';

interface Props {
  appliedFilters: Partial<CRMBoosterAppliedFilters>;
  updatedFilters: FilterItem[];
  onRemoveFilter(row: FilterItem): void;
}

export const CRMBoosterDetailsContent: FC<Props> = ({
  appliedFilters,
  updatedFilters,
  onRemoveFilter
}) => {
  const { t } = useTranslation();
  const isTablet = useTablet();
  const isMobile = useMobile();

  const cards = [
    {
      title: t('Page.CRMBooster.Companies'),
      value: 6900
    },
    {
      title: t('Page.CRMBooster.Machines'),
      value: 136000
    },
    {
      title: t('Page.CRMBooster.Employees'),
      value: 345000
    }
  ];

  return (
    <div className={styles['container-wrapper']}>
      {!isTablet && !isMobile && (
        <FloatingFilter
          data={updatedFilters}
          className={styles.filter}
          onRemoveFilter={onRemoveFilter}
        />
      )}
      <div className={styles.container}>
        <div className={styles['cards-container']}>
          {cards.map((card) => (
            <BigNumberCard
              key={card.title}
              title={card.title}
              value={card.value}
            />
          ))}
        </div>
        <CRMBoosterDetailsTable appliedFilters={appliedFilters} />
      </div>
    </div>
  );
};
