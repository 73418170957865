import { FC } from 'react';
import { Slider } from '@mui/material';

import styles from './styles.module.scss';

export type RangeSelectorValue = number | number[];

interface Props {
  min?: number;
  max?: number;
  name?: string;
  label?: string;
  value: RangeSelectorValue;
  disabled?: boolean;
  onChange(event: Event, value: RangeSelectorValue, activeThumb: number): void;
}

export const RangeSelector: FC<Props> = ({
  min,
  max,
  name,
  value,
  label,
  disabled,
  onChange
}) => {
  const sliderHasTwoEdges = Array.isArray(value);
  const numberFormatter = new Intl.NumberFormat('en-US');

  return (
    <div className={styles.container}>
      {label && (
        <span className={styles.label} aria-label="Number of Employees">
          {label}
        </span>
      )}
      {sliderHasTwoEdges && (
        <div className={styles.values}>
          <span className={styles.value}>
            {numberFormatter.format(value[0])}
          </span>
          <span className={styles.value}>
            {numberFormatter.format(value[1])}
          </span>
        </div>
      )}
      <Slider
        min={min}
        max={max}
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
        className={styles.slider}
        valueLabelDisplay="off"
      />
    </div>
  );
};
