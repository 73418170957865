import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PricingWrapper } from '../PricingWrapper';
import { BoosterCard } from './BoosterCard';

import styles from './styles.module.scss';

const questions = [
  {
    title: 'Page.Pricing.CRMBooster.Questions.List.First.Title',
    description: 'Page.Pricing.CRMBooster.Questions.List.First.Description'
  },
  {
    title: 'Page.Pricing.CRMBooster.Questions.List.Second.Title',
    description: 'Page.Pricing.CRMBooster.Questions.List.Second.Description'
  },
  {
    title: 'Page.Pricing.CRMBooster.Questions.List.Third.Title',
    description: 'Page.Pricing.CRMBooster.Questions.List.Third.Description'
  },
  {
    title: 'Page.Pricing.CRMBooster.Questions.List.Fourth.Title',
    description: 'Page.Pricing.CRMBooster.Questions.List.Fourth.Description'
  }
];

export const CRMBooster: FC = () => {
  const { t } = useTranslation();

  return (
    <PricingWrapper
      questions={questions}
      title={t('Page.Pricing.CRMBooster.Title')}
      subtitle={t('Page.Pricing.CRMBooster.Subtitle')}
    >
      <div className={styles.container}>
        <BoosterCard />
      </div>
    </PricingWrapper>
  );
};
