export const STORAGE_KEY_PREFIX = 'GIENI_STORAGE_';
export const APP_UI_LANGUAGE = 'appUiLanguage';
export const USER_AUTHENTICATED = 'userAuthenticated';
export const SAVED_USER_QUERY = 'savedUserQuery';
export const REDIRECT_PATH = 'redirectPath';
export const SSE_CONNECTION_OPENED = 'sseConnectionOpened';
export const DEFAULT_PREFERRED_LANGUAGE = 'en';

export const DAYS_BEFORE_AUTO_CANCELLATION = 7;

export const SHOW_LOGIN_MODAL = 'showLoginModal';

export const SHOW_NEW_DEVELOPMENTS_TOAST = 'showNewDevelopmentsToast';

export const DEFAULT_NOTIFICATIONS_FILTERS = {
  limit: 2,
  offset: 0,
  isViewed: false
};

export const DEFAULT_SIDEBAR_CHATS_FILTERS = {
  includeArchived: false,
  limit: 3
};

export const DEFAULT_REPORTS_FILTERS = {
  limit: 10,
  offset: 0
};

export const DEFAULT_CRM_BOOSTER_FILTERS = {
  limit: 3
};

export const AVAILABLE_LANGUAGES = ['en', 'de'];

export const APPOINTMENT_CALENDAR_URL =
  'https://calendar.app.google/KpuQuadq9grySrRx8';
