export const mockedMaterialsData: any = {
  title: 'Materials',
  values: [
    16.300000000000033, 8.3, 6.7, 6, 5.9, 4.4, 4.1, 3.9, 3.6, 3.3, 3.3, 3.3,
    2.7, 2.6, 2.6, 2.6, 2.5, 2.4, 2.4, 2.3, 2.3, 2.2, 2.1, 2.1, 2.1
  ],
  labels: [
    'cnc machining',
    'automation',
    'metal fabrication',
    'welding',
    'surface treatment',
    'welding processes',
    'laser cutting',
    'cnc milling',
    'robotics',
    'cnc turning',
    'metals',
    'automation technology',
    'forming',
    'metalworking',
    'industrial automation',
    'cnc technology',
    'engineering and design',
    'control systems',
    'cutting',
    'engineering',
    'laser technology',
    '3d printing',
    'grinding',
    'precision machining',
    'turning'
  ],
  name: 'Materials',
  type: 'materials_pie',
  absolute_values: [
    72961, 37566, 30166, 26970, 26752, 19786, 18488, 17556, 16315, 15087, 14962,
    14802, 12378, 11764, 11647, 11530, 11138, 11073, 11016, 10622, 10342, 10133,
    9683, 9681, 9594
  ]
};
