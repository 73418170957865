import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Skeleton } from '@mui/material';
import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import { ReactComponent as GieniReportIcon } from 'assets/GieniReportIcon.svg';
import reportLoadedImage from 'assets/report-loaded.png';
import reportLoadingImage from 'assets/report-loading.png';
import cn from 'classnames';
import { CommonTooltip, Tooltip } from 'components';
import { Report } from 'entities/Report.entity';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import styles from './styles.module.scss';

interface Props {
  data: Report;
  loading?: boolean;
}

export const ReportCard: FC<Props> = ({ data, loading }) => {
  const { t } = useTranslation();

  const handleViewReport = () => {
    mixpanel?.track(MixpanelEvents.ViewReportClick, {
      'Report URL': data.filename
    });

    window.open(
      `${process.env.REACT_APP_BASE_URL}/reports/${data.id}/download?inline=true`
    );
  };

  const reportTitle = loading
    ? t('Page.Report.LoadingReportCard.Title')
    : data.title || '';

  const handleDownloadReport = () => {
    mixpanel?.track(MixpanelEvents.DownloadReportClick, {
      'Report URL': data.filename
    });

    window.open(
      `${process.env.REACT_APP_BASE_URL}/reports/${data.id}/download`
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles['title-container']}>
        <Tooltip title={reportTitle}>
          <h3 className={cn(styles.title, 'overflowed-text-multiline')}>
            {reportTitle}
          </h3>
        </Tooltip>

        {!loading && (
          <DownloadIcon
            className={styles.download}
            onClick={handleDownloadReport}
          />
        )}
      </div>

      <div className={styles['report-type']}>
        {loading ? (
          <Skeleton variant="text" className={styles.skeleton} />
        ) : (
          <>
            <GieniReportIcon />

            {t('Page.Report.ReportCard.GieniReport')}
          </>
        )}
      </div>
      <img
        alt="report"
        className={styles.image}
        src={loading ? reportLoadingImage : reportLoadedImage}
      />
      {loading ? (
        <div className={styles.description}>
          <Skeleton variant="text" className={styles.skeleton} />
          <Skeleton variant="text" className={styles.skeleton} />
          <Skeleton variant="text" className={styles.skeleton} />
          <Skeleton variant="text" className={styles.skeleton} />
          <Skeleton variant="text" className={styles.skeleton} />
          <Skeleton variant="text" className={styles.skeleton} />
          <Skeleton variant="text" className={styles.skeleton} />
        </div>
      ) : (
        <CommonTooltip
          title={data.description || ''}
          className={cn(styles.description, styles.loaded)}
        />
      )}
      <div className={styles['footer-container']}>
        {loading ? (
          t('Page.Report.LoadingReportCard.Description')
        ) : (
          <Button
            fullWidth
            size="medium"
            type="submit"
            color="primary"
            variant="outlined"
            onClick={handleViewReport}
          >
            {t('Page.Report.ViewReport')}
          </Button>
        )}
      </div>
    </div>
  );
};
