import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import boosterImagePrimary from 'assets/crm-booster-primary.png';
import boosterImageSecondary from 'assets/crm-booster-secondary.png';
import cn from 'classnames';
import { Card, Tooltip } from 'components';
import { Routes } from 'enums';
import { useMobile } from 'hooks';
import { useUserInfo } from 'hooks/api';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import styles from './styles.module.scss';

export const BoosterCard = () => {
  const isMobile = useMobile();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: userInfo } = useUserInfo();

  const handleBuyDataClick = () => {
    mixpanel?.track(MixpanelEvents.PricingPageRequestCRMBooster);

    navigate(Routes.NewChat);
  };

  const renderImages = () => (
    <div className={styles['image-wrapper']}>
      <img
        alt="crm-booster-primary"
        src={boosterImagePrimary}
        className={styles['image-primary']}
      />
      <img
        alt="crm-booster-primary"
        src={boosterImageSecondary}
        className={styles['image-secondary']}
      />
    </div>
  );

  return (
    <Card className={cn(styles.card)}>
      <div className={styles.container}>
        <div>
          <h3 className={styles.title}>
            {t('Page.Pricing.CRMBooster.PricingInfo')}
          </h3>
          <p className={styles.description}>
            <Trans i18nKey="Page.Pricing.CRMBooster.PricingDescription">
              CRM Booster records are priced at
              <span className={styles.highlighted}>€1.5</span>
              per line. The total cost will be calculated based on the number of
              records matching your request.
            </Trans>
          </p>
        </div>

        {isMobile && renderImages()}
        <Tooltip
          disabled={!userInfo?.deactivatedAt}
          title={t('Common.DeactivatedAccountNote')}
        >
          <span>
            <Button
              size="medium"
              color="primary"
              variant="contained"
              className={styles.button}
              onClick={handleBuyDataClick}
              disabled={!!userInfo?.deactivatedAt}
            >
              {t('Page.Pricing.CRMBooster.BuyData')}
            </Button>
          </span>
        </Tooltip>
      </div>

      {!isMobile && renderImages()}
    </Card>
  );
};
