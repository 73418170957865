import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from 'assets/InfoIcon.svg';
import cn from 'classnames';
import { BackdropOverlay, CommonTooltip } from 'components';
import type { SelectedFilterItem } from 'types';

import styles from './styles.module.scss';

interface Props {
  isPending?: boolean;
  notes: {
    active: string;
    inactive: string;
  };
  placeholder: string;
  selectedItem?: SelectedFilterItem;
}

export const CRMBoosterFilter: FC<Props> = ({
  notes,
  isPending,
  placeholder,
  selectedItem
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      {isPending && (
        <BackdropOverlay
          text={t('Page.CRMBoosterDetails.BackdropText')}
          isPending={isPending}
        />
      )}

      <div className={styles['filter-wrapper']}>
        <div className={styles.filter}>
          <CommonTooltip
            title={selectedItem?.name || placeholder}
            className={cn(
              styles['filter-value'],
              'overflowed-text',
              selectedItem && styles['has-value']
            )}
          />
        </div>
      </div>

      <div className={styles.note}>
        <InfoIcon />

        {selectedItem ? notes.active : notes.inactive}
      </div>
    </div>
  );
};
