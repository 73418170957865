import { ApiError } from 'entities/ApiError.entity';
import { CRMBooster } from 'entities/CRMBooster.entity';
import { CRMBoosterCompany } from 'entities/CRMBoosterCompany.entity';
import {
  CRMBoosterApi,
  FetchCRMBoosterCompaniesFilters,
  FetchCRMBoostersFilters
} from 'services/API/CRMBooster/CRMBoosterApi';

export const fetchCRMBoosters = async (
  filters: FetchCRMBoostersFilters
): Promise<CRMBooster[]> => {
  try {
    const response = await CRMBoosterApi.fetchCRMBoosters(filters);

    return response.data.map(CRMBooster.deserialize);
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchCRMBoosterCompanies = async (
  filters?: FetchCRMBoosterCompaniesFilters
): Promise<CRMBoosterCompany[]> => {
  try {
    const response = await CRMBoosterApi.fetchCRMBoosterCompanies(filters);

    return response.data.map(CRMBoosterCompany.deserialize);
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};
