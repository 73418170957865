import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { Button } from '@mui/material';
import cn from 'classnames';
import { Modal, Tooltip } from 'components';
import { Routes } from 'enums';
import { useCreateReport, useUserInfo } from 'hooks/api';
import { useCreateFreeSnippet } from 'hooks/api/useCreateFreeSnippet';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import { RequestFreeReportSuccessModal } from './RequestFreeReportSuccessModal';

import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  industry?: string;
  question: string;
  disabled?: boolean;
  hasValidation?: boolean;
}

const INDUSTRY_FIELD_LENGTH = {
  max: 100,
  min: 3
};

const QUESTION_FIELD_LENGTH = {
  max: 500,
  min: 5
};

export const RequestReportModal: FC<Props> = ({
  disabled = true,
  hasValidation,
  isOpen,
  onClose,
  industry = '',
  question
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { chatId, dashboardId } = useParams();

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [userQuestion, setUserQuestion] = useState(question);
  const [userIndustry, setUserIndustry] = useState(industry);

  const [questionError, setQuestionError] = useState('');
  const [industryError, setIndustryError] = useState('');

  useEffect(() => {
    if (userQuestion !== question) {
      setUserQuestion(question);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const { data: userInfo } = useUserInfo();

  const onGenerateSecretSuccess = () => {
    onClose();
    navigate({
      pathname: Routes.ReportCheckout,
      search: createSearchParams({
        industry: userIndustry || '',
        question: userQuestion || ''
      }).toString()
    });
  };

  const { mutate: createReport } = useCreateReport(onGenerateSecretSuccess);

  const getTrackPlace = () => {
    const { pathname } = window.location;
    let place = '-';

    if (pathname.includes('chat')) place = 'Chat';
    if (pathname.includes('dashboard')) place = 'Dashboard';

    return place;
  };

  const onSuccess = () => {
    mixpanel?.track(MixpanelEvents.SuccessfulRequestForFreeSnippet, {
      Place: getTrackPlace(),
      'Chat ID': chatId,
      'Dashboard ID': dashboardId || '-'
    });
    onClose();
    setIsSuccessModalOpen(true);
  };

  const onError = () => {
    mixpanel?.track(MixpanelEvents.UnsuccessfulRequestForFreeSnippet, {
      Place: getTrackPlace(),
      'Chat ID': chatId,
      'Dashboard ID': dashboardId || '-'
    });
  };

  const { mutate: requestFreeSnippet } = useCreateFreeSnippet(
    onSuccess,
    onError
  );

  const closeSuccessModal = useCallback(() => {
    setIsSuccessModalOpen(false);
  }, []);

  const checkQuestionError = (value: string) => {
    let error;
    if (value?.length < QUESTION_FIELD_LENGTH.min) {
      error = t('Page.RequestReportModal.Errors.MinLength', {
        amount: QUESTION_FIELD_LENGTH.min
      });
    } else if (value?.length > QUESTION_FIELD_LENGTH.max) {
      error = t('Page.RequestReportModal.Errors.MaxLength', {
        amount: QUESTION_FIELD_LENGTH.max
      });
    }

    setQuestionError(error || '');

    return error;
  };

  const checkIndustryError = (value: string) => {
    let error;
    if (value?.length < INDUSTRY_FIELD_LENGTH.min) {
      error = t('Page.RequestReportModal.Errors.MinLength', {
        amount: INDUSTRY_FIELD_LENGTH.min
      });
    } else if (value?.length > INDUSTRY_FIELD_LENGTH.max) {
      error = t('Page.RequestReportModal.Errors.MaxLength', {
        amount: INDUSTRY_FIELD_LENGTH.max
      });
    }

    setIndustryError(error || '');

    return error;
  };

  const checkErrors = () => {
    if (!hasValidation) {
      return false;
    }

    const userQuestionError = checkQuestionError(userQuestion);
    const userIndustryError = checkIndustryError(userIndustry);

    return userQuestionError || userIndustryError;
  };

  const createFreeSample = () => {
    if (checkErrors()) {
      return;
    }

    mixpanel?.track(MixpanelEvents.RequestForFreeSnippet, {
      Place: getTrackPlace(),
      'Chat ID': chatId,
      'Dashboard ID': dashboardId || '-'
    });

    requestFreeSnippet({
      industry: userIndustry,
      question: userQuestion
    });
  };

  const goToCheckout = () => {
    if (checkErrors()) {
      return;
    }

    mixpanel?.track(MixpanelEvents.RequestForFullReport, {
      Place: getTrackPlace(),
      'Chat ID': chatId,
      'Dashboard ID': dashboardId || '-'
    });

    createReport({
      industry: userIndustry || '',
      question: userQuestion || ''
    });
  };

  const changeIndustry = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setUserIndustry(e.target.value);
    checkIndustryError(e.target.value);
  };

  const changeQuestion = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setUserQuestion(e.target.value);
    checkQuestionError(e.target.value);
  };

  return (
    <>
      <RequestFreeReportSuccessModal
        place={getTrackPlace()}
        isOpen={isSuccessModalOpen}
        onClose={closeSuccessModal}
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        titleClassName={styles.title}
        title={t('Page.RequestReportModal.Title')}
      >
        <p className={styles.subtitle}>
          {t('Page.RequestReportModal.Subtitle')}
        </p>

        <div className={styles['main-content']}>
          <div className={styles['input-container']}>
            <span className={styles.label}>
              {t('Page.RequestReportModal.Industry')}
            </span>
            <TextareaAutosize
              maxRows={1}
              minLength={3}
              maxLength={100}
              value={userIndustry}
              disabled={disabled}
              onChange={changeIndustry}
              className={cn(styles.container, 'capitalize')}
              placeholder={t('Page.RequestReportModal.IndustryPlaceholder')}
            />
            {industryError && <p className={styles.error}>{industryError}</p>}
          </div>
          <div className={styles['input-container']}>
            <span className={styles.label}>
              {t('Page.RequestReportModal.AdditionalInformation')}
            </span>
            <TextareaAutosize
              minRows={2}
              minLength={5}
              maxLength={500}
              disabled={disabled}
              onChange={changeQuestion}
              placeholder={t('Page.RequestReportModal.QuestionPlaceholder')}
              value={
                question
                  ? t(
                      'Page.RequestReportModal.AdditionalInformationPlaceholder',
                      { question: userQuestion }
                    )
                  : userQuestion
              }
              className={styles.container}
            />
            {questionError && <p className={styles.error}>{questionError}</p>}
          </div>
          <p className={cn(styles.subtitle, styles.indented)}>
            {t('Page.RequestReportModal.AdditionalInfo', {
              discountPrice: '149',
              fullPrice: '299'
            })}
          </p>
        </div>

        <div className={styles.buttons}>
          <Tooltip
            disabled={!userInfo?.deactivatedAt}
            title={t('Common.DeactivatedAccountNote')}
          >
            <span className="full-width">
              <Button
                fullWidth
                size="medium"
                type="submit"
                color="primary"
                variant="outlined"
                onClick={createFreeSample}
                className={styles.button}
                disabled={!!userInfo?.deactivatedAt}
              >
                {t('Page.RequestReportModal.GetFreeSnippet')}
              </Button>
            </span>
          </Tooltip>

          <Tooltip
            disabled={!userInfo?.deactivatedAt}
            title={t('Common.DeactivatedAccountNote')}
          >
            <span className="full-width">
              <Button
                fullWidth
                size="medium"
                color="primary"
                variant="contained"
                className={styles.button}
                onClick={goToCheckout}
                disabled={!!userInfo?.deactivatedAt}
              >
                {t('Page.RequestReportModal.RequestFullReport')}
              </Button>
            </span>
          </Tooltip>
        </div>
      </Modal>
    </>
  );
};
