import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import type { SelectedFilterItem } from 'types';
import { ZoomableSunburst } from 'views/DashboardV2/ZoomableSunburstChart/ZoomableSunburst';

import { CRMBoosterFilter } from '../CRMBoosterFilter';
import { mockedIndustryData } from './mocks';

import styles from './styles.module.scss';

interface Props {
  isActive: boolean;
  selectedIndustry: SelectedFilterItem;
  setSelectedIndustry: Dispatch<SetStateAction<SelectedFilterItem>>;
}

export const CRMBoosterDetailsIndustryTab: FC<Props> = ({
  isActive,
  selectedIndustry,
  setSelectedIndustry
}) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.container, isActive && styles.active)}>
      <h2 className={styles.title}>
        {t('Page.CRMBoosterDetails.Tabs.Industry.Title')}
      </h2>

      <CRMBoosterFilter
        placeholder={t(
          'Page.CRMBoosterDetails.Tabs.Industry.Filter.Chart.Placeholder'
        )}
        selectedItem={selectedIndustry}
        notes={{
          inactive: t(
            'Page.CRMBoosterDetails.Tabs.Industry.Filter.Chart.Notes.Inactive'
          ),
          active: t(
            'Page.CRMBoosterDetails.Tabs.Industry.Filter.Chart.Notes.Active',
            { industry: selectedIndustry?.name }
          )
        }}
      />

      <div className={styles['chart-container']}>
        <ZoomableSunburst
          data={mockedIndustryData}
          selectItem={setSelectedIndustry}
        />
      </div>
    </div>
  );
};
