export const mockedCRMBoosterCompanies = [
  [
    {
      id: 1,
      name: 'John Doe',
      company: 'McDonalds',
      industry: 'Food',
      email: 'john@example.com',
      website: 'https://www.mcdonalds.com'
    },
    {
      id: 2,
      name: 'Jane Smith',
      company: 'Starbucks',
      industry: 'Beverage',
      email: 'jane@example.com',
      website: 'https://www.starbucks.com'
    },
    {
      id: 3,
      name: 'Robert Johnson',
      company: 'Google',
      industry: 'Technology',
      email: 'robert@example.com',
      website: 'https://www.google.com'
    },
    {
      id: 4,
      name: 'Emily Davis',
      company: 'Apple',
      industry: 'Technology',
      email: 'emily@example.com',
      website: 'https://www.apple.com'
    },
    {
      id: 5,
      name: 'Michael Brown',
      company: 'Walmart',
      industry: 'Retail',
      email: 'michael@example.com',
      website: 'https://www.walmart.com'
    },
    {
      id: 6,
      name: 'Sarah Wilson',
      company: 'Coca-Cola',
      industry: 'Beverage',
      email: 'sarah@example.com',
      website: 'https://www.coca-cola.com'
    },
    {
      id: 7,
      name: 'David Lee',
      company: 'Amazon',
      industry: 'E-commerce',
      email: 'david@example.com',
      website: 'https://www.amazon.com'
    },
    {
      id: 8,
      name: 'Laura Miller',
      company: 'Microsoft',
      industry: 'Technology',
      email: 'laura@example.com',
      website: 'https://www.microsoft.com'
    },
    {
      id: 9,
      name: 'James Martinez',
      company: 'Tesla',
      industry: 'Automotive',
      email: 'james@example.com',
      website: 'https://www.tesla.com'
    },
    {
      id: 10,
      name: 'Linda Anderson',
      company: 'Pepsi',
      industry: 'Beverage',
      email: 'linda@example.com',
      website: 'https://www.pepsi.com'
    }
  ],
  [
    {
      id: 11,
      name: 'William Thomas',
      company: 'Sony',
      industry: 'Electronics',
      email: 'william@example.com',
      website: 'https://www.sony.com'
    },
    {
      id: 12,
      name: 'Barbara Jackson',
      company: 'Nike',
      industry: 'Apparel',
      email: 'barbara@example.com',
      website: 'https://www.nike.com'
    },
    {
      id: 13,
      name: 'Christopher White',
      company: 'Facebook',
      industry: 'Technology',
      email: 'christopher@example.com',
      website: 'https://www.facebook.com'
    },
    {
      id: 14,
      name: 'Patricia Harris',
      company: 'Procter & Gamble',
      industry: 'Consumer Goods',
      email: 'patricia@example.com',
      website: 'https://www.pg.com'
    },
    {
      id: 15,
      name: 'Matthew Clark',
      company: 'Netflix',
      industry: 'Entertainment',
      email: 'matthew@example.com',
      website: 'https://www.netflix.com'
    },
    {
      id: 16,
      name: 'Susan Robinson',
      company: 'Samsung',
      industry: 'Electronics',
      email: 'susan@example.com',
      website: 'https://www.samsung.com'
    },
    {
      id: 17,
      name: 'Anthony Lewis',
      company: 'Adidas',
      industry: 'Apparel',
      email: 'anthony@example.com',
      website: 'https://www.adidas.com'
    },
    {
      id: 18,
      name: 'Jessica Hall',
      company: 'Intel',
      industry: 'Technology',
      email: 'jessica@example.com',
      website: 'https://www.intel.com'
    },
    {
      id: 19,
      name: 'Andrew Young',
      company: 'Oracle',
      industry: 'Software',
      email: 'andrew@example.com',
      website: 'https://www.oracle.com'
    }
    // Uncomment below for the 20th entry:
    // {
    //   id: 20,
    //   name: 'Karen King',
    //   company: 'IBM',
    //   industry: 'Technology',
    //   email: 'karen@example.com',
    //   website: 'https://www.ibm.com'
    // }
  ]
];

export const mockedCRMBoosterCompaniesMaxAmount =
  mockedCRMBoosterCompanies.flat().length;
