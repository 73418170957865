import { useEffect } from 'react';
import { Notification } from 'entities/Notification.entity';
import { useUpdateNotificationsStatus } from 'hooks/api';

export const useReadFetchedNotifications = (notifications?: Notification[]) => {
  const { mutate: updateNotificationsStatusMutation } =
    useUpdateNotificationsStatus();

  useEffect(() => {
    if (notifications?.length) {
      updateNotificationsStatusMutation();
    }
  }, [notifications, updateNotificationsStatusMutation]);
};
