import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import cn from 'classnames';
import { RequestReportModal, Tooltip } from 'components';
import { DashboardData } from 'entities/Dashboard.entity';
import { queryKeys } from 'enums';
import { useIsExternalUserDashboard } from 'hooks';
import { useUserInfo } from 'hooks/api';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import styles from './styles.module.scss';

export const FullReportsAd = () => {
  const { t } = useTranslation();
  const isExternalUserDashboard = useIsExternalUserDashboard();
  const { chatId, dashboardId } = useParams();
  const { data: userInfo } = useUserInfo();

  const [isReportModalOpen, setReportModalOpen] = useState(false);

  const { data: dashboardData } = useQuery<DashboardData>({
    queryKey: queryKeys.dashboardDetails(dashboardId!)
  });

  const handleCtaClick = () => {
    mixpanel?.track(MixpanelEvents.OpenRequestReportModal, {
      Place: 'Dashboard',
      'Chat ID': chatId,
      'Dashboard ID': dashboardId
    });

    setReportModalOpen(true);
  };

  const handleCloseModal = () => {
    mixpanel?.track(MixpanelEvents.CloseRequestReportModal, {
      Place: 'Dashboard',
      'Chat ID': chatId,
      'Dashboard ID': dashboardId
    });

    setReportModalOpen(false);
  };

  const industriesList = dashboardData?.nestedIndustries?.length
    ? dashboardData?.nestedIndustries
    : dashboardData?.rootIndustries;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.heading}>
          <h2 className={styles.title}>
            {t('Page.Dashboard.FullReportsAd.Title')}
          </h2>
          <span className={styles.subtitle}>
            {t('Page.Dashboard.FullReportsAd.Subtitle')}
          </span>
        </div>

        <div className={styles.actions}>
          <Tooltip
            disabled={!userInfo?.deactivatedAt}
            title={t('Common.DeactivatedAccountNote')}
          >
            <span className="flex">
              <Button
                fullWidth
                size="medium"
                color="primary"
                variant="outlined"
                onClick={handleCtaClick}
                className={cn(styles.cta)}
                disabled={!!userInfo?.deactivatedAt || !dashboardData?.title}
              >
                {t('Page.Dashboard.FullReportsAd.CtaFree')}
              </Button>
            </span>
          </Tooltip>

          <Tooltip
            disabled={!userInfo?.deactivatedAt}
            title={t('Common.DeactivatedAccountNote')}
          >
            <span className="flex">
              <Button
                size="medium"
                color="primary"
                variant="contained"
                onClick={handleCtaClick}
                className={cn(styles.cta, 'brilliance')}
                disabled={
                  isExternalUserDashboard ||
                  !!userInfo?.deactivatedAt ||
                  !dashboardData?.title ||
                  !!userInfo?.deactivatedAt
                }
              >
                {t('Page.Dashboard.FullReportsAd.CtaFull')}
              </Button>
            </span>
          </Tooltip>
        </div>
      </div>

      <RequestReportModal
        isOpen={isReportModalOpen}
        onClose={handleCloseModal}
        question={dashboardData?.title || ''}
        industry={industriesList?.join(', ')}
      />
    </>
  );
};
