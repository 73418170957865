import { deserialize, serializable } from 'serializr';

export class CRMBooster {
  @serializable
  id: string = '';

  @serializable
  title?: string = '';

  @serializable
  companyCount: number = 0;

  @serializable
  employeeCount: number = 0;

  @serializable
  imageUrl: string = '';

  @serializable
  filename?: string = '';

  static deserialize(json: Object | string): CRMBooster {
    return deserialize(CRMBooster, json);
  }
}
