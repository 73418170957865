import { AxiosResponse } from 'axios';
import { Report } from 'entities/Report.entity';

import { ApiBase } from '../ApiBase';

export interface FetchReportsFilters {
  title?: string | null;
  limit?: number;
  offset?: number;
}

class ReportsApi extends ApiBase {
  fetchReports(filters: FetchReportsFilters): Promise<AxiosResponse<Report[]>> {
    return this.client.get<Report[]>('', {
      params: filters
    });
  }

  requestFreeSnippet(body: { question: string; industry?: string }) {
    return this.client.post<void>('/free-snippet', body);
  }

  checkFreeSnippetAvailability() {
    return this.client.head<void>('/check-free-snippet');
  }
}

const instance = new ReportsApi('/reports');

export { instance as ReportsApi };
