import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { CRMBoosterFilters } from 'views/CRMBoosterDetails/CRMBoosterDetails';

import styles from './styles.module.scss';

interface Props {
  defaultFilters: CRMBoosterFilters;
  updatedFilters: CRMBoosterFilters;
  resetToDefaultFilters(): void;
  recalculateFilters(): void;
}

export const CRMBoosterDetailsFiltersActions: FC<Props> = ({
  defaultFilters,
  updatedFilters,
  resetToDefaultFilters,
  recalculateFilters
}) => {
  const { t } = useTranslation();

  const isActionsDisabled = useMemo(() => {
    return (Object.keys(defaultFilters) as (keyof CRMBoosterFilters)[]).every(
      (key) =>
        JSON.stringify(defaultFilters[key]) ===
        JSON.stringify(updatedFilters[key])
    );
  }, [defaultFilters, updatedFilters]);

  return (
    <div className={styles.container}>
      <Button
        size="medium"
        color="primary"
        variant="outlined"
        disabled={isActionsDisabled}
        className={styles.action}
        onClick={resetToDefaultFilters}
      >
        {t('Page.CRMBoosterDetails.Actions.ResetToDefault')}
      </Button>
      <Button
        size="medium"
        color="primary"
        variant="contained"
        disabled={isActionsDisabled}
        className={styles.action}
        onClick={recalculateFilters}
      >
        {t('Page.CRMBoosterDetails.Actions.Recalculate')}
      </Button>
      <Button
        size="medium"
        color="primary"
        variant="outlined"
        className={styles.action}
      >
        {t('Page.CRMBoosterDetails.Actions.ExportData')}
      </Button>
    </div>
  );
};
