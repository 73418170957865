export enum SSEEvents {
  Error = 'error',
  NewChat = 'new-chat',
  NewReport = 'new-report',
  UpdateReport = 'update-report',
  Notification = 'notification',
  NewCRMBooster = 'new-crm-booster',
  DashboardTitle = 'dashboard-title',
  NewChatMessage = 'new-chat-message',
  ReassignMainTab = 'reassign-main-tab',
  DashboardUpdate = 'dashboard-update',
  UpdateCRMBooster = 'update-crm-booster',
  UnauthorizedError = 'unauthorized-error',
  DashboardRateWidget = 'dashboard-rate-widget',
  DashboardTextWidgets = 'dashboard-text-widgets',
  DashboardCreateNested = 'dashboard-create-nested',
  DashboardUpdateWidgetFilter = 'dashboard-update-widget-filter'
}
