import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Map } from 'components/Map';
import { RangeSelector, RangeSelectorValue } from 'components/RangeSelector';
import type { SelectedFilterItem } from 'types';
import { CRMBoosterFilters } from 'views/CRMBoosterDetails/CRMBoosterDetails';

import { CRMBoosterFilter } from '../CRMBoosterFilter';
import { mockedMapData } from './mocks';

import styles from './styles.module.scss';

interface Props {
  isActive: boolean;
  defaultFilters: CRMBoosterFilters;
  selectedRegion: SelectedFilterItem;
  setSelectedRegion: Dispatch<SetStateAction<SelectedFilterItem>>;
  selectedEmployeesRange: number[];
  setSelectedEmployeesRange: Dispatch<SetStateAction<number[]>>;
}

export const CRMBoosterDetailsOverviewTab: FC<Props> = ({
  isActive,
  defaultFilters,
  selectedRegion,
  setSelectedRegion,
  selectedEmployeesRange,
  setSelectedEmployeesRange
}) => {
  const { t } = useTranslation();

  const handleSelectEmployeesRange = (
    _: MouseEvent,
    value: RangeSelectorValue
  ) => {
    setSelectedEmployeesRange(value as number[]);
  };

  return (
    <div className={cn(styles.container, isActive && styles.active)}>
      <h2 className={styles.title}>
        {t('Page.CRMBoosterDetails.Tabs.Overview.Title')}
      </h2>

      <CRMBoosterFilter
        placeholder={t(
          'Page.CRMBoosterDetails.Tabs.Overview.Filter.Map.Placeholder'
        )}
        selectedItem={selectedRegion}
        notes={{
          inactive: t(
            'Page.CRMBoosterDetails.Tabs.Overview.Filter.Map.Notes.Inactive'
          ),
          active: t(
            'Page.CRMBoosterDetails.Tabs.Overview.Filter.Map.Notes.Active',
            { region: selectedRegion?.name }
          )
        }}
      />

      <Map
        data={mockedMapData}
        className={styles.map}
        onSelectRegion={setSelectedRegion}
        selectedRegion={selectedRegion}
        disableAllFilter
        disableFoundationYears
      />

      <RangeSelector
        min={defaultFilters.selectedEmployeesRange[0]}
        max={defaultFilters.selectedEmployeesRange[1]}
        value={selectedEmployeesRange}
        label={t('Page.CRMBoosterDetails.Tabs.Overview.Filter.Employees.Label')}
        onChange={handleSelectEmployeesRange}
      />
    </div>
  );
};
